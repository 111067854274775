/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS424P
 * 화면 설명: 전자서명 - 새로운계좌추가
 */
<template>
  <ur-page-container class="msp" title="새로운 계좌 추가" :show-title="true" :topButton="true" type="subpage">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container alignV="start" componentid="" direction="column"  class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
            <div class="info-title-wrap">
              <span class="contain-title must">금융기관</span>
            </div>
            <div class="w100" @click="fn_BottomSheet('open')">
              <mo-text-field class="form-input-name select-st form-used" ref="bankSelect" v-model="bankSelect.label" :rules="requiredRule1" underline placeholder="선택하세요" :readonly="true"/>
            </div>
          </ur-box-container>
          
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap">
              <span class="contain-title must">계좌번호</span>
            </div>
            <mo-text-field type="number" ref="accontNo" maxlength="30" class="form-input-name" v-model="accontNo" :rules="requiredRule2" underline clearable placeholder="계좌번호를 입력하세요"/>
          </ur-box-container>
          
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap mb10">
              <span class="contain-title">예금주</span>
            </div>
            <mo-text-field class="form-input-name" v-model="accountOwner" readonly underline />
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap mb10">
              <span class="contain-title">생년월일</span>
            </div>
            <mo-text-field underline class="form-input-name" readonly v-model="userBirthDay" />
            <button v-show=false ref="validateNewAccntChkBtn" type="submit">validateNewAccntChkBtn</button>
          </ur-box-container>
          <div class="con-area">
            <ul class="terms-list-area crTy-bk7 fs14rem mt10">
              <li>기업은행 평생계좌는 실시간 출금이체가 불가능합니다. 
                <br>타계좌를 입력하여 주시기 바랍니다.</li>
            </ul>
          </div>
          
        </ur-box-container>
      </mo-validate-watcher>
      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <!-- <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$emit('cancelPopup')">이전</mo-button> -->
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-show="addAccnInfo" @click="fn_ValidateNewAccountChk">확인</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //--> 

      <!-- 바텀시트1 : 금융기관 선택시 금융기관선택 팝업호출 -->
      <mo-bottom-sheet ref="bottomSheet1" :close-btn="true" class="ns-bottom-sheet msp-bottomSt-ty4">
        <template v-slot:title>금융기관선택</template>
          <div class="content-area">
            <div class="bottom-fixed full-fixed">
              <mo-text-field ref="searchKeyword" v-model="searchKeyword" searchable underline clearable placeholder="은행/증권사명을 입력하세요" @keyup.enter="fn_SerchClickHander" @click-icon="fn_SerchClickHander" class="full mt0 mb20"/>
              <!-- 탭메뉴 --> 
              <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-segment">
                <mo-segment-wrapper solid primary v-model="tab_index">
                  <mo-segment-button value="1">은행</mo-segment-button>
                  <mo-segment-button value="2">증권사</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
              <!-- 탭메뉴 -->
            </div>

            <ur-box-container alignV="start" componentid="" direction="column" class="bottom-scroll">
              <!-- 탭1 내용: 은행선택 S -->
              <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type full48 mt20 mb20" v-if="tab_index === '1'">
                <mo-button class="ns-btn-check" :class="item.envCntnt === bankSelect.envCntnt ? 'on' : ''" v-for="item in bankList" :key="item.envCntnt" :value="item.envCntnt" @click="fn_SetBnkData(item)">
                  <span class="min160">{{item.label}}</span><span class="crTy-blue3 ml10" v-if="item.style">실시간</span><mo-icon icon="msp-check-bold"/>
                </mo-button>
              </ur-box-container>
              <!-- 탭1 내용: 은행선택 E //-->

              <!-- 탭2 내용: 증권사선택 S -->
              <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type full48 mt20 mb20" v-if="tab_index === '2'">
                <mo-button class="ns-btn-check" :class="item.envCntnt === bankSelect.envCntnt ? 'on' : ''" v-for="item in securitiesList" :key="item.envCntnt" :value="item.envCntnt" @click="fn_SetBnkData(item)">
                  <span class="min160">{{item.label}}</span><span class="crTy-blue3 ml10" v-if="item.style">실시간</span><mo-icon icon="msp-check-bold"/>
                </mo-button>
              </ur-box-container> 
              <!-- 탭2 내용: 증권사선택 E //-->
            </ur-box-container>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
              <div class="relative-div">
                <!-- <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SetBnkCd()" name="확인">확인</mo-button> -->
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      <!-- 바텀시트1 : 금융기관 선택시 금융기관선택 팝업호출 //-->  
    </ur-box-container>    
    <!-- 컨텐츠 영역 //-->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/

import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import moment from 'moment'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS424P', 
  screenId: 'MSPPS424P',

  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    userData1: {type: Object}, // 예금주정보
    inputData: {type: Object}
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return { 
      requiredRule1: [
        v => !!v || '필수입니다. 금융기관을 선택하세요.',
      ],
      requiredRule2: [
        v => !!v || '필수입니다. 계좌번호을 입력하세요.',
      ],
      searchKeyword: '',  //검색어
      addAccnInfo: false, // 신규계좌추가 버튼 Enable(초기값변경 true->false , 20200617 PJO)
      //newAccountChkFlag: false,
      bankList: [], //은행사
      orgBankList: [], //은행사 원본
      securitiesList: [], //증권사
      orgSecuritiesList: [], //증권사 원본
      accountChkData: {}, // 계좌 검증데이터
      psBefrInptInfoSVO: {}, // 계약자 정보 신태블릿(자체) 테이블 업데이트
      alertMessage: '',
      bankSelect: {key: '', label: ''},
      accontNo: '',
      accountOwner: '',
      userBirthDay: '',
      pSNewAccnRegSVO: {},
      rtpBank: ['004', '003', '011', '012', '088', '071', '023', '081', '027', '020', '039', '034', '031', '032', '037', '035', '045', '209', '240',
         '021', '026', '028', '006', '029', '010', '016', '013', '014', '015', '017', '024', '036', '053', '046', '048', '072', '073', '074', '075', '005', '033', '082',
         '007', '090'
      ], // 실시간출금이체가능 은행코드 (합병은행 추가 3/6 김태형)
      
      // 전자서명 시간 확대에 따른 , 전자서명검증예외시간 체크 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
      elstAccnVrfExcpTm: false, // 전자서명검증예외시간
      vSkipStrTm: '', // 전자서명 계좌 검증 예외_시작 시간
      vSkipEndTm: '', // 전자서명 계좌 검증 예외_종료 시간
      currTm: '', // 현재시간
      tab_index: '1', //탭 초기값 1:은행 2:증권사
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.fn_ServiceCall('txTSSAD02S3', 'ind')  // 은행목록조회

    //생년월일 예금주
    this.userBirthDay = PSDateUtil.getFullBirthDate(this.userData1.custRrnId, 'yyyy.MM.dd')
    this.accountOwner = this.userData1.detail.custNm

    //전자서명검증 예외시간 확인 ->  신규계좌등록버튼 보일지 여부 판단
    this.fn_ServiceCall2() // 전자서명 계좌 검증 예외 시간 확인 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    //focus
    this.fn_BottomSheet('open')
    //this.$refs.accontNo.focus()
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_ValidateNewAccountChk
    * 설명       : 계좌정보 초기화
    ******************************************************************************/
    fn_Init () { // 새로운 계좌 추가 창에 있는 닫기 버튼 클릭 시 수행
      this.addAccnInfo = !this.elstAccnVrfExcpTm // 전자서명 계좌 검증 예외시간이 아닌 경우만 계좌등록가능  (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
      this.accontNo = ''
      this.bankSelect = {key: '', label: ''}
    },
    /******************************************************************************
    * Function명 : fn_ValidateNewAccountChk
    * 설명       : 새로운 계좌 정보 입력 후 '확인' 버튼 클릭 시 수행
    ******************************************************************************/
    fn_ValidateNewAccountChk () {
      //this.$refs.vWatcher.clearValidation()
      let tApcPrson = []
      let tApcPrsons = []
      let tbankSelect = this.$refs['bankSelect'].isValid()
      tApcPrson.push(tbankSelect)
      if(!tbankSelect){
        tApcPrsons.push('bankSelect')
      }

      let taccontNo = this.$refs['accontNo'].isValid()
      tApcPrson.push(taccontNo)
      if(!taccontNo){
        tApcPrsons.push('accontNo')
      }
      
      if(tApcPrsons.length > 0){
        for(let i = 0; i < tApcPrsons.length; i++){
          if(tApcPrsons[i] === 'bankSelect'){
            this.fn_BottomSheet('open')
            break
          }
          if(tApcPrsons[i] === 'accontNo'){
            this.$refs[tApcPrsons[i]].focus() 
            break
          }
        }  
      }

      let allChkVld = []
      allChkVld = allChkVld.concat(tApcPrson)

      let allChk = allChkVld.some((item) =>
      {      
      if ( !item )
        {
          return true
        }
      })
      
      if(allChk) return

      if (this.bankSelect.label === '') {
        this.getStore('confirm').dispatch('ADD', '금융기관 항목은 필수 정보입니다.')
        return
      }
      if (this.accontNo === '') {
        this.getStore('confirm').dispatch('ADD', '계좌번호 항목은 필수 정보입니다.')
        return
      }
      //let result = this.$refs.vWatcher.validate()
      //if (result !== true) return

      // 신규계좌 검증
      this.accountChkData = {
        BUKRS: 'I100',
        ZA_TLGM_DTL_DMN_BNK_CD: this.bankSelect.envCntnt,
        ZA_TLGM_RECI_ACCN_NO: this.accontNo.replace(/\-|\s/g, ''),
        ZA_DPST_PESON_NM: this.accountOwner,
        ZA_DPST_PESON_NO: this.userBirthDay.replace(/\./g, '').substring(2, 8)
      }
      this.accontNo = this.accontNo.replace(/\-|\s/g, '')
      // this.serviceCall('txTSSPS13K1', 'di') // 위치이동 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
      //this.newAccountChkFlag = true
      // 현재시간체크 후 진행 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
      let t_This = this
      this.$commonUtil.getServerTime().then(function (response) {
        t_This.currTm = moment(response).format('HHmmss') // 서버시간( return 값. 170838 )
        t_This.fn_ChkSkipTime('accAdd', t_This.currTm)
      })
      
    },
    /******************************************************************************
    * Function명 : fn_NewAccountInsert
    * 설명       : 신규계좌 저장
    ******************************************************************************/
    fn_NewAccountInsert () {
      this.pSNewAccnRegSVO = {
        custScCd: '1',
        trtrEno: this.userData1.detail.cnsltNo,
        custNm: this.accountOwner,
        regNo: this.userData1.custRrnId,
        bnkCd: this.bankSelect.envCntnt,
        bnkbAccnNo: this.accontNo.replace(/\-|\s/g, ''),
        dpstrNm: this.accountOwner,
        accnTrtPathCd: '01',
        dpstrCnfYn: 'Y',
        dpstrChkCd: '1',
        trtSystmPathCd: '22', // 처리시스템경로코드
        busnTrtPathCd: '2210' // 업무처리경로코드
      }
      this.fn_ServiceCall('txTSSPS13I1', 'di')
    },
    /******************************************************************************
    * Function명 : fn_ServiceCall2
    * 설명       : 전자서명 계좌 검증 예외 시간 조회
    ******************************************************************************/
    // txTSSAD02S3 업무환경그룹 상세검색
    // (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
    fn_ServiceCall2 () {
      var t_This = this
      let pParams = {}
      t_This.vSkipStrTm = ''
      t_This.vSkipEndTm = ''
      let btnId = 'S'
      pParams = {envId: 'ELST_ACCN_VRF_EXCP_TM'} // 전자서명 계좌 검증 예외 시간
      /*
      .그룹 명/ID  : 전자서명 확대시간 / ELST_ACCN_VRF_EXCP_TM
      .설명: 상세 이름/  값
      1) 시작시간 : STR_TM / 233000
      2) 종료시간 : END_TM / 073000
      */
      this.post(t_This, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            // 전자서명 계좌 검증 예외 시간 리스트 존재, ELST_ACCN_VRF_EXCP_TM
            let vList = response.body.returnDtlList
            if ( vList.length > 0 ) {
              for (const idx in vList) {
                const item = vList[idx]
                let o = {}
                o = Object.assign({}, item)
                o.label = item.smoUnitl // ID  (STR_TM ~ END_TM)
                if (o.label === 'STR_TM' ) { // 시작시간
                  t_This.vSkipStrTm = item.envCntnt // 값 (230000 ~ 070000)
                }
                if (o.label === 'END_TM' ) { // 종료시간
                  t_This.vSkipEndTm = item.envCntnt // 값 (230000 ~ 070000)
                }
              } // for~
            } else {
              console.log('list 값 없읍!')
            }
          }
          t_This.$commonUtil.getServerTime().then(function (response) {
            t_This.currTm = moment(response).format('HHmmss') // 서버시간( return 값. 170838 )
            t_This.fn_ChkSkipTime('init', t_This.currTm)
          })
        })
        .catch(function (error) {
          console.log('■■■ fn_ServiceCall2_error.......')
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_ChkSkipTime
    * 설명       : 전자서명 계좌 검증 예외 시간일경우 체크
    ******************************************************************************/
    // 전자서명 계좌 검증 예외 시간일경우 체크 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
    // mode : init 화면초기진입시점 , accChk 계좌체크(예금주체크), accAdd  계좌등록
    // vCurrTm :  서버시간 , 170838 (HHmmss)
    fn_ChkSkipTime (mode, vCurrTm) {
      let t_This = this
      t_This.currTm = vCurrTm
      // 시작시간이 현재시간이후이면 true ,  종료시간이 현재시간이전이면 true
      // ex) 233000 <= 233000 || 073000 >= 233000  : true
      // ex) 233000 <= 100000 || 073000 >= 100000  : false
      // console.log('------cvCurrTm:' + vCurrTm)
      // t_This.currTm = '000100' // test
      if ( t_This.vSkipStrTm <= t_This.currTm || t_This.vSkipEndTm >= t_This.currTm ) { // 전자서명 계좌 검증 예외 시간이면 신규계좌추가 버튼 안보이기
        t_This.elstAccnVrfExcpTm = true // 전자서명 계좌 검증 예외 시간 true
        t_This.addAccnInfo = false // 신규계좌추가버튼 안보이고
      } else {
        t_This.elstAccnVrfExcpTm = false // 전자서명 계좌 검증 예외 시간 false
        t_This.addAccnInfo = true
      }

      let vLogTitle = '' // 다이나트레이스 로그 title
      let vLog = '' // 다이나트레이스 로그
      // 영수증번호
      let tmpVuchId = ''
      if (t_This.inputData.moDCDocuMngVO.eltrnDoctId !== 'undefined' && t_This.inputData.moDCDocuMngVO.eltrnDoctId !== undefined) {
        tmpVuchId = t_This.inputData.moDCDocuMngVO.eltrnDoctId
      }
      if ( mode === 'init') { // init 화면초기진입시점
        vLogTitle = '142P_초기진입시점'
      } else if ( mode === 'accAdd') { // accAdd 계좌등록
        vLogTitle = '142P_신규계좌추가'
        if ( t_This.elstAccnVrfExcpTm ) { // 전자서명 계좌 검증 예외 시간 true
          // t_This.alertMessage = '신규계좌 등록 불가시간입니다.'
          // t_This.alert = true
          vLog += ',예금주체크_SKIP'
          t_This.fn_Init() // 새로운 계좌 추가 창에 있는 닫기 버튼 클릭 수행
        } else {
          vLog += ',예금주체크_진행'
          t_This.fn_ServiceCall('txTSSPS13K1', 'di') // 예금주체크 후 정상이면 -> 계좌등록(txTSSPS13K1)
        }
      }
      vLog += ',현재시간:[' + t_This.currTm + ']'
      vLog += ',전자서명확대시간:[' + t_This.vSkipStrTm + ']이후~[' + t_This.vSkipEndTm + ']이전'
      vLog += ',계좌검증예외여부:[' + t_This.elstAccnVrfExcpTm + ']'
      vLog += ',신규계좌등록가능여부:[' + t_This.addAccnInfo + ']'
      // ASR220600423_사랑온 가설 Dynatracae 연계 라이브러리 수정_20220621
      // PSServiceManager.setDynaTraceLog( vLogTitle, vLog) // event명, vLog
      
      PSServiceManager.setDynaTraceLog(vLogTitle, vLog, 'PS424P', tmpVuchId)
    },
    /******************************************************************************
    * Function명 : fn_ServiceCall
    * 설명       : 서비스 호출 함수
    ******************************************************************************/
    fn_ServiceCall (trnstId, serviceDiv) {
      var lv_Vm = this
      let pParams = {}
      let btnId = 'S'
      let serviceName = ''

      switch (serviceDiv) {
        case 'di':
          switch (trnstId) {
            case 'txTSSPS13K1': // 계좌검증(예금주 체크)
              serviceName = 'K'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.eai'
              pParams.trnstId = 'txTSSPS13K1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1CDE0001_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDE0001ReqVO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDE0001ResVO'
              pParams.data = this.accountChkData
              break
            case 'txTSSPS13I1': // 고객계좌 등록
              serviceName = 'I'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.eai'
              pParams.trnstId = 'txTSSPS13I1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0009_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0009VO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0009VO'
              pParams.data = this.pSNewAccnRegSVO
              break
            default:
              break
          }
          PSServiceManager.invoke(pParams, lv_Vm.fn_ServiceResultHandler, btnId, lv_Vm.fn_ServiceFaultHandler)
          break

        /********************************************************
        * 자바서비스 호출
        ********************************************************/
        case 'ind':
          switch (trnstId) {
            case 'txTSSAD02S3':
              pParams = {envId: 'PS_BNK_CD'} // 은행목록
              break
            default:
              break
          }

          this.post(lv_Vm, pParams, trnstId, btnId)
            .then(function (response) {
              // 서버 데이터 가져오기
              switch (trnstId) {
                case 'txTSSAD02S3': // 은행리스트 조회
                  if (response.body !== null) {
                    let resultBankList = response.body.returnDtlList
                    let rtpBankCnt = lv_Vm.rtpBank.length

                    lv_Vm.bankList = []
                    if ( resultBankList.length > 0 ) {
                      for (const idx in resultBankList) {
                        const item = resultBankList[idx]
                        let o = {}
                        o = Object.assign({}, item)
                        o.key = idx
                        o.label = item.smoUnitl
                        o.style = false
                        for (let k = 0; k < rtpBankCnt; k++) {
                          if (o.envCntnt === lv_Vm.rtpBank[k]) {
                            o.style = true
                            break
                          }
                        }

                        if (o.envCntnt.startsWith('0')) { //0으로 시작하면 은행
                          lv_Vm.bankList.push(o)
                        }
                        else {  //2로 시작하면 증권사
                          lv_Vm.securitiesList.push(o)
                        }
                      }
                    }
                    
                    lv_Vm.orgBankList = lv_Vm.bankList
                    lv_Vm.orgSecuritiesList = lv_Vm.securitiesList

                    //lv_Vm.addAccnInfo = false
                    //lv_Vm.accontNo = ''
                    //lv_Vm.bankSelect = {key: '', label: ''}
                  }
                  break
                default:
                  break
              }
            })
            .catch(function (error) {
              //lv_Vm.error(error)
              window.vue.error(error)
            })
          break
        default:
          break
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, pParams, moreData) {
      let trnstId = lv_Vm.common.fahrTrnId

      if (lv_Vm.data !== null) {
        switch (trnstId) {
          case 'txTSSPS13K1': // 예금주체크
            if (lv_Vm.data.za_RFND_YN === 'Y') {
              this.fn_NewAccountInsert() //신규계좌 검증
            } else {
              this.alertMessage = lv_Vm.data.natxt
              this.getStore('confirm').dispatch('ADD', this.alertMessage)
            }
            break
          case 'txTSSPS13I1': // 계좌 등록
            if (lv_Vm.data.custAccnTrtRsltVal !== 'S') { // 'S':정상 'E':비정상 - 저장시 유효계좌인지(?) 체크해서 결과값리턴해줌(ERP)
              this.alertMessage = lv_Vm.data.trtRsltCntnt
              this.getStore('confirm').dispatch('ADD', this.alertMessage)
            } else {
              let o = {
                accnId: lv_Vm.data.accnId,
                accnNo: lv_Vm.data.bnkbAccnNo,
                dpstrNm: lv_Vm.data.dpstrNm,
                bnkCdNm: this.bankSelect.label,
                bnkCd: lv_Vm.data.bnkCd,
                custId: lv_Vm.data.custID
              }
              this.$emit('confirmPopup', o)
            }
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ServiceFaultHandler
    * 설명       : 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler (event) {
      if (event.msgCd === 'EEAC928') {
        event.msgDesc = event.msgDesc.substring(event.msgDesc.indexOf('은행'), event.msgDesc.length)
      }
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /******************************************************************************
    * Function명  : fn_SetBnkData
    * 설명        : 은행/증권사 탭 전환
    ******************************************************************************/
    fn_tabIdx (str) {
      this.tab_index = str
      this.$refs.tab_index.setIdx(str)
    },

    /******************************************************************************
    * Function명  : fn_SetBnkData
    * 설명        : 은행/증권사 BottomSheet 제어
    ******************************************************************************/
    fn_BottomSheet(action) {
      if (action === 'open') {
        this.$refs.bottomSheet1.open()
      }
      else {
        this.$refs.bottomSheet1.close()
        //focus 
        if(this.bankSelect.label !== ''){
          this.$refs.accontNo.focus()
        }
      }
    },

    /******************************************************************************
    * Function명  : fn_SerchClickHander
    * 설명        : 검색버튼 핸들러
    ******************************************************************************/
    fn_SerchClickHander() {
      let bankArray = []
      let securitiesArray = []
      this.orgBankList.forEach(item => {
        if (item.label.indexOf(this.searchKeyword.trim()) > -1) {
          bankArray.push(item)
        }
      })
      this.orgSecuritiesList.forEach(item => {
        if (item.label.indexOf(this.searchKeyword.trim()) > -1) {
          securitiesArray.push(item)
        }
      })

      this.bankList = bankArray
      this.securitiesList = securitiesArray    
    },

    /******************************************************************************
    * Function명  : fn_SetBnkData
    * 설명        : 은행/증권사코드 셋팅하고 BottomSheet 닫기
    ******************************************************************************/
    fn_SetBnkData(param) {
      this.bankSelect = {
        envCntnt : param.envCntnt,
        label : param.label
      }
      this.fn_BottomSheet('close')
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>