/*
 * 업무구분: 청약
 * 화 면 명: MSPPS424D
 * 화면설명: 전자서명 - 선심사정보조회
 * 접근권한: 
 * 작 성 일: 2024.02
 * 작 성 자: jiyun23.jeong
 */
<template>
  <ur-box-container direction="column" alignV="start" class="msp-ps-wrap" componentid="ur_box_container_010">
    <div class="ns-sliding-topbar">
      <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

        <!-- scroll 영역 -->
        <template #scroll>            
          <mo-tab-box :defaultIdx="defaultIdxs" @tab-change="fn_TabChangeHandler" ref="tabbox"  class="ns-move-tab-box">
            <mo-tab-content :idx="1" label="사전정보" ></mo-tab-content>
            <mo-tab-content :idx="2" label="질병정보" ></mo-tab-content>
          </mo-tab-box>
          <!-- 사전정보 -->
          <MSPPI802D v-show="slctTabIdx === 1"
                    :mnisdCustInfo="lv_MnisdCustInfo"
                    :formId="lv_FormId"
                    :befrInfoDto="lv_BefrInfoDto"
                    :isReadOnly="true"
                     />
          <!-- 질병정보 -->
          <MSPPI810D v-show="slctTabIdx === 2"
                    :diseDtlDto="lv_DiseDtlDto"
                    :formId="lv_FormId"
                    :isReadOnly="true" />

          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="$emit('move', 'previous')">이전</mo-button>
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_nextBtn()" :disabled="nextBtnFlag">다음</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-collapsing-header-view>
    </div>
  </ur-box-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPPI802D from '@/ui/pi/MSPPI802D'
import MSPPI810D from '@/ui/pi/MSPPI810D'
import MSPPS425P from '@/ui/ps/MSPPS425P'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS424D', 
  screenId: 'MSPPS424D',

  components: {
      'MSPPI802D' : MSPPI802D,
      'MSPPI810D' : MSPPI810D
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    commData: {type: Object}, // 계약자정보
    scrollId: {type: String}
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return { 
      slctTabIdx : 1, // 선택 탭 ( 1:사전정보, 2:질병정보 )
      defaultIdxs : 1,
      
      eltrnDoctId: '', // 영수증번호
      lv_DiseDtlDto: [], // 조회한 질병정보 데이터
      lv_BefrInfoDto: {}, // 조회한 사전정보입력 데이터
      lv_MnisdCustInfo: {}, // 지급정보동의 고객정보 - 주피보험자
      lv_FormId: '', // 사전정보입력 formID
      lv_NundwJdgPrgStatCd: '', // 선심사진행상태코드
      
      nundwOpin: '', // 추가정보내용
      nundwNrmYn: '', // 입력정보일치여부

      currDt: '', // 현재날짜
      holyDayChkDateArry: [],
      alertMessage: '',
      nextBtnFlag: false
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.fn_Init()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    // 스크롤 맨 위로
    PSCommUtil.fn_ScrollTopForScrollId(this.scrollId)
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // fn_TabChangeHandler
    this.fn_TabChangeHandler(this.defaultIdxs)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 초기화
    ******************************************************************************/
    fn_Init: function () {
      // 선심사 정보 dto
      this.currDt = PSDateUtil.fn_CurrentDate() // 현재날짜 (20211221_PJO)

      // 영수증번호
      let moDCDocuMngVO = JSON.stringify(this.commData.inputData.moDCDocuMngVO)
      this.eltrnDoctId = JSON.parse(moDCDocuMngVO).eltrnDoctId

      if (this.commData.nundwInfoTrtDto.hasOwnProperty('befrInfoDto')) {
        // 사전정보 관련 세팅
        this.lv_BefrInfoDto = JSON.parse(this.commData.nundwInfoTrtDto.befrInfoDto.dsclsInptInfoCntnt) // 조회한 사전정보 데이터
        this.lv_FormId = this.lv_BefrInfoDto.pvmnotice.formData[0].formId // 사전정보입력 formID
        this.lv_MnisdCustInfo = JSON.stringify(this.commData.nundwInfoTrtDto.pymBrkdnCnsntInfoTrtDto) // 지급정보동의 고객정보 - 주피보험자
        //this.lv_NundwJdgPrgStatCd = JSON.parse(this.commData.nundwInfoTrtDto.nundwJdgPrgStatCd) // 선심사진행상태코드
      }
      if (this.commData.nundwInfoTrtDto.hasOwnProperty('diseDtlDto')) {
        // 질병정보 관련 세팅
        let diseDtlDto = JSON.stringify(this.commData.nundwInfoTrtDto.diseDtlDto) // 조회한 질병정보 데이터
        this.lv_DiseDtlDto = JSON.parse(diseDtlDto)
      }
    },
    /******************************************************************************
    * Function명 : fn_nextBtn
    * 설명       : 추가정보입력 알럿
    ******************************************************************************/
    fn_nextBtn() {
      let lv_Vm = this
      let backMsg = "사전정보와 질병정보를 모두 확인 후 입력정보가<br>일치하지 않는 경우 '추가정보 입력' 버튼을 눌러<br>추가정보 내용을 작성해주세요."
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          showCloseBtn: true,
          content: backMsg,
          title_pos_btn: "확인",
          title_neg_btn: "추가정보입력"
        },
        listeners: {
          // 확인 btn
          onPopupConfirm: () => {
            lv_Vm.$bottomModal.close(lv_AlertPop)
            this.nundwNrmYn = 'Y' // 입력정보일치여부
            setTimeout(() => {
              this.fn_CheckYmd()
            }, 500)
          },
          // 추가정보입력 btn
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_AlertPop)
            setTimeout(() => {
              this.fn_openMSPPS425P() // 추가정보입력팝업
            }, 500)
          },
          // X btn
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_AlertPop)
          }
        }
      },
      {properties: {preventTouchClose : false}
      })
    },
    /******************************************************************************
    * Function명 : fn_openMSPPS425P
    * 설명       : 추가정보입력
    ******************************************************************************/
    fn_openMSPPS425P() {
      // 추가정보입력팝업
      const pop = this.$bottomModal.open(MSPPS425P, {
        properties: {
        },
        listeners: {
          closePopup: (nundwOpin) => {
            this.nundwOpin = nundwOpin // 추가정보내용
            this.nundwNrmYn = 'N' // 입력정보일치여부
            this.$bottomModal.close(pop)
            setTimeout(() => {
              this.fn_CheckYmd()
            }, 500)
          },
          onlyClosePopup: () => {
            this.$bottomModal.close(pop)
          }
        }
      },
      {properties: {preventTouchClose : false}
      })
    },
    /******************************************************************************
    * Function명 : fn_ServiceCall
    * 설명       : 서비스 호출 함수
    ******************************************************************************/
    fn_ServiceCall (trnstId) {
      var lv_Vm = this
      let pParams = {}
      let btnId = 'S'
      let serviceName = ''

      switch (trnstId) {
        case 'txTSSPS13S9': // 191111, 현재날짜 +10일까지 날짜정보 조회 : 실시간이체일경우 +1영업일 조회용
              pParams.appId = 'sli.ic'
              pParams.trnstId = ''
              pParams.fnctScCd = 'S'
              pParams.serviceId = ''
              pParams.trnstId = 'txTSSPS13S9'
              pParams.reqSrvcNm = 'ICCommMknoCtlImpl'
              pParams.reqMthdNm = 'selListYmdInfo'
              pParams.reqVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
              pParams.resVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              // pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), +1)}
              pParams.data = {slrcYmYmd: PSDateUtil.addDate(this.currDt, +1)} // 현재일자변수화(20211221_PJO)
              break
        default:
          break
      }

      PSServiceManager.invoke(pParams, lv_Vm.fn_ServiceResultHandler, btnId, lv_Vm.fn_ServiceFaultHandler)
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, pParams, moreData) {
      let trnstId = lv_Vm.common.fahrTrnId

      if (lv_Vm.data !== null) {
        switch (trnstId) {
          case 'txTSSPS13S9': // 191111, 현재날짜 +10일까지 날짜정보 조회 : 실시간이체일경우 +1영업일 조회용
            if (lv_Vm.data !== null && lv_Vm.data.icymdMngVO.length > 0) {
              this.holyDayChkDateArry = lv_Vm.data.icymdMngVO
            }
            this.nextLevel()
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_CheckYmd
    * 설명        : 영업일 조회
    ******************************************************************************/
    fn_CheckYmd () {
      this.fn_ServiceCall('txTSSPS13S9') // 영업일 조회
    },
    nextLevel () {
      // 191111 : alert6 알림팝업 삭제 : 장유휘 프로 요청
      //        : 인증 순서 및 여부와 상관업시 초회출금 실시간 자동이체 선택시에만 나타남
      //        : 초회출금 실시간 자동이체 선택 + 계좌정보 입력에서 [다음] 선택시 표시
      //        : [본 전자서명의 계약일자는 **년 **월 **일 입니다. **년 **월 **일 23:00까지만 청약 접수가 가능합니다 (확인)]
      if (this.commData.frtmPrmPadMthCd === '1'|| this.commData.frtmPrmPadMthCd === '9') {  // 계좌이체 및 카드납인 경우 알림창 띄움. - 1 RT, 4 가상계좌, F 간편납입, 9 신용카드
          let curYmd = this.currDt // PSDateUtil.fn_CurrentDate() // 현재일자변수화(20211221_PJO)
          let nxtSalesYmd = this.currDt // PSDateUtil.fn_CurrentDate() // 현재일자변수화(20211221_PJO)

          let i = 0
          let holyDayLen = this.holyDayChkDateArry.length
          for ( i; i < holyDayLen; i++ ) {
            // +1영업일 조회
            if ( this.holyDayChkDateArry[i].holyCd === '00') {
              nxtSalesYmd = this.holyDayChkDateArry[i].slrcYmYmd
              break
            }
          }
          // 191209 : 장유휘 프로
          //        : 회계년도마감으로 청약접수일자 표시 팝업 고정
          // 계약일자가 12월, 청약접수 가능일자가 이듬해 1월일 경우, 청약접수가능일을 해당년의 마지막날(12월 31일)로 고정
          // EX ) 본 전자서명의 계약일자는 2019년 12월 31일입니다. 2019년 12월 31일 23:00까지만 청약접수가 가능합니다  // 20:00 ->23:00 ASR201200613_회계년도 마감_사랑온변경(20201222 PJO)
          //
          // ASR211200170_회계년도 마감 관련 시스템 변경사항_가입설계(도유림 / 20211221_PJO)
          // 1) +1영업일이 다음년도일 경우
          // 본 전자서명의 계약일자는 2021년 12월 31일 입니다.
          // 회계연도 마감 등 대내외 정책 변경에 따라 2021년   12월 31일 23:00까지 청약 접수/출금  가능합니다.
          // 2) 그외
          // 본 전자서명의 계약일자는 2021년 12월 30일입니다. 2021년 12월 31일 23:00까지만 청약접수가 가능합니다
          /* ASR221200180_회계년도변경시 별도 로직제거 ( 이현수 / 221220_PJO)
          if ( curYmd.substr(0, 4) !== nxtSalesYmd.substr(0, 4)) {
            nxtSalesYmd = curYmd.substr(0, 4) + '1231'
            this.alert7Message = '본 전자서명의 계약일자는 ' + curYmd.substr(0, 4) + '년 ' + curYmd.substr(4, 2) + '월 ' + curYmd.substr(6, 2) + '일 입니다.\n\n' + '회계연도 마감 등 대내외 정책 변경에 따라\n\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지 청약 접수/출금 가능합니다.'
          } else {
            this.alert7Message = '본 전자서명의 계약일자는 ' + curYmd.substr(0, 4) + '년 ' + curYmd.substr(4, 2) + '월 ' + curYmd.substr(6, 2) + '일 입니다.\n\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지만 청약접수가 가능합니다.'
          }
          */
          this.alertMessage = '본 전자서명의 계약일자는 ' + curYmd.substr(0, 4) + '년 ' + curYmd.substr(4, 2) + '월 ' + curYmd.substr(6, 2) + '일 입니다.<br /><br />' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지만 청약접수가 가능합니다.'
          this.fn_BottomAlert(this.alertMessage)
      } else {
        this.nextLevelMove(event)
      }
    },
    nextLevelMove (event) {      
      // 선심사 적합여부 및 미적합사유 입력 정보 저장
      this.nundwOpin = (this.$bizUtil.isEmpty(this.nundwOpin)) ? ' ' : this.nundwOpin
      this.$emit('move', 'nundw', {'nundwNrmYn': this.nundwNrmYn, 'nundwOpin': this.nundwOpin})
      
      // TTSD00010 테이블에 저장
      let auth = 'I'
      let trnstId = 'txTSSPS99I6'
      let param = {}

      param.vuchId = this.eltrnDoctId // 영수증번호
      param.nundwNrmYn = this.nundwNrmYn // 입력정보일치여부
      param.nundwOpin = this.nundwOpin // 추가정보내용

      this.post(this, param, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            let ret = response.body
            if (ret.retYn === 'N') {
              console.log('오류발생')
            }
          }
        })

    },
    /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업 - TSSPS141D의 fdp-confirm7 컨버전
    ******************************************************************************/
    fn_BottomAlert (pContent) {
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.nextLevelMove()
            this.$bottomModal.close(lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },
    /******************************************************************************
    * Function명  : fn_TabChangeHandler
    * 설명        : fn_TabChangeHandler
    ******************************************************************************/
    fn_TabChangeHandler(idx){      
      this.slctTabIdx = idx
    }
 },// methods

}//export default


</script>