/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS426P
 * 화면 설명: 전자서명 - 새로운카드추가
 */
<template>
  <ur-page-container class="msp" title="새로운 카드 추가" :show-title="true" :topButton="true" type="subpage">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" class="msp-pm-wrap">
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container componentid="" direction="column"  class="ns-add-area">
          <ur-box-container componentid="" direction="column" class="ns-input-box mt30">
            <div class="info-title-wrap">
              <span class="contain-title must">금융기관</span>
            </div>
            <div class="w100" @click="fn_BottomSheet('open')">
              <mo-text-field class="form-input-name select-st" v-model="cardSelect.name" :rules="requiredRule1" underline placeholder="선택하세요" :readonly="true"/>
            </div>
          </ur-box-container>
<!--           
          <ur-box-container componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap">
              <span class="contain-title must">카드번호</span>
            </div>
            <mo-text-field type="number" maxlength="30" class="form-input-name" v-model="cardNo" :rules="requiredRule2" underline clearable placeholder="카드번호를 입력해주세요"/>
          </ur-box-container> -->
          
          <ur-box-container componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap">
              <span class="contain-title must">카드번호</span>
            </div>
            <mo-text-field type="number" maxlength="16"  class="form-input-name" v-model="cardNo" underline placeholder="카드번호를 입력해주세요" :rules="requiredRule2" error-message="카드번호 항목은 필수 정보입니다."/>
          </ur-box-container>

          <ur-box-container componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap mb10">
              <span class="contain-title">카드주</span>
            </div>
            <mo-text-field class="form-input-name" v-model="cardOwner" underline :disabled="true"/>
          </ur-box-container>
          <ur-box-container componentid="" direction="column" class="ns-input-box mt50">
            <div class="info-title-wrap mb10">
              <span class="contain-title must">유효년월</span>
            </div>
            <div class="fexTy1 full">
              <mo-text-field type="number" maxlength="2"  class="form-input-name" v-model="cardMm" underline placeholder="mm"  :rules="requiredRule3" />
              <span class="mt10 ml10 mr10 fs17rem">/</span>
              <mo-text-field type="number" maxlength="4"  class="form-input-name" v-model="cardYyyy" underline placeholder="yyyy"  :rules="requiredRule4" />
            </div>
          </ur-box-container>
          
        </ur-box-container>
      </mo-validate-watcher>
      <!-- 하단 버튼영역 -->
      <ur-box-container componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <!-- <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$emit('cancelPopup')">이전</mo-button> -->
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ValidateNewCardChk">확인</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //--> 

      <!-- 바텀시트1 : 금융기관 선택시 금융기관선택 팝업호출 -->
      <mo-bottom-sheet ref="bottomSheet1" :close-btn="true" class="ns-bottom-sheet msp-bottomSt-ty4">
        <template v-slot:title>금융기관선택</template>
          <div class="content-area">
            <ur-box-container componentid="" direction="column">
              <!-- 탭1 내용: 금융기관 선택  -->
              <ur-box-container componentid="" direction="column" class="btn-list-type full48 mb20">
                <mo-button class="ns-btn-check" :class="item.name === cardSelect.name ? 'on' : ''" v-for="item in CARD_PAYMENT_LIST" :key="item.code" :value="item.name" @click="fn_SetCardData(item)">
                  <span class="min160">{{item.name}}</span><mo-icon icon="msp-check-bold"/>
                </mo-button>
              </ur-box-container>
              <!-- 탭1 내용: 금융기관 E //-->
            </ur-box-container>
          </div>
          <template v-slot:action>
            <ur-box-container componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
              <div class="relative-div">
                <!-- <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SetBnkCd()" name="확인">확인</mo-button> -->
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      <!-- 바텀시트1 : 금융기관 선택시 금융기관선택 팝업호출 //-->  
    </ur-box-container>    
    <!-- 컨텐츠 영역 //-->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS426P', 
  screenId: 'MSPPS426P',

  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    userData1: {type: Object}, // 예금주정보
    CARD_PAYMENT_LIST: {type: Array} // 등록가능한 카드사 리스트
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return { 
      pSNewCardRegSVO: {},
      cardNo: '', // 입력.카드번호
      cardOwner: '', // 입력.고객명
      cardMm: '', // 입력.유효월
      cardYyyy: '', // 입력.유효년

      requiredRule1: [
        v => !!v || '금융기관 항목은 필수 정보입니다.',
      ],
      requiredRule2: [
        v => !!v || '카드번호 항목은 필수 정보입니다.',
      ],
      requiredRule3: [
        v => !!v || '유효월 항목은 필수 정보입니다.',
      ],
      requiredRule4: [
        v => !!v || '유효년 항목은 필수 정보입니다.',
      ],
      searchKeyword: '',  //검색어
      addAccnInfo: false, // 신규계좌추가 버튼 Enable(초기값변경 true->false , 20200617 PJO)

      //newAccountChkFlag: false,
      cardList: [], //카드사
      orgCardList: [], //카드사 원본

      // accountChkData: {}, // 계좌 검증데이터
      // psBefrInptInfoSVO: {}, // 계약자 정보 신태블릿(자체) 테이블 업데이트

      alertMessage: '',
      cardSelect: { code: '', name: ''},
      // userBirthDay: '',
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.cardOwner = this.userData1.detail.custNm
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    // window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_ValidateNewCardChk
    * 설명       : 계좌정보 초기화
    ******************************************************************************/
    fn_Init () { // 새로운 계좌 추가 창에 있는 닫기 버튼 클릭 시 수행
      this.cardNo = ''
      this.cardSelect = {code: '', name: ''}
    },
    /******************************************************************************
    * Function명 : fn_ValidateNewCardChk
    * 설명       : 새로운 계좌 정보 입력 후 '확인' 버튼 클릭 시 수행
    ******************************************************************************/
    fn_ValidateNewCardChk () {
      this.$refs.vWatcher.clearValidation()
      
      if (this.cardSelect.name === '' || this.cardSelect.code === '' ) {
        this.getStore('confirm').dispatch('ADD', '금융기관 항목은 필수 정보입니다.')
        return
      }
      if (this.cardNo === '') {
        this.getStore('confirm').dispatch('ADD', '카드번호 항목은 필수 정보입니다.')
        return
      }
      // 월
      if (this.cardMm === '') {
        this.getStore('confirm').dispatch('ADD', '유효년월(월) 항목은 필수 정보입니다.')
        return
      }
      if (this.cardMm.length !== 2) {
        this.getStore('confirm').dispatch('ADD', '유효년월(월) 2자리 입력하세요.')
        return
      }
      let mmArray = ['01','02','03','04','05','06','07','08','09','10','11','12']
      if ( !mmArray.includes ( this.cardMm )) {
        this.getStore('confirm').dispatch('ADD', '유효년월(월) 01~12월 사이로 입력하세요.')
        this.cardMm = ''
        return
      }
      // 년
      if (this.cardYyyy === '') {
        this.getStore('confirm').dispatch('ADD', '유효년월(년) 항목은 필수 정보입니다.')
        return
      }
      if (this.cardYyyy.length !== 4) {
        this.getStore('confirm').dispatch('ADD', '유효년월(년) 4자리 입력하세요.')
        return
      }

      let currDt = PSDateUtil.fn_CurrentDate()
      if ( currDt.substring(0,6) > this.cardYyyy+''+this.cardMm  ) {
        this.getStore('confirm').dispatch('ADD', '유효년월 확인하세요.(과거)')
        return
      }

      this.cardNo = this.cardNo.replace(/\-|\s/g, '')
      this.cardAvlYm = this.cardYyyy+''+this.cardMm // 카드유효년월 (YYYYMM)
      this.fn_NewCardInsert()
    },
    /******************************************************************************
    * Function명 : fn_NewCardInsert
    * 설명       : 신규카드 저장
    ******************************************************************************/
    fn_NewCardInsert () {

      // if ( !this.fn_ValidateNewCardChk ) return

      // MSPPS423D_commData.dpstrInfo  -> MSPPS426P.userData1
      this.pSNewCardRegSVO = {
        custId : this.userData1.partnerId, // (10)고객ID
        cardId : '', // (6 )지급카드 ID / 등록시 빈값
        custNm : this.cardOwner ,// (40)고객명 / commData.dpstrInfo.detail.custNm
        knbScCd : '' ,// (6 )식별번호 타입 / 사용하는곳 없고, 빈값으로 보내도 정상등록됨 안보냄 / ZPER01 주민등록번호 , ZPER02 외국인/재외국민주민등록번호
        regNo : this.userData1.custRrnId ,// (60)식별번호  / userData1.custRrnId
        cardType : this.fn_SetCardType(this.cardSelect) ,// (4 )지급카드 유형 / 0001 삼성카드,0002 현대카드,0003 비씨카드,9999 기타
        cardNum : this.cardNo ,// (25)지급 카드: 카드 번호
        cardNmChkYn : 'Y' ,// (1 )카드주체크여부 / 카드주체크여부 (Y / N )
        bpCardStatCd : '01' ,// (2 )BP카드상태코드 / 01 정상, 02 불량, 03 거래중지
        cardAvlYm : this.cardYyyy +''+ this.cardMm ,// (6 )카드유효년월 / (YYYYMM)
        trtrEno :  this.userData1.detail.cnsltNo ,// (12)처리자사번
        trtSystmPathCd : '22' ,// (2 )처리시스템경로코드 / 22 태블릿
        busnTrtPathCd : '2220'  // (4 )업무처리경로코드 (2220 태블릿_사랑온고객관리)
      }
      this.fn_ServiceCall('txTSSPS15I1', 'di')
    },
    /******************************************************************************
    * Function명 : fn_ServiceCall
    * 설명       : 서비스 호출 함수
    ******************************************************************************/
    fn_ServiceCall (trnstId, serviceDiv) {
      var lv_Vm = this
      let pParams = {}
      let btnId = 'S'
      let serviceName = ''

      switch (serviceDiv) {
        case 'di':
          switch (trnstId) {
            case 'txTSSPS15I1': // 카드정보 등록  F1MDI0331  txTSSPS15I1
              serviceName = 'I'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.iic'
              pParams.trnstId = 'txTSSPS15I1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0331_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0331ReqVO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0331ResVO'
              pParams.data = this.pSNewCardRegSVO
              break
            default:
              break
          }
          PSServiceManager.invoke(pParams, lv_Vm.fn_ServiceResultHandler, btnId, lv_Vm.fn_ServiceFaultHandler)
          break

        default:
          break
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, pParams, moreData) {
      let trnstId = lv_Vm.common.fahrTrnId

      if (lv_Vm.data !== null) {
        switch (trnstId) {
          case 'txTSSPS15I1': // 등록
            if (lv_Vm.data.custAccnTrtRsltVal !== 'S') { // 'S':정상 'E':비정상 - 저장시 유효계좌인지(?) 체크해서 결과값리턴해줌(ERP)
              this.alertMessage = lv_Vm.data.custAccnTrtRsltMsg
              this.getStore('confirm').dispatch('ADD', this.alertMessage)
            } else {
              let o = {
                cardId: lv_Vm.data.cardId
              }
              this.$emit('confirmPopupCard', o)
            }
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ServiceFaultHandler
    * 설명       : 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler (event) {
      if (event.msgCd === 'EEAC928') {
        event.msgDesc = event.msgDesc.substring(event.msgDesc.indexOf('은행'), event.msgDesc.length)
      }
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /******************************************************************************
    * Function명  : fn_SetCardData
    * 설명        : 은행/증권사 BottomSheet 제어
    ******************************************************************************/
    fn_BottomSheet(action) {
      if (action === 'open') {
        this.$refs.bottomSheet1.open()
      }
      else {
        this.$refs.bottomSheet1.close()
      }
    },

    /******************************************************************************
    * Function명  : fn_SerchClickHander
    * 설명        : 검색버튼 핸들러
    *****************************************************************************
    fn_SerchClickHander() {
      let bankArray = []
      // let securitiesArray = []
      this.orgCardList.forEach(item => {
        if (item.label.indexOf(this.searchKeyword.trim()) > -1) {
          _Array.push(item)
        }
      })
      // this.orgSecuritiesList.forEach(item => {
      //   if (item.label.indexOf(this.searchKeyword.trim()) > -1) {
      //     securitiesArray.push(item)
      //   }
      // })

      this.cardList = _Array
      // this.securitiesList = securitiesArray    
    },
    */
    /******************************************************************************
    * Function명  : fn_SetCardData
    * 설명        : 카드 선택 셋팅하고 BottomSheet 닫기
    ******************************************************************************/
    fn_SetCardData(param) {
      this.cardSelect = {
        code : param.code,
        name : param.name
      }
      this.fn_BottomSheet('close')
    },

    /******************************************************************************
    * Function명  : fn_SetCardData
    * 설명        : 
    ******************************************************************************/
    fn_SetCardType(param) {
// 0001 삼성카드
// 0002 현대카드
// 0003 비씨카드
// 9999 기타
      let vNo = ''
      // this.cardSelect.envCntnt
      if (param.name === '삼성카드')  vNo = '0001'
      else if (param.name === '현대카드')  vNo = '0002'
      else if (param.name === '비씨카드')  vNo = '0003'
      else vNo = '9999'
      return vNo
    },
        
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>