/*
 * 업무구분: 청약
 * 화 면 명: MSPPS425P
 * 화면설명: 추가정보사항 입력
 * 접근권한: 
 * 작 성 일: 2024.02
 * 작 성 자: jiyun23.jeong
 */
<template>
  <ur-page-container title="New Page" :show-title="false" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <div class="ns-bottom-sheet">

          <!-- Popup: 심사요청의견 -->
          <mo-bottom-sheet ref="nsbottomsheet" :close-btn="false" class="ns-bottom-sheet closebtn" @closed="$emit('onlyClosePopup')"> <!--  @closed="fn_ClosePopup(false)" -->
            <template v-slot:title>
              추가정보사항 입력
            </template>
            <div class="ns-btn-close" @click="fn_ClosePopup(false)"></div>
            
            <div class="content-area pb6">
              <!-- 안내문구 추가 2024.03.19 -->
              <div class="fexTy2 crTy-orange2 pb20"> 
                <mo-icon class="mr5">warning</mo-icon>
                <span class="fs16rem">추가정보사항은 등록 후 수정이 불가합니다. 확인 후 등록해주세요.</span>
              </div>
              <!-- // 안내문구 추가 2024.03.19 -->
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb0">
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                    <mo-text-area class="hauto pa15 crTy-bk1 lh23" rows="8" placeholder="다른 정보에 대하여 상세하게 기술해주세요. (45자 이내)" v-model="inputInfo" maxlength="45"/>
                    <div class="fex-rw fexJsCtTy3 pt10 fs12rem">
                      <div class="crTy-bk7"><span class="fwb pr2 crTy-blue2">{{inputInfo.length}}</span><span class="pl2 pr2">/</span><span class="pl2 pr2">45</span>자</div>
                    </div>
                  </ur-box-container>
                </ur-box-container>
              </ur-box-container>
            </div>

            <template v-slot:action>
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
                <div class="relative-div">
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClosePopup(false)">취소</mo-button>
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClosePopup(true, inputInfo)">확인</mo-button> <!-- :disabled="!inputInfo.length"-->
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
          <!-- // Popup: 심사요청의견 -->
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

  export default {

    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPPS425P",
    screenId: "MSPPS425P",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$refs.nsbottomsheet.open()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        inputInfo: ''
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: { },
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/ 
    methods: {
      /******************************************************************************
      * Function명  : fn_ClosePopup
      * 설명        : 취소/확인 버튼 클릭
      ******************************************************************************/
      fn_ClosePopup (next, nundwOpin) { 
        // 확인 Btn
        if (next) {
          // 값을 입력하지 않은 경우
          if(this.$bizUtil.isEmpty(nundwOpin)) {
            this.fn_BottomConfirm('추가정보사항을 입력해주세요.', true, false)
          } else {
            this.fn_BottomConfirm('입력한 추가정보사항을 등록하시겠습니까?', false, true, nundwOpin)
            // this.$emit('closePopup', nundwOpin)
          }
        // 취소 Btn
        } else {
          this.fn_BottomConfirm('입력된 내용은 저장되지 않습니다.<br>추가정보사항 입력을 취소하시겠습니까?', false, false)
          // this.$emit('onlyClosePopup')
        }
      },
      /******************************************************************************
      * Function명  : fn_BottomConfirm
      * 설명        : Confirm 팝업
      ******************************************************************************/
      fn_BottomConfirm (contents, isSingle, isNext, nundwOpin) {
        const lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            showCloseBtn: !isSingle,
            single: isSingle,
            content: contents,
            title_pos_btn: '확인',
            title_neg_btn: '취소'
          },
          listeners: {
            // 확인 btn
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_ConfirmPop)
              if(isNext) {
                // 추가정보 입력 완료
                this.$refs.nsbottomsheet.close()
                this.$emit('closePopup', nundwOpin)
              } else if(!isSingle) {
                // 추가정보 입력 취소
                this.$refs.nsbottomsheet.close()
                this.$emit('onlyClosePopup')
              }
            },
            // 취소 btn
            onPopupClose: () => {
              this.$bottomModal.close(lv_ConfirmPop)
            },
            // X btn
            onPopupCancel: () => {
              this.$bottomModal.close(lv_ConfirmPop)
            }
          }
        },
        {properties: {preventTouchClose : isSingle}
        })
      },
    }
  };
</script>
<style scoped>
</style>