/*
 * 업무구분: 고객
 * 화 면 명: MSPCM010P
 * 화면설명: 고객 등록_알뜰폰 확인 팝업
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.14
 * 작 성 자: 이태훈
 */
<template>
  <ur-page-container title="알뜰폰 확인" :show-title="false" type="popup">
    <ur-box-container direction="row" alignV="start" class="ns-subpage ns-bottom-sheet-xl">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-telselect-ttl">
        <div class="">고객의 통신사를 선택하세요</div>
      </ur-box-container>
      
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-segment">        
        <mo-segment-wrapper solid primary v-model="firstItem">
          <template v-for="(tab, index) in tabsItems">
            <mo-segment-button v-on:click="currentTab=index" :value="tab.value" :key="index">{{tab.id}}</mo-segment-button>
          </template>
        </mo-segment-wrapper>
      </ur-box-container>
      
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-telselect-div">
        <div v-for="(item, index) in mockData" :key="index">
          <div v-show="currentTab == index">
            <p>{{item.desc}}</p>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_100" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm">확인</mo-button>
        </div>
      </ur-box-container>


    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM010P',
  // 현재 화면 ID
  screenId: 'MSPCM010P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '고객 등록_알뜰폰 확인 팝업', // 화면명
      tabsItems: [
        {
        id: 'SKT',
        value: 'SKTM'
        },
        {
          id: 'KT',
          value: 'KTM'
        },
        {
          id: 'LGU+',
          value: 'LGTM'
        }
      ],
      currentTab: 0,
      firstItem: "SKTM",
      mockData: [{
        id: 'SKTM',
        title: 'SKT(알뜰폰)',
        desc: `KCT(Tplus), SK텔링크(7모바일), 큰사람(이야기알뜰폰), 이마트(e모바일), 스마텔(스마텔), 아이즈비전(아이즈모바일),에스원(안심모바일), 유니컴즈(모빙), 프리텔레콤(freeT), CJ헬로비전(헬로모바일), 에넥스텔레콤(A모바일)`
      },
      {
        id: 'KTM',
        title: 'KT(알뜰폰)',
        desc: `CJ헬로비전, KT파워텔, 홈플러스, 씨엔커뮤니케이션, 에넥스텔레콤, 에스원, 위너스텔, 에이씨앤코리아, 세종텔레콤, KT텔레캅, 프리텔레콤(freeT), 이지모바일, kt M모바일, 앤텔레콤, 아이즈비전, 제이씨티, 머천드코리아, 장성모바일, 유니컴즈, 아이원, (주)파인디지털, (주)미니게이트, (주)핀플레이, 드림라인(주), KCT(Tplus)`
      },
      {
        id: 'LGTM',
        title: 'LG U+(알뜰폰)',
        desc: `인스코비(freeT), 머천드코리아(마이월드), (주)엠티티텔레콤(메리큐), (주)미디어로그, 이마트, 서경방송, 울산방송, 푸른방송, 남인천방송, 금강방송, 제주방송, (주)와이엘랜드(여유텔레콤), ACN코리아(플래시모바일), 이지모바일, 유니컴즈(모빙), 큰사람(이야기), 스마텔, 에넥스텔레콤(A모바일), 레그원(온국민폰), 드림에이치앤비(셀모바일), 조이텔(조이텔)`
      }
      ],

    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
  },
  
  /** watch 정의 영역 */
  watch: {
  },


  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // listen to Event
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog('MSPCM010P')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // stop listenning Event
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_ConfirmContent
    * 설명       : 확인버튼 선택 시 호출 - 선택한 항목의 값을 Main 창으로 돌려보냄.
    ******************************************************************************/
    fn_Confirm () {
      if (this.currentTab !== null) { // 리스트가 선택될 경우 '확인' 버튼이 활성화 됨. 따라서 선택하지 않을 경우를 체크할 필요 없음
        let lv_Param = {val: this.firstItem}
        this.$emit('confirmPopup', lv_Param)
      }
    },
    /******************************************************************************
    * Function명 : fn_Cancel
    * 설명       : 취소버튼 선택 시 호출  - 팝업창 닫는 이벤트 호출
    ******************************************************************************/
    fn_Cancel () {
      let lv_Param = ''
      this.$emit('confirmPopup', lv_Param)
    },

  }//methods end
}
</script>
