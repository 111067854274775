/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS421P
 * 화면 설명: FATCA/CRS 본인확인서(개인용)
 */
 <template>
  <ur-page-container class="msp txtSkip" title="FATCA/CRS 본인확인서(개인용)" :show-title="true" type="subpage" @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="pb82">

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area bd-T-Ty1 bd-b-Ty1 bgcolor-3 pal1624">
          <span class="fs14rem crTy-bk7">본 확인서는 상호주의에 따른 정기적인 금융정보의 교환을 위한 조세조약의 이행과 관련하여 『국세조세조정에 관한 법률』 제31조의 및 같은 법 시행령 제47조에 의거한 『정기 금융정보 자동교환을 위한 조세조약 이행규정』에 의하여 작성이 요구되는 필수서식입니다.</span>
        </ur-box-container>
        
        <ur-box-container alignH="between" alignV="center" componentid="" direction="column" class="pal2024">
          
          <span class="dsD fs18rem fwm">다음 해당란에 체크해 주시기 바랍니다.
            <br>①또는 ②는 중복선택이 가능합니다.
          </span>
          <div class="mt10 w100">
            <span class="dsD mb10 fs14rem crTy-bk7 must">① 미국거주자</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="returnData1">
                <mo-segment-button v-for="(item, idx) in segmentfields.foreignUsa" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="" v-if="returnData1 === '2'">
              <mo-radio class="radio mt10 flex h40px" v-model="radioStrValue" value="2">미국 시민권자(이중 국적자 포함)</mo-radio>
              <mo-radio class="radio mt10 flex h40px" v-model="radioStrValue" value="3">미국 영주권자</mo-radio>
              <mo-radio class="radio mt10 flex h40px" v-model="radioStrValue" value="4">미국세법상 미국거주자</mo-radio>
            </ur-box-container>
          </div>
          <div class="mt20 w100">
            <span class="dsD mb10 fs14rem crTy-bk7 must">② 한국, 미국 이외의 조세목적상 해외 거주자</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="returnData2">
                <mo-segment-button v-for="(item, idx) in segmentfields.foreign" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </div>

          <span class="dsD mt50 fs18rem fwm">위에서 ①또는 ②에 표기한 경우 영문성명, 영문주소, 조세목적상 거주지국 및 납세자번호(TIN, Taxpayer Identification Number)를 기재해 주시기 바랍니다.</span>
          <div class="mt10 w100">
            <span class="dsD fs14rem crTy-bk7 must">영문성명</span>
            <mo-text-field class="form-input-name full" underline clearable v-model="engName1" placeholder="성(Sur Name)" :rules="requiredRuleSur" @focus="textInScrnUp"/>
            <mo-text-field class="form-input-name full mt10" underline clearable v-model="engName2" placeholder="명(Given Name)" :rules="requiredRuleGiven" @focus="textInScrnUp"/>
          </div>
          <div class="mt20 w100">
            <span class="dsD fs14rem crTy-bk7 must">영문주소(현재 거주주소)</span>
            <mo-text-field class="form-input-name full" underline clearable v-model="engAdress" placeholder="입력하세요" :rules="requiredRuleAddr" focus="[textInScrnUp, inputFocused = true]" @blur="() => {inputFocused = false}"/>
          </div>

          <span class="dsD mt50 fs18rem fwm">납세자 정보</span>
          <div class="mt10 w100 bdTy10 pal1620 closebtn-box" v-for="(nation, index) in nationList" :key="index">
            <mo-button v-if="index !== 0" class="ns-btn-round hauto icon-btn" @click="fn_ClickCloseNation(index)"><mo-icon icon="close" /></mo-button>
            <div class="w100">
              <span class="dsD mb10 fs16rem fwb">조세목적상 거주지 국가</span>
              <msp-bottom-select :itemHeight="374" underline class="ns-dropdown-sheet" bottom-title="거주자국가 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="nation.label.key" :items="selNationList" :disabled="!selNationFlag"/>
            </div>

            <div class="w100 mt30">
              <span class="dsD mb10 fs16rem fwb">납세자번호(TIN : SSN 또는 ITN)</span>
              <mo-text-field class="form-input-name full" underline clearable placeholder="입력하세요" v-model="nation.tin" @input="fn_CheckTinOmitValList(index, arguments[0])" @focus="textInScrnUp"/>
            </div>
            
            <div class="w100 mt30">
              <span class="dsD mb10 fs16rem fwb">납세자번호 미기재사유(미국은 미제출 불가)</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="">
                <mo-radio class="radio mt10 flex h40px" v-model="nation.tinOmitVal" value="01" @input="fn_TinOmitValRadioBtnEvent(index,arguments[0])" :disabled="nation.tin.trim().length>0">미발급 국가</mo-radio>
                <mo-radio class="radio mt10 flex h40px" v-model="nation.tinOmitVal" value="02" @input="fn_TinOmitValRadioBtnEvent(index,arguments[0])" :disabled="nation.tin.trim().length>0">미요구 국가</mo-radio>
                <mo-radio class="radio mt10 flex h40px" v-model="nation.tinOmitVal" value="03" @input="fn_TinOmitValRadioBtnEvent(index,arguments[0])" :disabled="nation.tin.trim().length>0">미취득</mo-radio>
              </ur-box-container>
            </div>
          </div>
          <div class="w100" v-if="notAcqreReasonFlag">
            <span class="dsD fs14rem crTy-bk7 mt20">미취득 사유</span>
            <mo-text-area class="hauto lhauto w100 mt10 pa12 crTy-bk7" rows="3" placeholder="사유를 입력해 주세요" v-model="notAcqreReason" :rules="validateRuleNotAcqreReason" :maxlength="30" @focus="[textInScrnUp, inputFocused = true]" @blur="() => {inputFocused = false}"/>
          </div>
          <mo-button v-if="addNationFlag" class="ns-btn-round h40 rounded mt20 white w100 fs16rem" @click="fn_AddNation">추가하기</mo-button>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_FatCancel">취소</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmBtn" :disabled="confirmBtnFlag">확인</mo-button>
        </div>
      </ur-box-container>
    </template>
    
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS421P', 
  screenId: 'MSPPS421P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    isShowPopUp: {type: Boolean, default: false},
    userData: {}
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      requiredRuleSur: [
        v => !!v || '영문성명항목(Sur Name)은 필수 정보입니다.',
        v => !(v.search('[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]') > -1) || '한글은 입력할수 없습니다.',
      ],
      requiredRuleGiven: [
        v => !!v || '영문성명항목(Given Name)은 필수 정보입니다.',
        v => !(v.search('[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]') > -1) || '한글은 입력할수 없습니다.',
      ],
      requiredRuleAddr: [
        v => !!v || '영문주소항목은 필수 정보입니다.',
        v => !(v.search('[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]') > -1) || '한글은 입력할수 없습니다.',
      ],
      validateRuleNotAcqreReason: [
         v => !!v || '미취득 사유는 필수입력항목입니다'
      ],
      lv_AlertPop: null,  // 확인 팝업
      inputFocused: false,
      //alert: false,
      alertMessage: '',
      index: 0,
      returnData1: '', // 미국 거주자
      returnData2: '', // 한국,미국 이외의 조세목적상 해외거주자
      radioStrValue: '', // 미국 거주자 구분값
      engName1: '',
      engName2: '',
      engAdress: '',
      returnData1EnableFlag: false,
      returnData2EnableFlag: false,
      radioStrValueEnableFlag: false,
      engNameEnableFlag: false,
      engAdressEnableFlag: false,
      engTaxNationEnableFlag: false,
      taxPayerNoInfo: false, // 납세자번호 및 납세자번호 미기재사유 판단
      confirmBtnFlag: true,
      addNationFlag: false,
      selNationFlag: false,
      notAcqreReason: '', // 미취득 사유
      notAcqreReasonFlag: '',
      selNationList: [],
      selAllNationList: [], // 전체나라
      selNotUsNationList: [], // 미국제외 전체나라
      nationList: [
        {
          label: {key: 'US', label: '미국'},
          addr: '',
          tin: '',
          tinOmitVal: '',
          tinOmitText: '',
          index: 0
        }
      ],
      segmentfields: {
        foreignUsaValues: [
          {
            key: '1',
            label: '아니오'
          }
        ],
        foreignUsa: [
          {
            key: '1',
            label: '아니오'
          },
          {
            key: '2',
            label: '예'
          }
        ],
        foreignValues: [
          {
            key: '1',
            label: '아니오'
          }
        ],
        foreign: [
          {
            key: '1',
            label: '아니오'
          },
          {
            key: '2',
            label: '예'
          }
        ]
      },
      txTSSPS13P1Data: {},
      pSFatcaPCustInfoSVO: {}
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    var lv_Vm = this
    this.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD').then(function () {
      let comSelNationList = lv_Vm.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')

      if (comSelNationList !== null && comSelNationList.length > 0) {
        lv_Vm.selAllNationList = Array.prototype.slice.call(comSelNationList)
        // 나라선택 -> 미국제외
        for (let i = 0; i < lv_Vm.selAllNationList.length; i++ ) {
          if ( lv_Vm.selAllNationList[i].label.trim().indexOf('미국') === -1 ) {
            lv_Vm.selNotUsNationList.push(lv_Vm.selAllNationList[i])
          }
        }
      }
    })
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    returnData1 () {
      if (this.returnData1.length > 0) {
        this.returnData1EnableFlag = true
        if (this.returnData1 === '1') { // 미국거주자가 아니면
          this.returnData2 = '2' //[{key: '2', label: '예'}] // 한국,미국 이외의 조세목적상 해외거주자 '예' 선택
          this.radioStrValueEnableFlag = true
          this.addNationFlag = true
          this.selNationFlag = true
          this.selNationList = []
          this.selNationList = this.selNotUsNationList
        } else {
          if ( this.returnData2 === '2' ) { // 미국포함 전체나라
            this.selNationList = []
            this.selNationList = this.selAllNationList
          }
          if ( this.radioStrValue.length > 0 ) { // 라디오버튼 선택되어있으면...
            this.radioStrValueEnableFlag = true
          } else {
            this.radioStrValueEnableFlag = false
          }
        }
        // 납세자번호 미기재 사유 초기화
        for ( let i = 0; i < this.nationList.length; i++ ) {
          this.nationList[i].tinOmitVal = ''
        }
        // 미취득 사유 항목 비활성화
        this.notAcqreReasonFlag = false
        //this.$bizUtil.clearValidator(['regist'])
        this.fn_ConfirmBtnEnable()
      } else {
        this.returnData1EnableFlag = false
        this.confirmBtnFlag = true
      }
    },
    returnData2 () {
      if (this.returnData2.length > 0) {
        if ( this.returnData2 === '1' ) { // 한국,미국 이외의 조세목적상 해외거주자가 아니면
          this.returnData1 = '2' //[{key: '2', label: '예'}] // 미국 거주자 '예' 선택
          this.addNationFlag = false
          this.selNationFlag = false
          this.nationList = []
          this.nationList.push(
            {
              label: {key: 'US', label: '미국'},
              addr: '',
              tin: '',
              tinOmitVal: '',
              tinOmitText: '',
              index: 0
            }
          )
        } else {
          if ( this.returnData1 === '1' ) {
            this.selNationList = []
            this.selNationList = this.selNotUsNationList
          } else {
            this.selNationList = []
            this.selNationList = this.selAllNationList
          }

          this.nationList = []
          this.nationList.push(
            {
              label: { key: this.selNationList[0].key, label: this.selNationList[0].label },
              addr: '',
              tin: '',
              tinOmitVal: '',
              tinOmitText: '',
              index: 0
            }
          )
          this.addNationFlag = true
          this.selNationFlag = true
        }
        // 납세자번호 미기재 사유 초기화
        for ( let i = 0; i < this.nationList.length; i++ ) {
          this.nationList[i].tinOmitVal = ''
        }
        // 미취득 사유 항목 비활성화
        this.notAcqreReasonFlag = false
        //this.$bizUtil.clearValidator(['regist'])
        this.returnData2EnableFlag = true
        this.fn_ConfirmBtnEnable()
      } else {
        this.returnData2EnableFlag = false
        this.confirmBtnFlag = true
      }
    },
    radioStrValue () {
      if (this.radioStrValue.length > 0) {
        this.radioStrValueEnableFlag = true
        this.fn_ConfirmBtnEnable()
      } else {
        this.radioStrValueEnableFlag = false
        this.confirmBtnFlag = true
      }
    },
    engName1 () {
      if ( this.engName1.trim().length > 0 && this.engName2.trim().length > 0 ) {
        this.engNameEnableFlag = true
        this.fn_ConfirmBtnEnable()
      } else {
        this.engNameEnableFlag = false
        this.confirmBtnFlag = true
      }
    },
    engName2 () {
      if ( this.engName1.trim().length > 0 && this.engName2.trim().length > 0 ) {
        this.engNameEnableFlag = true
        this.fn_ConfirmBtnEnable()
      } else {
        this.engNameEnableFlag = false
        this.confirmBtnFlag = true
      }
    },
    engAdress () {
      if (this.engAdress.trim().length > 0) {
        this.engAdressEnableFlag = true
        this.fn_ConfirmBtnEnable()
      } else {
        this.engAdressEnableFlag = false
        this.confirmBtnFlag = true
      }
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    sumName () {
      return this.engName1 + this.engName2
    }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /************************************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ************************************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 이전버튼 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.fn_FatCancel ()
    },// fn_HeaderBackBtnHandler
    
    /******************************************************************************
    * Function명  : 안드로이드 키보드 활성화시 텍스트 입력화면이 키보드에 가려지지 않기위해 하는 이벤트
    * 설명        :
    ******************************************************************************/
    textInScrnUp () {
      this.$commonUtil.setHiddenPlugin(false)
    },

    /******************************************************************************
    * Function명  : 납세자 번호 입력 체크 및 납세자번호 미기재 사유 체크
    * 설명        :
    ******************************************************************************/
    fn_TinOmitValRadioBtnEvent (idx, tinOmitVal) {
      //this.$bizUtil.clearValidator(['regist'])
      let flag = false
      for ( let i = 0; i < this.nationList.length; i++ ) {
        if ( this.nationList[i].tinOmitVal === '03' ) { // 납세자번호 미기재사유중 미취득이 있으면
          flag = true
        }
      }
      if ( flag ) {
        // 미취득 사유 항목 활성화
        this.notAcqreReasonFlag = true
      } else this.notAcqreReasonFlag = false

      /*if ( tinOmitVal === '03' ) {
        // 미취득 사유 입력항목으로 스크롤 이동
        this.$nextTick(() => {
          var element = this.$refs.notAcqreReasonScroll
          var top = element.offsetTop // 특정 ref의 y축 위쪽 위치 정보
          this.$refs.rightScroll.scrollTop = top
        })
      }*/
      this.fn_ConfirmBtnEnable()
    },
    /******************************************************************************
    * Function명  : 납세자번호 입력
    * 설명        : - 납세자 번호가 입력되면 해당국가 납세자번호 미기재 사유 비활성화
    *               - 나머지 국가중 하나라도 납세자번호 미기재 사유에 미취득 선택시 미취득사유 입력항목 활성화
    ******************************************************************************/
    fn_CheckTinOmitValList (idx, inputValue) {
      let flag = false
      if (inputValue.trim().length !== 0 && this.nationList[idx]) {
        this.nationList[idx].tinOmitVal = ''
      }
      for ( let i = 0; i < this.nationList.length; i++ ) {
        if ( this.nationList[i].tinOmitVal === '03' ) {
          flag = true
        }
      }
      flag ? this.notAcqreReasonFlag = true : this.notAcqreReasonFlag = false
      this.fn_ConfirmBtnEnable()
    },
    /******************************************************************************
    * Function명  : 국가 추가
    * 설명        : 3개까지 추가
    ******************************************************************************/
    //
    fn_AddNation () {
      if (this.returnData1 === '1') { // 미국거주자가 아니면
        this.selNationList = []
        this.selNationList = this.selNotUsNationList
      } else {
        if ( this.returnData2 === '2' ) { // 미국포함 전체나라
          this.selNationList = []
          this.selNationList = this.selAllNationList
        }
      }
      let newNation = {
        label: {key: this.selNationList[0].key, label: this.selNationList[0].label},
        addr: '',
        tin: '',
        tinOmitVal: '',
        tinOmitText: '',
        index: ++this.index
      }
      this.nationList.push(newNation)
      if ( this.nationList.length === 3) {
        this.addNationFlag = false
      }
      this.fn_ConfirmBtnEnable()
    },
    /******************************************************************************
    * Function명  : 국가 삭제
    * 설명        : 추가된 국가 삭제
    ******************************************************************************/
    fn_ClickCloseNation (idx) {
      --this.index
      this.nationList.splice(idx, 1)
      this.addNationFlag = true
      this.fn_ConfirmBtnEnable()
    },
    /******************************************************************************
    * Function명  : 취소 버튼
    * 설명        :
    ******************************************************************************/
    fn_FatCancel () {
      for ( let i = 0; i < this.nationList.length; i++ ) {
        this.nationList[i].tinOmitVal = ''
      }
      this.radioStrValue = ''
      this.$emit('fatcaPopUpClose', 'cancel')
    },
    /******************************************************************************
    * Function명  : fatca 완료
    * 설명        : 저장 완료후 팝업 Close
    ******************************************************************************/
    fn_PopUpClose () {
      this.$emit('fatcaPopUpClose', 'success')
    },
    /******************************************************************************
    * Function명  : 다음버튼 활성화,비활성화 여부 판단
    * 설명        :
    ******************************************************************************/
    fn_ConfirmBtnEnable () {
      if ( this.returnData1EnableFlag && this.returnData2EnableFlag && this.radioStrValueEnableFlag &&
       this.engNameEnableFlag && this.engAdressEnableFlag ) {
        for (let i = 0; i < this.nationList.length; i++) {
          if (this.nationList[i].tin.trim().length > 0 || this.nationList[i].tinOmitVal.trim().length > 0) {
            this.confirmBtnFlag = false
          } else {
            this.confirmBtnFlag = true
            return
          }
        }
      } else {
        this.confirmBtnFlag = true
      }
    },
    /******************************************************************************
    * Function명  : 최종 확인버튼
    * 설명        :
    ******************************************************************************/
    fn_ConfirmBtn () {
      let taxprNo01 = ''
      let taxprNo02 = ''
      let taxprNo03 = ''
      let taxprNoUnSbmtRsnCd01 = ''
      let taxprNoUnSbmtRsnCd02 = ''
      let taxprNoUnSbmtRsnCd03 = ''
      let taxsPpsDmclCntryCd01 = ''
      let taxsPpsDmclCntryCd02 = ''
      let taxsPpsDmclCntryCd03 = ''

      for (let i = 0; i < this.nationList.length; i++) {
        if ( i === 0 ) {
          taxprNo01 = this.nationList[i].tin
          taxprNoUnSbmtRsnCd01 = this.nationList[i].tinOmitVal
          taxsPpsDmclCntryCd01 = this.nationList[i].label.key
        } else if ( i === 1 ) {
          taxprNo02 = this.nationList[i].tin
          taxprNoUnSbmtRsnCd02 = this.nationList[i].tinOmitVal
          taxsPpsDmclCntryCd02 = this.nationList[i].label.key
        } else {
          taxprNo03 = this.nationList[i].tin
          taxprNoUnSbmtRsnCd03 = this.nationList[i].tinOmitVal
          taxsPpsDmclCntryCd03 = this.nationList[i].label.key
        }
      }

      if (taxprNoUnSbmtRsnCd01 === '03' || taxprNoUnSbmtRsnCd02 === '03' || taxprNoUnSbmtRsnCd03 === '03') {
        if ( this.notAcqreReason.trim().length < 1) {
          this.$nextTick(() => {
            //var element = this.$refs.notAcqreReasonScroll
            //var top = element.offsetTop // 특정 ref의 y축 위쪽 위치 정보
            //this.$refs.rightScroll.scrollTop = top
          })
          //this.$refs.refWrapper.validateBeforeSubmit()
          this.alertMessage = '미취득 사유 항목은 필수 정보입니다.'
          this.getStore('confirm').dispatch('ADD', this.alertMessage)
          return
        }
      }

      // 미국거주자 여부
      if (this.returnData1 === '1') { // '아니오'
        this.txTSSPS13P1Data = {
          custId: '',
          // custId: this.userData.detail.chnlCustId,
          custNm: this.userData.detail.custNm,
          custRrn: this.userData.custRrnId,
          trtrEno: this.userData.detail.cnsltNo,
          fatcaCustCnfMatrCd: '1', // 고객확인사항코드
          koreaUsaExcluOversDwYn: 'Y',
          fatcaEngNm01: this.engName1, // 영문성명 '성'
          fatcaEngNm02: this.engName2, // 영문성명 '이름'
          fatcaEngAddr: this.engAdress, // 영문주소
          taxsPpsDmclCntryCd01: taxsPpsDmclCntryCd01, // 조세목적거주지국가코드 1
          taxsPpsDmclCntryCd02: taxsPpsDmclCntryCd02, // 조세목적거주지국가코드 2
          taxsPpsDmclCntryCd03: taxsPpsDmclCntryCd03, // 조세목적거주지국가코드 3
          taxprNo01: taxprNo01, // 납세자 번호 1
          taxprNo02: taxprNo02, // 납세자 번호 2
          taxprNo03: taxprNo03, // 납세자 번호 3
          taxprNoUnSbmtRsnCd01: taxprNoUnSbmtRsnCd01, // 납세자번호 미기재 사유 1
          taxprNoUnSbmtRsnCd02: taxprNoUnSbmtRsnCd02, // 납세자번호 미기재 사유 2
          taxprNoUnSbmtRsnCd03: taxprNoUnSbmtRsnCd03, // 납세자번호 미기재 사유 3
          fatcaSbmtDnlRsn: this.notAcqreReason, // 미취득 사유
          fatcaInptPathCd: '1181',
          FatcaAcinsDgrId: ''
        }
      } else { // '예'
        this.txTSSPS13P1Data = {
          custId: '',
          // custId: this.userData.detail.chnlCustId,
          custNm: this.userData.detail.custNm,
          custRrn: this.userData.custRrnId,
          trtrEno: this.userData.detail.cnsltNo,
          fatcaCustCnfMatrCd: this.radioStrValue, // 미국거주자 여부
          koreaUsaExcluOversDwYn: this.returnData2 === '1' ? 'N' : 'Y', // 한국,미국 이외의 조세목적상 해외 거주자
          fatcaEngNm01: this.engName1, // 영문성명 '성'
          fatcaEngNm02: this.engName2, // 영문성명 '이름'
          fatcaEngAddr: this.engAdress, // 영문주소
          taxsPpsDmclCntryCd01: taxsPpsDmclCntryCd01, // 조세목적거주지국가코드 1
          taxsPpsDmclCntryCd02: taxsPpsDmclCntryCd02, // 조세목적거주지국가코드 2
          taxsPpsDmclCntryCd03: taxsPpsDmclCntryCd03, // 조세목적거주지국가코드 3
          taxprNo01: taxprNo01, // 납세자 번호 1
          taxprNo02: taxprNo02, // 납세자 번호 2
          taxprNo03: taxprNo03, // 납세자 번호 3
          taxprNoUnSbmtRsnCd01: taxprNoUnSbmtRsnCd01, // 납세자번호 미기재 사유 1
          taxprNoUnSbmtRsnCd02: taxprNoUnSbmtRsnCd02, // 납세자번호 미기재 사유 2
          taxprNoUnSbmtRsnCd03: taxprNoUnSbmtRsnCd03, // 납세자번호 미기재 사유 3
          fatcaSbmtDnlRsn: this.notAcqreReason, // 미취득 사유
          fatcaInptPathCd: '1181',
          FatcaAcinsDgrId: ''
        }
      }

      this.fn_ServiceCall('txTSSPS13P1')
    },
    /******************************************************************************
    * Function명  : 서비스
    * 설명        :
    ******************************************************************************/
    fn_ServiceCall (trnstId) {
      var lv_Vm = this
      let pParams = {}
      // let btnId = ''
      let serviceName = 'S'

      switch (trnstId) {
        case 'txTSSPS13P1':
          serviceName = 'I'
          pParams.srnId = this.$options.screenId // 대표 화면 명
          pParams.appId = 'sli.ipi'
          pParams.trnstId = 'txTSSPS13P1'
          pParams.fnctScCd = serviceName
          pParams.serviceId = 'C339_F1MDI0055_S'
          pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0055VO'
          pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0055VO'
          pParams.data = this.txTSSPS13P1Data
          break
        default:
          break
      }
      PSServiceManager.invoke(pParams, lv_Vm.fn_ServiceResultHandler, serviceName, lv_Vm.fn_ServiceFaultHandler)
    },
    /******************************************************************************
    * Function명  : 서비스 콜백
    * 설명        :
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, pParams, moreData) {
      let trnstId = lv_Vm.common.fahrTrnId

      if (lv_Vm.data !== null) {
        switch (trnstId) {
          case 'txTSSPS13P1':
            if (lv_Vm.data !== null && lv_Vm.data.length !== 0) {
              this.alertMessage = '저장 되었습니다.'
              this.fn_BottomAlert(this.alertMessage)
            }
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
    * Function명  : 서비스 콜백
    * 설명        :
    ******************************************************************************/
    fn_ServiceFaultHandler (event) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업
    ******************************************************************************/
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_PopUpClose()
          },
          onPopUpClose: () => {
            console.log('onfn_PopUpClose call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_PopUpClose()
          }
        }
      })
    },
    
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>