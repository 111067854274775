/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS422D
 * 화면 설명: 전자서명 - 계약관계자 정보확인
 */
<template> 
<ur-box-container ref="rootDivBox" alignH="center" componentid="" direction="column" class="pb82 bgcolor-1"><!-- // alignV="start"를 alignH="center" 변경 2024.03.25 -->          
  <ur-box-container  alignV="start" componentid="ur_box_container_001" direction="column" class="card-list-wrap list-ty2">
    <!-- 피보험자정보 -->
    <ur-box-container v-show="isInsPersonInfo" alignV="start" componentid="ur_box_container_011" direction="column">
      <msp-expand ref="mspExp2" expanded titleFirst btnAreaFirst expandOnlyBtn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" @expanded="fn_SetFocus">
        <template #title>
          <div class="list-item__contents overflow-v">
            <div class="list-item__contents__title overflow-v">
              <mo-badge class="badge-sample-badge blue2" text="" shape="status">피보험자</mo-badge>                
              <div class="ml10">
                <div>
                  <span class="name">{{userData2.hasOwnProperty('detail') ? userData2.detail.custNm : ''}}</span>                 
                </div>                    
                <div class="mt4 lh20px w100calc50">
                  <span class="info">{{userData2.hasOwnProperty('age') ? userData2.age : ''}}세</span>
                  <em>|</em>
                  <span class="info">{{userData2.hasOwnProperty('custRrnId') ? userData2.custRrnId.substring(0,6)+'-'+userData2.custRrnId.substring(6,13) : ''}}</span>
                </div>
                <div class="result mt6">
                  <template v-if="insuredCmpltFlag">
                    <div class="fexTy5">
                      <mo-icon icon="msp-is-checked" icon-size="27px" />
                      <span class="ml4 fwn crTy-bk7">입력완료</span>                        
                    </div>
                  </template>
                  <template v-else>
                    <span class="fwn crTy-orange2">입력필요</span>
                  </template>
                </div>  
              </div>  
              <!-- // 고객 선택 case -->
            </div>
          </div>            
        </template>
        <template #btn>
          <mo-button class="link-arrow">
            <mo-icon icon="expand_menu" class="expand__icon" />                  
            <span class="open">접기</span>
            <span class="close">열기</span>
          </mo-button>
        </template>
        <template #content>
          <div class="con-area pt10 pb30">
            <div class="w100">
              <div class="contain-title">직업정보</div>
              <span class="dsD label-title must">직장명</span>
              <mo-text-field ref="insured1" v-model="insuredJobTitle" @focus="fn_setTxtInputFocus('rootDivBox','insured1',$event)" @blur="fn_setTxtInputBlur('rootDivBox','insured1',$event)" class="form-input-name full" :rules="validateRuleInsuredJob" underline placeholder="입력하세요(필수)" :maxlength="6" clearable/>

              <div class="fexTy-half20">
                <div>  
                  <span class="dsD label-title mt20">업종</span>
                  <mo-text-field ref="insuredJobType" v-model="insuredJobType" @focus="fn_setTxtInputFocus('rootDivBox','insuredJobType',$event)" @blur="fn_setTxtInputBlur('rootDivBox','insuredJobType',$event)" class="form-input-name full" :maxlength="10" underline placeholder="" clearable/>
                </div>  
                <div>
                  <span class="dsD label-title must mt20">하시는 일</span>
                  <mo-text-field ref="insured2" v-model="insuredWork" @focus="fn_setTxtInputFocus('rootDivBox','insured2',$event)" @blur="fn_setTxtInputBlur('rootDivBox','insured2',$event)" class="form-input-name full" :rules="validateRuleInsuredWork" underline placeholder="입력하세요(필수)" :maxlength="10" clearable/>                  
                  <!-- <div class="fs12rem crTy-bk7 lh20px mt6">{{userData2.hasOwnProperty('insrJobNm') ? userData2.insrJobNm : ''}}<br><strong>{{userData2.hasOwnProperty('insrJobCd') ? userData2.insrJobCd : ''}}</strong></div> -->
                  <div class="fs14rem crTy-bk7 mt5"><span class="inline-block mr5">{{userData2.hasOwnProperty('insrJobNm') ? userData2.insrJobNm : ''}}</span> <span class="inline-block">{{userData2.hasOwnProperty('insrJobCd') ? userData2.insrJobCd : ''}}</span></div> <!-- 수정0530 -->
                </div>  
              </div> 
              <div class="full48 h10px bgcolor-3 mt30"></div>
            </div> 

            <div class="w100 mt30">
              <div class="contain-title">연락처정보</div>
              <span class="dsD label-title">휴대폰번호</span>
              <!-- <span class="mt10 w100 mh40px fs17rem bd-b-Ty1 fexTy5-1">{{ (userData2.hasOwnProperty('detail') && userData2.detail.celno.trim().length > 0) ? userData2.detail.celno : '없음' }}</span> -->
              <mo-text-field type="none" underline class="form-input-name full" :value="(userData2.hasOwnProperty('detail') && userData2.detail.celno.trim().length > 0) ? userData2.detail.celno : '없음'" readonly/>

              <span class="dsD label-title must mt20">문자서비스</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData7" class="chip-type-wrap chip-ty1" ref="insured3" :rules="validateRuleInsuredSms">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData1" :value="item.key" :key="idx" :disabled="(userData2.hasOwnProperty('detail') && userData2.detail.celno.trim().length > 0) ? false : true">{{item.label}}</mo-segment-button>
                  </div>
                </mo-segment-wrapper>
              </ur-box-container>
              <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                <li>휴대전화 문자메시지 또는 이에 준하는 전자적 의사표시 활용 안내입니다. (전자적 의사표시의 예 : 카카오알림톡, 모니모 등 App을 통한 안내)</li>
                <li>기존에 받아보시던 안내가 있을 경우 동의내용이 함께 적용됩니다. (기존 안내의 예 : 변액보험계약변동안내, 변액자산보고운용서 등)</li>
              </ul>

              <span class="dsD label-title must mt20">거주구분</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData8" class="chip-type-wrap chip-ty1" ref="insured4" :rules="validateRuleInsuredNation">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData3" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </div>
                </mo-segment-wrapper>
              </ur-box-container>

              <div class="full48 h10px bgcolor-3 mt30"></div>
            </div>
            

            <div class="w100 mt30">
              <div class="contain-title">수령지정보</div>
              <span class="dsD label-title">이메일</span>
              <div class="ns-certify-sed pal0"><!-- error  class 추가로 상태변환 -->
                <div class="ns-certify-sed-code">
                  <msp-email class="form-input-name full" underline v-if="userData2.hasOwnProperty('detail')" v-model="userData2.detail.emailAddr" placeholder="이메일을 입력해주세요" clearable/>
                </div>
              </div>

              <span class="dsD label-title mt20 must">이메일서비스</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData9" class="chip-type-wrap chip-ty1" ref="insured5" :rules="validateRuleInsuredEmail">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData4" :value="item.key" :key="idx" :disabled="(userData2.hasOwnProperty('detail') && userData2.detail.emailAddr.trim().length > 0) ? false : true">{{item.label}}</mo-segment-button>
                  </div>
                </mo-segment-wrapper>
              </ur-box-container>
              <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                <li>문자와 이메일 수신에 모두 동의하신 경우, 회사에서 정한 방법으로 안내드립니다.</li>
              </ul>

              <span class="dsD label-title mt20 must">주소</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData10" class="chip-type-wrap chip-ty1" ref="insured6" :rules="validateRuleInsuredAddr">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData6" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </div>  
                </mo-segment-wrapper>
              </ur-box-container>
              
              <mo-text-field v-model="insurdAddressInfo1" class="form-input-name full mt20" value="우편번호 검색하기" underline searchable placeholder="우편번호 검색하기" @click-icon="fn_ZipCodeSrch('02')" readonly :disabled="insuredZipCodeEnable"/>
              <span class="mt10 w100 mh40px fs17rem bd-b-Ty fexTy5-1">{{insurdAddressInfo2}}</span>              
              <!-- <span class="dsD fs14rem crTy-bk7 mt20">상세주소</span>
              <div class="verify-phone msp-verify-phone">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ZipCodeSrch('01')" value="02" :disabled="insuredZipCodeEnable">우편번호</mo-button>
                <mo-text-field v-model="insurdAddressInfo" readonly/>
              </div> -->

              <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                <li>주소는 자택/직장 중 현재 선택된 주소 구분에 대한 정보만 수정됩니다.</li>
                <li>자택/직장 중 선택하여 입력한 주소구분에 따라 청약서상 기본우편물 수령처로 지정됩니다.</li>
              </ul>

              <span class="dsD label-title mt20">전화번호</span>
              <div class="verify-phone msp-verify-phone">
                <msp-bottom-select :itemHeight="374" underline class="ns-dropdown-sheet mr20" placeholder="선택" bottom-title="지역번호" closeBtn scrolling :itemValue="'key'" :itemText="'label'" v-model="insurdHomeTelSelect.label" :items="homeTelList" @selectedItem="fn_initHomeTel2"/>
                <mo-text-field v-model="insurdHomeTel" ref="insurdHomeTel" :maxlength="8" mask="########" type="number" underline placeholder="00000000" class="mb0 txt-center" clearable/>
              </div>
            </div>

            <div class="mt20 relative-div fexTy4" @click="fn_FoldingExpand('mspExp2', $event)"><!-- 퍼블0525 fexTy4 우측정렬을 위해 추가 -->
              <!-- <mo-button class="ns-btn-round process-btn white">입력완료1</mo-button> -->
              <mo-button class="ns-btn-round process-btn blue" :disabled="!insuredCmpltFlag">입력완료</mo-button><!-- 퍼블0525 class="ns-btn-round process-btn blue" -->
            </div>
          </div>
        </template>
      </msp-expand>
    </ur-box-container>
    <!-- 피보험자정보 -->

    <!-- 계약자정보 -->
    <ur-box-container v-show="isContractPersonInfo" alignV="start" componentid="ur_box_container_011" direction="column">
      <msp-expand ref="mspExp1" expanded titleFirst btnAreaFirst expandOnlyBtn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" @expanded="fn_SetFocus">
        <template #title>
          <div class="list-item__contents overflow-v">
            <div class="list-item__contents__title overflow-v">
              <mo-badge class="badge-sample-badge green" text="" shape="status">계약자</mo-badge> 
              <div class="ml10">
                <div>
                  <span class="name">{{userData1.detail.custNm}}</span>
                </div>                    
                <div class="mt4 lh20px w100calc50">
                  <span class="info">{{userData1.age}}세</span>
                  <em>|</em>
                  <span class="info">{{userData1.custRrnId.substring(0,6)}}-{{userData1.custRrnId.substring(6,13)}}</span>
                </div>
                <div class="result mt6">
                  <template v-if="contractorCmpltFlag">
                    <div class="fexTy5">
                      <mo-icon icon="msp-is-checked" icon-size="27px" />
                      <span class="ml4 fwn crTy-bk7">입력완료</span>                        
                    </div>
                  </template>
                  <template  v-else>
                   <span class="fwn crTy-orange2">입력필요</span>
                  </template>
                </div>  
              </div>  
              <!-- // 고객 선택 case -->
            </div>
          </div>
        </template>
        <template #btn>
          <mo-button class="link-arrow">
            <mo-icon icon="expand_menu" class="expand__icon" />                  
            <span class="open">접기</span>
            <span class="close">열기</span>
          </mo-button>
        </template>
        <template #content>
          <div class="con-area pt10 pb30">
            <div class="w100">
              <div class="contain-title">직업정보</div>
              <span class="dsD label-title must">직장명</span>
              <mo-text-field ref="contractor1" v-model="contractorJobTitle" @focus="fn_setTxtInputFocus('rootDivBox','contractor1',$event)" @blur="fn_setTxtInputBlur('rootDivBox','contractor1',$event)" class="form-input-name full" :rules="validateRuleContractorJob" underline placeholder="입력하세요(필수)" :maxlength="6" clearable/>

              <div class="fexTy-half20">
                <div>
                  <span class="dsD label-title mt20">업종</span>
                  <mo-text-field ref="contractorJobType" v-model="contractorJobType" @focus="fn_setTxtInputFocus('rootDivBox','contractorJobType',$event)" @blur="fn_setTxtInputBlur('rootDivBox','contractorJobType',$event)" class="form-input-name full" :maxlength="10" underline placeholder="" clearable/>
                </div>  
                <div>
                  <span class="dsD label-title must mt20">하시는 일</span>
                  <mo-text-field ref="contractor2" v-model="contractorWork" @focus="fn_setTxtInputFocus('rootDivBox','contractor2',$event)" @blur="fn_setTxtInputBlur('rootDivBox','contractor2',$event)" class="form-input-name full" :rules="validateRuleContractorWork" underline placeholder="입력하세요(필수)" :maxlength="10" clearable/>
                  <!-- {{userData1.detail.hasOwnProperty('jobCdNm') ? userData1.detail.jobCdNm : ''}} <strong>{{userData1.detail.hasOwnProperty('jobCd') ? userData1.detail.jobCd : ''}}</strong> -->
                  <!-- <div class="fs12rem crTy-bk7 lh20px mt6">{{userData1.detail.hasOwnProperty('jobCdNm') ? userData1.detail.jobCdNm : ''}}<br><strong>{{userData1.detail.hasOwnProperty('jobCd') ? userData1.detail.jobCd : ''}}</strong></div> -->
                  <div class="fs14rem crTy-bk7 mt5"><span class="inline-block mr5">{{userData1.detail.hasOwnProperty('jobCdNm') ? userData1.detail.jobCdNm : ''}}</span> <span class="inline-block">{{userData1.detail.hasOwnProperty('jobCd') ? userData1.detail.jobCd : ''}}</span></div> <!-- 수정0530 -->
                </div>  
               </div>  
              <div class="full48 h10px bgcolor-3 mt30"></div>
            </div>

            <div class="w100 mt30">
              <div class="contain-title">연락처정보</div>
              <span class="dsD label-title">휴대폰번호</span>
              <!-- <span class="mt10 w100 mh40px fs17rem bd-b-Ty1 fexTy5-1">{{ (userData1.detail.celno.trim().length > 0) ? userData1.detail.celno : '없음' }}</span> -->
              <mo-text-field type="none" underline class="form-input-name full" :value="(userData1.detail.celno.trim().length > 0) ? userData1.detail.celno : '없음'" readonly/>

              <span class="dsD label-title mt20 must">문자서비스</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData1" class="chip-type-wrap chip-ty1" ref="contractor3" :rules="validateRuleContractorSms">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData1" :value="item.key" :key="idx" :disabled="userData1.detail.celno.trim().length < 1">{{item.label}}</mo-segment-button>
                  </div>
                </mo-segment-wrapper>
              </ur-box-container>
              <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                <li>휴대전화 문자메시지 또는 이에 준하는 전자적 의사표시 활용 안내입니다. (전자적 의사표시의 예 : 카카오알림톡, 모니모 등 App을 통한 안내)</li>
                <li>기존에 받아보시던 안내가 있을 경우 동의내용이 함께 적용됩니다. (기존 안내의 예 : 변액보험계약변동안내, 변액자산보고운용서 등)</li>
              </ul>

              <span class="dsD label-title mt20 must">해외거주자/납세의무자 여부</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData2" class="chip-type-wrap chip-ty1" ref="contractor4" :rules="validateRuleContractorFatca">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData2" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </div>  
                </mo-segment-wrapper>
              </ur-box-container>

              <span class="dsD label-title mt20 must">거주구분</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData3" class="chip-type-wrap chip-ty1" ref="contractor5" :rules="validateRuleContractorNation">
                  <div class="wrapper-inner"> 
                    <mo-segment-button v-for="(item, idx) in segmentData3" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </div>  
                </mo-segment-wrapper>
              </ur-box-container>

              <template v-if="planInfo && planInfo.vainsUnQulfYn && planInfo.vainsUnQulfYn === 'N'">
                <span class="dsD label-title mt20 must">일반/전문금융소비자 여부</span>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                  <mo-segment-wrapper solid primary v-model="returnData13" class="chip-type-wrap chip-ty1" ref="contractor9" :rules="validateRuleContractorFinance">
                    <div class="wrapper-inner">
                      <mo-segment-button v-for="(item, idx) in segmentData13" :value="item.key" :key="idx" disabled>{{item.label}}</mo-segment-button>
                    </div>  
                  </mo-segment-wrapper>
                </ur-box-container>
                <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                  <li>금융소비자보호에 관한 법률 제17조 제1항과 제2조 9호에 따라 계약자가 일반금융소비자인지 전문금융소비자인지 여부를 확인합니다.</li>
                </ul>

                <span v-if="returnData13.length > 0 && returnData13 === 'Y'" class="dsD label-title mt20 must">전문 -> 일반 금융소비자 전환</span>
                <ur-box-container v-if="returnData13.length > 0 && returnData13 === 'Y'" alignV="start" componentid="" direction="column" class="ns-segment mt10">
                  <mo-segment-wrapper solid primary v-model="returnData14" class="chip-type-wrap chip-ty1" ref="contractor10" :rules="validateRuleContractorNormalFinance">
                    <div class="wrapper-inner">
                      <mo-segment-button v-for="(item, idx) in segmentData14" :value="item.key" :key="idx" disabled>{{item.label}}</mo-segment-button>
                    </div>  
                  </mo-segment-wrapper>
                </ur-box-container>
              </template>

              <div class="full48 h10px bgcolor-3 mt30"></div>
            </div>
            

            <div class="w100 mt30">
              <div class="contain-title">수령지정보</div>
              <span class="dsD label-title">이메일</span>
              <div class="ns-certify-sed pal0"><!-- error  class 추가로 상태변환 -->
                <div class="ns-certify-sed-code">
                  <msp-email class="form-input-name full" underline v-model="userData1.detail.emailAddr" placeholder="이메일을 입력해주세요" clearable/>
                </div>
              </div>

              <span class="dsD label-title mt20 must">이메일서비스</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData4" class="chip-type-wrap chip-ty1" ref="contractor6" :rules="validateRuleContractorEmail">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData4" :value="item.key" :key="idx" :disabled="userData1.detail.emailAddr.trim().length<1">{{item.label}}</mo-segment-button>
                  </div>
                </mo-segment-wrapper>
              </ur-box-container>
              <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                <li>문자와 이메일 수신에 모두 동의하신 경우, 회사에서 정한 방법으로 안내드립니다.</li>
              </ul>

              <span class="dsD label-title mt20 must">증권수령방법</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData5" class="chip-type-wrap chip-ty1" ref="contractor7" :rules="validateRuleContractorReceipt">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData5" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </div>  
                </mo-segment-wrapper>
              </ur-box-container>
              <div class="mt10">
                <!-- <span v-if="!returnData5ValidateCmpltFlag" class="dsD fs14rem crTy-orange2"><mo-icon icon="warning" class="mr4"></mo-icon>증권수령방법 항목은 필수 정보입니다.</span> -->
                <div class="text-gray-box mt10">
                  <ul class="terms-list-area crTy-bk7 fs14rem">
                    <li>모바일(휴대폰)/이메일은 본인명의만 신청 가능합니다.</li>
                    <li>모바일/이메일 증권 전달 실패시 서면교부(방문전달)드리며, 방문전달시 보험증권수령 후 서명확인이 필요합니다.</li>
                  </ul>
                </div>
                <div class="ns-check mt10 fexTy5">
                  <mo-checkbox small class="crTy-bk7" v-model="secusCvancMth_paper" :disabled="secusCvancMth_paper_disable">서면교부(방문전달)필요시 신청 바랍니다.</mo-checkbox>
                </div>
              </div>

              <span class="dsD label-title mt20 must">주소</span>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="returnData6" class="chip-type-wrap chip-ty1" ref="contractor8" :rules="validateRuleContractorAddr">
                  <div class="wrapper-inner">
                    <mo-segment-button v-for="(item, idx) in segmentData6" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </div>  
                </mo-segment-wrapper>
              </ur-box-container>
              
              <mo-text-field v-model="cntrctAddressInfo1" class="form-input-name full mt20" value="우편번호 검색하기" underline searchable placeholder="우편번호 검색하기" @click-icon="fn_ZipCodeSrch('01')" readonly :disabled="contractZipCodeEnable"/>
              <span class="mt10 w100 mh40px fs17rem bd-b-Ty fexTy5-1">{{cntrctAddressInfo2}}</span>
              
              <!-- <span class="dsD fs14rem crTy-bk7 mt20">상세주소</span>
              <div class="verify-phone msp-verify-phone">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ZipCodeSrch" value="01" :disabled="contractZipCodeEnable">우편번호</mo-button>
                <mo-text-field v-model="cntrctAddressInfo" readonly/>
              </div> -->

              <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                <li>주소는 자택/직장 중 현재 선택된 주소 구분에 대한 정보만 수정됩니다.</li>
                <li>자택/직장 중 선택하여 입력한 주소구분에 따라 청약서상 기본우편물 수령처로 지정됩니다.</li>
              </ul>

              <span class="dsD label-title mt20">전화번호</span>
              <div class="verify-phone msp-verify-phone">
                <msp-bottom-select :itemHeight="374" underline class="ns-dropdown-sheet mr20" placeholder="선택" bottom-title="지역번호" closeBtn scrolling :itemValue="'key'" :itemText="'label'" v-model="contractorHomeTelSelect.label" :items="homeTelList" @selectedItem="fn_initHomeTel1"/>
                <mo-text-field v-model="contractorHomeTel" ref="contractorHomeTel" :maxlength="8" mask="########" type="number" underline placeholder="00000000" class="mb0 txt-center" clearable/>
              </div>
            </div>

            <div class="mt20 relative-div fexTy4" @click="fn_FoldingExpand('mspExp1', $event)"><!-- 퍼블0525 fexTy4 우측정렬을 위해 추가 -->
              <!-- <mo-button class="ns-btn-round process-btn white">입력완료1</mo-button> -->
              <mo-button class="ns-btn-round process-btn blue" :disabled="!contractorCmpltFlag">입력완료</mo-button><!-- 퍼블0525 class="ns-btn-round process-btn blue" -->
            </div>
          </div>
        </template>
      </msp-expand>
    </ur-box-container>
    <!-- 계약자정보 -->

    <!-- 수익자 (계약자) -->
    <ur-box-container v-if="isBnfrInfo31 || isBnfrInfo32 || isBnfrInfo33 || isBnfrInfo39" alignV="start" componentid="ur_box_container_011" direction="column">
      <msp-expand expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
        <template #title>
          <div class="list-item__contents overflow-v">
            <div class="list-item__contents__title overflow-v">
              <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
              <div class="ml10">
                <div>
                  <span class="name">{{ bnfrInfo1.custNm }}</span>
                  <mo-badge class="badge-sample-badge sm ml10 lightgreen" text="" shape="status">계약자</mo-badge>
                </div>                    
                <div class="mt4 lh20px w100calc50">
                  <span class="info">{{bnfrInfo1.age}}세</span>
                  <em>|</em>
                  <span class="info">{{bnfrInfo1.knbEncr1}}-{{bnfrInfo1.knbEncr2}}</span>
                </div>                
              </div>  
            </div>
          </div>  
        </template>
        <template #btn>
          <mo-button class="link-arrow">
            <mo-icon icon="expand_menu" class="expand__icon" />                  
            <span class="open">접기</span>
            <span class="close">열기</span>
          </mo-button>
        </template>
        <template #content>
          <mo-tab-box :defaultIdx="isBnfrInfo31 ? '1' : (isBnfrInfo32 ? '2' : (isBnfrInfo33 ? '3' : '4'))" class="ns-move-tab-box tween w100">
            <mo-tab-label idx="1" v-if="isBnfrInfo31">만기</mo-tab-label>
            <mo-tab-label idx="2" v-if="isBnfrInfo32">분할</mo-tab-label>
            <mo-tab-label idx="3" v-if="isBnfrInfo33">연금</mo-tab-label>
            <mo-tab-label idx="4" v-if="isBnfrInfo39">연금후</mo-tab-label>
            <mo-tab-content idx="1">
              <!-- tab1 -->
              <div class="con-area pt30 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field v-model="bnfrInfo31.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <!-- eslint-disable-next-line -->
                  <mo-text-field v-model="bnfrInfo31.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="bnfrInfo31.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="bnfrInfo31.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">지급률(%)</div>
                  <mo-text-field v-model="bnfrInfo31.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                </div>
              </div>
            </mo-tab-content>
            <mo-tab-content idx="2">
              <!-- tab2 -->
              <div class="con-area pt30 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field v-model="bnfrInfo32.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <!-- eslint-disable-next-line -->
                  <mo-text-field v-model="bnfrInfo32.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="bnfrInfo32.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="bnfrInfo32.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">지급률(%)</div>
                  <mo-text-field v-model="bnfrInfo32.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                </div>
              </div>
            </mo-tab-content>
            <mo-tab-content idx="3">
              <!-- tab3 -->
              <div class="con-area pt30 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field v-model="bnfrInfo33.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <!-- eslint-disable-next-line -->
                  <mo-text-field v-model="bnfrInfo33.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="bnfrInfo33.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="bnfrInfo33.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">지급률(%)</div>
                  <mo-text-field v-model="bnfrInfo33.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                </div>
              </div>
            </mo-tab-content>
            <mo-tab-content idx="4">
              <!-- tab4 -->
              <div class="con-area pt30 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field v-model="bnfrInfo39.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <!-- eslint-disable-next-line -->
                  <mo-text-field v-model="bnfrInfo39.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="bnfrInfo39.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="bnfrInfo39.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">지급률(%)</div>
                  <mo-text-field v-model="bnfrInfo39.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                </div>
              </div>
            </mo-tab-content>      
          </mo-tab-box>        
        </template>
      </msp-expand>
    </ur-box-container>
    <!-- 수익자 (계약자) end -->

    <!-- 수익자 (주피) -->
    <ur-box-container v-if="isBnfrInfo34 || isBnfrInfo35" alignV="start" componentid="ur_box_container_011" direction="column">
      <msp-expand expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
        <template #title>
          <div class="list-item__contents overflow-v">
            <div class="list-item__contents__title overflow-v">
              <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
              <div class="ml10">
                <div>
                  <span class="name">{{ bnfrInfo2.custNm }}</span>
                  <mo-badge class="badge-sample-badge sm ml10 lightblue" text="" shape="status">주피</mo-badge>
                </div>                    
                <div class="mt4 lh20px w100calc50">
                  <span class="info">{{bnfrInfo2.age}}세</span>
                  <em>|</em>
                  <span class="info">{{bnfrInfo2.knbEncr1}}-{{bnfrInfo2.knbEncr2}}</span>
                </div>
                <div class="result mt6">
                  <template v-if="isAmlDthBnfr01Flag && dthBnfr01CmpltFlag">
                    <div class="fexTy5">
                      <mo-icon icon="msp-is-checked" icon-size="27px" />
                      <span class="ml4 fwn crTy-bk7">입력완료</span>                        
                    </div>
                  </template>
                  <template  v-else-if="isAmlDthBnfr01Flag && !dthBnfr01CmpltFlag">
                    <span class="fwn crTy-orange2">입력필요</span>
                  </template>
                </div>  
              </div>  
              <!-- // 고객 선택 case -->
            </div>
          </div>  
        </template>
        <template #btn>
          <mo-button class="link-arrow">
            <mo-icon icon="expand_menu" class="expand__icon" />                  
            <span class="open">접기</span>
            <span class="close">열기</span>
          </mo-button>
        </template>
        <template #content>
          <mo-tab-box :defaultIdx="isBnfrInfo34 ? '1' : '2'" class="ns-move-tab-box tween w100">
            <mo-tab-label idx="1" v-if="isBnfrInfo34">상해</mo-tab-label>
            <mo-tab-label idx="2" v-if="isBnfrInfo35">사망</mo-tab-label>
            <mo-tab-content idx="1">
              <!-- tab1 -->
              <div class="con-area pt30 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field v-model="bnfrInfo34.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <!-- eslint-disable-next-line -->
                  <mo-text-field v-model="bnfrInfo34.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="bnfrInfo34.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="bnfrInfo34.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">지급률(%)</div>
                  <mo-text-field v-model="bnfrInfo34.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                </div>
              </div>
            </mo-tab-content>
            <mo-tab-content idx="2">
              <!-- tab2 -->
              <!-- 사망수익자 주소  / (AML 보완 / 2311_PJO)_START -->
              <div class="con-area pt30 pb30" v-if="isAmlDthBnfr01Flag">
                <span class="dsD label-title must mt20">주소</span>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                  <mo-segment-wrapper solid primary v-model="returnDataDthBnfr01" class="chip-type-wrap chip-ty1">
                    <mo-segment-button v-for="(item, idx) in segmentData6" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
                
                <mo-text-field v-model="isBnfrInfo35Info1" class="form-input-name full mt20" value="우편번호 검색하기" underline searchable placeholder="우편번호 검색하기" @click-icon="fn_ZipCodeSrch('dthBnfr01')" readonly :disabled="isBnfrInfo35ZipCodeEnable"/>
                <span class="mt10 w100 mh40px fs17rem bd-b-Ty fexTy5-1">{{isBnfrInfo35Info2}}</span>
              </div>
              <!-- 사망수익자 주소 / (AML 보완 / 2311_PJO)_END -->
              <div class="con-area pt30 pb30">
                <div class="text-blue-box bgcolor-7 pt16 pb16 mb10" v-for="(bnfrInfo, index) in bnfrInfo35" :key="index">
                  <div class="fexTy5">
                    <span class="fs18rem ml0 flex-1 fwm">{{bnfrInfo.custNm}}</span>
                    <!-- <mo-button class="ns-btn-round h28 blue-line" v-if="bnfrInfo.bnfrSno === 1">수정</mo-button> -->
                  </div>
                  <span class="dsD ml0 mt10"><span class="fs14rem ml0 crTy-bk7 min100">주민등록번호</span>{{bnfrInfo.knbEncr1}}-{{bnfrInfo.knbEncr2}}</span>
                  <span class="dsD ml0 mt4"><span class="fs14rem ml0 crTy-bk7 min100">연령</span>{{bnfrInfo.age}}세</span>
                  <span class="dsD ml0 mt4" v-if="bnfrInfo.cusDtlTypCd === '11'"><span class="fs14rem ml0 crTy-bk7 min100">내외국인구분</span>내국인</span>
                  <span class="dsD ml0 mt4" v-if="bnfrInfo.cusDtlTypCd === '12'"><span class="fs14rem ml0 crTy-bk7 min100">내외국인구분</span>외국인</span>
                  <span class="dsD ml0 mt4"><span class="fs14rem ml0 crTy-bk7 min100">지급률</span>{{bnfrInfo.shareVl}}%</span>
                  <!-- <ul class="terms-list-area crTy-orange2 fs14rem mt10" v-if="bnfrInfo.bnfrSno === 1">
                    <li>AML 대상입니다. 고객정보를 확인해 주세요.</li>
                  </ul> -->
                </div>

              </div>
            </mo-tab-content>
          </mo-tab-box>        
        </template>
      </msp-expand>
    </ur-box-container>
    <!-- 수익자 (주피) end -->
      
    <ur-box-container direction="column" align-v="start" class="ns-agreeArea2">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="nextBtn">다음</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
   </ur-box-container>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/

import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSElstUtil from '@/ui/ps/comm/PSElstUtil'
import MSPCM130P from '@/ui/cm/MSPCM130P'
import MSPPS421P from '@/ui/ps/MSPPS421P'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS422D', 
  screenId: 'MSPPS422D',

  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    commData: {type: Object},
    scrollId: {type: String}
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return { 
      validateRuleContractorJob: [
          (v) => !!v || '필수입니다. 직장명을 입력하세요.'
      ],
      validateRuleContractorWork: [
        (v) => !!v || '필수입니다.하시는 일을 입력하세요.'
      ],
      validateRuleInsuredJob: [
          (v) => !!v || '필수입니다. 직장명을 입력하세요.'
      ],
      validateRuleInsuredWork: [
        (v) => !!v || '필수입니다.하시는 일을 입력하세요.'
      ],
      validateRuleInsuredSms: [
        v => !((this.userData2.detail.celno.trim().length < 1) && !(this.returnData7 === '')) || '필수입니다. 하나를 선택하세요.',  
        v => !((this.userData2.detail.celno.trim().length > 0) && (this.returnData7 === '')) || '필수입니다. 하나를 선택하세요.'      
      ],
      validateRuleInsuredNation: [
        v => !!v || '필수입니다. 하나를 선택하세요.',
        v => !(this.returnData8 === '') || '필수입니다. 하나를 선택하세요.'               
      ],
      validateRuleInsuredEmail: [
        v => !(this.userData2.detail.emailAddr.trim().length > 0 && this.returnData9.length < 1) || '필수입니다. 하나를 선택하세요.'               
      ],
      validateRuleInsuredAddr: [
        v => !!v || '필수입니다. 하나를 선택하세요.',
        v => !(this.returnData10 === '') || '필수입니다. 하나를 선택하세요.'               
      ], 

      validateRuleContractorSms: [
        v => !((this.userData1.detail.celno.trim().length < 1) && !(this.returnData1 === '')) || '필수입니다. 하나를 선택하세요.', 
        v => !((this.userData1.detail.celno.trim().length > 0) && (this.returnData1 === '')) || '필수입니다. 하나를 선택하세요.'                        
      ],
      validateRuleContractorFatca: [
        v => !!v || '필수입니다. 하나를 선택하세요.',
        v => !(this.returnData2 === '') || '필수입니다. 하나를 선택하세요.'               
      ],
      validateRuleContractorNation: [
        v => !!v || '필수입니다. 하나를 선택하세요.',
        v => !(this.returnData3 === '') || '필수입니다. 하나를 선택하세요.'               
      ],
      validateRuleContractorFinance: [             
        v => !(this.returnData13 === '') || '필수입니다. 하나를 선택하세요.'
      ],
      validateRuleContractorNormalFinance: [             
        v => !(this.returnData14 === '') || '필수입니다. 하나를 선택하세요.' 
      ],
      validateRuleContractorEmail: [
        v => !(this.userData1.detail.emailAddr.trim().length > 0 && this.returnData4.length < 1)   || '필수입니다. 하나를 선택하세요.'
      ],
      validateRuleContractorReceipt: [
        v => !!v || '필수입니다. 하나를 선택하세요.',
        v => !(this.returnData5 === '') || '필수입니다. 하나를 선택하세요.'               
      ],
      validateRuleContractorAddr: [
        v => !!v || '필수입니다. 하나를 선택하세요.',
        v => !(this.returnData6 === '') || '필수입니다. 하나를 선택하세요.'               
      ],

      bnfrInfo31 : {}, //만기수익자
      bnfrInfo32 : {}, //분할수익자
      bnfrInfo33 : {}, //연금수익자
      bnfrInfo34 : {}, //상해수익자
      bnfrInfo35 : [], //사망수익자 (최대4명)
      bnfrInfo39 : {}, //연금후수익자
      isBnfrInfo31 : false,
      isBnfrInfo32 : false,
      isBnfrInfo33 : false,
      isBnfrInfo34 : false,
      isBnfrInfo35 : false,
      isBnfrInfo39 : false,
      bnfrInfo1 : {},  //수익자명 (계약자) 노출정보
      bnfrInfo2 : {},  //수익자명 (주피) 노출정보

      alert: false,
      alertMssge: '',
      //alertCloseCnt: 0,
      //alertInfo1: '',
      //alertInfo2: '',
      //lv_AlertPop: null,  // 확인 팝업
      //lv_ShowAlert: null,  // 다음클릭시 확인팝업 (계약자)
      //lv_ShowAlert2: null,  // 다음클릭시 확인팝업 (피보험자)
      serviceCmpltCnt: 0, // 모든 서비스 완료여부 카운트
      cntrctAddressInfo: '', // 계약자 주소
      cntrctAddressInfo1: '', // 계약자 기본주소
      cntrctAddressInfo2: '', // 계약자 상세주소
      insurdAddressInfo: '', // 피보험자 주소
      insurdAddressInfo1: '', // 피보험자 기본주소
      insurdAddressInfo2: '', // 피보험자 상세주소
      nextBtnFlag: true, // 다음버튼 활성 True-비활성 False-활성
      newContractAddressFlag: false,
      newInsuredAddressFlag: false,
      newContractAddressInfo1: {}, // 계약자 자택 새주소
      newContractAddressInfo2: {}, // 계약자 직장 새주소
      newInsuredAddressInfo1: {}, // 피보험자 자택 새주소
      newInsuredAddressInfo2: {}, // 피보험자 직장 새주소
      addrSrchContractInsuredDiv: '', // 주소검색시 계약자인지 피보험자인지 구분값 -> '01':계약자, '02':피보험자, dthBnfr01 사망수익자01
      inputData: {},
      planInfo: {},
      contractorCertify: false, // 계약자 인증여부
      insuredCertify: false, // 피보험자 인증여부
      userData1: {}, // 계약자 정보
      userData2: {}, // 피보험자 정보
      userData3: {}, // 수익자 정보
      userData4: {}, // 종피보험자 정보
      unchYn: false, // 주피보험자 태아여부
      txTSSPS22S4DataContractor: {}, // 계약자 정보 조회데이터
      txTSSPS22S4DataInsurd: {}, // 피보험자 정보 조회데이터
      contractorCustCardRegMdfcSVO: {}, // 계약자 정보 조회 결과데이터
      insurdCustCardRegMdfcSVO: {}, // 피보험자 정보 조회 결과데이터
      txTSSPS13U1Data: {}, // 계약자 정보 CRM 업데이트
      psBefrInptInfoSVO: {}, // 계약자 정보 신태블릿(자체) 테이블 업데이트
      psBPCustCardInfoSVO: {}, // 계약자,피보험자 구직업코드,자택및 직장주소 검증용 BP 업데이트 저장데이터
      txTSSPS13P1Data: {}, // fatca 데이터
      //isContOpen: true, // 계약자 상세영역 on/off
      //isInsOpen: true, // 피보험자 상세영역 on/off
      isContractPersonInfo: false, // 계약자 정보 on/off
      isInsPersonInfo: false, // 피보험자 정보 on/off
      isContInsFlag: false, // 계약자 피보험자 동일여부
      contractorJobTitle: '', // 계약자 직장명
      contractorJobType: '', // 계약자 업종
      contractorWork: '', // 계약자 하시는일
      insuredJobTitle: '', // 피보험자 직장명
      insuredJobType: '', // 피보험자 업종
      insuredWork: '', // 피보험자 하시는일
      returnData1: null,
      returnData2: null,
      returnData3: null,
      returnData4: null,  //계약자 이메일서비스
      returnData5: null,
      returnData6: null,  //계약자 자택직장 구분코드
      returnData7: null,
      returnData8: null,
      returnData9: null,  //피보험자 이메일서비스
      returnData10: null, //피보험자 자택직장 구분코드
      // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
      returnData13: null,
      returnData14: null,
      contractorHomeTel: '',
      insurdHomeTel: '',
      //inputEmailAddr1: '', // 계약자 입력 이메일 주소
      //inputEmailAddr2: '', // 피보험자 입력 이메일 주소
      //inputEmailDomain1: '', // 계약자 입력 이메일 도메인
      //inputEmailDomain2: '', // 피보험자 입력 이메일 도메인
      //selEmailDomain1: {}, // 계약자 선택 도메인
      //selEmailDomain2: {}, // 피보험자 선택 도메인
      //editContractorEmailAddr: '', // 계약자 수정된 이메일 주소
      //editInsuredEmailAddr: '', // 피보험자 수정된 이메일 주소
      //editEmailFlag1: false, // 계약자 이메일 수정여부
      //editEmailFlag2: false, // 피보험자 이메일 수정여부
      //editEmailCmpltFlag1: false, // 계약자 이메일 수정완료
      //editEmailCmpltFlag2: false, // 피보험자 이메일 수정완료
      //editEmailBtnMsg1: '', // 계약자 이메일 버튼 '입력','수정','수정완료'
      //editEmailBtnMsg2: '', // 피보험자 이메일 버튼 '입력','수정','수정완료'
      contractorJobTitleValidateCmpltFlag: '',
      contractorWorkValidateCmpltFlag: '',
      insuredJobTitleValidateCmpltFlag: '',
      insuredWorkValidateCmpltFlag: '',
      returnData1ValidateCmpltFlag: false, // 계약자 문자수신
      returnData2ValidateCmpltFlag: false, // 계약자 해외거주자/납세 여부
      returnData3ValidateCmpltFlag: false, // 계약자 거주구분
      returnData4ValidateCmpltFlag: false, // 계약자이메일 서비스 수신 여부
      returnData5ValidateCmpltFlag: false, // 계약자 증권수령방법
      returnData6ValidateCmpltFlag: false, // 계약자 주소 선택
      returnData7ValidateCmpltFlag: false, // 피보험자 문자서비스 수신여부
      returnData8ValidateCmpltFlag: false, // 피보험자 거주구분
      returnData9ValidateCmpltFlag: false, // 피보험자 거주구분
      returnData10ValidateCmpltFlag: false, // 피보험자 주소 선택
      // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
      returnData13ValidateCmpltFlag: false, // 일반/전문 금융소비자 여부
      returnData14ValidateCmpltFlag: false, // 전문->일반 금융소비자 전환
      contractorCmpltFlag : false,
      insuredCmpltFlag : false,
      isInitEnd: false, // init 완료여부
      // ASR210800597_증권수령방법 변경 (20210907_PJO)
      secusCvancMth_paper: false, // 증권수령방법_지류
      secusCvancMth_paper_disable: true, // 증권수령방법_지류_보이고안보이고
      segmentData1: [{
        'key': '01',
        'label': '수신'
      },
      {
        'key': '02',
        'label': '수신안함'
      }],
      segmentData2: [{
        'key': '02',
        'label': '해당없음'
      },
      {
        'key': '01',
        'label': '해당함'
      }],
      segmentData3: [{
        'key': '01',
        'label': '국내'
      },
      {
        'key': '02',
        'label': '해외'
      }],
      segmentData4: [{
        'key': '01',
        'label': '수신'
      },
      {
        'key': '02',
        'label': '수신안함'
      }],
      // 고액낱장 -> 변경 : 방문(고액브로셔)
      // 지점발행 -> 변경 : 방문(지점발행)
      // 변경: 방문(고액브로셔) -> 고액브로셔/방문 (ASR200600254_[증권] 사랑On 증권접수화면 텍스트 변경_20200623 PJO )
      segmentData5: [],
      segmentData5Mock1: [{
        'key': 'M',
        'label': '모바일(휴대폰)'
      }],
      segmentData5Mock2: [{
        'key': '4',
        'label': '이메일'
      }],
      // 주석처리 (20210907_PJO)
      // segmentData5Mock3: [{
      //   'label': '고액브로셔/방문',
      //   'key': '7'
      // }],
      // segmentData5Mock4: [{
      //   'label': '방문(지점발행)',
      //   'key': '8'
      // }],
      segmentData6: [{
        'key': '01',
        'label': '자택'
      },
      {
        'key': '02',
        'label': '직장'
      }],
      // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
      segmentData13: [{
        'key': 'N',
        'label': '일반'
      },
      {
        'key': 'Y',
        'label': '전문'
      }],
      segmentData14: [{
        'key': 'N',
        'label': '아니오'
      },
      {
        'key': 'Y',
        'label': '예'
      }],

      contractorHomeTelSelect: {},
      insurdHomeTelSelect: {},
      homeTelList: [], // 전화번호 국번 리스트
      emailDomainList: [], // 이메일 도메인 목록 리스트
      affordance: true, // 181126 어포던스 화살표 추가
      scrollTop: 0,
      isScroll: false,
      //offsetVal: 98,
      insrdTrtyFgr: '', // 주피보험자 특약수
      aisdTrtyFgr: '', // 종피보험자 특약수
      secusFrmCd: ' ', // 증권양식코드RD
      untyCovrInsrTypCd: ' ', // 통합보장보험유형코드RD
      ptclrPrdtTypCd: ' ', // 특이상품유형코드RD
      kliaInsrScCd: ' ', // 생보협회상품분류코드RD
      contractDivCd: '10', // 계약업무구분코드 - 전자서명은 개인신계약만 해당 함
      // scnAcpnRstcYn: false, // 임직원 여부 //  주석처리  2019.11.19 PJO
      selfContYn: false, // 임직원 여부 (계약자만체크) 2019.11.19 PJO
      fpContYn: false, // 설계사계약 여부 - [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
      segmentData5Type: '1', // 증권수령방법 표시 중입 버튼 유형
      useEmail: true, // email사용
      useSns: true, // sns 사용
      // 전산심사결과 중 사망수익자01 이 AML( EDD) 대상인경우  주소 정보 변경 가능  (AML 보완 / 2311_PJO)
      isAmlDthBnfr01Flag: false,
      dthBnfr01CmpltFlag : false, // 사망수익자 입력완료 플래그
      returnDataDthBnfr01: null, //사망수익자01 자택직장 구분코드
      userData3DthBnfr01AML: {}, // 사망수익자01 정보
      returnDataDthBnfr01ValidateCmpltFlag: false, // 사망수익자01 주소 선택
      isBnfrInfo35Info: '', // 사망수익자01 주소
      isBnfrInfo35Info1: '', // 사망수익자01 기본주소
      isBnfrInfo35Info2: '', // 사망수익자01 상세주소
      newIsBnfrInfo35AddressInfo1: {}, // 사망수익자01 자택 새주소
      newIsBnfrInfo35AddressInfo2: {}, // 사망수익자01 직장 새주소
      newContractAddressFlag: false, // 사망수익자01 새주소 선택 플래그
      isBnfrInfo35CustCardRegMdfcSVO: {} // 사망수익자01 CRM 고객카드 업데이트 할 정보세팅 (주소정보만 업데이트함)
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    //강제로 watch를 일으키기 위해 data에서 null로 초기화후 create에서 다시 초기화
    this.returnData1 = ''
    this.returnData1 = ''
    this.returnData2 = ''
    this.returnData3 = ''
    this.returnData4 = ''
    this.returnData5 = ''
    this.returnData6 = ''
    this.returnData7 = ''
    this.returnData8 = ''
    this.returnData9 = ''
    this.returnData10 = ''
    this.returnData13 = ''
    this.returnData14 = ''
    this.returnDataDthBnfr01 = '' // (AML 보완 / 2311_PJO)
    //this.returnData4= ''  //강제로 watch를 일으키기 위해 data에서 null로 초기화후 ''로 다시 초기화
    //this.returnData9= ''  //강제로 watch를 일으키기 위해 data에서 null로 초기화후 ''로 다시 초기화
    // backButton event 등록
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    if (this.commData.hasOwnProperty('inputData')) {
      this.inputData = this.commData.inputData
    }
    if (this.commData.hasOwnProperty('planInfo') && this.commData.planInfo !== null) {
      this.planInfo = this.commData.planInfo[0]
    }
    if (this.commData.hasOwnProperty('contractorCertify')) {
      this.contractorCertify = this.commData.contractorCertify
    }
    if (this.commData.hasOwnProperty('insuredCertify')) {
      this.insuredCertify = this.commData.insuredCertify
    }
    if (this.commData.hasOwnProperty('userData1')) {
      this.userData1 = this.commData.userData1
    }
    if (this.commData.hasOwnProperty('userData2')) {
      this.userData2 = this.commData.userData2
    }
    if (this.commData.hasOwnProperty('userData3')) {
      this.userData3 = this.commData.userData3
      
      this.fn_SetBnfrInfo() //수익자 화면노출정보 설정
      // 사망수익자01이 AML(EDD)대상일경우 MSPPS420M  에서 세팅됨 (AML 보완 / 2311_PJO)_START
      if (this.commData.hasOwnProperty('isAmlDthBnfr01')) {
        this.isAmlDthBnfr01Flag = this.commData.isAmlDthBnfr01 // 사망수익자edd 인경우 true / 아니면  false (발행전문에 사망수익자EDD서류포함되었는경우 TRUE)
      }
      if (this.commData.hasOwnProperty('userData3DthBnfr01AML')) {
        this.userData3DthBnfr01AML = this.commData.userData3DthBnfr01AML // 사망수익자1의 고객카드 정보(txTSSPS13S4/F1MDI0270) + BPID/채널고객ID/고객명
        this.isBnfrInfo35CustCardRegMdfcSVO = this.commData.userData3DthBnfr01AML.detail // MSPPS420M -> 사망수익자1의 고객카드 정보 조회(txTSSPS13S4/F1MDI0270)
        // 사망수익자 주소 디폴트값 : 자택/직장 중에  1)주소있는 값으로 디폴트 / 2) 둘다 있으면 자택 / 둘다 없으면 미선택
        // this.userData3DthBnfr01AML.detail.homFrnoPstcd = " " //  test 자택삭제 
        if ( this.userData3DthBnfr01AML.detail.homFrnoPstcd.trim().length > 0 ) {
          this.returnDataDthBnfr01 = '01' // 자택
        } else if (this.userData3DthBnfr01AML.detail.jobpFrnoPstcd.trim().length > 0 ) {
          this.returnDataDthBnfr01 = '02' // 직장
        }
        console.log('>>사망수익자주소.디폴트:' + this.returnDataDthBnfr01)
      }
      // 사망수익자01이 AML(EDD)대상일경우 MSPPS420M  에서 세팅됨 (AML 보완 / 2311_PJO)_END
    }
    if (this.commData.userData4 !== undefined) {
      this.userData4 = this.commData.userData4
    } else {
      this.userData4 = undefined
    }
    if (this.commData.unchYn !== undefined) {
      this.unchYn = true
    }

    var lv_Vm = this
    // 공통코드 조회(전화번호 국번 목록, 이메일 도메인 목록)
    window.vue.getInstance('vue').$commonUtil.selListMassEACommCd(['ZA_TELNO_DSTRT_CD', 'ZA_ELTRN_MAL_DM_CD']).then(function () {
      let comHomeTelList = lv_Vm.getStore('code').getters.getState.get('ZA_TELNO_DSTRT_CD')
      let comEmailDomainList = lv_Vm.getStore('code').getters.getState.get('ZA_ELTRN_MAL_DM_CD')

      if (comHomeTelList !== null && comHomeTelList.length > 0) {
      // 전화번호 국번 fdp-select 에 key,label에 맞게 데이터 가공
        let o0 = { key: '', label: '선택' }
        lv_Vm.homeTelList.push(o0)
        comHomeTelList.forEach(item => {
          if (item.key.trim().length > 0) {
            let o = {}
            o.key = o.label = item.key
            lv_Vm.homeTelList.push(o)
          }
        })
      }

      if (comEmailDomainList !== null && comEmailDomainList.length > 0) {
        let t = { key: '00', label: '선택' }
        lv_Vm.emailDomainList.push(t)
        comEmailDomainList.forEach(item => {
          if (item.key.trim().length > 0) {
            t = {}
            t.key = item.key
            t.label = item.label
            lv_Vm.emailDomainList.push(t)
          }
        })
      }
    })
    //this.selEmailDomain1 = { key: '00', label: '선택' }
    //this.selEmailDomain2 = { key: '00', label: '선택' }

    if (this.userData1.hasOwnProperty('insrdNm') && this.userData2.hasOwnProperty('insrdNm')) { // 계피상이
      if (this.contractorCertify) { // 계약자 본인인증 여부
        this.isContractPersonInfo = true

        // 계약자, 피보험자 집전화번호 셋팅
        if (this.userData1.detail.homTelno.trim().length > 0) {
          this.contractorHomeTelSelect = {label: this.userData1.detail.homTelno.substring(0, this.userData1.detail.homTelno.indexOf('-')), key: this.userData1.detail.homTelno.substring(0, this.userData1.detail.homTelno.indexOf('-'))}
          this.contractorHomeTel = this.$bizUtil.replaceAll(this.userData1.detail.homTelno.substring( (this.userData1.detail.homTelno.indexOf('-') + 1), this.userData1.detail.homTelno.length), '-', '')
        }
        // 고객정보 업데이트를 위한 CRM고객카드 조회(계약자, 피보험자 -> 업데이트 저장시 계약자, 피보험자 별개로 저장)
        this.txTSSPS22S4DataContractor = {'custNm': this.userData1.detail.custNm, 'chnlCustId': this.userData1.detail.chnlCustId, 'cnsltNo': this.userData1.detail.cnsltNo}
        this.serviceCall('txTSSPS22S4', 'di')
        // 계약자 임직원 여부 조회
        this.serviceCall('txTSSPS18S8', 'ind') // txTSSPS18S7 -> txTSSPS18S8  2019.11.19 PJO
      }
      if ( this.insuredCertify ) { // 피보험자 본인인증 여부
        this.isInsPersonInfo = true

        if (this.userData2.detail.homTelno.trim().length > 0) {
          this.insurdHomeTelSelect = {label: this.userData2.detail.homTelno.substring(0, this.userData2.detail.homTelno.indexOf('-')), key: this.userData2.detail.homTelno.substring(0, this.userData2.detail.homTelno.indexOf('-'))}
          this.insurdHomeTel = this.$bizUtil.replaceAll(this.userData2.detail.homTelno.substring( (this.userData2.detail.homTelno.indexOf('-')) + 1, this.userData2.detail.homTelno.length), '-', '')
        }
        this.txTSSPS22S4DataInsurd = {'custNm': this.userData2.detail.custNm, 'chnlCustId': this.userData2.detail.chnlCustId, 'cnsltNo': this.userData2.detail.cnsltNo}
        this.serviceCall('txTSSPS22S5', 'di')
      }
      this.isContInsFlag = false // 피보험자 유무 체크

      // 선심사에서 넘어온 근무처, 업종, 하시는일 세팅 (2024.02.16 - 선심사PJT)- 계피상이일 경우 선심사는 피보험자의 직업명만 전달함.
      if (this.commData.hasOwnProperty('nundwPvmnotice')) {
        if (this.commData.nundwPvmnotice.hasOwnProperty('OZ1_conm')) { // 근무처 세팅
          if (!this.$bizUtil.isEmpty(this.commData.nundwPvmnotice.OZ1_conm)) {
            this.insuredJobTitle = this.commData.nundwPvmnotice.OZ1_conm
          }
        }
        if (this.commData.nundwPvmnotice.hasOwnProperty('OZ1_indclnm')) { // 업종 세팅
          if (!this.$bizUtil.isEmpty(this.commData.nundwPvmnotice.OZ1_indclnm)) {
            this.insuredJobType = this.commData.nundwPvmnotice.OZ1_indclnm
          }
        }
        if (this.commData.nundwPvmnotice.hasOwnProperty('OZ1_jobnm')) { // 하시는일 세팅
          if (!this.$bizUtil.isEmpty(this.commData.nundwPvmnotice.OZ1_jobnm)) {
            this.insuredWork = this.commData.nundwPvmnotice.OZ1_jobnm
          }
        }
      }
    } else { // 계피동일
      this.isContractPersonInfo = true

      if (this.userData1.detail.homTelno.trim().length > 0) {
        this.contractorHomeTelSelect = {label: this.userData1.detail.homTelno.substring(0, this.userData1.detail.homTelno.indexOf('-')), key: this.userData1.detail.homTelno.substring(0, this.userData1.detail.homTelno.indexOf('-'))}
        this.contractorHomeTel = this.$bizUtil.replaceAll(this.userData1.detail.homTelno.substring( (this.userData1.detail.homTelno.indexOf('-') + 1), this.userData1.detail.homTelno.length), '-', '')
      }
      this.isContInsFlag = true // 피보험자 유무 체크
      this.isInsPersonInfo = false
      // 고객정보 업데이트를 위한 CRM고객카드 조회(계약자)
      this.txTSSPS22S4DataContractor = {'custNm': this.userData1.detail.custNm, 'chnlCustId': this.userData1.detail.chnlCustId, 'cnsltNo': this.userData1.detail.cnsltNo}
      this.serviceCall('txTSSPS22S4', 'di')
      // 계약자 임직원 여부 조회
      this.serviceCall('txTSSPS18S8', 'ind') // txTSSPS18S7 -> txTSSPS18S8  2019.11.19 PJO

      // 선심사에서 넘어온 근무처, 업종, 하시는일 세팅 (2024.02.16 - 선심사PJT)
      if (this.commData.hasOwnProperty('nundwPvmnotice')) {
        if (this.commData.nundwPvmnotice.hasOwnProperty('OZ1_conm')) { // 근무처 세팅
          if (!this.$bizUtil.isEmpty(this.commData.nundwPvmnotice.OZ1_conm)) {
            this.contractorJobTitle = this.commData.nundwPvmnotice.OZ1_conm
          }
        }
        if (this.commData.nundwPvmnotice.hasOwnProperty('OZ1_indclnm')) { // 업종 세팅
          if (!this.$bizUtil.isEmpty(this.commData.nundwPvmnotice.OZ1_indclnm)) {
            this.contractorJobType = this.commData.nundwPvmnotice.OZ1_indclnm
          }
        }
        if (this.commData.nundwPvmnotice.hasOwnProperty('OZ1_jobnm')) { // 하시는일 세팅
          if (!this.$bizUtil.isEmpty(this.commData.nundwPvmnotice.OZ1_jobnm)) {
            this.contractorWork = this.commData.nundwPvmnotice.OZ1_jobnm
          }
        }
      }
    }
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    //this.fn_SetFocus()
    //debugger
    PSCommUtil.fn_ScrollTopForScrollId(this.scrollId)

    //focus
    if (this.$refs.mspExp1.isExpand && this.contractorJobTitle.trim().length === 0) {
      this.$refs.contractor1.focus()
    }
    if (this.$refs.mspExp2.isExpand && this.insuredJobTitle.trim().length === 0) {
      this.$refs.insured1.focus()
    }

    if(!this.insuredCmpltFlag){ //피보험자 입력필요
        this.$refs['mspExp2'].isExpand =  true
    }
    if(!this.contractorCmpltFlag){//계약자 입력필요
        this.$refs['mspExp1'].isExpand =  true
    }
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    contractorJobTitle () {
      if (this.contractorJobTitle.trim().length > 0) {
        this.contractorJobTitleValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.contractorCmpltFlag = false
        this.contractorJobTitleValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    contractorWork () {
      if (this.contractorWork.trim().length > 0) {
        this.contractorWorkValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.contractorCmpltFlag = false
        this.contractorWorkValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    insuredJobTitle () {
      if (this.insuredJobTitle.trim().length > 0) {
        this.insuredJobTitleValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.insuredCmpltFlag = false
        this.insuredJobTitleValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    insuredWork () {
      if (this.insuredWork.trim().length > 0) {
        this.insuredWorkValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.insuredCmpltFlag = false
        this.insuredWorkValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // 계약자 문자서비스 수신 여부
    returnData1 () {
      // 설정초기화
      this.returnData5 = '' // 증권수령방법 초기화
      let bContrSnsBtn = false // SNS버튼 유무
      let contrSnsBtnLoc = 0 // SNS버튼위치
      let bContrUseSns = false // 휴대폰번호존재
      if ( this.userData1.detail.celno.trim().length > 0) {
        bContrUseSns = true
      }
      // 수신방법 모바일버튼 존재 여부
      for (let i = 0; i < this.segmentData5.length; i++) {
        if (this.segmentData5[i].key === 'M') {
          bContrSnsBtn = true
          contrSnsBtnLoc = i
        }
      }
      // 문자서비스선택
      if (this.returnData1.length > 0) {
        // SNS버튼추가 - 문자서비스선택 & 휴대폰번호존재 & SNS버튼없음 & 모바일사용
        if (this.returnData1 === '01' && this.useSns && !bContrSnsBtn && bContrUseSns) {
          this.segmentData5 = this.segmentData5Mock1.concat(this.segmentData5)
        // SNS버튼제거 - 문자서비스선택 안함 || 휴대폰금지 || 휴대폰번호없음
        } else if (this.returnData1 === '02' || !this.useSns || !bContrUseSns) {
          if (bContrSnsBtn) {
            this.segmentData5.splice(contrSnsBtnLoc, 1)
          }
        }
        this.returnData1ValidateCmpltFlag = true
        //this.offsetVal = 98
        // this.fn_NextBtnEnable()
      } else {
        if ( this.userData1.detail.celno.trim().length > 0) {
          this.contractorCmpltFlag = false
          this.returnData1ValidateCmpltFlag = false
        } else {
          this.returnData1ValidateCmpltFlag = true
        }
        this.nextBtnFlag = true
      }
    },
    // 계약자 해외거주자/납세 여부
    returnData2 () {
      // FATCA 팝업호출
      if (this.returnData2.length > 0 && this.returnData2 === '01') {
        this.isScroll = false
        this.fn_ShowFatcaPopUp()
      } else {
        this.isScroll = true
      }

      if (this.returnData2.length > 0) {
        this.returnData2ValidateCmpltFlag = true
        //this.offsetVal = 98
        this.fn_NextBtnEnable()
      } else {
        this.contractorCmpltFlag = false
        this.returnData2ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // 계약자 거주구분
    returnData3 () {
      if (this.returnData3.length > 0) {
        this.returnData3ValidateCmpltFlag = true
        //this.offsetVal = 98
        this.fn_NextBtnEnable()
      } else {
        this.contractorCmpltFlag = false
        this.returnData3ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // 계약자이메일 서비스 수신 여부
    returnData4 () {
      // 증권수령방법 초기화
      this.returnData5 = ''
      // 수령방법데이터 없을경우 중지
      if (this.segmentData5.length === -1) {
        return
      }
      // 데이터 확인
      let bEmailBtn = false // 이메일버튼 유무
      let emailBtnLoc = 0 // 이메일 버튼위치
      let bContrSnsBtn = false // SNS버튼 유무
      let bUserEmail = false // 계약자 Email주소
      let tempObj // 버튼 순서용
      for (let i = 0; i < this.segmentData5.length; i++) {
        if (this.segmentData5[i].key === '4') {
          bEmailBtn = true
          emailBtnLoc = i
        } else if (this.segmentData5[i].key === 'M') {
          bContrSnsBtn = true
        }
      }
      if (this.userData1.detail.emailAddr.trim().length > 0 ) {
        bUserEmail = true
      }

      // 이메일서비스 선택
      if (this.returnData4.length > 0) {
        // 이메일 수신 - 버튼추가 : 이메일 선택 & 이메일선택가능 & 데이터 이메일 없을경우 && 계약자이메일 존재
        if (this.returnData4 === '01' && this.useEmail && !bEmailBtn && bUserEmail) {
          this.segmentData5 = this.segmentData5Mock2.concat(this.segmentData5)
          if (bContrSnsBtn) {
            tempObj = this.segmentData5[0]
            this.segmentData5[0] = this.segmentData5[1]
            this.segmentData5[1] = tempObj
          }
        // 이메일 수신안함 - 버튼 삭제 : 이메일선택안함 || 이메일금지 || 이메일없음
        } else if (this.returnData4 === '02' || !this.useEmail || !bUserEmail) {
          if (bEmailBtn) {
            this.segmentData5.splice(emailBtnLoc, 1)
          }
        }

        this.returnData4ValidateCmpltFlag = true
        //this.offsetVal = 98
      } else {
        if (bUserEmail) {
          this.contractorCmpltFlag = false
          this.returnData4ValidateCmpltFlag = false
        } else {
          this.returnData4ValidateCmpltFlag = true
        }
        this.nextBtnFlag = true
      }
    },
    // 계약자 증권수령방법
    returnData5 () {
      if (this.returnData5.length > 0 ) {
        this.isScroll = true
        this.returnData5ValidateCmpltFlag = true
        //this.offsetVal = 98
        this.secusCvancMth_paper_disable = false // 증권수령방법_지류_선택가능(20210907_PJO)
        this.fn_NextBtnEnable()
      } else {
        this.isScroll = false
        this.contractorCmpltFlag = false
        this.returnData5ValidateCmpltFlag = false
        this.nextBtnFlag = true
        this.secusCvancMth_paper_disable = true // 증권수령방법_지류_선택불가능(20210907_PJO)
        this.secusCvancMth_paper = false // 서면교부 체크 해제
      }

      // 증권수령방법이 없을 경우 alert  ( ASR191000042_사랑온 개선 2019.11.05  PJO )
      if ( this.segmentData5.length === 0 && this.isInitEnd ) {
        this.alertMssge = '선택가능한 증권수령방법이 없습니다.<br />모바일/이메일서비스 수신여부를 확인해주세요.'
        this.fn_BottomAlert(this.alertMssge)
      }
    },
    segmentData5 () {
      if (this.returnData5.length > 0 ) {
        this.isScroll = true
        this.returnData5ValidateCmpltFlag = true
        //this.offsetVal = 98
        this.secusCvancMth_paper_disable = false // 증권수령방법_지류_선택가능(20210907_PJO)
        this.fn_NextBtnEnable()
      } else {
        this.isScroll = false
        this.contractorCmpltFlag = false
        this.returnData5ValidateCmpltFlag = false
        this.nextBtnFlag = true
        this.secusCvancMth_paper_disable = true // 증권수령방법_지류_선택불가능(20210907_PJO)
        this.secusCvancMth_paper = false // 서면교부 체크 해제
      }

      // 증권수령방법이 없을 경우 alert  ( ASR191000042_사랑온 개선 2019.11.05  PJO )
      if ( this.segmentData5.length === 0 && this.isInitEnd ) {
        this.alertMssge = '선택가능한 증권수령방법이 없습니다.<br />모바일/이메일서비스 수신여부를 확인해주세요.'
        this.fn_BottomAlert(this.alertMssge)
      }
    },
    // 계약자 주소 선택
    returnData6 () {
      if (this.returnData6.length > 0) {
        if (this.returnData6 === '01') {
          if (this.newContractAddressInfo1.hasOwnProperty('basAddr')) {
            this.cntrctAddressInfo = '(' + this.newContractAddressInfo1.pstCd + ')' + this.newContractAddressInfo1.basAddr + this.newContractAddressInfo1.dtladAddr
            this.cntrctAddressInfo1 = '(' + this.newContractAddressInfo1.pstCd + ')' + this.newContractAddressInfo1.basAddr
            this.cntrctAddressInfo2 = this.newContractAddressInfo1.dtladAddr
          } else {
            if ( this.userData1.detail.homFrnoPstcd.trim().length > 0 ) {
              if (this.userData1.detail.homAddrScCd === '1') { // 지번주소
                this.cntrctAddressInfo = '(' + this.userData1.detail.homFrnoPstcd + ')' + this.userData1.detail.homAddr + this.userData1.detail.homDtlad
                this.cntrctAddressInfo1 = '(' + this.userData1.detail.homFrnoPstcd + ')' + this.userData1.detail.homAddr
                this.cntrctAddressInfo2 = this.userData1.detail.homDtlad
              } else { // 도로명주소
                this.cntrctAddressInfo = '(' + this.userData1.detail.homFrnoPstcd + ')' + this.userData1.detail.homAddr + ' ' + this.userData1.detail.bldgNo01 + this.userData1.detail.adtnAddr01 + this.userData1.detail.homDtlad
                this.cntrctAddressInfo1 = '(' + this.userData1.detail.homFrnoPstcd + ')' + this.userData1.detail.homAddr
                this.cntrctAddressInfo2 = this.userData1.detail.bldgNo01 + this.userData1.detail.adtnAddr01 + this.userData1.detail.homDtlad
              }
            } else {
              this.cntrctAddressInfo = ''
              this.cntrctAddressInfo1 = ''
              this.cntrctAddressInfo2 = ''
            }
          }
        } else {
          if (this.newContractAddressInfo2.hasOwnProperty('basAddr')) {
            this.cntrctAddressInfo = '(' + this.newContractAddressInfo2.pstCd + ')' + this.newContractAddressInfo2.basAddr + this.newContractAddressInfo2.dtladAddr
            this.cntrctAddressInfo1 = '(' + this.newContractAddressInfo2.pstCd + ')' + this.newContractAddressInfo2.basAddr
            this.cntrctAddressInfo2 = this.newContractAddressInfo2.dtladAddr
          } else {
            if (this.userData1.detail.jobpFrnoPstcd.trim().length > 0 ) {
              if (this.userData1.detail.jobpAddrScCd === '1') { // 지번주소
                this.cntrctAddressInfo = '(' + this.userData1.detail.jobpFrnoPstcd + ')' + this.userData1.detail.jobpAddr + this.userData1.detail.jobpDtlad
                this.cntrctAddressInfo1 = '(' + this.userData1.detail.jobpFrnoPstcd + ')' + this.userData1.detail.jobpAddr
                this.cntrctAddressInfo2 = this.userData1.detail.jobpDtlad
              } else { // 도로명주소
                this.cntrctAddressInfo = '(' + this.userData1.detail.jobpFrnoPstcd + ')' + this.userData1.detail.jobpAddr + ' ' + this.userData1.detail.bldgNo02 + this.userData1.detail.adtnAddr02 + this.userData1.detail.jobpDtlad
                this.cntrctAddressInfo1 = '(' + this.userData1.detail.jobpFrnoPstcd + ')' + this.userData1.detail.jobpAddr
                this.cntrctAddressInfo2= this.userData1.detail.bldgNo02 + this.userData1.detail.adtnAddr02 + this.userData1.detail.jobpDtlad
              }
            } else {
              this.cntrctAddressInfo = ''
              this.cntrctAddressInfo1 = ''
              this.cntrctAddressInfo2 = ''
            }
          }
        }
        this.returnData6ValidateCmpltFlag = true
        //this.offsetVal = 98
        this.fn_NextBtnEnable()
      } else {
        this.cntrctAddressInfo = ''
        this.cntrctAddressInfo1 = ''
        this.cntrctAddressInfo2 = ''
        this.contractorCmpltFlag = false
        this.returnData6ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // 피보험자 문자서비스 수신여부
    returnData7 () {
      // 문자서비스선택
      if (this.returnData7.length > 0) {
        this.returnData7ValidateCmpltFlag = true
      } else {
        if (this.userData2.hasOwnProperty('detail')) {
          if ( this.userData2.detail.celno.trim().length > 0 ) {
            this.insuredCmpltFlag = false
            this.returnData7ValidateCmpltFlag = false
          } else {
            this.returnData7ValidateCmpltFlag = true
          }
          this.nextBtnFlag = true
        }
      }
    },
    // 피보험자 거주구분
    returnData8 () {
      if (this.returnData8.length > 0) {
        this.returnData8ValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.insuredCmpltFlag = false
        this.returnData8ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // 피보험자 이메일서비스 수신여부
    returnData9 () {
      if (this.isContInsFlag) {
        return
      }
      if (this.returnData9.length > 0) {
        this.returnData9ValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        if (this.userData2.hasOwnProperty('detail')) {
          if (this.userData2.detail.emailAddr.trim().length > 0) {
            this.insuredCmpltFlag = false
            this.returnData9ValidateCmpltFlag = false
          } else this.returnData9ValidateCmpltFlag = true
          this.nextBtnFlag = true
        }
      }
    },
    // 피보험자 주소 선택
    returnData10 () {
      if (this.isContInsFlag) {
        return
      }
      if (this.returnData10.length > 0) {
        if (this.returnData10 === '01') {
          if (this.newInsuredAddressInfo1.hasOwnProperty('basAddr')) {
            this.insurdAddressInfo = '(' + this.newInsuredAddressInfo1.pstCd + ')' + this.newInsuredAddressInfo1.basAddr + this.newInsuredAddressInfo1.dtladAddr
            this.insurdAddressInfo1 = '(' + this.newInsuredAddressInfo1.pstCd + ')' + this.newInsuredAddressInfo1.basAddr
            this.insurdAddressInfo2 = this.newInsuredAddressInfo1.dtladAddr
          } else {
            if (this.userData2.detail.homFrnoPstcd.trim().length > 0) {
              if (this.userData2.detail.homAddrScCd === '1') { // 지번주소
                this.insurdAddressInfo = '(' + this.userData2.detail.homFrnoPstcd + ')' + this.userData2.detail.homAddr + this.userData2.detail.homDtlad
                this.insurdAddressInfo1 = '(' + this.userData2.detail.homFrnoPstcd + ')' + this.userData2.detail.homAddr
                this.insurdAddressInfo2 = this.userData2.detail.homDtlad
              } else { // 도로명주소
                this.insurdAddressInfo = '(' + this.userData2.detail.homFrnoPstcd + ')' + this.userData2.detail.homAddr + ' ' + this.userData2.detail.bldgNo01 + this.userData2.detail.adtnAddr01 + this.userData2.detail.homDtlad
                this.insurdAddressInfo1 = '(' + this.userData2.detail.homFrnoPstcd + ')' + this.userData2.detail.homAddr
                this.insurdAddressInfo2 = this.userData2.detail.bldgNo01 + this.userData2.detail.adtnAddr01 + this.userData2.detail.homDtlad
              }
            } else {
              this.insurdAddressInfo = ''
              this.insurdAddressInfo1 = ''
              this.insurdAddressInfo2 = ''
            }
          }
        } else {
          if (this.newInsuredAddressInfo2.hasOwnProperty('basAddr')) {
            this.insurdAddressInfo = '(' + this.newInsuredAddressInfo2.pstCd + ')' + this.newInsuredAddressInfo2.basAddr + this.newInsuredAddressInfo2.dtladAddr
            this.insurdAddressInfo1 = '(' + this.newInsuredAddressInfo2.pstCd + ')' + this.newInsuredAddressInfo2.basAddr
            this.insurdAddressInfo2 = this.newInsuredAddressInfo2.dtladAddr
          } else {
            if (this.userData2.detail.jobpFrnoPstcd.trim().length > 0) {
              if (this.userData2.detail.jobpAddrScCd === '1') { // 지번주소
                this.insurdAddressInfo = '(' + this.userData2.detail.jobpFrnoPstcd + ')' + this.userData2.detail.jobpAddr + this.userData2.detail.jobpDtlad
                this.insurdAddressInfo1 = '(' + this.userData2.detail.jobpFrnoPstcd + ')' + this.userData2.detail.jobpAddr
                this.insurdAddressInfo2 = this.userData2.detail.jobpDtlad

              } else { // 도로명주소
                this.insurdAddressInfo = '(' + this.userData2.detail.jobpFrnoPstcd + ')' + this.userData2.detail.jobpAddr + ' ' + this.userData2.detail.bldgNo02 + this.userData2.detail.adtnAddr02 + this.userData2.detail.jobpDtlad
                this.insurdAddressInfo1 = '(' + this.userData2.detail.jobpFrnoPstcd + ')' + this.userData2.detail.jobpAddr
                this.insurdAddressInfo2 = this.userData2.detail.bldgNo02 + this.userData2.detail.adtnAddr02 + this.userData2.detail.jobpDtlad
              }
            } else {
              this.insurdAddressInfo = ''
              this.insurdAddressInfo1 = ''
              this.insurdAddressInfo2 = ''
            }
          }
        }
        this.returnData10ValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.insurdAddressInfo = ''
        this.insurdAddressInfo1 = ''
        this.insurdAddressInfo2 = ''
        this.insuredCmpltFlag = false
        this.returnData10ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
    // 일반/전문 금융소비자 여부
    returnData13 () {
      if (this.returnData13.length > 0) {
        this.returnData13ValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.insuredCmpltFlag = false
        this.returnData13ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },

    // 전문->일반 금융소비자 전환
    returnData14 () {
      if (this.returnData14.length > 0) {
        this.returnData14ValidateCmpltFlag = true
        this.fn_NextBtnEnable()
      } else {
        this.insuredCmpltFlag = false
        this.returnData14ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    // 181126 어포던스 화살표 추가
    scrollTop () {
      this.affordance = false
    },
    // 사망수익자01 주소 선택 (AML 보완 / 2311_PJO)
    returnDataDthBnfr01 () {
      if (this.returnDataDthBnfr01.length > 0) {
        if (this.returnDataDthBnfr01 === '01') {
          if (this.newIsBnfrInfo35AddressInfo1.hasOwnProperty('basAddr')) {
            this.isBnfrInfo35Info = '(' + this.newIsBnfrInfo35AddressInfo1.pstCd + ')' + this.newIsBnfrInfo35AddressInfo1.basAddr + this.newIsBnfrInfo35AddressInfo1.dtladAddr
            this.isBnfrInfo35Info1 = '(' + this.newIsBnfrInfo35AddressInfo1.pstCd + ')' + this.newIsBnfrInfo35AddressInfo1.basAddr
            this.isBnfrInfo35Info2 = this.newIsBnfrInfo35AddressInfo1.dtladAddr
          } else {
            if ( this.userData3DthBnfr01AML.detail.homFrnoPstcd.trim().length > 0 ) {
              if (this.userData3DthBnfr01AML.detail.homAddrScCd === '1') { // 지번주소
                this.isBnfrInfo35Info = '(' + this.userData3DthBnfr01AML.detail.homFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.homAddr + this.userData3DthBnfr01AML.detail.homDtlad
                this.isBnfrInfo35Info1 = '(' + this.userData3DthBnfr01AML.detail.homFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.homAddr
                this.isBnfrInfo35Info2 = this.userData3DthBnfr01AML.detail.homDtlad
              } else { // 도로명주소
                this.isBnfrInfo35Info = '(' + this.userData3DthBnfr01AML.detail.homFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.homAddr + ' ' + this.userData3DthBnfr01AML.detail.bldgNo01 + this.userData3DthBnfr01AML.detail.adtnAddr01 + this.userData3DthBnfr01AML.detail.homDtlad
                this.isBnfrInfo35Info1 = '(' + this.userData3DthBnfr01AML.detail.homFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.homAddr
                this.isBnfrInfo35Info2 = this.userData3DthBnfr01AML.detail.bldgNo01 + this.userData3DthBnfr01AML.detail.adtnAddr01 + this.userData3DthBnfr01AML.detail.homDtlad
              }
            } else {
              this.isBnfrInfo35Info = ''
              this.isBnfrInfo35Info1 = ''
              this.isBnfrInfo35Info2 = ''
            }
          }
        } else {
          if (this.newIsBnfrInfo35AddressInfo2.hasOwnProperty('basAddr')) {
            this.isBnfrInfo35Info = '(' + this.newIsBnfrInfo35AddressInfo2.pstCd + ')' + this.newIsBnfrInfo35AddressInfo2.basAddr + this.newIsBnfrInfo35AddressInfo2.dtladAddr
            this.isBnfrInfo35Info1 = '(' + this.newIsBnfrInfo35AddressInfo2.pstCd + ')' + this.newIsBnfrInfo35AddressInfo2.basAddr
            this.isBnfrInfo35Info2 = this.newIsBnfrInfo35AddressInfo2.dtladAddr
          } else {
            if (this.userData3DthBnfr01AML.detail.jobpFrnoPstcd.trim().length > 0 ) {
              if (this.userData3DthBnfr01AML.detail.jobpAddrScCd === '1') { // 지번주소
                this.isBnfrInfo35Info = '(' + this.userData3DthBnfr01AML.detail.jobpFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.jobpAddr + this.userData3DthBnfr01AML.detail.jobpDtlad
                this.isBnfrInfo35Info1 = '(' + this.userData3DthBnfr01AML.detail.jobpFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.jobpAddr
                this.isBnfrInfo35Info2 = this.userData3DthBnfr01AML.detail.jobpDtlad
              } else { // 도로명주소
                this.isBnfrInfo35Info = '(' + this.userData3DthBnfr01AML.detail.jobpFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.jobpAddr + ' ' + this.userData3DthBnfr01AML.detail.bldgNo02 + this.userData3DthBnfr01AML.detail.adtnAddr02 + this.userData3DthBnfr01AML.detail.jobpDtlad
                this.isBnfrInfo35Info1 = '(' + this.userData3DthBnfr01AML.detail.jobpFrnoPstcd + ')' + this.userData3DthBnfr01AML.detail.jobpAddr
                this.isBnfrInfo35Info2= this.userData3DthBnfr01AML.detail.bldgNo02 + this.userData3DthBnfr01AML.detail.adtnAddr02 + this.userData3DthBnfr01AML.detail.jobpDtlad
              }
            } else {
              this.isBnfrInfo35Info = ''
              this.isBnfrInfo35Info1 = ''
              this.isBnfrInfo35Info2 = ''
            }
          }
        }
        this.returnDataDthBnfr01ValidateCmpltFlag = true
        //this.offsetVal = 98
        this.fn_NextBtnEnable()
      } else {
        this.isBnfrInfo35Info = ''
        this.isBnfrInfo35Info1 = ''
        this.isBnfrInfo35Info2 = ''
        this.contractorCmpltFlag = false
        this.returnDataDthBnfr01ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    } // ~returnDataDthBnfr01
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    contractZipCodeEnable () {
      if (this.returnData6.length > 0) {
        return false
      } else return true
    },
    insuredZipCodeEnable () {
      if (this.returnData10.length > 0 ) {
        return false
      } else return true
    },
    isBnfrInfo35ZipCodeEnable () { // (AML 보완 / 2311_PJO)
      if ( this.returnDataDthBnfr01.length > 0 ) {
        return false
      } else return true
    }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    fn_setTxtInputFocus(rootContainRef,targetRef, event){ 
      console.log('fn_setTxtInputFocus1',event) 
      let elRootContain     = this.$refs[rootContainRef].$el
      let elTargetTextInput = this.$refs[targetRef].$el.getElementsByTagName('input')[0]
      setTimeout(() => {
        PSCommUtil.fn_SetBlurScrollTochEventListener(elRootContain,elTargetTextInput, false)
        PSCommUtil.fn_SetBlurScrollTochEventListener(elRootContain,elTargetTextInput, true)
      }, 500);
    },

    fn_setTxtInputBlur(rootContainRef,targetRef,event){ 
      console.log('fn_setTxtInputBlur',event)
      let elRootContain     = this.$refs[rootContainRef].$el
      let elTargetTextInput = this.$refs[targetRef].$el.getElementsByTagName('input')[0]
      PSCommUtil.fn_SetBlurScrollTochEventListener(elRootContain , elTargetTextInput , false)
    }, 
    /******************************************************************************
    * Function명 : fn_SetFocus
    * 설명       : 하시는일 focus
    ******************************************************************************/
    fn_SetFocus : function(){
      if (this.isInsPersonInfo && this.$refs.mspExp2.isExpand && this.insuredJobTitle.trim().length === 0) {
        setTimeout(()=>{
          this.$refs.insured1.focus()
        },1000)
      } else if (this.$refs.mspExp1.isExpand && this.contractorJobTitle.trim().length === 0) {
        setTimeout(()=>{
          this.$refs.contractor1.focus()
        },1000)
      }
    },
    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/
    fn_init: function () {
      this.segmentData5Type = '1'
      this.fn_SecusCvancMthBtn()
    },
    /******************************************************************************
    * Function명  : fn_SecusCvancMthBtn
    * 설명        : 증권수령방법 버튼 표시 제한 처리
    ******************************************************************************/
    fn_SecusCvancMthBtn: function () {
      // 계약자 휴대폰 여부
      let useMobile = false
      if (this.userData1.detail.celno.trim().length > 0 ) {
        useMobile = true
      } else {
        useMobile = false
      }

      // 계약자 이메일 여부
      let useEmail = false
      if (this.userData1.detail.emailAddr.trim().length > 0 ) {
        useEmail = true
      } else {
        useEmail = false
      }

      // 초기값
      if (useMobile) {
        this.segmentData5 = [{'key': 'M', 'label': '모바일(휴대폰)'}]
      }
      if (useEmail) {
        this.segmentData5 = this.segmentData5.concat([{'key': '4', 'label': '이메일'}])
      }

      this.isInitEnd = true
      // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
      // 일반/전문 금융소비자 여부 초기값 : 일반
      this.returnData13 = this.segmentData13[0].key
      // 계약자의 직업이 당사설계사,타사설계사인 경우 -> 전문
      let fpJobCd = '042302|042304' // 당사설계사|타사설계사
      let contrJobCd = this.userData1.detail.jobCd ? this.userData1.detail.jobCd.trim() : '' // 직업코드 공란일경우 로직보완 (ASR240800056 / 240820 PJO)
      if (contrJobCd.length > 0 ) {
        // let contrJobCd = this.userData1.detail.jobCd.trim()
        if (fpJobCd.indexOf(contrJobCd) > -1) {
          this.returnData13 = this.segmentData13[1].key // 전문
        }
      }
      // 설계사계약 -> 전문
      if (this.fpContYn) {
        this.returnData13 = this.segmentData13[1].key // 전문
      }
      // 전문->일반 금융소비자 전환 초기값 : 아니오
      this.returnData14 = this.segmentData14[0].key // 아니오
    },
    /******************************************************************************
    * Function명  : fn_contractorAreaClick
    * 설명        : 입력필드및 선택필드 제외 영역터치시 자동스크롤
    ******************************************************************************/
    // fn_contractorAreaClick (event) {
    //   // 계피상이,계약자,피보험자 본인인증시
    //   if ( !this.isContInsFlag && (this.contractorCertify && this.insuredCertify) ) {
    //     this.fn_NextBtnEnable()
    //   }
    // },
    /******************************************************************************
    * Function명  : fn_textFieldKeyup
    * 설명        : 계약자 직장명,하시는일 텍스트필드 keyup 이벤트처리
    ******************************************************************************/
    fn_textFieldKeyup (event) {
      this.isScroll = false
    },
    /******************************************************************************
    * Function명  : fn_contractorFocusEvnt
    * 설명        : 계약자 직장명,하시는일 텍스트필드 포커스 이벤트처리
    ******************************************************************************/
    fn_contractorFocusEvnt (event) {
      this.isScroll = false
    },
    /******************************************************************************
    * Function명  : fn_blurEvent
    * 설명        : 계약자 직장명,하시는일 텍스트필드 blur 이벤트처리
    ******************************************************************************/
    fn_blurEvent (pIsScroll = false) {
      //this.$refs.scroll.focus()
      this.isScroll = true
      if (pIsScroll) {
        this.fn_NextBtnEnable()
      }
    },
    /******************************************************************************
    * Function명  : textInScrnUp
    * 설명        : 안드로이드 키보드 활성화시 텍스트 입력화면이 키보드에 가려지지 않기위해 하는 이벤트
    ******************************************************************************/
    textInScrnUp () {
      this.$commonUtil.setHiddenPlugin(false)
    },
    /******************************************************************************
    * Function명  : nextBtn
    * 설명        : 다음버튼 클릭시
    ******************************************************************************/
    nextBtn (event) {

      //추가      
      let tInsured    = []
      let tInsureds    = []
      let tContractor    = []
      let tContractors    = []

      if(this.isInsPersonInfo){ //피보험자 입력필요
        
        for(let i = 1 ; i < 7; i++){
          this.$refs['insured'+i].isValid()
          tInsured.push(this.$refs['insured'+i].isValid())
          if(!this.$refs['insured'+i].isValid()){
            this.$refs['mspExp2'].isExpand =  true
            tInsureds.push('insured'+i)
          }
        }        
      }

      if(this.isContractPersonInfo){//계약자 입력필요 
        
        if(this.planInfo && this.planInfo.vainsUnQulfYn && this.planInfo.vainsUnQulfYn === 'N'){
          if(this.returnData13.length > 0 && this.returnData13 === 'Y'){
            for(let i = 1 ; i < 11; i++){
              this.$refs['contractor'+i].isValid()
              tContractor.push(this.$refs['contractor'+i].isValid())
              if(!this.$refs['contractor'+i].isValid()){
                this.$refs['mspExp1'].isExpand =  true
                tContractors.push('contractor'+i)
              }
            }
          }else{
            for(let i = 1 ; i < 10; i++){
              this.$refs['contractor'+i].isValid()
              tContractor.push(this.$refs['contractor'+i].isValid())
              if(!this.$refs['contractor'+i].isValid()){
                this.$refs['mspExp1'].isExpand =  true
                tContractors.push('contractor'+i)
              }
            } 
          }
        }else{
          for(let i = 1 ; i < 9; i++){
            this.$refs['contractor'+i].isValid()
            tContractor.push(this.$refs['contractor'+i].isValid())
            if(!this.$refs['contractor'+i].isValid()){
              this.$refs['mspExp1'].isExpand =  true
              tContractors.push('contractor'+i)
            }
          }
        }
      }

      let allChkVld = []
      allChkVld = allChkVld.concat(tInsured)
      allChkVld = allChkVld.concat(tContractor)
      
      if(tContractors.length > 0){//계약자
        for(let i = 0; i < tContractors.length; i++){
          if(tInsureds.length == 0 && tContractors[i] === 'contractor1'){
            this.$refs[tContractors[i]].focus() 
            break
          }
          if(tInsureds.length == 0 && tContractors[i] === 'contractor2'){
            this.$refs[tContractors[i]].focus() 
            break
          }
          if(tContractors[i] === 'contractor3'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor4'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor5'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor9'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor10'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor6'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor7'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }
          if(tContractors[i] === 'contractor8'){
            this.$refs[tContractors[i]].focusOn() 
            break
          }  
        }
      }

      if(tInsureds.length > 0){//피보험자
        for(let i = 0; i < tInsureds.length; i++){
          if(tInsureds[i] === 'insured1'){
            this.$refs[tInsureds[i]].focus() 
            break
          }
          if(tInsureds[i] === 'insured2'){
            this.$refs[tInsureds[i]].focus() 
            break
          }
          if(tInsureds[i] === 'insured3'){
            this.$refs[tInsureds[i]].focusOn() 
            break
          }
          if(tInsureds[i] === 'insured4'){
            this.$refs[tInsureds[i]].focusOn() 
            break
          }
          if(tInsureds[i]=== 'insured5'){
            this.$refs[tInsureds[i]].focusOn() 
            break
          }
          if(tInsureds[i] === 'insured6'){
            this.$refs[tInsureds[i]].focusOn() 
            break
          }
        }
      }      

      let allChk = allChkVld.some((item) =>
      {      
      if ( !item )
        {          
          return true
        }
      })
      
      if(allChk) return  

      // 집전화번호 체크
      let telNo1 = this.contractorHomeTel
      let telNo2 = this.insurdHomeTel
      telNo1 = telNo1.replace(/\-|\s/g, '')
      telNo2 = telNo2.replace(/\-|\s/g, '')
      if (!this.isContInsFlag) { // 계피상이
        if (this.contractorCertify) {
          //var element = this.$refs.scroll
          //var top = element.offsetTop - this.offsetVal // 특정 ref의 y축 위쪽 위치 정보
          //const scrollEl = this.$refs.scroll
          //const smoothScrollAnimate = this.$commonUtil.createScrollAnimateInstance(scrollEl, 'y')
          if ( (telNo1.trim().length > 0 && telNo1.trim().length !== 7 && telNo1.trim().length !== 8) || ( telNo1.trim().length > 0 && (this.contractorHomeTelSelect.label === '' || this.contractorHomeTelSelect.label === '선택' || this.contractorHomeTelSelect.label === undefined)) || ( telNo1.trim().length === 0 && (this.contractorHomeTelSelect.label !== '' && this.contractorHomeTelSelect.label !== '선택' && this.contractorHomeTelSelect.label !== undefined))) {
            this.alertMssge = '계약자 전화번호를 확인후 다시 입력해주세요'
            this.fn_BottomAlert(this.alertMssge)
            //smoothScrollAnimate.animate(top, 700, 0, 'easeInOut')
            return
          }
          // if ( this.editEmailFlag1 && !this.editEmailCmpltFlag1 ) {
          //   this.alertMssge = "계약자 이메일 주소 '수정완료'를 선택해주세요"
          //   this.fn_BottomAlert(this.alertMssge)
          //   //smoothScrollAnimate.animate(top, 700, 0, 'easeInOut')
          //   return
          // }
          if ( this.userData1.detail.emailAddr.trim().length > 0 && this.returnData4.length < 1 ) {
            this.alertMssge = '계약자 이메일 서비스 항목을 선택해주세요'
            this.fn_BottomAlert(this.alertMssge)
            //smoothScrollAnimate.animate(top, 700, 0, 'easeInOut')
            return
          }
        }
        if (this.insuredCertify) {
          var elementInsr = this.$refs.insuredScroll
          //var topInsr = elementInsr.offsetTop - this.offsetVal // 특정 ref의 y축 위쪽 위치 정보
          //const scrollElInsr = this.$refs.scroll
          //const smoothScrollAnimateInsr = this.$commonUtil.createScrollAnimateInstance(scrollElInsr, 'y')
          if ( (telNo2.trim().length > 0 && telNo2.trim().length !== 7 && telNo2.trim().length !== 8) || ( telNo2.trim().length > 0 && (this.insurdHomeTelSelect.label === '' || this.insurdHomeTelSelect.label === '선택' || this.insurdHomeTelSelect.label === undefined)) || ( telNo2.trim().length === 0 && (this.insurdHomeTelSelect.label !== '' && this.insurdHomeTelSelect.label !== '선택' && this.insurdHomeTelSelect.label !== undefined))) {
            this.alertMssge = '피보험자 전화번호를 확인후 다시 입력해주세요'
            this.fn_BottomAlert(this.alertMssge)
            //smoothScrollAnimateInsr.animate(topInsr, 700, 0, 'easeInOut')
            return
          }
          // if ( this.editEmailFlag2 && !this.editEmailCmpltFlag2 ) {
          //   this.alertMssge = "피보험자 이메일 주소 '수정완료'를 선택해주세요"
          //   this.fn_BottomAlert(this.alertMssge)
          //   //smoothScrollAnimateInsr.animate(topInsr, 700, 0, 'easeInOut')
          //   return
          // }
          if ( this.userData2.detail.emailAddr.trim().length > 0 && this.returnData9.length < 1 ) {
            this.alertMssge = '피보험자 이메일 서비스 항목을 선택해주세요'
            this.fn_BottomAlert(this.alertMssge)
            //smoothScrollAnimateInsr.animate(topInsr, 700, 0, 'easeInOut')
            return
          }
        }
      } else {
        if ( (telNo1.trim().length > 0 && telNo1.trim().length !== 7 && telNo1.trim().length !== 8) || ( telNo1.trim().length > 0 && (this.contractorHomeTelSelect.label === '' || this.contractorHomeTelSelect.label === '선택' || this.contractorHomeTelSelect.label === undefined)) || ( telNo1.trim().length === 0 && (this.contractorHomeTelSelect.label !== '' && this.contractorHomeTelSelect.label !== '선택' && this.contractorHomeTelSelect.label !== undefined))) {  
          this.alertMssge = '계약자 전화번호를 확인후 다시 입력해주세요'
          this.fn_BottomAlert(this.alertMssge)
          return
        }
        // if ( this.editEmailFlag1 && !this.editEmailCmpltFlag1 ) {
        //   this.alertMssge = "계약자 이메일 주소 '수정완료'를 선택해주세요"
        //   this.fn_BottomAlert(this.alertMssge)
        //   return
        // }
        if ( this.userData1.detail.emailAddr.trim().length > 0 && this.returnData4.length < 1 ) {
          this.alertMssge = '계약자 이메일 서비스 항목을 선택해주세요'
          this.fn_BottomAlert(this.alertMssge)
          return
        }
      }

      // 마지막 주소 입력했는지(빈주소일때) 체크 - 안했으면 주소검색 팝업 호출
      if (!this.isContInsFlag) { // 계피상이
        if ( this.contractorCertify && !this.insuredCertify ) {
          if ( this.cntrctAddressInfo.length < 1 ) {
            this.addrSrchContractInsuredDiv = '01'
            this.getStore('confirm').dispatch('ADD', '계약자의 주소를 입력하세요.\n주소는 필수입력정보입니다.')
            this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
            return
          }
        } else if ( !this.contractorCertify && this.insuredCertify ) {
          if ( this.insurdAddressInfo.length < 1 ) {
            this.addrSrchContractInsuredDiv = '02'
            this.getStore('confirm').dispatch('ADD', '피보험자의 주소를 입력하세요.\n주소는 필수입력정보입니다.')
            this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
            return
          }
        } else {
          if ( this.cntrctAddressInfo.length < 1 ) {
            this.addrSrchContractInsuredDiv = '01'
            this.getStore('confirm').dispatch('ADD', '계약자의 주소를 입력하세요.\n주소는 필수입력정보입니다.')
            this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
            return
          } else if ( this.insurdAddressInfo.length < 1 ) {
            this.getStore('confirm').dispatch('ADD', '피보험자의 주소를 입력하세요.\n주소는 필수입력정보입니다.')
            this.addrSrchContractInsuredDiv = '02'
            this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
            return
          }
        }
      } else {
        if ( this.cntrctAddressInfo.length < 1 ) {
          this.addrSrchContractInsuredDiv = '01'
          this.getStore('confirm').dispatch('ADD', '계약자의 주소를 입력하세요.\n주소는 필수입력정보입니다.')
          this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
          return
        }
      }

      // 사망수익자1 이 EDD 대상일경우 주소정보 필수 (AML 보완 / 2311_PJO)
      if ( this.isAmlDthBnfr01Flag ) {
        if ( this.isBnfrInfo35Info.length < 1 ) {
          this.addrSrchContractInsuredDiv = 'dthBnfr01'
          this.getStore('confirm').dispatch('ADD', '사망수익자 주소를 입력하세요.\n주소는 필수입력정보입니다.')
          this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
          return
        }
      }

      // if ( !this.contractorCertify && this.insuredCertify ) {
      //   this.alertInfo1 = '[피보험자: ' + this.userData2.detail.custNm + ']'
      //   this.alertInfo2 = this.insurdAddressInfo
      // } else {
      //   this.alertInfo1 = '[계약자: ' + this.userData1.detail.custNm + ']'
      //   this.alertInfo2 = this.cntrctAddressInfo
      // }
      
      // this.fn_Confirm('입력하신 정보가 맞습니까?' + this.alertInfo1 + " " + this.alertInfo2)

      let content = ''
      if (this.isContInsFlag || (this.contractorCertify && !this.insuredCertify) ) { // 계피동일이거나 계약자만 본인인증일 경우
        content += '[계약자: ' + this.userData1.detail.custNm + ']<br />' + this.cntrctAddressInfo
      } else if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 본인인증일 경우
        content = '[피보험자: ' + this.userData2.detail.custNm + ']<br />' + this.insurdAddressInfo
      } else {
        content = '[피보험자: ' + this.userData2.detail.custNm + ']<br />' + this.insurdAddressInfo + '<br />'
        content += '[계약자: ' + this.userData1.detail.custNm + ']<br />' + this.cntrctAddressInfo
      }
      if ( this.isAmlDthBnfr01Flag ) { // AML 보완 / 2311_PJO
        content += '<br>[사망수익자: ' + this.bnfrInfo2.custNm + ']<br />' + this.isBnfrInfo35Info
      }  
      this.fn_Confirm('입력하신 정보가 맞습니까?<br /><br />' + content)
    },
     /******************************************************************************
    * Function명  : fn_Confirm
    * 설명        : 입력정보 확인창
    ******************************************************************************/
    fn_Confirm(content) {
      const lv_ShowAlert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: content,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            if (this.isContInsFlag || (this.contractorCertify && !this.insuredCertify) ) { // 계피동일이거나 계약자만 본인인증일 경우
              this.contractorCrmUpdate()
            } else if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 본인인증일 경우
              this.insurdCrmUpdate()
            } else {
              this.contractorCrmUpdate()
            }
            this.$bottomModal.close(lv_ShowAlert)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_ShowAlert)
          }
        }
      })
    },
    // fn_Confirm(content) {
    //   let lv_Vm = this
    //   this.lv_ShowAlert = this.$bottomModal.open(MSPBottomAlert, {
    //     properties: {
    //       title: '알림',
    //       content: content,
    //       title_pos_btn: "예",
    //       title_neg_btn: "아니오"
    //     },
    //     listeners: {
    //       onPopupConfirm: () => {
    //         if (this.alertCloseCnt !== 0) {
    //           this.alert = !this.alert
    //           this.contractorCrmUpdate()
    //           this.$bottomModal.close(this.lv_ShowAlert)
    //         } else {
    //           if (this.isContInsFlag || (this.contractorCertify && !this.insuredCertify) ) { // 계피동일이거나 계약자만 본인인증일 경우
    //             this.alert = !this.alert
    //             this.alertCloseCnt = 0
    //             this.contractorCrmUpdate()
    //             this.$bottomModal.close(this.lv_ShowAlert)
    //           } else if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 본인인증일 경우
    //             this.alert = !this.alert
    //             this.alertCloseCnt = 0
    //             this.insurdCrmUpdate()
    //             this.$bottomModal.close(this.lv_ShowAlert)
    //           } else {
    //             this.alertInfo1 = '[피보험자: ' + this.userData2.detail.custNm + ']'
    //             this.alertInfo2 = this.insurdAddressInfo
    //             this.alertCloseCnt++ // 피보험자 주소확인 완료 (alertCloseCnt 가 0이 아니면 완료)
    //             lv_Vm.fn_Confirm2('입력하신 정보가 맞습니까?' + this.alertInfo1 + " " + this.alertInfo2)
    //           }
    //         }
    //       },
    //       onPopupClose: () => {
    //         lv_Vm.alertCloseCnt = 0
    //         this.$bottomModal.close(this.lv_ShowAlert)
    //       }
    //     }
    //   })
    // },
    // fn_Confirm2(content) {
    //   let lv_Vm = this
    //   this.lv_ShowAlert2 = this.$bottomModal.open(MSPBottomAlert, {
    //     properties: {
    //       title: '알림',
    //       content: content,
    //       title_pos_btn: "예",
    //       title_neg_btn: "아니오"
    //     },
    //     listeners: {
    //       onPopupConfirm: () => {
    //         if (this.alertCloseCnt !== 0) {
    //           this.alert = !this.alert
    //           this.contractorCrmUpdate()
    //           this.$bottomModal.close(this.lv_ShowAlert)
    //           this.$bottomModal.close(this.lv_ShowAlert2)
    //         } else {
    //           if (this.isContInsFlag || (this.contractorCertify && !this.insuredCertify) ) { // 계피동일이거나 계약자만 본인인증일 경우
    //             this.alert = !this.alert
    //             this.alertCloseCnt = 0
    //             this.contractorCrmUpdate()
    //             this.$bottomModal.close(this.lv_ShowAlert)
    //             this.$bottomModal.close(this.lv_ShowAlert2)
    //           } else if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 본인인증일 경우
    //             this.alert = !this.alert
    //             this.alertCloseCnt = 0
    //             this.insurdCrmUpdate()
    //             this.$bottomModal.close(this.lv_ShowAlert)
    //             this.$bottomModal.close(this.lv_ShowAlert2)
    //           } else {
    //             this.alertInfo1 = '[피보험자: ' + this.userData2.detail.custNm + ']'
    //             this.alertInfo2 = this.insurdAddressInfo
    //             this.alertCloseCnt++ // 피보험자 주소확인 완료 (alertCloseCnt 가 0이 아니면 완료)
    //             this.fn_Confirm2('입력하신 정보가 맞습니까?' + this.alertInfo1 + " " + this.alertInfo2)
    //           }
    //         }
    //       },
    //       onPopupClose: () => {
    //         lv_Vm.alertCloseCnt = 0
    //         this.$bottomModal.close(this.lv_ShowAlert)
    //         this.$bottomModal.close(this.lv_ShowAlert2)
    //       }
    //     }
    //   })
    // },
    /******************************************************************************
    * Function명  : fn_CustDataUpdate
    * 설명        : 고객 데이터 업데이트
    ******************************************************************************/
    fn_CustDataUpdate () {
      // 계피동일건이거나 계피상이지만 계약자만 본인인증시
      if ( this.isContInsFlag || ( !this.isContInsFlag && (this.contractorCertify && !this.insuredCertify) )) {
        /********************
        *   계약자 정보    *
        *********************/
        // 전자서명설계ID
        this.psBefrInptInfoSVO.vuchId = this.inputData.moDCDocuMngVO.eltrnDoctId
        // 직장명
        this.psBefrInptInfoSVO.contrJobpNm = this.contractorJobTitle.toUpperCase()
        // 업종
        if ( this.contractorJobType.trim().length < 1 ) {
          this.psBefrInptInfoSVO.contrIndclNm = ' '
        } else this.psBefrInptInfoSVO.contrIndclNm = this.contractorJobType
        // 하시는 일
        this.psBefrInptInfoSVO.contrDtlBusnNm = this.contractorWork
        // SMS수신 동의여부
        if (this.returnData1.length > 0) {
          this.returnData1 === '01' ? this.psBefrInptInfoSVO.contrSmsReciCnsntYn = 'Y' : this.psBefrInptInfoSVO.contrSmsReciCnsntYn = 'N'
        } else {
          this.psBefrInptInfoSVO.contrSmsReciCnsntYn = ' '
        }
        // 2022.11 ASR221100526 스마트안내삭제 김문준프로요청 (해당컬럼은 not null이라 사용안하더라도 빈값이라도 채워야 TTSD00002테이블에 인서트됨)
        this.psBefrInptInfoSVO.contrSmartReciCnsntYn = ' '

        // 해외/납세의무자 여부
        if (this.returnData2 === '01') {
          this.psBefrInptInfoSVO.fatcaCnfmObjYn = 'Y'
          this.psBefrInptInfoSVO.fatcaCustCnfCd = ' '
          this.psBefrInptInfoSVO.fatcaUnAcqRsnCntnt = ' '
        } else {
          this.psBefrInptInfoSVO.fatcaCnfmObjYn = 'N'
          this.psBefrInptInfoSVO.fatcaCustCnfCd = ' '
          this.psBefrInptInfoSVO.fatcaUnAcqRsnCntnt = ' '
        }
        // 거주구분
        this.psBefrInptInfoSVO.contrDwScCd = this.returnData3
        // 증권수령방법
        // ASR210800597_증권수령방법 변경 (20210907_PJO)
        // DB저장 : M 모바일만선택,  4 이메일만선택, A 모바일+지류, B 이메일+지류 (TTSD00002.SECUS_CVANC_MTH_CD)
        // 양식전달 : M 모바일만선택, 04 이메일만선택, A 모바일+지류, B 이메일+지류 (pnbau1.insrSecusRcvMth)
        let _secusCvancMthCd = this.returnData5
        if ( this.secusCvancMth_paper === true ) { // 서면교부 체크시
          if (_secusCvancMthCd === 'M') {
            _secusCvancMthCd = 'A'
          } else if (_secusCvancMthCd === '4') {
            _secusCvancMthCd = 'B'
          }
        }
        this.psBefrInptInfoSVO.secusCvancMthCd = _secusCvancMthCd

        // 이메일수신 동의여부
        if (this.returnData4.length > 0) {
          this.returnData4 === '01' ? this.psBefrInptInfoSVO.contrEltrnMalReciYn = 'Y' : this.psBefrInptInfoSVO.contrEltrnMalReciYn = 'N'
        } else this.psBefrInptInfoSVO.contrEltrnMalReciYn = ' '
        // 주소 구분(자택,직장)
        this.psBefrInptInfoSVO.mailRcpplCd = this.returnData6

        /* [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면) */
        if (this.planInfo && this.planInfo.vainsUnQulfYn && this.planInfo.vainsUnQulfYn === 'N') {
          // 전문가금융소비자여부
          this.psBefrInptInfoSVO.exprtFncCspYn = this.returnData13
          if (this.returnData13 === 'Y') {
          // 일반금융소비자동일대우여부
            this.psBefrInptInfoSVO.genFncCspSameTrtmtYn = this.returnData14
          }
        }

        this.fn_ChngBeneficiaryInfo()
        this.serviceCall('txTSSPS99U11', 'ind')
      } else {
        if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 본인인증시
          /********************
          *   피보험자 정보    *
          *********************/
          // 전자서명설계ID
          this.psBefrInptInfoSVO.vuchId = this.inputData.moDCDocuMngVO.eltrnDoctId
          // 직장명
          this.psBefrInptInfoSVO.insrdJobpNm = this.insuredJobTitle.toUpperCase()
          // 업종
          if ( this.insuredJobType.trim().length < 1 ) {
            this.psBefrInptInfoSVO.insrdIndclNm = ' '
          } else this.psBefrInptInfoSVO.insrdIndclNm = this.insuredJobType
          // 하시는 일
          this.psBefrInptInfoSVO.insrdDtlBusnNm = this.insuredWork
          // SMS수신 동의여부
          if (this.returnData7.length > 0) {
            this.returnData7 === '01' ? this.psBefrInptInfoSVO.insrdSmsReciCnsntYn = 'Y' : this.psBefrInptInfoSVO.insrdSmsReciCnsntYn = 'N'
          } else {
            this.psBefrInptInfoSVO.insrdSmsReciCnsntYn = ' '
          }
          // 2022.11 ASR221100526 스마트안내삭제 김문준프로요청 (해당컬럼은 not null이라 사용안하더라도 빈값이라도 채워야 TTSD00002테이블에 인서트됨)
          this.psBefrInptInfoSVO.insrdSmartReciCnsntYn = ' '
          // 거주구분
          this.psBefrInptInfoSVO.insrdDwScCd = this.returnData8
          // 이메일수신 동의여부
          if (this.returnData9.length > 0) {
            this.returnData9 === '01' ? this.psBefrInptInfoSVO.insrdEltrnMalReciYn = 'Y' : this.psBefrInptInfoSVO.insrdEltrnMalReciYn = 'N'
          } else this.psBefrInptInfoSVO.insrdEltrnMalReciYn = ' '
          // 주소 구분(자택,직장)
          this.psBefrInptInfoSVO.insrdMailRcpplCd = this.returnData10

          this.serviceCall('txTSSPS99U12', 'ind')
        } else { // 계약자,피보험자 본인인증시
          /********************
          *   계약자 정보    *
          *********************/
          // 전자서명설계ID
          this.psBefrInptInfoSVO.vuchId = this.inputData.moDCDocuMngVO.eltrnDoctId
          // 직장명
          this.psBefrInptInfoSVO.contrJobpNm = this.contractorJobTitle.toUpperCase()
          // 업종
          if ( this.contractorJobType.trim().length < 1 ) {
            this.psBefrInptInfoSVO.contrIndclNm = ' '
          } else this.psBefrInptInfoSVO.contrIndclNm = this.contractorJobType
          // 하시는 일
          this.psBefrInptInfoSVO.contrDtlBusnNm = this.contractorWork
          // SMS수신 동의여부
          if (this.returnData1.length > 0) {
            this.returnData1 === '01' ? this.psBefrInptInfoSVO.contrSmsReciCnsntYn = 'Y' : this.psBefrInptInfoSVO.contrSmsReciCnsntYn = 'N'
          } else {
            this.psBefrInptInfoSVO.contrSmsReciCnsntYn = ' '
          }
          // 2022.11 ASR221100526 스마트안내삭제 김문준프로요청 (해당컬럼은 not null이라 사용안하더라도 빈값이라도 채워야 TTSD00002테이블에 인서트됨)
          this.psBefrInptInfoSVO.contrSmartReciCnsntYn = ' '

          // 해외/납세의무자 여부
          if (this.returnData2 === '01') {
            this.psBefrInptInfoSVO.fatcaCnfmObjYn = 'Y'
            this.psBefrInptInfoSVO.fatcaCustCnfCd = ' '
            this.psBefrInptInfoSVO.fatcaUnAcqRsnCntnt = ' '
          } else {
            this.psBefrInptInfoSVO.fatcaCnfmObjYn = 'N'
            this.psBefrInptInfoSVO.fatcaCustCnfCd = ' '
            this.psBefrInptInfoSVO.fatcaUnAcqRsnCntnt = ' '
          }
          // 거주구분
          this.psBefrInptInfoSVO.contrDwScCd = this.returnData3
          // 증권수령방법
          // ASR210800597_증권수령방법 변경 (20210907_PJO)
          // DB저장 : M 모바일만선택,  4 이메일만선택, A 모바일+지류, B 이메일+지류 (TTSD00002.SECUS_CVANC_MTH_CD)
          // 양식전달 : M 모바일만선택, 04 이메일만선택, A 모바일+지류, B 이메일+지류 (pnbau1.insrSecusRcvMth)
          let _secusCvancMthCd = this.returnData5
          if ( this.secusCvancMth_paper === true ) { // 서면교부 체크시
            if (_secusCvancMthCd === 'M') {
              _secusCvancMthCd = 'A'
            } else if (_secusCvancMthCd === '4') {
              _secusCvancMthCd = 'B'
            }
          }
          this.psBefrInptInfoSVO.secusCvancMthCd = _secusCvancMthCd

          // 이메일수신 동의여부
          if (this.returnData4.length > 0) {
            this.returnData4 === '01' ? this.psBefrInptInfoSVO.contrEltrnMalReciYn = 'Y' : this.psBefrInptInfoSVO.contrEltrnMalReciYn = 'N'
          } else this.psBefrInptInfoSVO.contrEltrnMalReciYn = ' '
          // 주소 구분(자택,직장)
          this.psBefrInptInfoSVO.mailRcpplCd = this.returnData6

          /* [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면) */
          if (this.planInfo && this.planInfo.vainsUnQulfYn && this.planInfo.vainsUnQulfYn === 'N') {
          // 전문가금융소비자여부
            this.psBefrInptInfoSVO.exprtFncCspYn = this.returnData13
            if (this.returnData13 === 'Y') {
            // 일반금융소비자동일대우여부
              this.psBefrInptInfoSVO.genFncCspSameTrtmtYn = this.returnData14
            }
          }
          /********************
          *   피보험자 정보    *
          *********************/
          // 전자서명설계ID
          this.psBefrInptInfoSVO.vuchId = this.inputData.moDCDocuMngVO.eltrnDoctId
          // 직장명
          this.psBefrInptInfoSVO.insrdJobpNm = this.insuredJobTitle.toUpperCase()
          // 업종
          if ( this.insuredJobType.trim().length < 1 ) {
            this.psBefrInptInfoSVO.insrdIndclNm = ' '
          } else this.psBefrInptInfoSVO.insrdIndclNm = this.insuredJobType
          // 하시는 일
          this.psBefrInptInfoSVO.insrdDtlBusnNm = this.insuredWork
          // SMS수신 동의여부
          if (this.returnData7.length > 0) {
            this.returnData7 === '01' ? this.psBefrInptInfoSVO.insrdSmsReciCnsntYn = 'Y' : this.psBefrInptInfoSVO.insrdSmsReciCnsntYn = 'N'
          } else {
            this.psBefrInptInfoSVO.insrdSmsReciCnsntYn = ' '
          }
          // 2022.11 ASR221100526 스마트안내삭제 김문준프로요청 (해당컬럼은 not null이라 사용안하더라도 빈값이라도 채워야 TTSD00002테이블에 인서트됨)
          this.psBefrInptInfoSVO.insrdSmartReciCnsntYn = ' '
          // 거주구분
          this.psBefrInptInfoSVO.insrdDwScCd = this.returnData8
          // 이메일수신 동의여부
          if (this.returnData9.length > 0) {
            this.returnData9 === '01' ? this.psBefrInptInfoSVO.insrdEltrnMalReciYn = 'Y' : this.psBefrInptInfoSVO.insrdEltrnMalReciYn = 'N'
          } else this.psBefrInptInfoSVO.insrdEltrnMalReciYn = ' '
          // 주소 구분(자택,직장)
          this.psBefrInptInfoSVO.insrdMailRcpplCd = this.returnData10

          this.fn_ChngBeneficiaryInfo()
          this.serviceCall('txTSSPS99U2', 'ind')
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_ChngBeneficiaryInfo
    * 설명        : 수익자 정보 변경시 로직(전자서명앱(유니닥스)에 전달할 데이터 가공)
    ******************************************************************************/
/*
ASR240800965	수익자 별도 지정 시 값 세팅 로직 변경 / 김문준 / 2024-09-24
□  전자서명 진행 중 수익자 별도 지정 시 TTSD00002.BNFR_CHG_CNTNT(수익자변경내용) 값 세팅 로직 변경
1. 만기/분할/연금/상해/사망/연금 후 수익자  성명 또는 실명번호 = 계약자 또는 피보험자의 성명 또는 실명번호와 같은 경우 공란
(종피 또는 상속인과 비교하는 경우 유지(기존과 동일)

*
[김문준] 프로 / 업무지원팀  신계약지원P / 삼성생명 2024-09-13 11:18
이건 모청 법인계약과 동일하게 사실 이름만 가지고 수익자를 세대구성까지 잘못해서 하는 경우는 거의없을거라
이름 비교해도 무방할거같긴합니다

*
userData1 계약자정보 / userData2 피보험자정보  / userData3 수익자정보  / userData4 종피보험자
*/
    fn_ChngBeneficiaryInfo () {
      if ( this.userData3 !== null && this.userData3.length > 0) {
        let str = ''
        let cnt = 0
        for (let i = 0; i < this.userData3.length; i++ ) {
          if (i !== 0 && str.length > 0) {
            str += '|'
          }
          console.log('#수익자RolCd:' + this.userData3[i].bnfrRolCd)
          switch (this.userData3[i].bnfrRolCd) {
            case '31': // 만기수익자(기본값:계약자)
            case '32': // 분할수익자(기본값:계약자)
            case '33': // 연금수익자(기본값:계약자)
              // if (!str.indexOf('1')) {
              // if ( this.userData1.detail.custNm !== this.userData3[i].custNm ) {
              //   str += '6'
              // } else {
              //   if ( this.userData1.custRrnId !== this.userData3[i].custRrnId ) {
              //     str += '6'
              //   }
              // }
              // ASR240800965_START
              if ( this.isContInsFlag ) { // 계피동일
                if ( this.userData3[i].custNm !== this.userData1.detail.custNm ) {
                  str += '6'
                } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId ) {
                  str += '6'
                }
              } else { // 계피상이
                  if ( this.userData3[i].custNm !== this.userData1.detail.custNm && this.userData3[i].custNm !== this.userData2.detail.custNm ){
                    str += '6'
                    console.log(' 31~33.수익자_계/피와다른사람1')
                  } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId && this.userData3[i].custRrnId !== this.userData2.custRrnId ){
                    str += '6'
                    console.log(' 31~33.수익자_계/피와다른사람2')
                  }
              }
              // ASR240800965_END
              // }
              break
            case '34': // 상해수익자(기본값:피보험자)
              if ( this.unchYn ) break // 주피보험자가 '태아'일경우 패스
              if ( this.userData4 !== undefined && this.userData3[i].contvInsrdCd === '23') { // 종피보험자
                if ( this.userData4.insrdNm !== this.userData3[i].custNm ) {
                  str += '8'
                } else {
                  if ( this.userData4.custRrnId !== this.userData3[i].custRrnId ) {
                    str += '8'
                  }
                }
              } else {
                if ( this.isContInsFlag ) { // 계피동일
                  if ( this.userData1.detail.custNm !== this.userData3[i].custNm ) {
                    str += '1'
                  } else if ( this.userData1.custRrnId !== this.userData3[i].custRrnId ) {
                    str += '1'
                  }
                } else { // 계피상이
                  // if ( this.userData2.detail.custNm !== this.userData3[i].custNm ) {
                  //   str += '1'
                  // } else {
                  //   if ( this.userData2.custRrnId !== this.userData3[i].custRrnId ) {
                  //     str += '1'
                  //   }
                  // }
                  // ASR240800965_START
                  if ( this.userData3[i].custNm !== this.userData1.detail.custNm && this.userData3[i].custNm !== this.userData2.detail.custNm ){
                    str += '1'
                    console.log(' 31~33.수익자_계/피와다른사람1')
                  } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId  && this.userData3[i].custRrnId !== this.userData2.custRrnId ){
                    str += '1'
                    console.log(' 34.수익자_계/피와다른사람2')
                  }
                  // ASR240800965_END
                }
              }
              break
            case '35': // 사망수익자(기본값:상속자)
              if ( this.userData3[i].contvInsrdCd === '23' ) { // 종피보험자
                if ( this.userData3[i].custNm !== '상속인') str += '9'
              } else {
                // if ( cnt === 0 ) {
                //   if ( this.userData3[i].custNm !== '상속인') str += '2'
                // } else {
                //   if ( this.userData3[i].custNm !== ' ' && this.userData3[i].custNm !== '상속인' ) {
                //     if ( cnt === 1 ) str += '3'
                //     if ( cnt === 2 ) str += '4'
                //     if ( cnt === 3 ) str += '5'
                //   }
                // }
                // cnt++
                if ( this.isContInsFlag ) { // 계피동일
                  if ( this.userData3[i].custNm !== '상속인' ) {
                    if ( this.userData3[i].custNm !== this.userData1.detail.custNm ) {
                      str += (cnt+2)+ '' // cnt 0 -> '2' , 1 -> '3',  2-> '4', 3 -> '5'
                    } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId ) {
                      str += (cnt+2)+ '' // cnt 0 -> '2' , 1 -> '3',  2-> '4', 3 -> '5'
                    }
                  }
                } else { // 계피상이
                  // 수익자 - 계약자,피보험자동일이면 - 공란  / 그외는 수익자변경구분 추가
                  if ( this.userData3[i].custNm !== '상속인' ) {
                    if ( this.userData3[i].custNm !== this.userData1.detail.custNm && this.userData3[i].custNm !== this.userData2.detail.custNm ){
                      str += (cnt+2)+ '' // cnt 0 -> '2' , 1 -> '3',  2-> '4', 3 -> '5'
                    } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId  && this.userData3[i].custRrnId !== this.userData2.custRrnId ){
                      str += (cnt+2)+ ''
                    }
                  }
                }
                cnt++
              }
              break
            case '39': // 연금후수익자(기본값:상속자)
              // if ( this.userData3[i].custNm !== '상속인' ) str += '7'
              if ( this.isContInsFlag ) { // 계피동일
                if ( this.userData3[i].custNm !== '상속인' ) {
                  if ( this.userData3[i].custNm !== this.userData1.detail.custNm ) {
                    str += '7'
                  } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId ) {
                    str += '7'
                  }
                }
              } else {
                if ( this.userData3[i].custNm !== '상속인' ) {
                  if ( this.userData3[i].custNm !== this.userData1.detail.custNm && this.userData3[i].custNm !== this.userData2.detail.custNm ) {
                      str += '7'
                  } else if ( this.userData3[i].custRrnId !== this.userData1.custRrnId  && this.userData3[i].custRrnId !== this.userData2.custRrnId ){
                    str += '7'
                  }
                }
              }
              break
          }
        }
        str.trim().length < 1 ? this.psBefrInptInfoSVO.bnfrChgCntnt = ' ' : this.psBefrInptInfoSVO.bnfrChgCntnt = str // 수익자변경내용
        console.log('수익자변경내용:' + this.psBefrInptInfoSVO.bnfrChgCntnt)
      }
    },
    /******************************************************************************
    * Function명  : fn_NoFatcaInfoInsert
    * 설명        : FATCA 정보 저장(FATCA 해당없음 처리)
    ******************************************************************************/
    fn_NoFatcaInfoInsert () {
      this.txTSSPS13P1Data = {
        custNm: this.userData1.detail.custNm,
        custRrn: this.userData1.custRrnId,
        trtrEno: this.userData1.detail.cnsltNo,
        fatcaCustCnfMatrCd: '1', // 고객확인사항코드 -> 해당없음
        koreaUsaExcluOversDwYn: '',
        fatcaEngNm01: '',
        fatcaEngNm02: '',
        fatcaEngAddr: '',
        taxsPpsDmclCntryCd01: '',
        taxsPpsDmclCntryCd02: '',
        taxsPpsDmclCntryCd03: '',
        taxprNo01: '',
        taxprNo02: '',
        taxprNo03: '',
        taxprNoUnSbmtRsnCd01: '',
        taxprNoUnSbmtRsnCd02: '',
        taxprNoUnSbmtRsnCd03: '',
        fatcaSbmtDnlRsn: '',
        fatcaInptPathCd: '1181',
        FatcaAcinsDgrId: ''
      }
      this.serviceCall('txTSSPS13P1', 'di')
    },
    /******************************************************************************
    * Function명  : contractorCrmUpdate
    * 설명        : 고객(계약자) 변경데이터 CRM 업데이트
    ******************************************************************************/
    contractorCrmUpdate () {
      this.contractorCustCardRegMdfcSVO.jobpNm = this.contractorJobTitle // 직장명
      if ( this.contractorHomeTelSelect.label !== '' && this.contractorHomeTelSelect.label !== '선택' && this.contractorHomeTelSelect.label !== undefined && this.contractorHomeTel.length > 0 ) {
        //this.contractorCustCardRegMdfcSVO.homTelno = this.contractorHomeTelSelect.label + '-' + this.$cmUtil.replacePhoneBackNumWithHyphen(this.contractorHomeTel)
        //TO-DO $cmUtil.replacePhoneBackNumWithHyphen 만들어지면 변경
        this.contractorCustCardRegMdfcSVO.homTelno = this.contractorHomeTelSelect.label + '-' + this.fn_replacePhoneBackNumWithHyphen(this.contractorHomeTel)
      } else this.contractorCustCardRegMdfcSVO.homTelno = ' '

      // 계약자 이메일 수정
      if ( this.userData1.detail.emailAddr.trim().length > 0 ) {
        this.contractorCustCardRegMdfcSVO.emailStatFlag = 'X'
        this.contractorCustCardRegMdfcSVO.emailAddr = this.userData1.detail.emailAddr
      }

      if (this.newContractAddressFlag) { // 새주소 등록시
        if ( this.newContractAddressInfo1.hasOwnProperty('basAddr') ) { // 계약자 자택주소 변경시
          if ( this.newContractAddressInfo1.addrScCd === '1') { // 지번주소
            this.contractorCustCardRegMdfcSVO.homAddr = this.newContractAddressInfo1.basAddr
            this.contractorCustCardRegMdfcSVO.homDtlad = this.newContractAddressInfo1.dtladAddr
            this.contractorCustCardRegMdfcSVO.adtnAddr01 = ' '
          } else { // 도로명 주소
            this.contractorCustCardRegMdfcSVO.homAddr = this.newContractAddressInfo1.basAddr
            this.contractorCustCardRegMdfcSVO.homDtlad = this.newContractAddressInfo1.roadNmDtlad
            this.contractorCustCardRegMdfcSVO.adtnAddr01 = this.newContractAddressInfo1.roadNmAddmAddr
          }

          if ( this.newContractAddressInfo1.bldgBonbHo.trim().length < 1 ) {
            this.contractorCustCardRegMdfcSVO.bldgNo01 = ' '
          } else this.contractorCustCardRegMdfcSVO.bldgNo01 = this.newContractAddressInfo1.bldgBonbHo

          this.contractorCustCardRegMdfcSVO.homFrnoPstcd = this.newContractAddressInfo1.pstCd
          this.contractorCustCardRegMdfcSVO.homAddrScCd = this.newContractAddressInfo1.addrScCd
          this.contractorCustCardRegMdfcSVO.homAddrRefnCd = this.newContractAddressInfo1.addrRefnCd
          this.contractorCustCardRegMdfcSVO.homAddrStatCd = '11'
        }
        if ( this.newContractAddressInfo2.hasOwnProperty('basAddr') ) { // 계약자 직장주소 변경시
          if ( this.newContractAddressInfo2.addrScCd === '1' ) { // 지번 주소
            this.contractorCustCardRegMdfcSVO.jobpAddr = this.newContractAddressInfo2.basAddr
            this.contractorCustCardRegMdfcSVO.jobpDtlad = this.newContractAddressInfo2.dtladAddr
            this.contractorCustCardRegMdfcSVO.adtnAddr02 = ' '
          } else { // 도로명 주소
            this.contractorCustCardRegMdfcSVO.jobpAddr = this.newContractAddressInfo2.basAddr
            this.contractorCustCardRegMdfcSVO.jobpDtlad = this.newContractAddressInfo2.roadNmDtlad
            this.contractorCustCardRegMdfcSVO.adtnAddr02 = this.newContractAddressInfo2.roadNmAddmAddr
          }

          if ( this.newContractAddressInfo2.bldgBonbHo.trim().length < 1 ) {
            this.contractorCustCardRegMdfcSVO.bldgNo02 = ' '
          } else this.contractorCustCardRegMdfcSVO.bldgNo02 = this.newContractAddressInfo2.bldgBonbHo

          this.contractorCustCardRegMdfcSVO.jobpFrnoPstcd = this.newContractAddressInfo2.pstCd
          this.contractorCustCardRegMdfcSVO.jobpAddrScCd = this.newContractAddressInfo2.addrScCd
          this.contractorCustCardRegMdfcSVO.jobpAddrRefnCd = this.newContractAddressInfo2.addrRefnCd
          this.contractorCustCardRegMdfcSVO.jobpAddrStatCd = '11'
        }
      }
      this.contractorCustCardRegMdfcSVO.ichoshInfoListInqrVO = []

      this.serviceCall('txTSSPS13U1', 'di')
    },
    /******************************************************************************
    * Function명  : insurdCrmUpdate
    * 설명        : 고객(피보험자) 변경데이터 CRM 업데이트
    ******************************************************************************/
    insurdCrmUpdate () {
      this.insurdCustCardRegMdfcSVO.jobpNm = this.insuredJobTitle // 직장명
      if ( this.insurdHomeTelSelect.label !== '' && this.insurdHomeTelSelect.label !== '선택' && this.insurdHomeTelSelect.label !== undefined && this.insurdHomeTel.length > 0 ) {
        // this.insurdCustCardRegMdfcSVO.homTelno = this.$bizUtil.telNoWithHyphen(this.insurdHomeTelSelect.label + this.insurdHomeTel) // 집전화
        //this.insurdCustCardRegMdfcSVO.homTelno = this.insurdHomeTelSelect.label + '-' + this.$cmUtil.replacePhoneBackNumWithHyphen(this.insurdHomeTel)
        //TO-DO $cmUtil.replacePhoneBackNumWithHyphen 만들어지면 변경
        this.insurdCustCardRegMdfcSVO.homTelno = this.insurdHomeTelSelect.label + '-' + this.fn_replacePhoneBackNumWithHyphen(this.insurdHomeTel)
      } else this.insurdCustCardRegMdfcSVO.homTelno = ' '

      // 피보험자 이메일 수정
      if ( this.userData2.detail.emailAddr.trim().length > 0 ) {
        this.insurdCustCardRegMdfcSVO.emailStatFlag = 'X'
        this.insurdCustCardRegMdfcSVO.emailAddr = this.userData2.detail.emailAddr
      }

      if (this.newInsuredAddressFlag) { // 새주소 등록시
        if ( this.newInsuredAddressInfo1.hasOwnProperty('basAddr') ) { // 피보험자 자택주소 변경시
          if ( this.newContractAddressInfo1.addrScCd === '1') { // 지번주소
            this.insurdCustCardRegMdfcSVO.homAddr = this.newInsuredAddressInfo1.basAddr
            this.insurdCustCardRegMdfcSVO.homDtlad = this.newInsuredAddressInfo1.dtladAddr
            this.insurdCustCardRegMdfcSVO.adtnAddr01 = ' '
          } else { // 도로명 주소
            this.insurdCustCardRegMdfcSVO.homAddr = this.newInsuredAddressInfo1.basAddr
            this.insurdCustCardRegMdfcSVO.homDtlad = this.newInsuredAddressInfo1.roadNmDtlad
            this.insurdCustCardRegMdfcSVO.adtnAddr01 = this.newInsuredAddressInfo1.roadNmAddmAddr
          }

          if ( this.newInsuredAddressInfo1.bldgBonbHo.trim().length < 1 ) {
            this.insurdCustCardRegMdfcSVO.bldgNo01 = ' '
          } else this.insurdCustCardRegMdfcSVO.bldgNo01 = this.newInsuredAddressInfo1.bldgBonbHo

          this.insurdCustCardRegMdfcSVO.homFrnoPstcd = this.newInsuredAddressInfo1.pstCd
          this.insurdCustCardRegMdfcSVO.homAddrScCd = this.newInsuredAddressInfo1.addrScCd
          this.insurdCustCardRegMdfcSVO.homAddrRefnCd = this.newInsuredAddressInfo1.addrRefnCd
          this.insurdCustCardRegMdfcSVO.homAddrStatCd = '11'
        }
        if ( this.newInsuredAddressInfo2.hasOwnProperty('basAddr') ) { // 피보험자 직장주소 변경시
          if ( this.newContractAddressInfo2.addrScCd === '1' ) { // 지번 주소
            this.insurdCustCardRegMdfcSVO.jobpAddr = this.newInsuredAddressInfo2.basAddr
            this.insurdCustCardRegMdfcSVO.jobpDtlad = this.newInsuredAddressInfo2.dtladAddr
            this.insurdCustCardRegMdfcSVO.adtnAddr02 = ' '
          } else { // 도로명 주소
            this.insurdCustCardRegMdfcSVO.jobpAddr = this.newInsuredAddressInfo2.basAddr
            this.insurdCustCardRegMdfcSVO.jobpDtlad = this.newInsuredAddressInfo2.roadNmDtlad
            this.insurdCustCardRegMdfcSVO.adtnAddr02 = this.newInsuredAddressInfo2.roadNmAddmAddr
          }

          if ( this.newInsuredAddressInfo2.bldgBonbHo.trim().length < 1 ) {
            this.insurdCustCardRegMdfcSVO.bldgNo02 = ' '
          } else this.insurdCustCardRegMdfcSVO.bldgNo02 = this.newInsuredAddressInfo2.bldgBonbHo

          this.insurdCustCardRegMdfcSVO.jobpFrnoPstcd = this.newInsuredAddressInfo2.pstCd
          this.insurdCustCardRegMdfcSVO.jobpAddrScCd = this.newInsuredAddressInfo2.addrScCd
          this.insurdCustCardRegMdfcSVO.jobpAddrRefnCd = this.newInsuredAddressInfo2.addrRefnCd
          this.insurdCustCardRegMdfcSVO.jobpAddrStatCd = '11'
        }
      }
      this.insurdCustCardRegMdfcSVO.ichoshInfoListInqrVO = []

      this.serviceCall('txTSSPS13U2', 'di')
    },
    /******************************************************************************
    * Function명  : fn_ZipCodeSrch
    * 설명        : 우편번호 검색
    ******************************************************************************/
    fn_ZipCodeSrch (srchTp) {
      if (srchTp === '01') { // 계약자 우편번호 검색시
        this.addrSrchContractInsuredDiv = '01'
      } else if (srchTp === '02') { // 피보험자 우편번호 검색시
        this.addrSrchContractInsuredDiv = '02'
      } else if (srchTp === 'dthBnfr01') { // 사망수익자01 우편번호 검색시(AML 보완 / 2311_PJO)
        this.addrSrchContractInsuredDiv = 'dthBnfr01'
      }
      this.fn_OpenAddrPopUp() // 주소검색 팝업오픈
    },
    /*********************************************************
     * Function명: fn_OpenAddrPopUp
     * 설명 : 주소검색 팝업 열기
     *
     *********************************************************/
    fn_OpenAddrPopUp () {  
      const lv_ShowAddrPopUp = this.$bottomModal.open(MSPCM130P, {
        properties: {
        },
        listeners: {
          onPopupClose: (pData) => {
            this.$bottomModal.close(lv_ShowAddrPopUp)
           
            this.fn_AddressReturnHandler(pData)
           
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_AddressReturnHandler
    * 설명       : 주소 검색 결과 이벤트 핸들러
    * 인자       : value: Object 검증 변환 주소선택 리턴 값
    * Return     :
    ******************************************************************************/
    fn_AddressReturnHandler: function (value) {
      if (this.addrSrchContractInsuredDiv === '01') { // 계약자 주소검색시
        if (this.returnData6 === '01') { // 자택
          this.newContractAddressInfo1 = value
        } else { // 직장
          this.newContractAddressInfo2 = value
        }
        this.cntrctAddressInfo = '(' + value.pstCd + ')' + value.basAddr + value.dtladAddr
        this.cntrctAddressInfo1 = '(' + value.pstCd + ')' + value.basAddr
        this.cntrctAddressInfo2 = value.dtladAddr
        this.newContractAddressFlag = true
      } else if (this.addrSrchContractInsuredDiv === '02') { // 피보험자 주소검색시 (조건수정/ 2311_PJO)
        if (this.returnData10 === '01') {
          this.newInsuredAddressInfo1 = value
        } else {
          this.newInsuredAddressInfo2 = value
        }
        this.insurdAddressInfo = '(' + value.pstCd + ')' + value.basAddr + value.dtladAddr
        this.insurdAddressInfo1 = '(' + value.pstCd + ')' + value.basAddr
        this.insurdAddressInfo2 = value.dtladAddr
        this.newInsuredAddressFlag = true
      } else if (this.addrSrchContractInsuredDiv === 'dthBnfr01') { // 사망수익자 주소검색시(AML 보완 / 2311_PJO)
        if (this.returnDataDthBnfr01 === '01') { // 자택
          this.newIsBnfrInfo35AddressInfo1 = value
        } else { // 직장
          this.newIsBnfrInfo35AddressInfo2 = value
        }
        this.isBnfrInfo35Info = '(' + value.pstCd + ')' + value.basAddr + value.dtladAddr
        this.isBnfrInfo35Info1 = '(' + value.pstCd + ')' + value.basAddr
        this.isBnfrInfo35Info2 = value.dtladAddr
        this.newIsBnfrInfo35AddressFlag = true
      }
    },
    /******************************************************************************
    * Function명  : fn_NextBtnEnable
    * 설명        : 다음버튼 활성화,비활성화 여부 판단
    * [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면) : returnData13ValidateCmpltFlag , this.returnData14ValidateCmpltFlag 체크 추가
    ******************************************************************************/
    fn_NextBtnEnable () {
      // 계피동일
      if (this.isContInsFlag) {
        if ( this.contractorJobTitleValidateCmpltFlag && this.contractorWorkValidateCmpltFlag && this.returnData1ValidateCmpltFlag &&
            this.returnData2ValidateCmpltFlag && this.returnData3ValidateCmpltFlag && this.returnData4ValidateCmpltFlag && this.returnData5ValidateCmpltFlag &&
            this.returnData6ValidateCmpltFlag && this.returnData13ValidateCmpltFlag && this.returnData14ValidateCmpltFlag ) {
          this.contractorCmpltFlag = true
          this.nextBtnFlag = false
        } else {
          this.contractorCmpltFlag = false
          this.nextBtnFlag = true
        }
      // 계피상이
      } else {
        // 계약자만 인증한 경우
        if ( this.contractorCertify && !this.insuredCertify ) {
          if ( this.contractorJobTitleValidateCmpltFlag && this.contractorWorkValidateCmpltFlag && this.returnData1ValidateCmpltFlag &&
            this.returnData2ValidateCmpltFlag && this.returnData3ValidateCmpltFlag && this.returnData4ValidateCmpltFlag && this.returnData5ValidateCmpltFlag &&
            this.returnData6ValidateCmpltFlag && this.returnData13ValidateCmpltFlag && this.returnData14ValidateCmpltFlag) {
            this.contractorCmpltFlag = true
            this.nextBtnFlag = false
          } else {
            this.contractorCmpltFlag = false
            this.nextBtnFlag = true
          }
        // 피보험자만 인증한 경우
        } else if ( !this.contractorCertify && this.insuredCertify ) {
          if ( this.insuredJobTitleValidateCmpltFlag && this.insuredWorkValidateCmpltFlag && this.returnData7ValidateCmpltFlag &&
            this.returnData8ValidateCmpltFlag && this.returnData9ValidateCmpltFlag && this.returnData10ValidateCmpltFlag ) {
            this.insuredCmpltFlag = true
            this.nextBtnFlag = false
          } else {
            this.insuredCmpltFlag = false
            this.nextBtnFlag = true
          }
        // 계약자, 피보험자 인증
        } else {
          // 피보험자 정보 입력후(필수값 충족시) 피보험자 입력화면으로 자동 스크롤
          if ( this.insuredJobTitleValidateCmpltFlag && this.insuredWorkValidateCmpltFlag && this.returnData7ValidateCmpltFlag &&
            this.returnData8ValidateCmpltFlag && this.returnData9ValidateCmpltFlag && this.returnData10ValidateCmpltFlag) {
            
            this.insuredCmpltFlag = true
          }
          else {
            this.insuredCmpltFlag = false
          }

          // 계약자 정보 입력후(필수값 충족시) 피보험자 입력화면으로 자동 스크롤
          if ( this.contractorJobTitleValidateCmpltFlag && this.contractorWorkValidateCmpltFlag && this.returnData1ValidateCmpltFlag &&
            this.returnData2ValidateCmpltFlag && this.returnData3ValidateCmpltFlag && this.returnData4ValidateCmpltFlag && this.returnData5ValidateCmpltFlag &&
            this.returnData6ValidateCmpltFlag && this.returnData13ValidateCmpltFlag && this.returnData14ValidateCmpltFlag) {
            
            this.contractorCmpltFlag = true
            //var element = this.$refs.insuredScroll
            //var top = element.offsetTop - this.offsetVal // 특정 ref의 y축 위쪽 위치 정보
            //const scrollEl = this.$refs.scroll
            //const smoothScrollAnimate = this.$commonUtil.createScrollAnimateInstance(scrollEl, 'y')
            //smoothScrollAnimate.animate(top, 700, 0, 'easeInOut')
          }
          else {
            this.contractorCmpltFlag = false
          }
          
          if ( this.contractorJobTitleValidateCmpltFlag && this.contractorWorkValidateCmpltFlag && this.returnData1ValidateCmpltFlag &&
            this.insuredJobTitleValidateCmpltFlag && this.insuredWorkValidateCmpltFlag && this.returnData1ValidateCmpltFlag && this.returnData2ValidateCmpltFlag &&
            this.returnData3ValidateCmpltFlag && this.returnData4ValidateCmpltFlag && this.returnData5ValidateCmpltFlag && this.returnData6ValidateCmpltFlag &&
            this.returnData7ValidateCmpltFlag && this.returnData8ValidateCmpltFlag && this.returnData9ValidateCmpltFlag && this.returnData10ValidateCmpltFlag &&
            this.returnData13ValidateCmpltFlag && this.returnData14ValidateCmpltFlag) {
            this.nextBtnFlag = false
          } else {
            this.nextBtnFlag = true
          }
        }
      }
      // 사망수익자1 이 EDD 대상일경우  ( MSPPS420M 에서 세팅해줌) 사망수익자1 주소세팅해야 함 (AML 보완 / 2311_PJO)
      console.log('isAmlDthBnfr01Flag:' + this.isAmlDthBnfr01Flag + '/returnDataDthBnfr01ValidateCmpltFlag:'+ this.returnDataDthBnfr01ValidateCmpltFlag)
      if ( this.isAmlDthBnfr01Flag ) { // created 에서 세팅함 ( == this.commData.isAmlDthBnfr01 ) 
        if( this.returnDataDthBnfr01ValidateCmpltFlag ) { // 사망수익자1 주소세팅함
          this.dthBnfr01CmpltFlag = true // 입력완료 표기
        } else { // 사망수익자1 주소세팅 안함
          this.nextBtnFlag = true // 다음 버튼 비활성화 ( true )
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_InsElstEtlInfo
    * 설명       : 사망수익자1 주소정보 업데이트 /  CRM고객카드(F1MDI0272) , BP업데이트 (F1MDI0067)
    ******************************************************************************/
    fn_bnfInfo35Update : async function () {
      let lv_Vm = this
      let rtnData = {isError : false , msgCntnt : ''}
      console.log('--------------사망수익자1 주소정보 업데이트.시작')
     // CRM고객카드(F1MDI0272) 업데이트 :사망수익자는 주소만 변경가능 하므로 신규주소 선택시에만 CRM 주소정보업데이트
     if ( this.newIsBnfrInfo35AddressFlag ) {
        console.log('--------------사망수익자1.CRM고객카드(F1MDI0272) 업데필요')
        this.fn_bnfInfo35CRMVO() // isBnfrInfo35CustCardRegMdfcSVO 세팅
        let rsltUpdCRMCard = null
        let pParamsCRMCard = {}
        pParamsCRMCard = Object.assign({}, PSServiceManager.getParameter('txTSSPS13U1'))  // F1MDI0272
        pParamsCRMCard.srnId = this.$options.screenId  // 대표 화면 명
        pParamsCRMCard.data  = {}
        pParamsCRMCard.data  = this.isBnfrInfo35CustCardRegMdfcSVO // 고객카드조회(F1MDI0270) 조회값 + this.fn_bnfInfo35CRMVO() 에서 신규주소정보 채워줌
        rsltUpdCRMCard  = await PSServiceManager.invokeSendAsync(pParamsCRMCard)
        console.log('사망수익자1.CRM고객카드(F1MDI0272) 업데결과.isError:' +  rsltUpdCRMCard.isError )
        if(rsltUpdCRMCard && rsltUpdCRMCard.isError){ // 사망수익자01 업데이트 결과 에러 처리
          rtnData.isError = true
          rtnData.msgCntnt = '사망수익자 주소정보 CRM고객카드 업데이트 실패(chnlCustId:'+ this.isBnfrInfo35CustCardRegMdfcSVO.chnlCustId +')\n'
        }
        if(rtnData.isError) return rtnData
     }
     // 2. BP고객정보 (F1MDI0067) 업데이트  / PSOfrAcpnSVCImpl.updBpCustCardReg
     // txTSSPS13U2 // this.serviceCall('txTSSPS13U2', 'di') 
     console.log('--------------사망수익자1.기준정보 업데.필요')
      let rsltUpdBP = null
      let pParamsDthBnfr = {}
      pParamsDthBnfr = Object.assign({}, PSServiceManager.getParameter('txTSSPS13U2')) // PSOfrAcpnSVCImpl.updBpCustCardReg
      pParamsDthBnfr.srnId = this.$options.screenId  // 대표 화면 명
      pParamsDthBnfr.data = {}
      pParamsDthBnfr.data.elstPartnNo     = this.userData3DthBnfr01AML.partnerId // 대상고객 BPID
      pParamsDthBnfr.data.mobslChnlCustId = this.userData3DthBnfr01AML.agtFileId // 대상고객채널ID
      pParamsDthBnfr.data.mnContrNm       = this.userData3DthBnfr01AML.custNm    // 대상고객명
      pParamsDthBnfr.data.mailRcpplCd     = this.returnDataDthBnfr01             // 주소.직장/자택 선택 구분
      rsltUpdBP = await PSServiceManager.invokeSendAsync(pParamsDthBnfr)  //업데이트 수행
      console.log('--------------사망수익자1.기준정보 업데결과.isError:' + rsltUpdBP.isError )
      if(rsltUpdBP && rsltUpdBP.isError){ // 사망수익자01 업데이트 결과 에러 처리
        rtnData.isError = true
        rtnData.msgCntnt = '사망수익자 주소정보 기준정보 업데이트 실패(BP:'+ this.userData3DthBnfr01AML.partnerId +')\n'
      }
      return rtnData
   },
   fn_bnfInfo35CRMVO () {
      console.log('--------------fn_bnfInfo35CRMVO set')
      if (this.newIsBnfrInfo35AddressFlag) { // 새주소 등록시
        if ( this.newIsBnfrInfo35AddressInfo1.hasOwnProperty('basAddr') ) { // 계약자 자택주소 변경시
          if ( this.newIsBnfrInfo35AddressInfo1.addrScCd === '1') { // 지번주소
            this.isBnfrInfo35CustCardRegMdfcSVO.homAddr = this.newIsBnfrInfo35AddressInfo1.basAddr
            this.isBnfrInfo35CustCardRegMdfcSVO.homDtlad = this.newIsBnfrInfo35AddressInfo1.dtladAddr
            this.isBnfrInfo35CustCardRegMdfcSVO.adtnAddr01 = ' '
          } else { // 도로명 주소
            this.isBnfrInfo35CustCardRegMdfcSVO.homAddr = this.newIsBnfrInfo35AddressInfo1.basAddr
            this.isBnfrInfo35CustCardRegMdfcSVO.homDtlad = this.newIsBnfrInfo35AddressInfo1.roadNmDtlad
            this.isBnfrInfo35CustCardRegMdfcSVO.adtnAddr01 = this.newIsBnfrInfo35AddressInfo1.roadNmAddmAddr
          }

          if ( this.newIsBnfrInfo35AddressInfo1.bldgBonbHo.trim().length < 1 ) {
            this.isBnfrInfo35CustCardRegMdfcSVO.bldgNo01 = ' '
          } else this.isBnfrInfo35CustCardRegMdfcSVO.bldgNo01 = this.newIsBnfrInfo35AddressInfo1.bldgBonbHo

          this.isBnfrInfo35CustCardRegMdfcSVO.homFrnoPstcd = this.newIsBnfrInfo35AddressInfo1.pstCd
          this.isBnfrInfo35CustCardRegMdfcSVO.homAddrScCd = this.newIsBnfrInfo35AddressInfo1.addrScCd
          this.isBnfrInfo35CustCardRegMdfcSVO.homAddrRefnCd = this.newIsBnfrInfo35AddressInfo1.addrRefnCd
          this.isBnfrInfo35CustCardRegMdfcSVO.homAddrStatCd = '11'
        }
        if ( this.newIsBnfrInfo35AddressInfo2.hasOwnProperty('basAddr') ) { // 계약자 직장주소 변경시
          if ( this.newIsBnfrInfo35AddressInfo2.addrScCd === '1' ) { // 지번 주소
            this.isBnfrInfo35CustCardRegMdfcSVO.jobpAddr = this.newIsBnfrInfo35AddressInfo2.basAddr
            this.isBnfrInfo35CustCardRegMdfcSVO.jobpDtlad = this.newIsBnfrInfo35AddressInfo2.dtladAddr
            this.isBnfrInfo35CustCardRegMdfcSVO.adtnAddr02 = ' '
          } else { // 도로명 주소
            this.isBnfrInfo35CustCardRegMdfcSVO.jobpAddr = this.newIsBnfrInfo35AddressInfo2.basAddr
            this.isBnfrInfo35CustCardRegMdfcSVO.jobpDtlad = this.newIsBnfrInfo35AddressInfo2.roadNmDtlad
            this.isBnfrInfo35CustCardRegMdfcSVO.adtnAddr02 = this.newIsBnfrInfo35AddressInfo2.roadNmAddmAddr
          }

          if ( this.newIsBnfrInfo35AddressInfo2.bldgBonbHo.trim().length < 1 ) {
            this.isBnfrInfo35CustCardRegMdfcSVO.bldgNo02 = ' '
          } else this.isBnfrInfo35CustCardRegMdfcSVO.bldgNo02 = this.newIsBnfrInfo35AddressInfo2.bldgBonbHo

          this.isBnfrInfo35CustCardRegMdfcSVO.jobpFrnoPstcd = this.newIsBnfrInfo35AddressInfo2.pstCd
          this.isBnfrInfo35CustCardRegMdfcSVO.jobpAddrScCd = this.newIsBnfrInfo35AddressInfo2.addrScCd
          this.isBnfrInfo35CustCardRegMdfcSVO.jobpAddrRefnCd = this.newIsBnfrInfo35AddressInfo2.addrRefnCd
          this.isBnfrInfo35CustCardRegMdfcSVO.jobpAddrStatCd = '11'
        }
      }
      this.isBnfrInfo35CustCardRegMdfcSVO.ichoshInfoListInqrVO = []
   },
  async nextStep_test () {
      console.log('--------------nextStep.진입')
      // EDD대상 사망수익자1 주소정보 업데이트 (AML 보완 / 2311_PJO)
      let newIsBnfrInfo35AddressRtnData = {isError : false , msgCntnt : ''}
      if (this.isAmlDthBnfr01Flag) {
        newIsBnfrInfo35AddressRtnData = await this.fn_bnfInfo35Update()
      }
      console.log('--------------nextStep.업데이트결과받았나?.isError:' + newIsBnfrInfo35AddressRtnData.isError)
      if (!newIsBnfrInfo35AddressRtnData.isError) {
        console.log('-------------- 다음스텝 이동-----------')
        this.nextStep2() // 다음스텝 이동
      } 
    },
    // 다음스텝 이동
    async nextStep () {
      console.log('--------------nextStep.진입')
      // EDD대상 사망수익자1 주소정보 업데이트 (AML 보완 / 2311_PJO)
      let newIsBnfrInfo35AddressRtnData = {isError : false , msgCntnt : ''}
      if (this.isAmlDthBnfr01Flag) {
        newIsBnfrInfo35AddressRtnData = await this.fn_bnfInfo35Update()
        console.log('--------------nextStep.업데이트결과받았나?.isError:' + newIsBnfrInfo35AddressRtnData.isError)
        if (newIsBnfrInfo35AddressRtnData.isError) {
          console.log('-------------- 사망수익자1 주소정보 업데이트시 오류')
          return
        }
      }
      console.log('--------------다음스텝 이동 진행-----------')
      // 고객정보 업데이트가 끝나면 이동(신모바일,crm 업데이트)
      if ( this.isContInsFlag ) { // 계피동일
        if ( this.returnData2 === '01' ) { // FATCA 선택여부 -> '01' 해당함 (해당시 FATCA 팝업에서 별도 저장)
          if ( this.serviceCmpltCnt !== 2 ) return
        } else {
          if ( this.serviceCmpltCnt !== 3 ) return
        }
        if ( this.planInfo.aftRctmHopYn === 'Y' && this.planInfo.aftRctmHopYn !== undefined ) { // 후입금 상태일때
          this.$emit('move', 'app') // 전자서명앱 이동
        } else this.$emit('move', 'next') // 계약자 계좌정보 확인 및 신규계좌입력 화면 이동
      } else { // 계피상이
        if ( !this.contractorCertify || !this.insuredCertify ) {
          if (this.contractorCertify && this.returnData2 === '02') { // 계약자 본인인증하고 FATCA 여부가 해당없음일때 서비스 3개체크
            if ( this.serviceCmpltCnt !== 3 ) return
          } else {
            if ( this.serviceCmpltCnt !== 2 ) return
          }
          if ( this.contractorCertify && !this.insuredCertify ) { // 계약자만 핸드폰 본인인증시
            if ( this.planInfo.aftRctmHopYn === 'Y' && this.planInfo.aftRctmHopYn !== undefined ) { // 후입금 상태일때
              this.$emit('move', 'app')
            } else this.$emit('move', 'next')
          } else if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 핸드폰 본인인증시
            // 200204 예금주 상이적용
            // this.$emit('move', 'app')
            this.$emit('move', 'next')
          }
        } else { // 계약자,피보험자 핸드폰 본인인증시
          if ( this.returnData2 === '01' ) {
            if ( this.serviceCmpltCnt !== 3 ) return
          } else {
            if ( this.serviceCmpltCnt !== 4 ) return
          }
          if ( this.planInfo.aftRctmHopYn === 'Y' && this.planInfo.aftRctmHopYn !== undefined ) { // 후입금 상태일때
            this.$emit('move', 'app') // 전자서명앱 이동
          } else this.$emit('move', 'next') // 계약자 계좌정보 확인 및 신규계좌입력 화면 이동
        }
      }
    },
    /******************************************************************************
    * Function명  : serviceCall
    * 설명        : 서비스 호출 함수
    ******************************************************************************/
    serviceCall (trnstId, serviceDiv) {
      var lv_Vm = this
      let pParams = {}
      let btnId = 'S'
      let serviceName = ''
      pParams.srnId = this.$options.screenId // 대표 화면 명
      switch (serviceDiv) {
        /********************************************************
        * PO 직접호출
        ********************************************************/
        case 'di': //
          switch (trnstId) {
            case 'txTSSPS22S4': // CRM 고객카드 조회(계약자)
              serviceName = 'S'

              pParams.appId = 'sli.ipi'
              pParams.trnstId = 'txTSSPS22S4'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0270_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0270ReqVO'
              pParams.resVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0270ResVO'
              pParams.useCompress = true
              pParams.data = this.txTSSPS22S4DataContractor
              break
            case 'txTSSPS22S5': // CRM 고객카드 조회(피보험자)
              serviceName = 'S'

              pParams.appId = 'sli.ipi'
              pParams.trnstId = 'txTSSPS22S5'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0270_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0270ReqVO'
              pParams.resVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0270ResVO'
              pParams.useCompress = true
              pParams.data = this.txTSSPS22S4DataInsurd
              break
            case 'txTSSPS13U1': // CRM 고객카드 업데이트(계약자)
              serviceName = 'U'

              pParams.appId = 'sli.ipi'
              pParams.trnstId = 'txTSSPS13U1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0272_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0272VO'
              pParams.resVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0272VO'
              pParams.data = this.contractorCustCardRegMdfcSVO
              break
            case 'txTSSPS13U2': // CRM 고객카드 업데이트(피보험자)
              serviceName = 'U'

              pParams.appId = 'sli.ipi'
              pParams.trnstId = 'txTSSPS13U2'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0272_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0272VO'
              pParams.resVONm = 'sli.in.ea2.vo.a0005md.EAF1MDI0272VO'
              pParams.data = this.insurdCustCardRegMdfcSVO
              break
            case 'txTSSPS13P1': // FATCA 고객 해당없음 저장처리 -> 해당없으면 해당없음('1')으로 저장처리
              serviceName = 'I'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.ipi'
              pParams.trnstId = 'txTSSPS13P1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0055_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0055VO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0055VO'
              pParams.data = this.txTSSPS13P1Data
              break
            default:
              break
          }
          PSServiceManager.invoke(pParams, lv_Vm.fn_ServiceResultHandler, serviceName, lv_Vm.fn_ServiceFaultHandler)
          break

        /********************************************************
        * 자바서비스 호출
        ********************************************************/
        case 'ind': // 자바서비스 호출
          switch (trnstId) {
            case 'txTSSPS99U2':
            case 'txTSSPS99U11':
            case 'txTSSPS99U12': // 고객정보 업데이트
              pParams = this.psBefrInptInfoSVO
              break
            // case 'txTSSPS18S8': // 임직원 여부 조회  // 주석처리   2019.11.19 PJO
            //   pParams = {
            //     custId: this.userData1.partnerId,
            //     custNm: this.userData1.detail.custNm,
            //     vuchId: this.inputData.moDCDocuMngVO.eltrnDoctId
            //   }
            case 'txTSSPS18S8': // 임직원 여부 조회 txTSSPS18S8  2019.11.19 PJO
              pParams = {
                custId: this.userData1.partnerId
              }
              break
            default:
              break
          }

          this.post(lv_Vm, pParams, trnstId, btnId)
            .then(function (response) {
              // 서버 데이터 가져오기
              switch (trnstId) {
                case 'txTSSPS99U2':
                case 'txTSSPS99U11':
                case 'txTSSPS99U12':
                  if (response.body !== null) {
                  // console.log('신태블릿 고객정보 업데이트')
                    lv_Vm.serviceCmpltCnt++
                    // lv_Vm.nextStep()
                    lv_Vm.fn_seqServiceHandler('txTSSPS99U2')
                  }
                  break
                // 주석 2019.11.19 PJO
                //  case 'txTSSPS18S7':
                //   if (response.body !== null) {
                //     if (response.body.scnAcpnRstcYn.trim() === 'Y') {
                //       lv_Vm.scnAcpnRstcYn = true
                //     }
                //   }
                case 'txTSSPS18S8': // 2019.11.19 PJO
                  if (response.body !== null) {
                    // console.log('txTSSPS18S8....임직원 여부 조회') //  FC또는 임직원인경우 Y 세팅됨
                    if (response.body.selfContYn.trim() === 'Y') {
                      lv_Vm.selfContYn = true
                    }
                    // [ASR210701056] 금소법 관련 시스템 반영 요청(확인서2종, 개인_사랑온_사전입력 화면)
                    if (response.body.fpContYn.trim() === 'Y') {
                      lv_Vm.fpContYn = true // 설계사계약여부
                    }
                  }
                  lv_Vm.fn_init() // 증권수령방법 버튼 초기화
                  break
                default:
                  break
              }
            })

            .catch(function (error) {
              lv_Vm.serviceCmpltCnt = 0
              //lv_Vm.alertCloseCnt = 0
              window.vue.error(error)
            })
          break
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, pParams, moreData) {
      let trnstId = lv_Vm.common.fahrTrnId

      if (lv_Vm.data !== null) {
        switch (trnstId) {
          case 'txTSSPS22S4':
            this.contractorCustCardRegMdfcSVO = lv_Vm.data
            // console.log('CRM고객(계약자) 정보 조회')
            break
          case 'txTSSPS22S5':
            this.insurdCustCardRegMdfcSVO = lv_Vm.data
            // console.log('CRM고객(피보험자) 정보 조회')
            break
          case 'txTSSPS13U1':
            // console.log('CRM고객(계약자) 정보변경 업데이트')
            this.fn_seqServiceHandler('txTSSPS13U1')
            break
          case 'txTSSPS13U2':
            // console.log('CRM고객(피보험자) 정보변경 업데이트')
            this.fn_seqServiceHandler('txTSSPS13U2')
            break
          case 'txTSSPS13P1':
            // console.log('FATCA 정보 저장')
            this.fn_seqServiceHandler('txTSSPS13P1')
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceFaultHandler
    * 설명        : 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler (event) {
      this.serviceCmpltCnt = 0
      //this.alertCloseCnt = 0
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },
    /******************************************************************************
    * Function명  : fn_seqServiceHandler
    * 설명        : 서비스 호출 순차처리 핸들러 (서비스 동기화 처리)
    ******************************************************************************/
    fn_seqServiceHandler ( val ) {

      let txt = ''
      switch(val) {
         case 'txTSSPS13U1': txt = '계약자.F1MDI0272(고객카드)' 
          break
         case 'txTSSPS13U2': txt = '피보험자.F1MDI0272(고객카드)' 
          break
        case 'txTSSPS13P1': txt = 'FATCA 정보 저장' 
          break
        case 'txTSSPS99U2': txt = 'TTSD00002.계약관계인업데이트(updContRltnrInfo)' 
          break
      }
      console.log(val+'/'+ txt + '_업데이트.성공')
      if ( this.isContInsFlag || (this.contractorCertify && !this.insuredCertify) ) { // 계피동일이거나 계약자만 본인인증시
        switch (val) {
          case 'txTSSPS13U1': // CRM고객(계약자) 정보변경 업데이트
            if ( this.returnData2 === '02' ) { // FATCA 해당없음 선택시 FATCA 인터페이스 해당없음으로 저장 (계약자만)
              this.fn_NoFatcaInfoInsert()
            } else this.fn_CustDataUpdate()
            break
          case 'txTSSPS13P1': // FATCA 정보 저장
            this.fn_CustDataUpdate()
            break
          case 'txTSSPS99U2': // 신태블릿 고객정보 업데이트
            // this.nextStep()
            this.userOldJobCdNAddrChk('1')
            break
        }
      } else { // 계피상이
        if ( !this.contractorCertify && this.insuredCertify ) { // 피보험자만 본인인증시
          switch (val) {
            case 'txTSSPS13U2': // CRM고객(피보험자) 정보변경 업데이트
              this.fn_CustDataUpdate()
              break
            case 'txTSSPS99U2': // 신태블릿 고객정보 업데이트
              // this.nextStep()
              this.userOldJobCdNAddrChk('2')
              break
          }
        } else if ( this.contractorCertify && this.insuredCertify ) { // 계약자,피보험자 본인인증시
          switch (val) {
            case 'txTSSPS13U1': // CRM고객(계약자) 정보변경 업데이트
              this.insurdCrmUpdate()
              break
            case 'txTSSPS13U2': // CRM고객(피보험자) 정보변경 업데이트
              if ( this.returnData2 === '02' ) {
                this.fn_NoFatcaInfoInsert()
              } else this.fn_CustDataUpdate()
              break
            case 'txTSSPS13P1': // FATCA 정보 저장
              this.fn_CustDataUpdate()
              break
            case 'txTSSPS99U2': // 신태블릿 고객정보 업데이트
              // this.nextStep()
              this.userOldJobCdNAddrChk('3')
              break
          }
        }
      }
      this.serviceCmpltCnt++
    },
    /******************************************************************************
    * Function명  : userOldJobCdNAddrChk
    * 설명        : CRM 에 등록된 고객 직업코드와 주소 검증작업(구직업코드와 잘못된 주소가 CRM에 저장되어있는 경우가 있음) -> BP업데이트를 통해 정합성 체크
    ******************************************************************************/
    userOldJobCdNAddrChk ( val ) {
      if (val !== '2') {
        this.psBPCustCardInfoSVO.elstPartnNo = this.userData1.partnerId // 파트너 ID
        this.psBPCustCardInfoSVO.mobslChnlCustId = this.userData1.detail.chnlCustId // 채널고객ID
        this.psBPCustCardInfoSVO.mnContrNm = this.userData1.detail.custNm // 고객 이름
        this.psBPCustCardInfoSVO.mailRcpplCd = this.returnData6 // 주소 구분(01: 자택, 02 직장)
      } else {
        this.psBPCustCardInfoSVO.elstPartnNo = this.userData2.partnerId
        this.psBPCustCardInfoSVO.mobslChnlCustId = this.userData2.detail.chnlCustId
        this.psBPCustCardInfoSVO.mnContrNm = this.userData2.detail.custNm
        this.psBPCustCardInfoSVO.mailRcpplCd = this.returnData10
      }

      let lv_Vm = this
      let subVal = '3_1'
      this.post(lv_Vm, this.psBPCustCardInfoSVO, 'txTSSPS13U2', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            if (response.body.trtRslt === 0) {
              if ( (val === '1' || subVal === '3_1') && val !== '2' ) {
                lv_Vm.alertMssge = '계약자의 주소가 정확하지 않습니다.<br />우편번호검색을 통해 주소를 수정해주세요'
                lv_Vm.fn_BottomAlert(lv_Vm.alertMssge)
              } else if ( val === '2' || subVal === '3_2') {
                lv_Vm.alertMssge = '피보험자 주소가 정확하지 않습니다.<br />우편번호검색을 통해 주소를 수정해주세요'
                lv_Vm.fn_BottomAlert(lv_Vm.alertMssge)
              }
              lv_Vm.serviceCmpltCnt = 0
              //lv_Vm.alertCloseCnt = 0
            } else {
              if( val === '1' || val === '3' ) { console.log( val + '/F1MDI0067.계약자_업데이트.성공' )}
              else if( val === '2') { console.log( val + '/F1MDI0067.피보험자_업데이트.성공' )}
              if ( val === '3' ) {
                subVal = '3_2'
                lv_Vm.userOldJobCdNAddrChk('2')
              } else {
                lv_Vm.serviceCmpltCnt-- // 현재함수 인라인펑션으로인해 위 fn_seqServiceHandler에서 serviceCmpltCnt가 하나 더 증가됨 따라서 -1
                lv_Vm.nextStep()
              }
            }
          }
        })
        .catch(function (error) {
          lv_Vm.serviceCmpltCnt = 0
          //lv_Vm.alertCloseCnt = 0
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명  : fn_initHomeTel1
    * 설명        : 계약자 국번 "선택" 설정시 뒷번호 초기화
    ******************************************************************************/
    fn_initHomeTel1 ( param ) {
      if (this.contractorHomeTelSelect.label === '' || this.contractorHomeTelSelect.label === '선택') {
        this.contractorHomeTel = ''
      }else{
        this.$refs.contractorHomeTel.focus()
      }
    },
    /******************************************************************************
    * Function명  : fn_initHomeTel2
    * 설명        : 피보험자 국번 "선택" 설정시 뒷번호 초기화
    ******************************************************************************/
    fn_initHomeTel2 ( param ) {
      if (this.insurdHomeTelSelect.label === '' || this.insurdHomeTelSelect.label === '선택') {
        this.insurdHomeTel = ''
      }else{
        this.$refs.insurdHomeTel.focus()
      }
    },

    /******************************************************************************
    * Function명  : fn_ShowFatcaPopUp
    * 설명        : FATCA/CRS 팝업
    ******************************************************************************/
    fn_ShowFatcaPopUp () {
      const lv_ShowFatcaPopUp = this.$bottomModal.open(MSPPS421P, {
        properties: {
          userData: this.userData1 // 계약자 정보
        },
        
        listeners: {
          // 대상팝업 close $emit 이벤트명 
          fatcaPopUpClose: (rtnData) => {
            this.$bottomModal.close(lv_ShowFatcaPopUp) // 모달 닫기                        
            if (rtnData === 'cancel') {
              this.returnData2 = ''
            }
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_ShowFatcaPopUp) // 모달 닫기                        
          }
        }
      })
    },

    /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업
    ******************************************************************************/
    fn_BottomAlert (pContent) {
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },

    /*********************************************************
    * Function명: fn_replacePhoneBackNumWithHyphen
    * 설명: 임의의 (임시 또는 더미) 전화번호(뒷자리) 형식 문자열(매스킹 포함 최대 9자리)에 하이픈을 추가한다.
    * Params: _str: 치환할 문자열 소스
    *
    * Return: 하이픈이 추가된 전화번호(뒷자리) 형식의 문자열
    *********************************************************/
    fn_replacePhoneBackNumWithHyphen (_str) {
      let str = ''
      if (_str !== undefined && _str !== null) {
        if (_str.indexOf('-') >= 0) {
          str = _str.replace(/(^\d{3}.{0}|^\d{4})-(\d{4}|[\d,*][\d,*][\d,*][\d,*])$/, '$1-$2')
        } else {
          str = _str.replace(/(^\d{3}.{0}|^\d{4})-?(\d{4}|[\d,*][\d,*][\d,*][\d,*])$/, '$1-$2')
        }
      }
      // 하이픈이 추가된 전화번호(뒷자리) 형식(매스킹문자 포함)의 문자열
      return str
    },

    /******************************************************************************
    * Function명  : fn_SetBnfrInfo
    * 설명        : 수익자 화면노출정보 설정
    ******************************************************************************/
    fn_SetBnfrInfo() {
      // 수익자
      let bnfrInfoList = this.userData3
      let bnfrInfo1_custNm = new Set()
      let bnfrInfo2_custNm = new Set()

      this.bnfrInfo31 = {} //만기수익자
      this.bnfrInfo32 = {} //분할수익자
      this.bnfrInfo33 = {} //연금수익자
      this.bnfrInfo34 = {} //상해수익자
      this.bnfrInfo35 = [] //사망수익자 (최대4명)
      this.bnfrInfo39 = {} //연금후수익자
      this.isBnfrInfo31 = false
      this.isBnfrInfo32 = false
      this.isBnfrInfo33 = false
      this.isBnfrInfo34 = false
      this.isBnfrInfo35 = false
      this.isBnfrInfo39 = false


      
      if (bnfrInfoList) {
        for (let i = 0; i < bnfrInfoList.length; i++ ) {
          let rrn = { 
            knbEncr1 : bnfrInfoList[i].custRrnId.substring(0, 6), // 주민번호 앞자리
            knbEncr2 : bnfrInfoList[i].custRrnId.substring(6) // 계약관계자 정보확인 화면은 마스킹 처리 X
          }
          switch (bnfrInfoList[i].bnfrRolCd) {
             case '31': // 만기수익자
               this.bnfrInfo31 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo31 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
             case '32': // 분할수익자
               this.bnfrInfo32 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo32 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
             case '33': // 연금수익자
               this.bnfrInfo33 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo33 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
             case '34': // 상해수익자
               this.bnfrInfo34 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo34 = true
               bnfrInfo2_custNm.add(bnfrInfoList[i].custNm)
               break
             case '35': // 사망수익자
               this.bnfrInfo35.push(Object.assign(bnfrInfoList[i], rrn))
               this.isBnfrInfo35 = true
               bnfrInfo2_custNm.add(bnfrInfoList[i].custNm)
               break
             case '39': // 연금후수익자
               this.bnfrInfo39 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo39 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
          }
        }
        //고객명은 전부노출
        this.bnfrInfo1.custNm = Array.from(bnfrInfo1_custNm).toString()
        this.bnfrInfo2.custNm = Array.from(bnfrInfo2_custNm).toString()
        
        //상해수익자 사망수익자 화면노출정보
        //고객명은 전부 나이 및 생년월일은 1순위 : 만기, 2순위 : 분할, 3순위 : 연금, 4순위 : 연금후
        if (this.isBnfrInfo31) {
          this.bnfrInfo1.age  = this.bnfrInfo31.age
          this.bnfrInfo1.knbEncr1 = this.bnfrInfo31.knbEncr1
          this.bnfrInfo1.knbEncr2 = this.bnfrInfo31.knbEncr2
        }
        else if (this.isBnfrInfo32) {
          this.bnfrInfo1.age  = this.bnfrInfo32.age
          this.bnfrInfo1.knbEncr1 = this.bnfrInfo32.knbEncr1
          this.bnfrInfo1.knbEncr2 = this.bnfrInfo32.knbEncr2
        }
        else if (this.isBnfrInfo33) {
          this.bnfrInfo1.age  = this.bnfrInfo33.age
          this.bnfrInfo1.knbEncr1 = this.bnfrInfo33.knbEncr1
          this.bnfrInfo1.knbEncr2 = this.bnfrInfo33.knbEncr2
        }
        else if (this.isBnfrInfo39) {
          this.bnfrInfo1.age  = this.bnfrInfo39.age
          this.bnfrInfo1.knbEncr1 = this.bnfrInfo39.knbEncr1
          this.bnfrInfo1.knbEncr2 = this.bnfrInfo39.knbEncr2
        }
        else {
          this.bnfrInfo1.age  = ''
          this.bnfrInfo1.knbEncr1 = ''
          this.bnfrInfo1.knbEncr2 = ''
        }
        //상해수익자 사망수익자 화면노출정보
        //고객명은 전부 나이 및 생년월일은 1순위 : 상해, 2순위 : 사망
         if (this.isBnfrInfo34) {
          this.bnfrInfo2.age = this.bnfrInfo34.age
          this.bnfrInfo2.knbEncr1 = this.bnfrInfo34.knbEncr1
          this.bnfrInfo2.knbEncr2 = this.bnfrInfo34.knbEncr2
        }
        else if (this.isBnfrInfo35) {
          this.bnfrInfo2.age = this.bnfrInfo35[0].age
          this.bnfrInfo2.knbEncr1 = this.bnfrInfo35[0].knbEncr1
          this.bnfrInfo2.knbEncr2 = this.bnfrInfo35[0].knbEncr2
        }
        else {
          this.bnfrInfo2.age  = ''
          this.bnfrInfo2.knbEncr1 = ''
          this.bnfrInfo2.knbEncr2 = ''
        }
      }
    },

    /******************************************************************************
    * Function명  : fn_FoldingExpand
    * 설명        : msp-expand 닫기
    ******************************************************************************/
    fn_FoldingExpand(control, event) {
      this.$refs[control].isExpand = false
    },

    

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>