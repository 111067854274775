/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS401D
 * 화면 설명: 전자서명 인증 - 휴대폰
 */
<template> 
  <!-- <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="con-area mt30 pb30"> -->
    <mo-validate-watcher ref="vWatcher">
      <ur-box-container direction="column" alignV="start" componentid="" class="ns-verify pal0">
        <ur-box-container alignV="start" componentid="" direction="column">
          <div class="ns-check arrow fexTy5">
            <mo-checkbox class="crTy-bk7" v-model="agreeCheckbox" @input="fn_AgreeCnfrmPop2('Open')">본인확인 서비스 이용 동의</mo-checkbox>
          </div>
          <div class="ns-check arrow fexTy5">
            <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" v-if="authPass" class="ns-btn-round white" @click="fn_AuthPassBtnClick">인증패스</mo-button>
          </div>
          <div class="verify-call mt20">
            <msp-bottom-select underline class="ns-dropdown-sheet call1" placeholder="선택" bottom-title="통신사를 선택해 주세요" closeBtn :itemValue="'key'" :itemText="'label'" v-model="telecomType" :disabled="disabled" :items="telecomTypes" @selectedItem="fn_TelecomTypeClick"/>
            <mo-text-field v-model="mobileNumber" ref="mobileNumber" :disabled="disabled" :readonly="celReadonly" @blur="fn_CelFocusBlur" @input="fn_CelFocusBlur" :maxlength="13"/>
          </div>
          <mo-button class="ns-btn-round h40 blue-line" :disabled="disabled" v-if="isBeforeSend" @click="fn_AuthConfirmBtnClick">인증번호 전송</mo-button>
          <mo-button class="ns-btn-round h40 blue-line" :disabled="disabled" v-else @click="fn_AuthConfirmBtnClick">인증번호 재전송</mo-button>
        </ur-box-container>

        <div class="ns-certify-sed pal0 mt50 success">
          <div class="ns-certify-sed-code mb2sam0">
            <div class="left">
              <mo-text-field  mask="######" class="form-input-name" v-model="authNumber" :disabled="disabledAuthNum" :maxlength="6" @input="fn_AuthNumKeyUp" ref="cNumber" underline placeholder="인증번호를 입력해주세요."/>
              <span class="time crTy-blue2" v-if="!isBeforeSend">{{confirmTime}}</span>
            </div>
          </div>
          <!-- <div class="ns-certify-sed-txt">
            <div class="txt-success" v-if="false"><mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon>인증 완료되었습니다.</div>
            <div class="txt-error" v-if="false">인증번호 오류입니다.</div>
          </div>
          <mo-button class="ns-btn-round h40 blue-line mt20" >인증확인</mo-button>-->
        </div>

        <div class="fs14rem crTy-bk7 mt30">인증번호 미도착시 아래와 같이 조치 부탁드립니다.</div>
        <div class="text-gray-box mt10">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>
              휴대폰 스팸번호 분류 확인<br>
              NICE인증 고객센터문의<span class="dsInline ml40">1600 - 1522</span>
            </li>
          </ul>
        </div>

        <!-- 이용동의 안내 bottomSheet -->
        <!-- <div class="ns-bottom-sheet"> -->
          <mo-bottom-sheet ref="agreeCnfrmPop2" :close-btn="false" class="ns-bottom-sheet closebtn">
            <template v-slot:title>
              본인확인서비스 이용동의
              <div class="ns-btn-close" @click="fn_AgreeCnfrmPop2('CloseBtn')" name="닫기"></div>
            </template>
            <div class="content-area">
              <ul class="terms-list-area pb36">
                <li>개인정보 수집/이용 동의</li>
                <li>고유식별정보 처리 동의</li>
                <li>통신사 이용약관 동의</li>
                <li>서비스 이용약관 동의</li>
                <li>개인정보 제 3자 제공 동의</li>
              </ul>
            </div>

            <template v-slot:action>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
                <div class="relative-div">
                  <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrmPop2('Confirm')">약관 확인</mo-button>
                  <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrmPop2('AllConfirm')">전체 약관 동의</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
        <!-- </div> -->

      </ur-box-container>
    </mo-validate-watcher>
  <!-- </ur-box-container> -->
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/

import Msg from '@/systems/webkit/msg/msg'

import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS401D', 
  screenId: 'MSPPS401D',

  components: {
    MSPCM010P
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    disabled: {type: Boolean, default: false},
    userData1: {type: Object}, // 계약자정보
    userData2: {type: Object}, // 피보험자정보
    userData6: {type: Object}, // 예금주정보
    bnfrInfoData: {type: Object}, // 수익자정보
    //parentalAuthorityInfo: {type: Boolean, default: false} // 수익자일때 수익자가 미성년자일때 친권자 정보(성명, 생년월일) 입력여부(true:입력, false:미입력)
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {   
      confirmTime: '7:00',
      limitTime: 420,
      timer: {},
      viewData: {},
      personType: 0, // 계약자 or 피보험자
      disabledAuthNum: true, // 인증번호 TextField
      isBeforeSend: true,
      agreeCheckbox: false,
      mobileNumber: '',
      authNumber: '', // 인증번호 model
      authResult: {}, // 인증요청 결과
      celReadonly: true, // 휴대전화번호 readonly 여부
      telecomTypes: [
        {key: 'SKT', label: 'SKT'},
        {key: 'KT', label: 'KT'},
        {key: 'LGT', label: 'LGU+'},
        {key: 'SKTM', label: 'SKT(알뜰폰)'},
        {key: 'KTM', label: 'KT(알뜰폰)'},
        {key: 'LGTM', label: 'LGU+(알뜰폰)'},
        {key: 'ETC', label: '알뜰폰 확인하기'}
      ],
      telecomType: 'SKT',
      prevTelecomType: '',
      popup010: null,
      //celNoHideError: false,
      //validateCheck: false,
      onSendFlag: false // 휴대폰 인증번호 요청이 두번씩 call 되는 현상 방지 플래그
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    this.authPass = (process.env.NODE_ENV !== 'production') // 검증계면 인증 패스 로직 유효 local/development/stage/production

    // 사용자 정보 설정
    if (this.userData1) {
      // 계약자
      this.viewData = this.userData1
      this.personType = 1
    } else if (this.userData2) {
      // 피보험자
      this.viewData = this.userData2
      this.personType = 2
    } else if (this.userData6) {
      // 예금주
      this.viewData = this.userData6
      this.personType = 6
      this.viewData.insrdNm = this.userData6.detail.custNm
      this.viewData.custRrnId = this.userData6.detail.knb
      this.viewData.knb = 'SE'
      this.viewData.vuchId = this.userData6.vuchId
    } else if (this.bnfrInfoData) {
      // 수익자
      this.viewData = this.bnfrInfoData
      this.personType = 3
      //let cmucoScCd = this.getStore('code').getters.getState.get('ZA_CMUCO_SC_CD')
      //if (cmucoScCd && cmucoScCd.length > 0) this.viewData.zaCmucoScCd = cmucoScCd
    }
    // 통신사 공통코드 셋팅
    //if (this.viewData && this.viewData.zaCmucoScCd) {
      //this.fn_CustomTelTypes(this.viewData.zaCmucoScCd)
    //}
    if (this.viewData && this.viewData.detail && this.viewData.detail.celno && this.viewData.detail.celno !== '') {
      if (this.personType !== 3) this.celReadonly = true
      this.mobileNumber = this.$bizUtil.telNoWithHyphen(this.viewData.detail.celno)
    } else {
      this.celReadonly = false
    }
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    disabled (newValue) {
      if (newValue) {
        this.agreeCheckbox = false
      } else {
        // if (this.personType === 3) {
        //   this.celNoHideError = false
        // }
      }
      //this.fn_clearValidator('tssps122d-validator-1') // 휴대폰번호 유효성 초기화
    },
    // bnfrInfoData (newValue) {
    //   // if (!this.parentalAuthorityInfo) {
    //   //   this.fn_ResetPage()
    //   // }
    //   this.viewData = newValue
    // },
    // validateCheck (newValue) {
    //   if (newValue && this.parentalAuthorityInfo) {
    //     if (this.isBeforeSend) this.fn_OnSend() // 인증번호 전송
    //     else this.fn_OnReSend() // 인증번호 재전송
    //   }
    // },
    // parentalAuthorityInfo (newValue) {
    //   if (newValue && this.validateCheck) {
    //     if (this.isBeforeSend) this.fn_OnSend() // 인증번호 전송
    //     else this.fn_OnReSend() // 인증번호 재전송
    //   }
    // }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_AuthPassBtnClick
    * 설명       : 검증계일때 카드인증을 바로 패스 해주는 함수
    ******************************************************************************/
    fn_AuthPassBtnClick () {
      this.$emit('authFinish', this.personType + 10) // 인증성공 11,12,13 -> 인증 패스는 11,12,13 으로 셋팅
    },
    
    /******************************************************************************
    * Function명 : fn_AuthConfirmBtnClick
    * 설명       : 수익자일때 상위로 친권자 입력정보 확인할 수 있는 이벤트를 넘겨주는 함수
    ******************************************************************************/
    fn_AuthConfirmBtnClick () {
      if (this.personType === 3) {
        this.$emit('authConfirmBtnClick')
      }
      else {
        this.fn_BeforeSend()
      }
    },
    /******************************************************************************
    * Function명 : fn_BeforeSend
    * 설명       : 인증번호 전송 / 재전송 분기
    ******************************************************************************/
    fn_BeforeSend () {
      if (this.isBeforeSend) {
        this.fn_OnSend() // 인증번호 전송
      } else {
        this.fn_OnReSend() // 인증번호 재전송
      }
    },
    /******************************************************************************
    * Function명 : fn_ResetPage
    * 설명       : 수익자일때 휴대폰 인증 데이터 초기화 함수
    ******************************************************************************/
    fn_ResetPage () {
      clearInterval(this.timer)
      //this.celNoHideError = true
      this.confirmTime = '7:00'
      this.limitTime = 420
      this.timer = {}
      this.viewData = {}
      this.disabledAuthNum = true // 인증번호 TextField
      this.isBeforeSend = true // 인증번호 만료시간 안보이게
      this.agreeCheckbox = false
      this.mobileNumber = ''
      this.authNumber = '' // 인증번호 model
      this.authResult = {}
      this.telecomType = {key: '1', label: 'SKT'}
      //this.validateCheck = false
    },
    
    /******************************************************************************
    * Function명 : fn_CelFocusBlur
    * 설명       : 휴대번호 blur 이벤트 컨트롤 함수
    ******************************************************************************/
    fn_CelFocusBlur (event) {
      if (this.mobileNumber && this.mobileNumber.trim() !== '') {
        this.mobileNumber = this.$bizUtil.telNoWithHyphen(this.mobileNumber)
      }
    },
    /******************************************************************************
    * Function명 : fn_OnSend
    * 설명       : 휴대폰 인증번호 전송 함수
    ******************************************************************************/
    fn_OnSend () {
      if (!this.agreeCheckbox) {
        this.getStore('confirm').dispatch('ADD', '본인확인 서비스 이용 동의에 체크하시면 인증번호가 발송됩니다.')
        //this.validateCheck = false
      } else if (this.mobileNumber.trim() === '' || this.mobileNumber.length < 7) {
        this.getStore('confirm').dispatch('ADD', '휴대폰번호는 필수항목입니다.')
        //this.validateCheck = false
      } else {
        if (this.onSendFlag) return
        this.onSendFlag = true
        let pParams = {}
        let telType = ''
        // 통신사 코드 맵핑 1->SKT
        if (this.telecomType === '1') telType = 'SKT'
        else if (this.telecomType === '2') telType = 'KT'
        else if (this.telecomType === '3') telType = 'LGT'
        else if (this.telecomType === '4') telType = 'SKTM'
        else if (this.telecomType === '5') telType = 'KTM'
        else if (this.telecomType === '6') telType = 'LGTM'
        else telType = this.telecomType

        if (this.personType === 3) {
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11M2')) // 수익자일때
        } else {
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11M1')) // 계약자/피보험자일때
        }
        pParams.data = {}
        pParams.data.srnId = this.$options.screenId // 대표 화면 명
        pParams.data.custNm = this.viewData.insrdNm // 계약자, 피보험자명
        pParams.data.rrn = this.viewData.custRrnId.replace(/-/g, '').substr(0, 7) + '000000' // TODO 4/30일 반영건
        pParams.data.cmmuScCd = telType // 휴대폰 통신사 코드 SKT, KT ...
        pParams.data.celno = this.mobileNumber // 휴대폰 번호
        pParams.data.knb = this.viewData.knb // PI(가입설계), SE(청약), RP(법인)
        pParams.data.vuchId = this.viewData.vuchId // 영수증ID - TODO 4/30일 반영건
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S', this.fn_ServiceFaultHandler)
      }
    },
    /******************************************************************************
    * Function명 : fn_ServiceResultHandler
    * 설명       : 서비스 호출 결과 함수 정의
    ******************************************************************************/
    fn_ServiceResultHandler (event, serviceName) {
      let e = event
      let d = e.data
      switch (serviceName) {
        case 'S':
          if (d.rsltCd) {
            if (d.rsltCd === '0000') {
              this.isBeforeSend = false
              this.fn_StartTimer() // 7분 카운트
              this.disabledAuthNum = false // 인증번호 TextField
              this.authResult = d
              setTimeout(()=> {
                this.$refs.cNumber.focus()
              }, 500)
            } else {
              this.getStore('confirm').dispatch('ADD', d.rsltCdVal)
              this.isBeforeSend = !!this.isBeforeSend
              this.disabledAuthNum = true // 인증번호 TextField
              //this.validateCheck = false
            }
          } else {
            if (event.msgComm) {
              this.getStore('confirm').dispatch('ADD', event.msgComm.msgCd + ' ' + event.msgComm.msgDesc)
            }
          }
          this.onSendFlag = false
          break
        case 'S1':
          if (e && d && d.rsltCd) {
            if (d.rsltCd === '0000') {
              this.$emit('authFinish', this.personType) // 인증성공
            } else {
              this.getStore('confirm').dispatch('ADD', d.rsltCdVal)
              this.isBeforeSend = !!this.isBeforeSend
            }
          }
          break
        default:
          break
      }
    },
    /******************************************************************************
    * Function명 : fn_ServiceFaultHandler
    * 설명       : 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler (event, serviceName) {
      switch (serviceName) {
        case 'S':
          //this.validateCheck = false
          this.onSendFlag = false
          break
        case 'S1':
          break
        default:
          break
      }
    },
    /******************************************************************************
    * Function명 : fn_OnReSend
    * 설명       : 휴대폰 인증번호 재전송 함수
    ******************************************************************************/
    fn_OnReSend () {
      this.fn_endTimer()
      this.fn_OnSend()
    },
    /******************************************************************************
    * Function명 : fn_AgreePopContent
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출 함수
    ******************************************************************************/
    fn_AgreePopContent () {
      if (!this.disabled) {
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: 'https://cert.vno.co.kr/app/agree/agree_main.jsp'}, () => {}, () => {})
        } else { // PC에서 작동
          window.open('https://cert.vno.co.kr/app/agree/agree_main.jsp', '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_TelecomTypeClick
    * 설명       : 알뜰폰 확인하기 선택 함수
    ******************************************************************************/
    fn_TelecomTypeClick (target) {
      if (target.key === 'ETC') {
        this.popup010 = this.$bottomModal.open(MSPCM010P, {
          properties: {          },
          listeners: {
            confirmPopup: (pData) => {
              this.$bottomModal.close(this.popup010)
              
              if (pData !== null && pData !== '') {
                this.$refs.mobileNumber.focus()
                this.telecomType = pData.val
              } else { // 취소버튼 클릭 시
                this.telecomType = this.prevTelecomType
              }
            }
          }
        })
      }
      else {
        this.$refs.mobileNumber.focus()
        this.prevTelecomType = target.key
        this.telecomType = target.key
      }
    },
    /******************************************************************************
    * Function명 : fn_AuthNumKeyUp
    * 설명       : 인증번호 입력박스 input 이벤트 핸들러
    ******************************************************************************/
    fn_AuthNumKeyUp () {
      if (this.authNumber.length === 6) {
        // 인증번호 6자리 입력했으니 서비스 태움
        let pParams = {}
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11K1'))
        pParams.data = {}
        pParams.data.srnId = this.$options.screenId // 대표 화면 명
        pParams.data.moblAthntCd = this.authResult.moblAthntCd // 사이트코드
        pParams.data.moblAthntPswd = this.authResult.moblAthntPswd // 사이트패스워드
        pParams.data.respUnqNo = this.authResult.respUnqNo // 응답고유번호
        pParams.data.reqUnqNo = this.authResult.reqUnqNo // 요청고유번호
        pParams.data.athntNo = this.authNumber // 인증번호
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S1', this.fn_ServiceFaultHandler)
        //this.$refs.cNumber.closeKeypad() // 키패드 닫기
      }
    },
    /******************************************************************************
    * Function명 : fn_StartTimer
    * 설명       : 7분 타이머 Start
    ******************************************************************************/
    fn_StartTimer () {
      let tmpNum = this.limitTime
      this.timer = setInterval(() => {
        if (tmpNum === 0) {
          this.fn_endTimer()
          return
        }
        tmpNum = --tmpNum
        let n = (tmpNum % 60)
        if (n < 10) {
          this.confirmTime = Math.floor(tmpNum / 60) + ':0' + n
        } else {
          this.confirmTime = Math.floor(tmpNum / 60) + ':' + n
        }
      }, 1000)
    },
    /******************************************************************************
    * Function명 : fn_endTimer
    * 설명       : 7분 타이머 end
    ******************************************************************************/
    fn_endTimer () {
      clearInterval(this.timer)
      this.disabledAuthNum = true // 인증번호 TextField
      this.authNumber = '' // 인증번호 model
      this.isBeforeSend = true // 인증번호 만료시간 안보이게
      this.confirmTime = '7:00'
      this.limitTime = 420
    },

    /******************************************************************************
    * Function명 : fn_AgreeCnfrmPop2
    * 설명       : 본인확인서비스 이용동의 안내 
    *           - Open(BottomSheet열기), CloseBtn(X버튼), Confirm(약관 확인), AllConfirm(전체 약관 확인)  선택에 대한 화면 이동
    ******************************************************************************/
    fn_AgreeCnfrmPop2(param) {
      switch (param) {
        case 'Open':
          if(this.agreeCheckbox){
            this.agreeCheckbox = false
            this.$refs.agreeCnfrmPop2.open()
          }
          break
        case 'CloseBtn':
          this.agreeCheckbox = false
          this.$refs.agreeCnfrmPop2.close()
          break
        case 'Confirm': // 약관 확인
          this.$refs.agreeCnfrmPop2.close()
          this.agreeCheckbox = true
          this.fn_AgreePopContent()
          break
        case 'AllConfirm': // 전체 약관 확인
          this.agreeCheckbox = true
          this.$refs.agreeCnfrmPop2.close()
          break
      }
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>