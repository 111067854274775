/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS402D
 * 화면 설명: 전자서명 인증 - 신용카드
 */
<template>
  <ur-box-container direction="column" alignV="start" componentid="" class="ns-verify pal0">
    <ur-box-container alignV="start" componentid="" direction="column">
      <div class="ns-check arrow fexTy5">
        <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" v-if="authPass" class="ns-btn-round white" @click="fn_AuthPassBtnClick">인증패스</mo-button>
      </div>
      <div class="verify-call mt20">
        <!-- 전화번호 앞자리 -->
        <msp-bottom-select :disabled="disabled" :readonly="celReadonly" :items="celphTlnumCdList" v-model="celphTlnum"  underline class="ns-dropdown-sheet call1" placeholder="선택" bottom-title="국번을 선택해 주세요" @selectedItem="fn_TelecomTypeClick" closeBtn/>
        <!-- 전화번호 뒷자리 -->
        <mo-text-field :disabled="disabled" :readonly="celReadonly" type="number" mask="########" ref="celnoRef" v-model="custTelnum" :rules="validateRuleCelno" underline placeholder="00000000" class="mo-readonly" />
      </div>
      <mo-button class="ns-btn-round h40 blue-line" @click="fn_AuthConfirmBtnClick">인증하기</mo-button>
    </ur-box-container>

    <div class="text-gray-box mt30">
      <span class="crTy-bk7 fs14rem">주의사항</span>
      <ul class="terms-list-area crTy-bk7 fs14rem mt4">
        <li>법인카드, 가족카드, 선불카드는 본인확인이 불가합니다.</li>
        <li>휴대폰에 착신전화, 수신거부, 로밍  등이 설정이 되어 있는 경우 서비스 이용이 불가능할 수 있습니다.</li>
        <li>카드사에 등록된 고객님의 휴대폰번호가 변경되었거나 잘못 등록된 경우 변경 후 이용하시기 바랍니다.</li>
      </ul>
    </div>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS402D', 
  screenId: 'MSPPS402D', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    disabled: {type: Boolean, default: false},
    userData1: {type: Object}, // 계약자정보
    userData2: {type: Object}, // 피보험자정보
    userData6: {type: Object}, // 예금주정보
    bnfrInfoData: {type: Object}, // 수익자정보
    //parentalAuthorityInfo: {type: Boolean, default: false} // 수익자일때 수익자가 미성년자일때 친권자 정보(성명, 생년월일) 입력여부(true:입력, false:미입력)
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      viewData: {},
      personType: 0, // 계약자(1) / 피보험자(2) / 예금주(6) / 수익자(3) or 지정대리인(3)
      celReadonly: true, // 휴대전화번호 readonly 여부
      
      celphTlnumCdList : [], // 전화번호 국번코드
      celphTlnum : '', //선택한 국번
      custTelnum : '' , //인증받을 고객 핸드폰 번호
      isAuthConfirm : '', //인증여부 Flag
      validateRuleCelno : [
          v => !!v || '필수입력항목입니다'
         ,v => v.length > 7 || '휴대폰번호를 입력하세요.'
         //,v => this.chkCelnoResult || this.celnoErrorMsg
        ],
       // 주고객 정보 VO
      custCardVO: {
        isExistCustYN:'', //신규 여부
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        chnlCstgrId: '', // 채널그룹ID
        knb: '', // 전체주민번호
        knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '', // 주민번호 앞자리
        knbBkno: '', // 주민번호 뒷자리
        knbBkFstNo: '', // 주민번호 뒷자리 첫자리 (성별구분코드)
        knbBknoCipher: '', // 암호화된 주민번호
        knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
        knbScCd: 'ZPER01', // 식별번호구분코드
        celno: '', // 휴대전화 전체
        celnoFrno: {}, // 휴대전화 앞자리
        celnoBkno: '', // 휴대전화 뒷자리
      },
      isLoading : false ,
      chkCelnoResult : false,        
      //-------------------------------------------------
      __end : null  
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    this.authPass = (process.env.NODE_ENV !== 'production') // 검증계면 인증 패스 로직 유효 local/development/stage/production

    // 사용자 정보 설정
    if (this.userData1) {
      // 계약자
      this.viewData = this.userData1
      this.personType = 1
    } else if (this.userData2) {
      // 피보험자
      this.viewData = this.userData2
      this.personType = 2
    } else if (this.userData6) {
      // 예금주
      this.viewData = this.userData6
      this.personType = 6
    } else if (this.bnfrInfoData) {
      // 수익자 or 지정대리인
      this.viewData = this.bnfrInfoData
      this.personType = 3
    }

    if (this.viewData && this.viewData.detail && this.viewData.detail.celno && this.viewData.detail.celno !== '') {
      if (this.personType !== 3) this.celReadonly = true
      let mobileNumber = this.$bizUtil.replaceAll(this.viewData.detail.celno, '-', '')
      if (mobileNumber.length >= 3) {
        this.celphTlnum = mobileNumber.substring(0,3)
        this.custTelnum = mobileNumber.substring(3)
      }
    } else {
      this.celReadonly = false
    }
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    this.fn_Init()
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    bnfrInfoData (newValue) {
      // if (!this.parentalAuthorityInfo) {
      //   this.fn_ResetPage()
      // }
      this.viewData = newValue
    },
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  fn_Init () {
       let lv_Vm = this
       let lv_CdList = []
      // ---------------------------------------------------------------------------------
      // 공통업무코드 조회 및 설정 처리
      // ---------------------------------------------------------------------------------
      let param = ['ZA_ELTRN_MAL_DM_CD','ZA_CELPH_KNB_CD'] // 휴대전화국번 코드정보

      window.vue.getInstance('vue').$commonUtil.selListMassEACommCd(param)
      .then(function () {
        // 휴대전화 앞자리 정보 세팅
        let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
        lv_CdList.push({value: '0', text: '선택'})
        if (lv_CelphCD && lv_CelphCD.length > 0) {
          lv_CelphCD.forEach((item) => {
              let lv_Item = {}
              lv_Item.value = item.key
              lv_Item.text = item.key
              lv_CdList.push(lv_Item)
            }
          )
        }
       lv_Vm.celphTlnumCdList = lv_CdList
      })

    },

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명 : fn_AuthPassBtnClick
    * 설명       : 검증계일때 카드인증을 바로 패스 해주는 함수
    ******************************************************************************/
    fn_AuthPassBtnClick () {
      this.$emit('authFinish', this.personType + 10) // 인증성공 11,12,13 -> 인증 패스는 11,12,13 으로 셋팅
    },

    /******************************************************************************
    * Function명 : fn_PopCertVnoClick
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출 함수
    ******************************************************************************/
    fn_PopCertVnoClick () {
      if (!this.disabled) {
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: 'https://cert.vno.co.kr/app/agree/agree_main.jsp'}, () => {}, () => {})
        } else { // PC에서 작동
          window.open('https://cert.vno.co.kr/app/agree/agree_main.jsp', '_blank')
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_AuthConfirmBtnClick
    * 설명       : 인증하기 버튼클릭시 수익자/지정대리인일때 상위로 친권자 입력정보 확인할 수 있는 이벤트를 넘겨주는 함수
    ******************************************************************************/
    fn_AuthConfirmBtnClick () {
      if (this.personType === 3) {  //수익자, 지정대리인일 경우 미성년자 처리를 위해 상위 함수 호출
        this.$emit('authConfirmBtnClick')
      }
      else {
        this.fn_BtnAuthClick()
      }
    },
    /******************************************************************************
    * Function명  : fn_BtnAuthClick
    * 설명        : 인증하기 버튼 클릭 핸들러
    ******************************************************************************/
    fn_BtnAuthClick () {
      let isValid = true
      let mesg = ''

      this.celphTlnum = String(this.celphTlnum).trim()
      this.custTelnum = String(this.custTelnum).trim().replace(/-/g,'')

      if(this.celphTlnum === '') {
        isValid = false 
        mesg = '국번을선택하세요'
      }

      if(this.custTelnum.length < 7 ) {
        isValid = false 
        mesg = 'ARS 인증받을 핸드폰 번호를 확인하세요'
      }

      if(isValid){
        //수익자는 chnlCustId가 없어서 핸드폰번호 유효성 체크 불가
        if (this.personType === 3) {
          if (this.viewData.hasOwnProperty('detail') && this.viewData.detail.chnlCustId !== '') {
            this.fn_ChkFcTelnum()
          }
          else {
            this.chkCelnoResult = true
            this.fn_CardCert()
          }
        }
        else {
          this.fn_ChkFcTelnum()
        }
      } else {
        this.getStore('confirm').dispatch('ADD',mesg)
      }

    },

   /******************************************************************************
    * Function명  : fn_CardCert
    * 설명        : 카드인증 실행
    ******************************************************************************/
    fn_CardCert () {
      let lv_Vm = this

      let phoneNum = this.celphTlnum + this.custTelnum
      let param = {
        custNm : this.viewData.insrdNm,
        birth : this.$bizUtil.getBirthDateFromKnb(this.viewData.custRrnId),
        sexCd : this.$bizUtil.getGenderFromKnb(this.viewData.custRrnId).substring(0,1).toUpperCase(),    //M:남자, F:여자
        phone : phoneNum, 
        cpCode: 'RO9900000179'
      }

      window.fdpbridge.exec('CardCertPlugin', param
      ,(result)=>{
        console.log('CardCertPlugin result !')
        console.log(result)
        
        //인증결과 처리
        if (result.data === 'Y') {
          lv_Vm.fn_ServiceData(result)
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', "신용카드인증에 실패하였습니다.")  
        }
      }
      ,(failed)=>{
        console.log('CardCertPlugin failed !')
        console.log(failed)
        lv_Vm.getStore('confirm').dispatch('ADD', "신용카드인증에 실패하였습니다.")
      })
    },
  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData(result) {
      const trnstId = 'txTSSPS11K3'
      let pParams = Object.assign({}, PSServiceManager.getParameter(trnstId))
      pParams.data.srnId = this.$options.screenId // 대표 화면 명
      pParams.data.cnsltNo = PSServiceManager.getUserInfo('userId') // FC사번
      pParams.data.rsltCd = result.data
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S')
    },
    /******************************************************************************
    * Function명 : fn_ServiceResultHandler
    * 설명       : 서비스 호출 결과 함수 정의
    ******************************************************************************/
    fn_ServiceResultHandler (event, serviceName) {
      let e = event
      let d = e.data
      switch (serviceName) {
        case 'S':
          console.log('rsltCdVal:' + d.rsltCdVal)
          if (d.rsltCdVal === 'Y') {
            this.$emit('authFinish', this.personType) // 인증성공
          }
          break
        default:
          break
      }
    },
    
    /******************************************************************************
    * Function명  : fn_ChkFcTelnum
    * 설명        : 핸드폰번호 유효성 체크(FC와 동일번호 체크)
    ******************************************************************************/
    fn_ChkFcTelnum () {
      //고객 채널 ID만 넘기면 처리계에서 재조회후 판단함..
      //CMNewCustRegBIZ.selCelnoCheck()

      this.custCardVO = {}      
      this.custCardVO.chnlCustId = this.viewData.detail.chnlCustId
      this.custCardVO.celnoFrno = this.celphTlnum
      this.custCardVO.celnoBkno = this.custTelnum
      this.custCardVO.celno = this.celphTlnum + '' + this.custTelnum
      
      let pParams = this.custCardVO

      let lv_Vm = this
      let trnstId = 'txTSSCM10S3'

      this.chkCelnoResult = false
      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, 'S')
      .then(function (response) {
        let celnoErrorMsg = ""
        
        lv_Vm.isLoading = false

        if (!response.body) {
          celnoErrorMsg = response.msgComm.msgDesc
          lv_Vm.getStore('confirm').dispatch('ADD', celnoErrorMsg)
          return
        }  
        
        if (response.body.celnoStatCo === '00') { // 정상일 경우
          lv_Vm.chkCelnoResult = true
          lv_Vm.fn_CardCert ()
        } else {

          if (response.body.celnoStatCo === '01') { // 01: 임시등록 번호
            celnoErrorMsg = lv_Vm.$t('ps')['EPSC065'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
          } else { // 02: FC휴대번호와 동일한 경우
            celnoErrorMsg = lv_Vm.$t('ps')['EPSC066'] // '컨설턴트 핸드폰번호로 등록된 번호입니다.'            
          }
          lv_Vm.getStore('confirm').dispatch('ADD', celnoErrorMsg)
        }
        
      })
      .catch(function (error) {
        window.vue.error(error)
      })
       
    },
   /******************************************************************************
    * Function명 : fn_TelecomTypeClick
    * 설명       : 알뜰폰 확인하기 선택 함수
    ******************************************************************************/
    fn_TelecomTypeClick (target) {
      if (this.celphTlnum !== '0') {
        this.$refs.celnoRef.focus()
      }      
    },
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/




  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>