export default Object.freeze({
  PVM_NOTICE: [
    // 일반 (pvm_notice) START
    {
      'ID': 'pvm_notice',
      'VUCH_NO': '',
      'PRN': '',
      'MNISD': {
        'name': ''
      },
      'AIDS': {
        'name': ''
      },
      'CHILDREN': {
        'CHLD_ONE_N': '',
        'CHLD_TWO_N': '',
        'CHLD_THREE_N': ''
      },
      'PAGE_CNT': 3,
      // 계약자:1, 피보험자:2, 종피보험자:3, 친권자1:4, 친권자2:5, 예금주:6, FC:9
      'TOTCNT': {
        'ALL': 13,
        '1': 0,
        '2': 13,
        '3': 13,
        '4': 0
      },
      'QUESTIONLIST': {
        'ALL': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13'],
        '1': [],
        '2': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13'],
        '3': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13'],
        '4': []
      },
      QUESTIONLIST_CNT: [
        {
          'INDEX': 1,
          'ID': 'G01',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 의사로부터 진찰 또는 검사(건강검진 포함)를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '질병확정진단, 질병의심소견, 치료, 입원, 수술(제왕절개 포함), 투약',
          'DEFINITION': [
            {'title': '질병의심소견', 'content': '이란 의사가 진단서나 소견서 또는 진료의뢰서 등을 포함하여 서면(전자문서 포함)으로 교부한 경우를 말합니다.'},
            {'title': '투약', 'content': '이란 의사가 환자에게 약을 처방하는 행위를 말하는 것으로 실제로 약을 구입하지 않았어도 작성해야 합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '질병확정진단'},
            {'key': '02', 'label': '질병의심소견'},
            {'key': '03', 'label': '치료'},
            {'key': '04', 'label': '입원'},
            {'key': '05', 'label': '수술(제왕절개 포함)'},
            {'key': '06', 'label': '투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 2,
          'ID': 'G02',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 마약을 사용하거나 혈압강하제, 신경안정제, 수면제, 각성제(흥분제), 진통제 등의 약물을 상시 복용한 적이 있으십니까?',
          'DEFINITION': [
            {'title': '혈압강하제', 'content': '란 혈압을 내리게 하는 의약품을, 각성제란 신경계를 흥분시켜 잠이 오는 것을 억제하는 의약품을 말합니다.'}
          ],
          'CHOICETYPE': '2',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 3,
          'ID': 'G03',
          'QUESTION': '<strong>최근 1년 이내</strong>에 의사로부터 진찰 또는 검사를 받고, 이를 통하여 추가검사 또는 재검사를 받은 적이 있으십니까?',
          'DEFINITION': [
            {'title': '추가검사 및 재검사', 'content': '는 검사 결과 이상 소견이 확인되어 보다 정확한 진단을 위해 시행한 검사를 의미하며, 병증에 대한 치료 필요없이 유지되는 상태에서 시행하는 정기검사 또는 추적관찰은 포함하지 않습니다.'}
          ],
          'CHOICETYPE': '2',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 4,
          'ID': 'G04',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '입원, 수술 계속하여 7일이상 치료, 계속하여 30일 이상 투약',
          'DEFINITION': [
            {'title': "'계속하여'", 'content': '란 같은 원인으로 치료 시작 후 완료일까지 실제 치료, 투약받은 일수를 말합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '입원'},
            {'key': '02', 'label': '수술(제왕절개 포함)'},
            {'key': '03', 'label': '계속하여 7일 이상 치료'},
            {'key': '04', 'label': '계속하여 30일 이상 투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 5,
          'ID': 'G05',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 아래 질병으로 질병확정진단, 치료, 입원, 수술, 투약을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '암'},
            {'key': '02', 'label': '고혈압'},
            {'key': '03', 'label': '협심증'},
            {'key': '04', 'label': '심근경색'},
            {'key': '05', 'label': '심장판막증'},
            {'key': '06', 'label': '뇌졸중(뇌경색, 뇌출혈 포함)'},
            {'key': '07', 'label': '당뇨병'},
            {'key': '08', 'label': '백혈병', 'disabled': true},
            {'key': '09', 'label': '간경화증(간경변증, 간섬유증 포함)', 'disabled': true},
            {'key': '10', 'label': '에이즈 및 HIV 보균', 'disabled': true}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 6,
          'ID': 'G06',
          'QUESTION': '<strong><span class="crTy-blue2">[장기요양상태를 보장하는 보험 가입하는 경우]</span> 최근 5년이내</strong>에 다음과 같은 기구, 장비를 사용하신 적이 있거나 현재 사용하고 계십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'DISABLED': true,
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '휠체어(보조이동장치 포함)'},
            {'key': '02', 'label': '목발 · 지팡이'},
            {'key': '03', 'label': '도뇨관(소변줄)'},
            {'key': '04', 'label': '산소호흡장비'},
            {'key': '05', 'label': '인공호흡장비'},
            {'key': '06', 'label': '투석장치'},
            {'key': '07', 'label': '기타의료보조장구'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 7,
          'ID': 'G07',
          'QUESTION': '귀하(피보험자)의 직업(부업 · 겸업 · 위험취미 포함)을 모두 작성해 주세요.',
          'DEFINITION': [
            {'title': "위험취미", 'content': "란 행글라이딩, 패러글라이딩, 스카이다이빙, 자동차/오토바이경주, 빙벽/암벽등반을 의미합니다. 해당하시는 경우 '위험취미'에 작성 바랍니다."}
          ],
          'CHOICETYPE': '5',
          'PAGE': '2',
          'ANSWERS': [
            {'key': 'conm', 'label': '근무처(직장명)', 'readonly': true, 'maxlength': 6,'must': true},
            {'key': 'indclnm', 'label': '업종', 'readonly': true, 'maxlength': 10},
            {'key': 'jobnm', 'label': '하시는일', 'readonly': true, 'maxlength': 10, 'must': true},
            {'key': 'G983', 'label': '부업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G984', 'label': '겸업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G985', 'label': '위험취미', 'check': true, 'maxlength': 10}
          ],
          'ANSWER': {
            'conm': '',
            'indclnm': '',
            'jobnm': '',
            'G983': '',
            'G984': '',
            'G985': ''
          },
          'PRVANSWER': ''
        },
        {
          'INDEX': 8,
          'ID': 'G08',
          'QUESTION': '현재 어떤 차종을 운전하십니까?</br>(복수선택 가능)',
          'DEFINITION': [
            {'title': '원동기장치 자전거', 'content': '(전동킥보드, 전동이륜평행차, 전동기의 동력만으로 움직일 수 있는 자전거 등 개인형 이동장치를 포함)를 상시적(직업, 직무, 동호회, 출퇴근용도 등)으로 사용하시는 경우 체크 바랍니다. (단,전동휠체어, 의료용 스쿠터 등 보행보조용 의자차는 제외)'}
          ],
          'CHOICETYPE': '3',
          'PAGE': '2',
          'ANSWERS': [],
          'DETAILS': [
            {'key': '01', 'label': '승용차(자가용)'},
            {'key': '02', 'label': '승용차(영업용)'},
            {'key': '03', 'label': '승합차(자가용)'},
            {'key': '04', 'label': '승합차(영업용)'},
            {'key': '05', 'label': '화물차(자가용)'},
            {'key': '06', 'label': '화물차(영업용)'},
            {'key': '07', 'label': '이륜자동차(자가용)'},
            {'key': '08', 'label': '이륜자동차(영업용)'},
            {'key': '09', 'label': '건설기계'},
            {'key': '10', 'label': '원동기장치 자전거(전동킥보드 등)'},
            {'key': '11', 'label': '운전하지 않음', 'clear': true},
            {'key': 'ETC3', 'label': '기타', 'value': ''}
          ],
          'DETAIL': [],
          'ANSWERTYPE': 'M',
          'PRVANSWER': ''
        },
        {
          'INDEX': 9,
          'ID': 'G09',
          'QUESTION': '<strong>향후 3개월 이내</strong>에 해외위험지역으로 출국할 예정이 있으십니까?',
          'DEFINITION': [
            {'title': '해외위험지역', 'content': '이란 전쟁지역, 미개척지(열대/한대), 등반산악지대를 말합니다.'}
          ],
          'CHOICETYPE': '4',
          'PAGE': '2',
          'ANSWERS': [
            {'key': 'ETC4', 'label': '국가', 'maxlength': 10, 'must': true},
            {'key': 'ETC5', 'label': '기간', 'unit': '개월', 'mask': 'decimal', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'ETC6', 'label': '목적', 'maxlength': 5, 'must': true}
          ],
          'ANSWER': {
            'ETC4': '',
            'ETC5': '',
            'ETC6': ''
          },
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 10,
          'ID': 'G10',
          'QUESTION': '<strong>최근 1년 이내</strong>에 담배를 피운 적이 있으십니까?',
          'DEFINITION': '',
          'CHOICETYPE': '2',
          'PAGE': '3',
          'ANSWERS': [],
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 11,
          'ID': 'G11',
          'QUESTION': '음주를 하십니까? 음주를 하시는 경우, 횟수와 주량을 작성해 주세요.',
          'DEFINITION': '',
          'CHOICETYPE': '4',
          'PAGE': '3',
          'ANSWERS': [ 
            {'key': 'ETC1', 'label': '횟수', 'unit': '회', 'sub_unit': '주','mask': '#', 'maxlength': 2, 'inputmode': 'decimal', 'must': true},
            {'key': 'ETC2', 'label': '주량', 'unit': '병', 'sub_unit': '소주기준', 'maxlength': 3, 'inputmode': 'decimal', 'must': true}
          ],
          'ANSWER': {
            'ETC1': '',
            'ETC2': ''
          },
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [],
          'DETAIL': []
        },
        {
          'INDEX': 12,
          'ID': 'G12',
          'QUESTION': '가장 최근에 측정하신 키와 몸무게를 작성해 주세요.',
          'DEFINITION': '',
          'CHOICETYPE': '5',
          'PAGE': '3',
          'ANSWERS': [
            {'key': 'G980', 'label': '키', 'unit': 'cm', 'mask': '###', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'G981', 'label': '몸무게', 'unit': 'kg', 'mask': '###', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'G982', 'label': '임신 중인 경우', 'unit': '주', 'mask': '##', 'maxlength': 2, 'inputmode': 'decimal'}
          ],
          'ANSWER': {
            'G980': '',
            'G981': '',
            'G982': ''
          },
          'PRVANSWER': ''
        },
        {
          'INDEX': 13,
          'ID': 'G13',
          'QUESTION': '연 평균 소득(계약자 기준)을 선택해 주세요.',
          'DEFINITION': '',
          'CHOICETYPE': '3',
          'PAGE': '3',
          'DETAILS': [
            {'key': '01', 'label': '없음'},
            {'key': '02', 'label': '5천만원 이하'},
            {'key': '03', 'label': '7천만원 이하'},
            {'key': '04', 'label': '1억원 이하'},
            {'key': '05', 'label': '1억원 초과'}
          ],
          'DETAIL': [],
          'ANSWERTYPE': 'S',
          'ANSWERS': [],
          'PRVANSWER': ''
        }
      ]
    },
    // 일반 (pvm_notice) END
    // 종합간병 (pvm_noticeCM) START
    {
      'ID': 'pvm_noticeCM',
      'VUCH_NO': '',
      'PRN': '',
      'MNISD': {
        'name': ''
      },
      'AIDS': {
        'name': ''
      },
      'CHILDREN': {
        'CHLD_ONE_N': '',
        'CHLD_TWO_N': '',
        'CHLD_THREE_N': ''
      },
      'PAGE_CNT': 2,
      // 계약자:1, 피보험자:2, 종피보험자:3, 친권자:4, 예금주:6, FC:9
      'TOTCNT': {
        'ALL': 8,
        '1': 0,
        '2': 8,
        '3': 0,
        '4': 0
      },
      'QUESTIONLIST': {
        'ALL': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08'],
        '1': [],
        '2': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08'],
        '3': [],
        '4': []
      },
      QUESTIONLIST_CNT: [
        {
          'INDEX': 1,
          'ID': 'G01',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 의사로부터 진찰 또는 검사(건강검진 포함)를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '',
          'DEFINITION': [
            {'title': '질병의심소견', 'content': '이란 의사가 진단서나 소견서 또는 진료의뢰서 등을 포함하여 서면(전자문서 포함)으로 교부한 경우를 말합니다.'},
            {'title': '투약', 'content': '이란 의사가 환자에게 약을 처방하는 행위를 말하는 것으로 실제로 약을 구입하지 않았어도 작성해야 합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'PRVANSWER': '',
          'ANSWER': '',
          'ANSWER_YN': '',
          'DETAILS': [
            {'key': '01', 'label': '질병확정진단'},
            {'key': '02', 'label': '질병의심소견'},
            {'key': '03', 'label': '치료'},
            {'key': '04', 'label': '입원'},
            {'key': '05', 'label': '수술'},
            {'key': '06', 'label': '투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 2,
          'ID': 'G02',
          'QUESTION': '<strong>최근 1년 이내</strong>에 의사로부터 진찰 또는 검사를 받고, 이를 통하여 추가검사 또는 재검사를 받은 적이 있으십니까?',
          'DEFINITION': [
            {'title': '추가검사 및 재검사', 'content': '는 검사 결과 이상 소견이 확인되어 보다 정확한 진단을 위해 시행한 검사를 의미하며, 병증에 대한 치료 필요없이 유지되는 상태에서 시행하는 정기검사 또는 추적관찰은 포함하지 않습니다.'}
          ],
          'CHOICETYPE': '2',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 3,
          'ID': 'G03',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '입원, 수술 계속하여 7일이상 치료, 계속하여 30일 이상 투약',
          'DEFINITION': [
            {'title': "'계속하여'", 'content': '란 같은 원인으로 치료 시작 후 완료일까지 실제 치료, 투약받은 일수를 말합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '입원'},
            {'key': '02', 'label': '수술'},
            {'key': '03', 'label': '계속하여 7일 이상 치료'},
            {'key': '04', 'label': '계속하여 30일 이상 투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 4,
          'ID': 'G04',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 아래 질병으로 질병확정진단, 치료, 입원, 수술, 투약을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '암'},
            {'key': '02', 'label': '고혈압'},
            {'key': '03', 'label': '협심증', 'disabled': true},
            {'key': '04', 'label': '심근경색', 'disabled': true},
            {'key': '05', 'label': '심장판막증', 'disabled': true},
            {'key': '06', 'label': '뇌졸중(뇌경색, 뇌출혈 포함)', 'disabled': true},
            {'key': '07', 'label': '당뇨병', 'disabled': true},
            {'key': '08', 'label': '백혈병', 'disabled': true},
            {'key': '09', 'label': '간경화증(간경변증, 간섬유증 포함)', 'disabled': true},
            {'key': '10', 'label': '에이즈 및 HIV 보균', 'disabled': true},
            {'key': '11', 'label': '경도인지장애', 'disabled': true},
            {'key': '12', 'label': '치매', 'disabled': true},
            {'key': '13', 'label': '알츠하이머병', 'disabled': true},
            {'key': '14', 'label': '파킨슨병', 'disabled': true}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 5,
          'ID': 'G05',
          'QUESTION': '<span class="fwb crTy-blue2">[장기요양상태를 보장하는 주보험을 가입하는 경우]</span><strong>최근 5년 이내</strong>에 다음과 같은 기구, 장비를 사용하신 적이 있거나 현재 사용하고 계십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'DISABLED': false, // (ASR240600865_240705)
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '휠체어(보조이동장치 포함)'},
            {'key': '02', 'label': '목발 · 지팡이'},
            {'key': '03', 'label': '도뇨관(소변줄)'},
            {'key': '04', 'label': '산소호흡장비'},
            {'key': '05', 'label': '인공호흡장비'},
            {'key': '06', 'label': '투석장치'},
            {'key': '07', 'label': '기타의료보조장구'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 6,
          'ID': 'G06',
          'QUESTION': '귀하(피보험자)의 직업(부업 · 겸업 · 위험취미 포함)을 모두 작성해 주세요.',
          'DEFINITION': [
            {'title': '위험취미', 'content': "란 행글라이딩, 패러글라이딩, 스카이다이빙, 자동차/오토바이경주, 빙벽/암벽등반을 의미합니다. 해당하시는 경우 '위험취미'에 작성 바랍니다."}
          ],
          'CHOICETYPE': '5',
          'PAGE': '2',
          'ANSWERS': [
            {'key': 'conm', 'label': '근무처(직장명)', 'readonly': true, 'maxlength': 6,'must': true},
            {'key': 'indclnm', 'label': '업종', 'readonly': true, 'maxlength': 10},
            {'key': 'jobnm', 'label': '하시는일', 'readonly': true, 'maxlength': 10, 'must': true},
            {'key': 'G983', 'label': '부업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G984', 'label': '겸업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G985', 'label': '위험취미', 'check': true, 'maxlength': 10}
          ],
          'ANSWER': {
            'conm': '',
            'indclnm': '',
            'jobnm': '',
            'G983': '',
            'G984': '',
            'G985': ''
          },
          'PRVANSWER': ''
        },
        {
          'INDEX': 7,
          'ID': 'G07',
          'QUESTION': '현재 어떤 차종을 운전하십니까?</br>(복수선택 가능)',
          'DEFINITION': [
            {'title': '원동기장치 자전거', 'content': '(전동킥보드, 전동이륜평행차, 전동기의 동력만으로 움직일 수 있는 자전거 등 개인형 이동장치를 포함)를 상시적(직업, 직무, 동호회, 출퇴근용도 등)으로 사용하시는 경우 체크 바랍니다. (단,전동휠체어, 의료용 스쿠터 등 보행보조용 의자차는 제외)'}
          ],
          'CHOICETYPE': '3',
          'PAGE': '2',
          'ANSWERS': [],
          'ANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '승용차(자가용)'},
            {'key': '02', 'label': '승용차(영업용)'},
            {'key': '03', 'label': '승합차(자가용)'},
            {'key': '04', 'label': '승합차(영업용)'},
            {'key': '05', 'label': '화물차(자가용)'},
            {'key': '06', 'label': '화물차(영업용)'},
            {'key': '07', 'label': '이륜자동차(자가용)'},
            {'key': '08', 'label': '이륜자동차(영업용)'},
            {'key': '09', 'label': '건설기계'},
            {'key': '10', 'label': '원동기장치 자전거(전동킥보드 등)'},
            {'key': '11', 'label': '운전하지 않음', 'clear': true},
            {'key': 'ETC3', 'label': '기타', 'value': ''}
          ],
          'DETAIL': [],
          'ANSWERTYPE': 'M',
          'PRVANSWER': ''
        },
        {
          'INDEX': 8,
          'ID': 'G08',
          'QUESTION': '가장 최근에 측정하신 키와 몸무게를 작성해 주세요.',
          'DEFINITION': '',
          'CHOICETYPE': '5',
          'PAGE': '2',
          'ANSWERS': [
            {'key': 'G980', 'label': '키', 'unit': 'cm', 'mask': '###', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'G981', 'label': '몸무게', 'unit': 'kg', 'mask': '###', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'G982', 'label': '임신 중인 경우', 'unit': '주', 'mask': '##', 'maxlength': 2, 'inputmode': 'decimal'}
          ],
          'ANSWER': {
            'G980': '',
            'G981': '',
            'G982': ''
          },
          'PRVANSWER': ''
        }
      ]
    },
    // 종합간병 (pvm_noticeCM) END
    // 연금저축 (pvm_noticePEN) START
    {
      'ID': 'pvm_noticePEN',
      'VUCH_NO': '',
      'PRN': '',
      'MNISD': {
        'name': ''
      },
      'AIDS': {
        'name': ''
      },
      'CHILDREN': {
        'CHLD_ONE_N': '',
        'CHLD_TWO_N': '',
        'CHLD_THREE_N': ''
      },
      'PAGE_CNT': 1,
      // 계약자:1, 피보험자:2, 종피보험자:3, 친권자:4, 예금주:6, FC:9
      'TOTCNT': {
        'ALL': 5,
        '1': 0,
        '2': 5,
        '3': 5,
        '4': 0
      },
      'QUESTIONLIST': {
        'ALL': ['G01', 'G02', 'G03', 'G04', 'G05'],
        '1': [],
        '2': ['G01', 'G02', 'G03', 'G04', 'G05'],
        '3': ['G01', 'G02', 'G03', 'G04', 'G05'],
        '4': []
      },
      QUESTIONLIST_CNT: [
        {
          'INDEX': 1,
          'ID': 'G01',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 의사로부터 진찰 또는 검사(건강검진 포함)를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '질병확정진단, 질병의심소견, 치료, 입원, 수술, 투약',
          'DEFINITION': [
            {'title': '질병의심소견', 'content': '이란 의사가 진단서나 소견서 또는 진료의뢰서 등을 포함하여 서면(전자문서 포함)으로 교부한 경우를 말합니다.'},
            {'title': '투약', 'content': '이란 의사가 환자에게 약을 처방하는 행위를 말하는 것으로 실제로 약을 구입하지 않았어도 작성해야 합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '질병확정진단'},
            {'key': '02', 'label': '질병의심소견'},
            {'key': '03', 'label': '치료'},
            {'key': '04', 'label': '입원'},
            {'key': '05', 'label': '수술'},
            {'key': '06', 'label': '투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 2,
          'ID': 'G02',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 마약을 사용하거나 혈압강하제, 신경안정제, 수면제, 각성제(흥분제), 진통제 등의 약물을 상시 복용한 적이 있으십니까?',
          'DEFINITION': [
            {'title': '혈압강하제', 'content': '란  혈압을 내리게 하는 의약품을, 각성제란 신경계를 흥분시켜 잠이 오는 것을 억제하는 의약품을 말합니다.'}
          ],
          'CHOICETYPE': '2',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 3,
          'ID': 'G03',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 아래 질병으로 질병확정진단, 치료, 입원, 수술, 투약을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '암'},
            {'key': '02', 'label': '고혈압'},
            {'key': '03', 'label': '협심증'},
            {'key': '04', 'label': '심근경색'},
            {'key': '05', 'label': '심장판막증'},
            {'key': '06', 'label': '뇌졸중(뇌경색, 뇌출혈 포함)'},
            {'key': '07', 'label': '당뇨병'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 4,
          'ID': 'G04',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 아래 질병으로 질병확정진단, 치료, 입원, 수술, 투약을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '백혈병', 'disabled': true},
            {'key': '02', 'label': '간경화증(간경변증, 간섬유증 포함)', 'disabled': true},
            {'key': '03', 'label': '에이즈 및 HIV 보균', 'disabled': true}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 5,
          'ID': 'G05',
          'QUESTION': '귀하(피보험자)의 직업(부업 · 겸업 · 위험취미 포함)을 모두 작성해 주세요.',
          'DEFINITION': [
            {'title': '위험취미', 'content': "란 행글라이딩, 패러글라이딩, 스카이다이빙, 자동차/오토바이경주, 빙벽/암벽등반을 의미합니다. 해당하시는 경우 '위험취미'에 작성 바랍니다."}
          ],
          'CHOICETYPE': '5',
          'PAGE': '1',
          'ANSWERS': [
            {'key': 'conm', 'label': '근무처(직장명)', 'readonly': true, 'maxlength': 6,'must': true},
            {'key': 'indclnm', 'label': '업종', 'readonly': true, 'maxlength': 10},
            {'key': 'jobnm', 'label': '하시는일', 'readonly': true, 'maxlength': 10, 'must': true},
            {'key': 'G983', 'label': '부업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G984', 'label': '겸업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G985', 'label': '위험취미', 'check': true, 'maxlength': 10}
          ],
          'ANSWER': {
            'conm': '',
            'indclnm': '',
            'jobnm': '',
            'G983': '',
            'G984': '',
            'G985': ''
          },
          'PRVANSWER': ''
        }
      ]
    },
    // 연금저축 (pvm_noticePEN) END
    // 어린이 (pvm_noticeKID) START
    {
      'ID': 'pvm_noticeKID',
      'VUCH_NO': '',
      'PRN': '',
      'MNISD': {
        'name': ''
      },
      'AIDS': {
        'name': ''
      },
      'CHILDREN': {
        'CHLD_ONE_N': '',
        'CHLD_TWO_N': '',
        'CHLD_THREE_N': ''
      },
      'PAGE_CNT': 4,
      // 계약자:1, 피보험자:2, 종피보험자:3, 친권자1:4, 친권자2:5, 예금주:6, FC:9
      'TOTCNT': {
        'ALL': 13,
        '1': 0,
        '2': 13,
        '3': 13,
        '4': 0
      },
      'QUESTIONLIST': {
        'ALL': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13'],
        '1': [],
        '2': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13'],
        '3': ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13'],
        '4': []
      },
      QUESTIONLIST_CNT: [
        {
          'INDEX': 1,
          'ID': 'G01',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 의사로부터 진찰 또는 검사(건강검진 포함)를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '질병확정진단, 질병의심소견, 치료, 입원, 수술, 투약',
          'DEFINITION': [
            {'title': '질병의심소견', 'content': '이란 의사가 진단서나 소견서 또는 진료의뢰서 등을 포함하여 서면(전자문서 포함)으로 교부한 경우를 말합니다.'},
            {'title': '투약', 'content': '이란 의사가 환자에게 약을 처방하는 행위를 말하는 것으로 실제로 약을 구입하지 않았어도 작성해야 합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '질병확정진단'},
            {'key': '02', 'label': '질병의심소견'},
            {'key': '03', 'label': '치료'},
            {'key': '04', 'label': '입원'},
            {'key': '05', 'label': '수술'},
            {'key': '06', 'label': '투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 2,
          'ID': 'G02',
          'QUESTION': '<strong>최근 3개월 이내</strong>에 마약을 사용하거나 혈압강하제, 신경안정제, 수면제, 각성제(흥분제), 진통제 등의 약물을 상시 복용한 적이 있으십니까?',
          'DEFINITION': [
            {'title': '혈압강하제', 'content': '란 혈압을 내리게 하는 의약품을, 각성제란 신경계를 흥분시켜 잠이 오는 것을 억제하는 의약품을 말합니다.'}
          ],
          'CHOICETYPE': '2',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 3,
          'ID': 'G03',
          'QUESTION': '<strong>최근 1년 이내</strong>에 의사로부터 진찰 또는 검사를 받고, 이를 통하여 추가검사 또는 재검사를 받은 적이 있으십니까?',
          'DEFINITION': [
            {'title': '추가검사 및 재검사', 'content': '는 검사 결과 이상 소견이 확인되어 보다 정확한 진단을 위해 시행한 검사를 의미하며, 병증에 대한 치료 필요없이 유지되는 상태에서 시행하는 정기검사 또는 추적관찰은 포함하지 않습니다.'}
          ],
          'CHOICETYPE': '2',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 4,
          'ID': 'G04',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 다음과 같은 의료행위를 받은 적이 있으십니까?',
          'SUB_QUESTION' : '입원, 수술 계속하여 7일이상 치료, 계속하여 30일 이상 투약',
          'DEFINITION': [
            {'title': "'계속하여'", 'content': '란 같은 원인으로 치료 시작 후 완료일까지 실제 치료, 투약받은 일수를 말합니다.'}
          ],
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '입원'},
            {'key': '02', 'label': '수술'},
            {'key': '03', 'label': '계속하여 7일 이상 치료'},
            {'key': '04', 'label': '계속하여 30일 이상 투약'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 5,
          'ID': 'G05',
          'QUESTION': '<strong>최근 5년 이내</strong>에 의사로부터 진찰 또는 검사를 통하여 아래 질병으로 질병확정진단, 치료, 입원, 수술, 투약을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '1',
          'DETAILMANDATORY': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '암'},
            {'key': '02', 'label': '고혈압'},
            {'key': '03', 'label': '협심증'},
            {'key': '04', 'label': '심근경색'},
            {'key': '05', 'label': '심장판막증'},
            {'key': '06', 'label': '뇌졸중(뇌경색, 뇌출혈 포함)'},
            {'key': '07', 'label': '당뇨병'},
            {'key': '08', 'label': '백혈병', 'disabled': true},
            {'key': '09', 'label': '간경화증(간경변증, 간섬유증 포함)', 'disabled': true},
            {'key': '10', 'label': '에이즈 및 HIV 보균', 'disabled': true}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 6,
          'ID': 'G06',
          'QUESTION': '가장 최근에 측정하신 키와 몸무게를 작성해 주세요.',
          'DEFINITION': '',
          'CHOICETYPE': '5',
          'PAGE': '1',
          'ANSWERS': [
            {'key': 'G980', 'label': '키', 'unit': 'cm', 'mask': '###', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'G981', 'label': '몸무게', 'unit': 'kg', 'mask': '###', 'maxlength': 3, 'inputmode': 'decimal', 'must': true},
            {'key': 'G982', 'label': '임신여부', 'unit': '주', 'mask': '##', 'maxlength': 2, 'inputmode': 'decimal', 'disabled': true}
          ],
          'ANSWER': {
            'G980': '',
            'G981': '',
            'G982': ''
          },
          'PRVANSWER': ''
        },
        {
          'INDEX': 7,
          'ID': 'G07',
          'QUESTION': '<span class="fwb crTy-blue2">[치아를 보장하는 특약에 가입하시는 경우]</span> 현재 가철성 의치(틀니)를 사용하고 계십니까?',
          'DEFINITION': '',
          'CHOICETYPE': '2',
          'PAGE': '2',
          'DETAILMANDATORY': true,
          'DISABLED': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 8,
          'ID': 'G08',
          'QUESTION': '<span class="fwb crTy-blue2">[치아를 보장하는 특약에 가입하시는 경우]</span> 최근 1년 이내에 치과의사로부터 진찰 또는 검사를 통하여 치료, 투약과 같은 의료행위를 받았거나 의료행위가 필요하다는 진단을 받은 적이 있습니까? (임플란트, 브릿지, 라미네이트, 치아/치열교정도 포함)',
          'DEFINITION': '',
          'CHOICETYPE': '2',
          'PAGE': '2',
          'DETAILMANDATORY': true,
          'DISABLED': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 9,
          'ID': 'G09',
          'QUESTION': '<span class="fwb crTy-blue2">[치아를 보장하는 특약에 가입하시는 경우]</span> 최근 5년 이내에 "치아우식증, 치주질환(치주염, 치은염 포함)"으로 영구치를 1개 이상 상실하였거나 치주수술을 받았거나 치주수술이 필요하다는 진단을 받은 적이 있습니까?',
          'DEFINITION': '',
          'CHOICETYPE': '2',
          'PAGE': '2',
          'DETAILMANDATORY': true,
          'DISABLED': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': ''
        },
        {
          'INDEX': 10,
          'ID': 'G10',
          'QUESTION': '<span class="fwb crTy-blue2">[피보험자가 될 자가 태아인 경우]</span> 임신과정 또는 산전검사에서 아래와 같은 태아 이상 가능성이 발견되었거나 진단을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'DISABLED': true,
          'PAGE': '3',
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '1', 'label': '선천성기형/장애', 'disabled': true},
            {'key': '2', 'label': '자궁내발육부전'},
            {'key': '3', 'label': '큰몸증'},
            {'key': '4', 'label': '신경학적결손'},
            {'key': '5', 'label': '염색체이상'},
            {'key': '6', 'label': '양수과소/과다증'},
            {'key': '7', 'label': '태아수종'},
            {'key': '8', 'label': '용혈성질환'},
            {'key': '9', 'label': '태아감염'},
            {'key': '10', 'label': '전치태반'},
            {'key': '11', 'label': '태반조기박리'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 11,
          'ID': 'G11',
          'QUESTION': '<span class="fwb crTy-blue2">[피보험자가 될 자가 태아인 경우]</span> 임신과정 또는 산전검사에서 아래와 같은 태아 이상 가능성이 발견되었거나 진단을 받은 적이 있으십니까?',
          'SUB_QUESTION': '',
          'DEFINITION': '',
          'CHOICETYPE': '1',
          'PAGE': '3',
          'DETAILMANDATORY': true,
          'DISABLED': true,
          'ANSWERS': [],
          'ANSWER': '',
          'ANSWER_YN': '',
          'PRVANSWER': '',
          'DETAILS': [
            {'key': '01', 'label': '다태아임신'},
            {'key': '02', 'label': '자궁경관무력증'}
          ],
          'DETAIL': []
        },
        {
          'INDEX': 12,
          'ID': 'G12',
          'QUESTION': '귀하(피보험자)의 직업(부업 · 겸업 · 위험취미 포함)을 모두 작성해 주세요.',
          'DEFINITION': [
            {'title': '위험취미', 'content': "란 행글라이딩, 패러글라이딩, 스카이다이빙, 자동차/오토바이 경주, 빙벽/암벽등반을 의미합니다. 해당하시는 경우 '위험취미'에 작성 바랍니다."}
          ],
          'CHOICETYPE': '5',
          'PAGE': '4',
          'ANSWERS': [
            {'key': 'conm', 'label': '근무처(직장명)', 'readonly': true, 'maxlength': 6,'must': true},
            {'key': 'indclnm', 'label': '업종', 'readonly': true, 'maxlength': 10},
            {'key': 'jobnm', 'label': '하시는일', 'readonly': true, 'maxlength': 10, 'must': true},
            {'key': 'G983', 'label': '부업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G984', 'label': '겸업(하시는 일)', 'check': true, 'maxlength': 10},
            {'key': 'G985', 'label': '위험취미', 'check': true, 'maxlength': 10}
          ],
          'ANSWER': {
            'conm': '',
            'indclnm': '',
            'jobnm': '',
            'G983': '',
            'G984': '',
            'G985': ''
          },
          'PRVANSWER': ''
        },
        {
          'INDEX': 13,
          'ID': 'G13',
          'QUESTION': '<span class="fwb crTy-blue2">[만 16세 이상인 경우]</span> 현재 어떤 차종을 운전하십니까?<br/>(복수선택 가능)',
          'DEFINITION': [
            {'title': '원동기장치 자전거', 'content': '(전동킥보드, 전동이륜평행차, 전동기의 동력만으로 움직일 수 있는 자전거 등 개인형 이동장치를 포함)를 상시적(직업, 직무, 동호회, 출퇴근용도 등)으로 사용하시는 경우 체크 바랍니다. (단,전동휠체어, 의료용 스쿠터 등 보행보조용 의자차는 제외)'}
          ],
          'CHOICETYPE': '3',
          'PAGE': '4',
          'DETAILS': [
            {'key': '01', 'label': '승용차(자가용)'},
            {'key': '02', 'label': '승용차(영업용)'},
            {'key': '03', 'label': '승합차(자가용)'},
            {'key': '04', 'label': '승합차(영업용)'},
            {'key': '05', 'label': '화물차(자가용)'},
            {'key': '06', 'label': '화물차(영업용)'},
            {'key': '07', 'label': '이륜자동차(자가용)'},
            {'key': '08', 'label': '이륜자동차(영업용)'},
            {'key': '09', 'label': '건설기계'},
            {'key': '10', 'label': '원동기장치 자전거(전동킥보드 등)'},
            {'key': '11', 'label': '운전하지 않음', 'clear': true},
            {'key': 'ETC3', 'label': '기타', 'value': ''}
          ],
          'DETAIL': [],
          'ANSWERTYPE': 'M',
          'DISABLED': true,
          'ANSWERS': [],
          'ANSWER': '',
          'PRVANSWER': ''
        }
      ]
    }
    // 어린이 (pvm_noticeKID) END
  ]
})