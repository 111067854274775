/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS421D
 * 화면 설명: 전자서명동의인증
 */
<template> 
  <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column">

    <!-- 피보험자 -->
    <template v-if="insuredPerson">
      <msp-expand ref="mspExpCard2" expanded titleFirst btnAreaFirst expandOnlyBtn class="mo-list-expand w100">
        <template #title>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="con-area pt20 pb20 bd-b-Ty1">
            <div class="fexTy3 full">
              <span class="fs19rem fwb txtSkip flex-1 ">{{userData2.insrdNm}}</span>
              <mo-badge class="badge-sample-badge lightyellow3 sm ml10" text="피보험자" shape="status">피보험자</mo-badge>
            </div>
            <div class="mt10 sub-text">
              <span>{{userData2.age}}세</span><em>|</em><span>{{userData2.custRrnId1}}-{{userData2.custRrnId2}}</span>
            </div>
            <div class="ns-check mt6 fexTy2" v-if="card2Later">
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardTwo.state === '3'"><mo-icon icon="msp-is-checked" class="fs28"/>동의완료</div>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardTwo.state === '1' && btnShowFlag" @click="fn_DoLater(2)"><mo-icon icon="msp-is-checked" class="fs28"/>나중에하기</div>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardTwo.state === '2' && btnShowFlag" @click="fn_DoCertify(2)"><mo-icon icon="msp-is-checked" class="fs28"/>나중에하기</div><!--인증하기-->
            </div>
            <div class="ns-check mt6 fexTy2" v-else>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardTwo.state === '3'"><mo-icon icon="msp-is-checked" class="fs28"/>동의완료</div>
              <mo-checkbox class="fexJsCtTy3" v-if="cardTwo.state === '1' && btnShowFlag" @click="fn_DoLater(2)">나중에하기</mo-checkbox>
              <mo-checkbox class="fexJsCtTy3" v-if="cardTwo.state === '2' && btnShowFlag" @click="fn_DoCertify(2)">나중에하기</mo-checkbox><!--인증하기-->
            </div>
          </ur-box-container>
        </template>
        <template #btn>
          <div></div>
        </template> 
        <template #content>
          <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 bd-b-Ty1 pb30">
            <div class="chk-box">
              <div class="fwb fs16rem ls--10">
                계약체결ᆞ이행 등을 위한  동의, 개인(신용)정보 동의 관련 추가확인 사항,전자적 방법에 의한 계약체결동의 및 보험계약 서류 수령동의에 대한 전체동의
              </div>
              <div class="ns-check mt10 fexTy2">
                <mo-checkbox v-model="cardTwo.isAllAgree" @input="fn_OnSelectAgreeAll(2)">전체동의</mo-checkbox>
              </div>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="con-area dsN bd-b-Ty1 bgcolor-3 pt20 pb20"><!-- dsN : display:none -->
            <div class="fs16rem fwb">계약체결ᆞ이행 등을 위한  동의</div>
            <div class="fs14rem crTy-bk7 mt6">귀하는 개인(신용)정보의 수집ᆞ이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의는 철회할 수 있습니다.다만, 
              <span class="crTy-orange2">본 동의는 ‘보험계약 인수심사 ᆞ체결 ᆞ이행 ᆞ유지 ᆞ관리’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행이 불가능합니다.</span>
            </div>
          </ur-box-container>

          <msp-expand ref="mspExpCardTwo1" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="1" @input="fn_ClickedCheck(2,0)"><span class="fs16rem">{{requiredAgreementContent[0].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">수집ᆞ이용 목적</div>
                  <div class="fs14rem crTy-bk7 mt6">보험계약의 인수심사ᆞ체결ᆞ이행ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담, 보험계약대출의 체결ᆞ유지ᆞ관리, 보험금 등 지급ᆞ심사, 위ᆞ수탁사무의 수행, 조사연구(리서치), 서비스 제공, 순보험요율의 산출ᆞ검증, 조사연구(리서치),서비스 제공, 순보험요율의 산출ᆞ검증, 보험원가관리,보험모집질서의 유지, 보험사기 조사, 분쟁대응 및 민원처리 적부 및 사고조사(보험사기 조사 포함), 법률 및 국제 협약 등의 의무 이행, 금융거래 관련업무(보험계약, 보험금청구, 보험계약대출,전자금융서비스 관련신청ᆞ해지ᆞ변경, 이체(자동-R/T 이체 포함),입출금 업무 등),가입한 보험계약 상담,새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명, 이상금융거래 탐지 등 금융사고 방지</div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-bk7 mt6">동의일로부터 거래종료 후 5년까지(단 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
    ※ 상기 ‘거래종료일＇이란 당사와의 모든 거래관계(보험,융자,수익증권,신탁,퇴직연금 등)에 대한＂①계약만기, 해지,취소,철회일 또는 소멸일,②보험금 청구권 소멸시효 완성일(상법 제662조)③채권ᆞ채무관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한날”을 뜻합니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 수집ᆞ이용 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[0]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[0]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등),진료기록(건강검진 포함)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[1]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[1]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem mt6">[일반개인정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장,가족관계,취미,CI(연계정보)</div>
                  <div class="fs14rem mt10">[신용거래정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">당사ᆞ타보험사(체신관서(우체국보험),공제사업자 포함) ᆞ신용정보집중기관 및 보험요율 산출기관의 보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금 지급관련정보(사고정보,본인의 위임을 받아 취득한 각종 조사서, 판결문,증명서,확인서 등),손해사정업무 및 그에 부수하는 업무 수행을 위해 필요한 정보(손해사정사 등),계약 전 알릴의무사항,법률 및 국제협약 등의 이무이행을 위한 정보,금융거래 관련 이체 및 입ᆞ출금정보(계좌개설 금융기관,예금주,계좌번호,이체내역,신청ᆞ해지 및 출금동의 관련정보 등)보험계약대출정보(대출실행일,대출금,금리,상환일자,대출번호 등)</div>
                  <div class="fs14rem mt10">[신용능력정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보</div>
                  <div class="fs14rem mt10">[신용도판단정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">압류 및 체납처분 관련 정보
                    <span class="crTy-blue4 underline">위 고유식별정보 수집ᆞ이용에 동의하십니까?</span>
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[2]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[2]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row01 end -->

          <msp-expand ref="mspExpCardTwo2" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="2" @input="fn_ClickedCheck(2,1)"><span class="fs16rem">{{requiredAgreementContent[1].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">제공받는자</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국가기관 등 : 법원,검찰,경찰,국세청,금융위원회,금융감독원,행정안전부,서민금융진흥원, 보험요율 산출기관 등 법령상 업무수행기관 | 금융거래기관 : 계좌개설 금융기관,은행연합회,금융결제원 | 종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험회사 등 : 생명ᆞ손해보험회사,국내 재보험사,공제사업자,체신관서(우체국포함) | 보험협회 : 생명ᆞ손해보험협회
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">제공받는자의 이용목적</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국가기관 등 : 보험업법,신용정보법 등 법령에 따른 업무수행(위탁업무,보험금 찾아주기,휴면보험금 출연 등 포함)| 금융거래기관 : 금융거래 업무| 종합신용정보집중기관 : 보험계약,ᆞ보험계약대출 및 보험금 지급 관련 정보의 집중관리 및 활용 등 기관의 업무,새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명 등 법령상 의무수행 지원| 신용정보회사 등 : 당사요청 정보제공을 위한 대상자 식별| 보험회사 등 : 중복보험 확인 및 비례보상,재보험 가입 및 재보험금 청구,보험계약 공동인수, 비과세 상품의가입한도 확인 | 보험협회 : 보험계약관련 업무지원,공정경쟁 질서유지에 관한 협정업무
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                  </div>
                  <div class="fs14rem crTy-blue4 underline mt10">
                    ※ 외국 재보험사의 국내지점이 재보험계약 가입 판단 지원, 보험계약 공동인수 지원업무를 위탁하기 위한 경우 별도의 동의 없이 외국소재본점에 귀하의 정보를 이전할 수 있습니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[3]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[3]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[4]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[4]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 『수집ᆞ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                    위 개인신용정보 제공에 동의하십니까?               
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[5]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[5]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem crTy-bk7">
                    ※ 향후 보험계약체결ᆞ유지ᆞ관리 및 서비스 제공 등을 위하여 불가피한 경우 제공받는 자, 제공받는자의 이용목적, 제공되는 저옵의 항목 등이 추가 또는 변동될 수 있습니다. 이 경우 추가 또는 변동되는 사항을 삼성생명 홈페이지를 통하여 알려드립니다.
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row02 end -->

          <msp-expand ref="mspExpCardTwo3" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="3" @input="fn_ClickedCheck(2,2)"><span class="fs16rem">{{requiredAgreementContent[2].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">제공받는자</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국외 재보험사
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">제공받는자의 이용목적</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    보험계약의 인수심사,보험계약 공동인수,재보험 가입 및 재보험금 청구
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[6]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[6]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[7]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[7]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 『수집ᆞ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                    위 개인신용정보 제공에 동의하십니까?
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[8]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[8]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row03 end -->

          <msp-expand ref="mspExpCardTwo4" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="4" @input="fn_ClickedCheck(2,3)"><span class="fs16rem">{{requiredAgreementContent[3].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">조회대상 기관</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험요율산출기관 : 보험개발원 | 세금우대저축자료집중기관 : 은행연합회
                    <br>* 조회대상기관의 구체적인 정보 및 조회목적은 삼성생명 홈페이지에서 확인할 수 있습니다.
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">조회목적</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    보험계약 체결ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담,보험계약대출 체결ᆞ유지ᆞ관리,보험금 등 지급ᆞ심사,적부 및 사고조사(보험사기 조사 포함),새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">조회동의의 효력기간</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 조회결과는 귀하와의 보험거래가 개시되는 경우 해당 보험거래종료일까지 동의의 효력이 지속됩니다.
                    <br>다만,귀하가 신청한 보험 거래의 설정이 거절된 경우에는 그 시점부터 동의의 효력은 소멸합니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 조회 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[9]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[9]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[10]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[10]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem mt6">[일반개인정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장</div>
                  <div class="fs14rem mt10">[신용거래정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">
                    보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금지급정보(사고정보 포함),연금저축/비과세/세금우대종합저축/비과세종합저축 가입총액 및 내역, 신용정보주체의 대출/보증 등 거래내용,신용도,재산 등 신용거래 능력을 판단할 수 있는 신용정보, 신용등급ᆞ신용평점 등 신용정보를 가공한 정보
                  </div>
                  <div class="fs14rem mt10">[신용능력정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보
                    <br>위 개인신용정보 제공에 동의하십니까?
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[11]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[11]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row04 end -->

          <msp-expand ref="mspExpCardTwo5" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="5" @input="fn_ClickedCheck(2,4)"><span class="fs16rem">{{requiredAgreementContent[4].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem crTy-bk7 mt6">
                    최근 1년내 실손/가입설계를 위한 개인 신용정보 처리 동의서에 직접 동의하였습니까?               
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[12]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[12]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="mt20 bd-b-Ty1 full48" />
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">전자적 방법에 의한 계약체결 동의</div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardTwo.agreeRadioList[13]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardTwo.agreeRadioList[13]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="fs14rem crTy-bk7 mt10">
                    본인은 다음과 같이 전자서명을 통해 보험계약을 체결하는데 동의합니다.               
                  </div>

                  <div class="fs14rem crTy-bk7 mt20 bd-b-Ty1 bd-T-Ty1 txt-center pt10 pb10">다음</div>
                  <div class="fs14rem crTy-bk7 mt10">
                    보험계약자 또는 피보험자의 자필서명이 필요한 사항(보험청약서, 계약 전 알릴사항, 상품설명서 등)에 대해서는 전자서명법 제2조 제2호의 전자서명 방식에 의해 보험계약을 청약합니다.
  보험계약체결의 모든 과정에서 삼성생명보험주식회사(이하＂회사＂라 합니다.)가 제공하는 전자서명시스템을 이용하여 보험계약을 체결합니다.
                  </div>
                  <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1">
                    <span class="indent10">- 전자문서 외에 서면문서를 이용하여 청약서를 작성하실 수 있습니다.</span>
                <br><span class="indent10">- 전자서명을 통한 보험계약 체결 후 삼성생명 홈페이지(www.samsunglife.com)에서 전자문서, 계약처리단계 및 담당자 정보를 조회ᆞ출력하실 수 있습니다.</span>
                <br><span class="indent10">- 계약자가 청약서 부본,상품설명서 및 약관의 서면교부를 요청하는 경우, 회사가 전자적 방법으로 교부할 수 없는 경우 보험계약자에게 우편발송 또는 직접 제공해 드립니다.</span>
                  </div>
                </div>

                <!-- <div class="mt20">
                  <div class="fs14rem crTy-bk7 fwb">전자적 방법에 의한 보험계약서류 수령 동의(선택)</div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio class="fs16rem crTy-bk1">동의안함</mo-radio> <mo-radio class="fs16rem crTy-bk1">동의함</mo-radio>
                  </div>
                  <div class="fs14rem crTy-bk7 mt30">
                    전자서명을 통해 작성된 보험청약서, 보험약관, 상품설명서 등 보험계약자료를 전자적 방법으로 수령함에 동의합니다.
                  </div>
                </div> -->
              </div>

            </template>
          </msp-expand>
          <!-- row05 end -->
          <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1" style="padding-left: 20px;padding-right: 20px;">
            <span class="indent10">※ 당사는 완전히 자동화된 시스템을 통해 개인정보를 처리하여 보험계약의 인수 및 보험금 지급에 대한 결정을 수행할 수 있음을 안내드립니다.</span><br>
            <span class="indent10">※ 위 개인정보 제공 동의하시는 경우, 계약 관계자가 각각 기재하여 주시기 바랍니다.</span><br>
            <span class="indent10">※ 미성년인 계약관련자가 복수이고 각각의 법정대리인이 다를 경우에는 모두 동의하셔야 합니다.</span><br>
            <span class="indent10">※ 미성년자인 경우, 친권자 또는 후견인이 기재 바랍니다. 부모가 공동친권자인 경우 부모 모두가 각각 성명 기재하여야 합니다. 다만, 다른 일방의 의사에 반하지 않을 경우 부모 중 일방이 부모 공동명의로 동의할 수 있습니다.</span>
          </div>

          <div class="con-area pt16 pb16">
            <span class="fs16rem">[CISO 심의필 2024-042]</span>
          </div>

          <template v-if="cardTwo.isAllAgree">
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb30">
              <div class="fs18rem fwm">본인인증</div>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="authType2[0].key" class="chip-type-wrap chip-ty1" :disabled="!cardTwo.isAllAgree">
                  <mo-segment-button v-for="(item, idx) in authTypes" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>

              <MSPPS401D v-if="authType2[0].key === '1'" :disabled="!cardTwo.isAllAgree" :userData2="userData2" @authFinish="fn_AuthFinish"/>
              <MSPPS402D v-else-if="authType2[0].key === '2'" :disabled="!cardTwo.isAllAgree" :userData2="userData2" @authFinish="fn_AuthFinish"/>

            </ur-box-container>
            
          </template>
        </template>
      </msp-expand>
    </template>
    <!-- 피보험자 end -->
        
    <!-- 계약자 -->
    <template>
      <msp-expand ref="mspExpCard1" :expanded="!insuredPerson" titleFirst btnAreaFirst expandOnlyBtn class="mo-list-expand w100">
        <template #title>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="con-area pt20 pb20 bd-b-Ty1">
            <div class="fexTy3 full">
              <span class="fs19rem fwb txtSkip flex-1">{{userData1.insrdNm}}</span>
              <mo-badge class="badge-sample-badge lightgreen sm ml10" text="계약자" shape="status">계약자</mo-badge>
            </div>
            <div class="mt10 sub-text">
              <span>{{userData1.age}}세</span><em>|</em><span>{{userData1.custRrnId1}}-{{userData1.custRrnId2}}</span>
            </div>
            <div class="ns-check mt6 fexTy2" v-if="card1Later">
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardOne.state === '3'"><mo-icon icon="msp-is-checked" class="fs28"/>동의완료</div>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardOne.state === '1' && btnShowFlag && (insuredPerson || dpstrPerson)" @click="fn_DoLater(1)"><mo-icon icon="msp-is-checked" class="fs28"/>나중에하기</div>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardOne.state === '2' && btnShowFlag && (insuredPerson || dpstrPerson)" @click="fn_DoCertify(1)"><mo-icon icon="msp-is-checked" class="fs28"/>나중에하기</div><!--인증하기-->
            </div>
            <div class="ns-check mt6 fexTy2" v-else>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardOne.state === '3'"><mo-icon icon="msp-is-checked" class="fs28"/>동의완료</div>
              <mo-checkbox class="fexJsCtTy3" v-if="cardOne.state === '1' && btnShowFlag && (insuredPerson || dpstrPerson)" @click="fn_DoLater(1)">나중에하기</mo-checkbox>
              <mo-checkbox class="fexJsCtTy3" v-if="cardOne.state === '2' && btnShowFlag && (insuredPerson || dpstrPerson)" @click="fn_DoCertify(1)">나중에하기</mo-checkbox><!--인증하기-->
            </div>
          </ur-box-container>
        </template>
        <template #btn>
          <div></div>
        </template> 
        <template #content>
          <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 bd-b-Ty1 pb30">
            <div class="chk-box">
              <div class="fwb fs16rem ls--10">
                계약체결ᆞ이행 등을 위한  동의, 개인(신용)정보 동의 관련 추가확인 사항,전자적 방법에 의한 계약체결동의 및 보험계약 서류 수령동의에 대한 전체동의
              </div>
              <div class="ns-check mt10 fexTy2">
                <mo-checkbox v-model="cardOne.isAllAgree" @input="fn_OnSelectAgreeAll(1)">전체동의</mo-checkbox>
              </div>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="con-area dsN bd-b-Ty1 bgcolor-3 pt20 pb20"><!-- dsN : display:none -->
            <div class="fs16rem fwb">계약체결ᆞ이행 등을 위한  동의</div>
            <div class="fs14rem crTy-bk7 mt6">귀하는 개인(신용)정보의 수집ᆞ이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의는 철회할 수 있습니다.다만, 
              <span class="crTy-orange2">본 동의는 ‘보험계약 인수심사 ᆞ체결 ᆞ이행 ᆞ유지 ᆞ관리’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행이 불가능합니다.</span>
            </div>
          </ur-box-container>

          <msp-expand ref="mspExpCardOne1" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardOne.agreeCheckboxList" value="1" @input="fn_ClickedCheck(1,0)"><span class="fs16rem">{{requiredAgreementContent[0].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">수집ᆞ이용 목적</div>
                  <div class="fs14rem crTy-bk7 mt6">보험계약의 인수심사ᆞ체결ᆞ이행ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담, 보험계약대출의 체결ᆞ유지ᆞ관리, 보험금 등 지급ᆞ심사, 위ᆞ수탁사무의 수행, 조사연구(리서치), 서비스 제공, 순보험요율의 산출ᆞ검증, 조사연구(리서치),서비스 제공, 순보험요율의 산출ᆞ검증, 보험원가관리,보험모집질서의 유지, 보험사기 조사, 분쟁대응 및 민원처리 적부 및 사고조사(보험사기 조사 포함), 법률 및 국제 협약 등의 의무 이행, 금융거래 관련업무(보험계약, 보험금청구, 보험계약대출,전자금융서비스 관련신청ᆞ해지ᆞ변경, 이체(자동-R/T 이체 포함),입출금 업무 등),가입한 보험계약 상담,새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명, 이상금융거래 탐지 등 금융사고 방지</div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-bk7 mt6">동의일로부터 거래종료 후 5년까지(단 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
    ※ 상기 ‘거래종료일＇이란 당사와의 모든 거래관계(보험,융자,수익증권,신탁,퇴직연금 등)에 대한＂①계약만기, 해지,취소,철회일 또는 소멸일,②보험금 청구권 소멸시효 완성일(상법 제662조)③채권ᆞ채무관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한날”을 뜻합니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 수집ᆞ이용 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[0]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[0]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등),진료기록(건강검진 포함)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[1]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[1]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem mt6">[일반개인정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장,가족관계,취미,CI(연계정보)</div>
                  <div class="fs14rem mt10">[신용거래정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">당사ᆞ타보험사(체신관서(우체국보험),공제사업자 포함) ᆞ신용정보집중기관 및 보험요율 산출기관의 보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금 지급관련정보(사고정보,본인의 위임을 받아 취득한 각종 조사서, 판결문,증명서,확인서 등),손해사정업무 및 그에 부수하는 업무 수행을 위해 필요한 정보(손해사정사 등),계약 전 알릴의무사항,법률 및 국제협약 등의 이무이행을 위한 정보,금융거래 관련 이체 및 입ᆞ출금정보(계좌개설 금융기관,예금주,계좌번호,이체내역,신청ᆞ해지 및 출금동의 관련정보 등)보험계약대출정보(대출실행일,대출금,금리,상환일자,대출번호 등)</div>
                  <div class="fs14rem mt10">[신용능력정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보</div>
                  <div class="fs14rem mt10">[신용도판단정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">압류 및 체납처분 관련 정보
                    <span class="crTy-blue4 underline">위 고유식별정보 수집ᆞ이용에 동의하십니까?</span>
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[2]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[2]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row01 end -->

          <msp-expand ref="mspExpCardOne2" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardOne.agreeCheckboxList" value="2" @input="fn_ClickedCheck(1,1)"><span class="fs16rem">{{requiredAgreementContent[1].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">제공받는자</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국가기관 등 : 법원,검찰,경찰,국세청,금융위원회,금융감독원,행정안전부,서민금융진흥원, 보험요율 산출기관 등 법령상 업무수행기관 | 금융거래기관 : 계좌개설 금융기관,은행연합회,금융결제원 | 종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험회사 등 : 생명ᆞ손해보험회사,국내 재보험사,공제사업자,체신관서(우체국포함) | 보험협회 : 생명ᆞ손해보험협회
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">제공받는자의 이용목적</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국가기관 등 : 보험업법,신용정보법 등 법령에 따른 업무수행(위탁업무,보험금 찾아주기,휴면보험금 출연 등 포함)| 금융거래기관 : 금융거래 업무| 종합신용정보집중기관 : 보험계약,ᆞ보험계약대출 및 보험금 지급 관련 정보의 집중관리 및 활용 등 기관의 업무,새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명 등 법령상 의무수행 지원| 신용정보회사 등 : 당사요청 정보제공을 위한 대상자 식별| 보험회사 등 : 중복보험 확인 및 비례보상,재보험 가입 및 재보험금 청구,보험계약 공동인수, 비과세 상품의가입한도 확인 | 보험협회 : 보험계약관련 업무지원,공정경쟁 질서유지에 관한 협정업무
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                  </div>
                  <div class="fs14rem crTy-blue4 underline mt10">
                    ※ 외국 재보험사의 국내지점이 재보험계약 가입 판단 지원, 보험계약 공동인수 지원업무를 위탁하기 위한 경우 별도의 동의 없이 외국소재본점에 귀하의 정보를 이전할 수 있습니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[3]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[3]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[4]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[4]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 『수집ᆞ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                    위 개인신용정보 제공에 동의하십니까?               
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[5]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[5]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem crTy-bk7">
                    ※ 향후 보험계약체결ᆞ유지ᆞ관리 및 서비스 제공 등을 위하여 불가피한 경우 제공받는 자, 제공받는자의 이용목적, 제공되는 저옵의 항목 등이 추가 또는 변동될 수 있습니다. 이 경우 추가 또는 변동되는 사항을 삼성생명 홈페이지를 통하여 알려드립니다.
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row02 end -->

          <msp-expand ref="mspExpCardOne3" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardOne.agreeCheckboxList" value="3" @input="fn_ClickedCheck(1,2)"><span class="fs16rem">{{requiredAgreementContent[2].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">제공받는자</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국외 재보험사
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">제공받는자의 이용목적</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    보험계약의 인수심사,보험계약 공동인수,재보험 가입 및 재보험금 청구
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[6]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[6]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[7]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[7]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 『수집ᆞ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                    위 개인신용정보 제공에 동의하십니까?
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[8]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[8]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row03 end -->

          <msp-expand ref="mspExpCardOne4" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardOne.agreeCheckboxList" value="4" @input="fn_ClickedCheck(1,3)"><span class="fs16rem">{{requiredAgreementContent[3].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">조회대상 기관</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험요율산출기관 : 보험개발원 | 세금우대저축자료집중기관 : 은행연합회
                    <br>* 조회대상기관의 구체적인 정보 및 조회목적은 삼성생명 홈페이지에서 확인할 수 있습니다.
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">조회목적</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    보험계약 체결ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담,보험계약대출 체결ᆞ유지ᆞ관리,보험금 등 지급ᆞ심사,적부 및 사고조사(보험사기 조사 포함),새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">조회동의의 효력기간</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 조회결과는 귀하와의 보험거래가 개시되는 경우 해당 보험거래종료일까지 동의의 효력이 지속됩니다.
                    <br>다만,귀하가 신청한 보험 거래의 설정이 거절된 경우에는 그 시점부터 동의의 효력은 소멸합니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 조회 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[9]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[9]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[10]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[10]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem mt6">[일반개인정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장</div>
                  <div class="fs14rem mt10">[신용거래정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">
                    보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금지급정보(사고정보 포함),연금저축/비과세/세금우대종합저축/비과세종합저축 가입총액 및 내역, 신용정보주체의 대출/보증 등 거래내용,신용도,재산 등 신용거래 능력을 판단할 수 있는 신용정보, 신용등급ᆞ신용평점 등 신용정보를 가공한 정보
                  </div>
                  <div class="fs14rem mt10">[신용능력정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보
                    <br>위 개인신용정보 제공에 동의하십니까?
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[11]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[11]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row04 end -->

          <msp-expand ref="mspExpCardOne5" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardOne.agreeCheckboxList" value="5" @input="fn_ClickedCheck(1,4)"><span class="fs16rem">{{requiredAgreementContent[4].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem crTy-bk7 mt6">
                    최근 1년내 실손/가입설계를 위한 개인 신용정보 처리 동의서에 직접 동의하였습니까?               
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[12]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[12]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="mt20 bd-b-Ty1 full48" />
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">전자적 방법에 의한 계약체결 동의</div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[13]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[13]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="fs14rem crTy-bk7 mt10">
                    본인은 다음과 같이 전자서명을 통해 보험계약을 체결하는데 동의합니다.               
                  </div>

                  <div class="fs14rem crTy-bk7 mt20 bd-b-Ty1 bd-T-Ty1 txt-center pt10 pb10">다음</div>
                  <div class="fs14rem crTy-bk7 mt10">
                    보험계약자 또는 피보험자의 자필서명이 필요한 사항(보험청약서, 계약 전 알릴사항, 상품설명서 등)에 대해서는 전자서명법 제2조 제2호의 전자서명 방식에 의해 보험계약을 청약합니다.
  보험계약체결의 모든 과정에서 삼성생명보험주식회사(이하＂회사＂라 합니다.)가 제공하는 전자서명시스템을 이용하여 보험계약을 체결합니다.
                  </div>
                  <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1">
                    <span class="indent10">- 전자문서 외에 서면문서를 이용하여 청약서를 작성하실 수 있습니다.</span>
                <br><span class="indent10">- 전자서명을 통한 보험계약 체결 후 삼성생명 홈페이지(www.samsunglife.com)에서 전자문서, 계약처리단계 및 담당자 정보를 조회ᆞ출력하실 수 있습니다.</span>
                <br><span class="indent10">- 계약자가 청약서 부본,상품설명서 및 약관의 서면교부를 요청하는 경우, 회사가 전자적 방법으로 교부할 수 없는 경우 보험계약자에게 우편발송 또는 직접 제공해 드립니다.</span>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem crTy-bk7 fwb">전자적 방법에 의한 보험계약서류 수령 동의(선택)</div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardOne.agreeRadioList[14]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[14]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="fs14rem crTy-bk7 mt30">
                    전자서명을 통해 작성된 보험청약서, 보험약관, 상품설명서 등 보험계약자료를 전자적 방법으로 수령함에 동의합니다.
                    <br><br>[전자적방법에 의한 보험계약서류 관련 고객안내사항]
                    <br>수령동의를 하지 않은 경우, 서면으로 보험청약서, 보험약관, 상품설명서 등을 제공해드립니다. 다만, 이 경우에도 청약서 및 약관은 모바일로도 제공됩니다.
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row05 end -->
          <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1" style="padding-left: 20px;padding-right: 20px;">
            <span class="indent10">※ 당사는 완전히 자동화된 시스템을 통해 개인정보를 처리하여 보험계약의 인수 및 보험금 지급에 대한 결정을 수행할 수 있음을 안내드립니다.</span><br>
            <span class="indent10">※ 위 개인정보 제공 동의하시는 경우, 계약 관계자가 각각 기재하여 주시기 바랍니다.</span><br>
            <span class="indent10">※ 미성년인 계약관련자가 복수이고 각각의 법정대리인이 다를 경우에는 모두 동의하셔야 합니다.</span><br>
            <span class="indent10">※ 미성년자인 경우, 친권자 또는 후견인이 기재 바랍니다. 부모가 공동친권자인 경우 부모 모두가 각각 성명 기재하여야 합니다. 다만, 다른 일방의 의사에 반하지 않을 경우 부모 중 일방이 부모 공동명의로 동의할 수 있습니다.</span>
          </div>
          
          <div class="con-area pt16 pb16">
            <span class="fs16rem">[CISO 심의필 2024-042]</span>
          </div>

          <template v-if="!cardOneDisabledFlag">
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb30">
              <div class="fs18rem fwm">본인인증</div>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="authType1[0].key" class="chip-type-wrap chip-ty1" :disabled="cardOneDisabledFlag">
                  <mo-segment-button v-for="(item, idx) in authTypes" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>

              <MSPPS401D v-if="authType1[0].key === '1'" :disabled="cardOneDisabledFlag" :userData1="userData1" @authFinish="fn_AuthFinish"/>
              <MSPPS402D v-else-if="authType1[0].key === '2'" :disabled="cardOneDisabledFlag" :userData1="userData1" @authFinish="fn_AuthFinish"/>
              
            </ur-box-container>
            
          </template>
        </template>
      </msp-expand>
    </template>
    <!-- 계약자 end -->

    <!-- 예금주 -->
    <template v-if="dpstrPerson">
      <msp-expand ref="mspExpCard3" titleFirst btnAreaFirst expandOnlyBtn class="mo-list-expand w100">
        <template #title>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="con-area pt20 pb20 bd-b-Ty1">
            <div class="fexTy3 full">
              <span class="fs19rem fwb txtSkip flex-1 ">{{userData6.detail.custNm}}</span>
              <mo-badge class="badge-sample-badge purple sm ml10" text="예금주" shape="status">예금주</mo-badge>
            </div>
            <div class="mt10 sub-text">
              <span>{{userData6.age}}세</span><em>|</em><span>{{userData6.custRrnId1}}-{{userData6.custRrnId2}}</span>
            </div>
            <div class="ns-check mt6 fexTy2" v-if="card3Later">
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardThree.state === '3'"><mo-icon icon="msp-is-checked" class="fs28"/>동의완료</div>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardThree.state === '1' && btnShowFlag" @click="fn_DoLater(3)"><mo-icon icon="msp-is-checked" class="fs28"/>나중에하기</div>
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardThree.state === '2' && btnShowFlag" @click="fn_DoCertify(3)"><mo-icon icon="msp-is-checked" class="fs28"/>나중에하기</div><!--인증하기-->
            </div>
            <div class="ns-check mt6 fexTy2">
              <div class="fexJsCtTy3 dsInFlax flex-auto fs18rem crTy-bk1" v-if="cardThree.state === '3'"><mo-icon icon="msp-is-checked" class="fs28"/>동의완료</div>
              <mo-checkbox class="fexJsCtTy3" v-if="cardThree.state === '1' && btnShowFlag" @click="fn_DoLater(3)">나중에하기</mo-checkbox>
              <mo-checkbox class="fexJsCtTy3" v-if="cardThree.state === '2' && btnShowFlag" @click="fn_DoCertify(3)">나중에하기</mo-checkbox><!-- 인증하기 -->
            </div>
          </ur-box-container>
        </template>
        <template #btn>
          <div></div>
        </template> 
        <template #content>
          <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 bd-b-Ty1 pb30">
            <div class="chk-box">
              <div class="fwb fs16rem ls--10">
                계약체결ㆍ이행 등을 위한  동의, 개인(신용)정보 동의 관련 추가확인 사항,전자적 방법에 의한 계약체결동의 및 보험계약 서류 수령동의에 대한 전체동의
              </div>
              <div class="ns-check mt10 fexTy2">
                <mo-checkbox v-model="cardThree.isAllAgree" @input="fn_OnSelectAgreeAll(3)">전체동의</mo-checkbox>
              </div>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="con-area dsN bd-b-Ty1 bgcolor-3 pt20 pb20"><!-- dsN : display:none -->
            <div class="fs16rem fwb">계약체결ㆍ이행 등을 위한  동의</div>
            <div class="fs14rem crTy-bk7 mt6">귀하는 개인(신용)정보의 수집ㆍ이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의는 철회할 수 있습니다.다만, 
              <span class="crTy-orange2">본 동의는 ‘보험계약 인수심사 ㆍ체결 ㆍ이행 ㆍ유지 ㆍ관리’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행이 불가능합니다.</span>
            </div>
          </ur-box-container>

          <msp-expand ref="mspExpCardThree1" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardThree.agreeCheckboxList" value="1" @input="fn_ClickedCheck(3,0)"><span class="fs16rem">{{requiredAgreementContent[0].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">수집ᆞ이용 목적</div>
                  <div class="fs14rem crTy-bk7 mt6">보험계약의 인수심사ㆍ체결ㆍ이행ㆍ유지ㆍ관리(부활 및 갱신 포함) ㆍ상담, 보험계약대출의 체결ㆍ유지ㆍ관리, 보험금 등 지급ㆍ심사, 위ㆍ수탁사무의 수행, 조사연구(리서치), 서비스 제공, 순보험요율의 산출ㆍ검증, 조사연구(리서치),서비스 제공, 순보험요율의 산출ㆍ검증, 보험원가관리,보험모집질서의 유지, 보험사기 조사, 분쟁대응 및 민원처리 적부 및 사고조사(보험사기 조사 포함), 법률 및 국제 협약 등의 의무 이행, 금융거래 관련업무(보험계약, 보험금청구, 보험계약대출,전자금융서비스 관련신청ㆍ해지ㆍ변경, 이체(자동-R/T 이체 포함),입출금 업무 등),가입한 보험계약 상담,새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명, 이상금융거래 탐지 등 금융사고 방지</div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-bk7 mt6">동의일로부터 거래종료 후 5년까지(단 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
    ※ 상기 ‘거래종료일＇이란 당사와의 모든 거래관계(보험,융자,수익증권,신탁,퇴직연금 등)에 대한＂①계약만기, 해지,취소,철회일 또는 소멸일,②보험금 청구권 소멸시효 완성일(상법 제662조)③채권ㆍ채무관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한날”을 뜻합니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 수집ㆍ이용 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ㆍ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[0]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[0]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ㆍ상해에 관한 정보(상병명,진료기록,기왕증 등),진료기록(건강검진 포함)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[1]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[1]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem mt6">[일반개인정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장,가족관계,취미,CI(연계정보)</div>
                  <div class="fs14rem mt10">[신용거래정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">당사ㆍ타보험사(체신관서(우체국보험),공제사업자 포함) ㆍ신용정보집중기관 및 보험요율 산출기관의 보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금 지급관련정보(사고정보,본인의 위임을 받아 취득한 각종 조사서, 판결문,증명서,확인서 등),손해사정업무 및 그에 부수하는 업무 수행을 위해 필요한 정보(손해사정사 등),계약 전 알릴의무사항,법률 및 국제협약 등의 이무이행을 위한 정보,금융거래 관련 이체 및 입ㆍ출금정보(계좌개설 금융기관,예금주,계좌번호,이체내역,신청ㆍ해지 및 출금동의 관련정보 등)보험계약대출정보(대출실행일,대출금,금리,상환일자,대출번호 등)</div>
                  <div class="fs14rem mt10">[신용능력정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보</div>
                  <div class="fs14rem mt10">[신용도판단정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">압류 및 체납처분 관련 정보
                    <span class="crTy-blue4 underline">위 고유식별정보 수집ㆍ이용에 동의하십니까?</span>
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[2]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[2]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row01 end -->

          <msp-expand ref="mspExpCardThree2" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardThree.agreeCheckboxList" value="2" @input="fn_ClickedCheck(3,1)"><span class="fs16rem">{{requiredAgreementContent[1].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">제공받는자</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국가기관 등 : 법원,검찰,경찰,국세청,금융위원회,금융감독원,행정안전부,서민금융진흥원, 보험요율 산출기관 등 법령상 업무수행기관 | 금융거래기관 : 계좌개설 금융기관,은행연합회,금융결제원 | 종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험회사 등 : 생명ᆞ손해보험회사,국내 재보험사,공제사업자,체신관서(우체국포함) | 보험협회 : 생명ᆞ손해보험협회
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">제공받는자의 이용목적</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국가기관 등 : 보험업법,신용정보법 등 법령에 따른 업무수행(위탁업무,보험금 찾아주기,휴면보험금 출연 등 포함)| 금융거래기관 : 금융거래 업무| 종합신용정보집중기관 : 보험계약,ᆞ보험계약대출 및 보험금 지급 관련 정보의 집중관리 및 활용 등 기관의 업무, 새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명 등 법령상 의무수행 지원| 신용정보회사 등 : 당사요청 정보제공을 위한 대상자 식별| 보험회사 등 : 중복보험 확인 및 비례보상,재보험 가입 및 재보험금 청구,보험계약 공동인수, 비과세 상품의가입한도 확인 | 보험협회 : 보험계약관련 업무지원,공정경쟁 질서유지에 관한 협정업무
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                  </div>
                  <div class="fs14rem crTy-blue4 underline mt10">
                    ※ 외국 재보험사의 국내지점이 재보험계약 가입 판단 지원, 보험계약 공동인수 지원업무를 위탁하기 위한 경우 별도의 동의 없이 외국소재본점에 귀하의 정보를 이전할 수 있습니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ㆍ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[3]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[3]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ㆍ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[4]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[4]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 『수집ㆍ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                    위 개인신용정보 제공에 동의하십니까?               
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[5]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[5]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem crTy-bk7">
                    ※ 향후 보험계약체결ㆍ유지ㆍ관리 및 서비스 제공 등을 위하여 불가피한 경우 제공받는 자, 제공받는자의 이용목적, 제공되는 저옵의 항목 등이 추가 또는 변동될 수 있습니다. 이 경우 추가 또는 변동되는 사항을 삼성생명 홈페이지를 통하여 알려드립니다.
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row02 end -->

          <msp-expand ref="mspExpCardThree3" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardThree.agreeCheckboxList" value="3" @input="fn_ClickedCheck(3,2)"><span class="fs16rem">{{requiredAgreementContent[2].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">제공받는자</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    국외 재보험사
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">제공받는자의 이용목적</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    보험계약의 인수심사,보험계약 공동인수,재보험 가입 및 재보험금 청구
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">보유 및 이용기간</div>
                  <div class="fs14rem crTy-blue4 underline mt6">
                    제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ㆍ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[6]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[6]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ㆍ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[7]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[7]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 『수집ㆍ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                    위 개인신용정보 제공에 동의하십니까?
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[8]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[8]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row03 end -->

          <msp-expand ref="mspExpCardThree4" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardThree.agreeCheckboxList" value="4" @input="fn_ClickedCheck(3,3)"><span class="fs16rem">{{requiredAgreementContent[3].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem fwb">조회대상 기관</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험요율산출기관 : 보험개발원 | 세금우대저축자료집중기관 : 은행연합회
                    <br>* 조회대상기관의 구체적인 정보 및 조회목적은 삼성생명 홈페이지에서 확인할 수 있습니다.
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">조회목적</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    보험계약 체결ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담,보험계약대출 체결ᆞ유지ᆞ관리,보험금 등 지급ᆞ심사,적부 및 사고조사(보험사기 조사 포함),새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">조회동의의 효력기간</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    위 조회결과는 귀하와의 보험거래가 개시되는 경우 해당 보험거래종료일까지 동의의 효력이 지속됩니다.
                    <br>다만,귀하가 신청한 보험 거래의 설정이 거절된 경우에는 그 시점부터 동의의 효력은 소멸합니다.
                  </div>
                </div>

                <div class="mt30">
                  <div class="fs14rem crTy-bk7">■ 조회 항목</div>
                  <div class="fs14rem fwb mt10">고유식별정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ㆍ이용에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[9]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[9]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">민감정보</div>
                  <div class="fs14rem crTy-bk7 mt6">
                    <span class="crTy-blue4 underline">피보험자의 질병ㆍ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[10]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[10]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">개인(신용)정보</div>
                  <div class="fs14rem mt6">[일반개인정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장</div>
                  <div class="fs14rem mt10">[신용거래정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">
                    보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금지급정보(사고정보 포함),연금저축/비과세/세금우대종합저축/비과세종합저축 가입총액 및 내역, 신용정보주체의 대출/보증 등 거래내용,신용도,재산 등 신용거래 능력을 판단할 수 있는 신용정보, 신용등급ㆍ신용평점 등 신용정보를 가공한 정보
                  </div>
                  <div class="fs14rem mt10">[신용능력정보]</div>
                  <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보
                    <br>위 개인신용정보 제공에 동의하십니까?
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[11]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[11]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                </div>
              </div>

            </template>
          </msp-expand>
          <!-- row04 end -->

          <msp-expand ref="mspExpCardThree5" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
            <template #title>
              <div class="ns-check fexTy2">
                <mo-checkbox v-model="cardThree.agreeCheckboxList" value="5" @input="fn_ClickedCheck(3,4)"><span class="fs16rem">{{requiredAgreementContent[4].title}}</span></mo-checkbox>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="con-area maxh200px">
                <div class="">
                  <div class="fs14rem crTy-bk7 mt6">
                    최근 1년내 실손/가입설계를 위한 개인 신용정보 처리 동의서에 직접 동의하였습니까?               
                  </div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[12]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[12]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="mt20 bd-b-Ty1 full48" />
                </div>

                <div class="mt20">
                  <div class="fs14rem fwb">전자적 방법에 의한 계약체결 동의</div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio v-model="cardThree.agreeRadioList[13]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardThree.agreeRadioList[13]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                  </div>
                  <div class="fs14rem crTy-bk7 mt10">
                    본인은 다음과 같이 전자서명을 통해 보험계약을 체결하는데 동의합니다.               
                  </div>

                  <div class="fs14rem crTy-bk7 mt20 bd-b-Ty1 bd-T-Ty1 txt-center pt10 pb10">다음</div>
                  <div class="fs14rem crTy-bk7 mt10">
                    보험계약자 또는 피보험자의 자필서명이 필요한 사항(보험청약서, 계약 전 알릴사항, 상품설명서 등)에 대해서는 전자서명법 제2조 제2호의 전자서명 방식에 의해 보험계약을 청약합니다.
  보험계약체결의 모든 과정에서 삼성생명보험주식회사(이하＂회사＂라 합니다.)가 제공하는 전자서명시스템을 이용하여 보험계약을 체결합니다.
                  </div>
                  <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1">
                    <span class="indent10">- 전자문서 외에 서면문서를 이용하여 청약서를 작성하실 수 있습니다.</span>
                <br><span class="indent10">- 전자서명을 통한 보험계약 체결 후 삼성생명 홈페이지(www.samsunglife.com)에서 전자문서, 계약처리단계 및 담당자 정보를 조회ㆍ출력하실 수 있습니다.</span>
                <br><span class="indent10">- 계약자가 청약서 부본,상품설명서 및 약관의 서면교부를 요청하는 경우, 회사가 전자적 방법으로 교부할 수 없는 경우 보험계약자에게 우편발송 또는 직접 제공해 드립니다.</span>
                  </div>
                </div>

                <!-- <div class="mt20">
                  <div class="fs14rem crTy-bk7 fwb">전자적 방법에 의한 보험계약서류 수령 동의(선택)</div>
                  <div class="ns-radio-list mt6 fexTy2">
                    <mo-radio class="fs16rem crTy-bk1">동의안함</mo-radio> <mo-radio class="fs16rem crTy-bk1">동의함</mo-radio>
                  </div>
                  <div class="fs14rem crTy-bk7 mt30">
                    전자서명을 통해 작성된 보험청약서, 보험약관, 상품설명서 등 보험계약자료를 전자적 방법으로 수령함에 동의합니다.
                  </div>
                </div> -->
              </div>

            </template>
          </msp-expand>
          <!-- row05 end -->
          <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1" style="padding-left: 20px;padding-right: 20px;">
            <span class="indent10">※ 당사는 완전히 자동화된 시스템을 통해 개인정보를 처리하여 보험계약의 인수 및 보험금 지급에 대한 결정을 수행할 수 있음을 안내드립니다.</span><br>
            <span class="indent10">※ 위 개인정보 제공 동의하시는 경우, 계약 관계자가 각각 기재하여 주시기 바랍니다.</span><br>
            <span class="indent10">※ 미성년인 계약관련자가 복수이고 각각의 법정대리인이 다를 경우에는 모두 동의하셔야 합니다.</span><br>
            <span class="indent10">※ 미성년자인 경우, 친권자 또는 후견인이 기재 바랍니다. 부모가 공동친권자인 경우 부모 모두가 각각 성명 기재하여야 합니다. 다만, 다른 일방의 의사에 반하지 않을 경우 부모 중 일방이 부모 공동명의로 동의할 수 있습니다.</span>
          </div>

          <div class="con-area pt16 pb16">
            <span class="fs16rem">[CISO 심의필 2024-042]</span>
          </div>

          <template v-if="cardThree.isAllAgree">
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb30">
              <div class="fs18rem fwm">본인인증</div>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper solid primary v-model="authType3[0].key" class="chip-type-wrap chip-ty1" :disabled="!cardThree.isAllAgree">
                  <mo-segment-button v-for="(item, idx) in authTypes" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>

              <MSPPS401D v-if="authType3[0].key === '1'" :disabled="!cardThree.isAllAgree" :userData6="userData6" @authFinish="fn_AuthFinish"/>
              <MSPPS402D v-else-if="authType3[0].key === '2'" :disabled="!cardThree.isAllAgree" :userData6="userData6" @authFinish="fn_AuthFinish"/>

            </ur-box-container>
            
          </template>
        </template>
      </msp-expand>
    </template>
    <!-- 예금주 -->
   

  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/

import Msg from '@/systems/webkit/msg/msg'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSCommUtil from '@/ui/ps/comm/PSCommUtil' 
import MSPPS401D from '@/ui/ps/MSPPS401D'
import MSPPS402D from '@/ui/ps/MSPPS402D'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS421D', 
  screenId: 'MSPPS421D',

  components: {
    'MSPPS401D' : MSPPS401D, //전자서명 인증 - 휴대폰
    'MSPPS402D' : MSPPS402D //전자서명 인증 - 신용카드
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    commData: {type: Object},
    scrollId: {type: String}
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {   
      //loadComplete : true,
      zaCmucoScCd : null,  //통신사 구분코드
      opened1: {a: true, b: true, c: true, d: true, e: true, f: true}, // 계약자 약관 오픈 여부
      opened2: {a: true, b: true, c: true, d: true, e: true}, // 피보험자 약관 오픈 여부
      insuredPerson: false, // 피보험자 유무
      dpstrPerson: false, // 예금주 유무
      cardOne: { // 계약자
        state: '1',
        isAllAgree: false,
        agreeRadioList: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], // 15 (13+2)
        allItemsRadio: ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1'],
        subItemsRadio: [['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1']],
        agreeCheckboxList: []
      },
      cardTwo: { // 피보험자
        state: '2',
        isAllAgree: false,
        agreeRadioList: ['', '', '', '', '', '', '', '', '', '', '', '', '', ''], // 14
        allItemsRadio: ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1'],
        subItemsRadio: [['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1']],
        agreeCheckboxList: []
      },
      cardThree: { // 예금주
        state: '2',
        isAllAgree: false,
        agreeRadioList: ['', '', '', '', '', '', '', '', '', '', '', '', '', ''], // 14
        allItemsRadio: ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1'],
        subItemsRadio: [['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1']],
        agreeCheckboxList: []
      },
      authTypes: [
        {key: '1', label: '휴대폰'},
        {key: '2', label: '신용카드'},
        //{key: '3', label: '신분증'}
      ],
      // authTypes2: [
      //   {key: '1', label: '휴대폰'}
      //   // {key: '2', label: '신용카드'}
      // ],
      authType1: [{key: '1', label: '휴대폰'}],
      authType2: [{key: '1', label: '휴대폰'}],
      authType3: [{key: '1', label: '휴대폰'}], // 예금주
      card1Later: false, // 계약자 인증 나중에하기 선택
      card2Later: false, // 피보험자 인증 나중에하기 선택
      card3Later: false, // 예금주 인증 나중에하기 선택
      card1Certify: false, // 계약자 인증 여부
      card2Certify: false, // 피보험자 인증 여부
      card3Certify: false, // 예금주 인증 여부
      userData1: {}, // 계약자 정보
      userData2: {}, // 피보험자 정보
      userData6: {}, // 예금주 정보
      //isOpenContractor: '',
      isOpenContractor1: false, // 계약자 본인인증
      isOpenContractor2: true, // 피보험자 본인인증
      isOpenContractor3: false, // 예금주 본인인증
      cardOneDisabledFlag: true, // 계약자 신원확인 및 본인인증 Disabled 여부 : true -> disabled
      contrElstYn: '', // TSSPS210M 에서 페이지 이동했을때 계약자 인증여부 Y/N
      insrdElstYn: '', // TSSPS210M 에서 페이지 이동했을때 피보험자 인증여부 Y/N
      dpstrElstYn: '', // 예금주인증여부 Y/N
      btnShowFlag: true, // TSSPS210M 에서 페이지 이동했을때 계약자/피보험자 중 1명만 인증 했을때 남은 1명은 무조건 인증을 받아야 함으로 인증하기/나중에하기 버튼을 숨김처리
      alertMessage: '',
      //alert: false,
      requiredAgreementContent: [
        {
          opened: true,
          title: '1. 수집 · 이용에 관한 사항',
          content: ''
        },
        {
          opened: true,
          title: '2. 제공에 관한 사항',
          content: ''
        },
        {
          opened: true,
          title: '3. [국외] 제공에 관한 사항',
          content: ''
        },
        {
          opened: true,
          title: '4. 조회에 관한 사항',
          content: ''
        },
        {
          opened: true,
          title: '5. 개인(신용)정보 동의 관련 추가확인 사항',
          content: ''
        },
        {
          opened: true,
          title: '전자적 방법에 의한 계약체결 동의',
          content: ''
        },
        {
          opened: true,
          title: '전자적 방법에 의한 보험계약서류<br>수령동의(선택)',
          content: ''
        }
      ]
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    // 1. 사용자 정보 : 계약자, 피보험자, 예금주
    this.userData1 = this.commData.userData1
    this.userData2 = this.commData.userData2
    this.userData6 = this.commData.dpstrInfo // 예금주 정보
    this.userData6.vuchId = this.userData1.vuchId

    // 2. 계피상이 여부 확인
    if (this.userData1.hasOwnProperty('insrdNm') && this.userData2.hasOwnProperty('insrdNm')) {
      if ( this.commData.unchYn === 'Y' ) {
        this.insuredPerson = false // 태아일때 인증완료 상태
      } else this.insuredPerson = true // 피보험자 유무 체크 - 계피상이
    } else {
      this.insuredPerson = false // 피보험자 유무 체크 - 계피동일
    }

    // 3. 예금주 단독여부
    if (this.userData6.dpstrIdpnYn === 'Y') { // 예금주독립여부 Y
      this.dpstrPerson = true
    } else {
      this.dpstrPerson = false
    }

    // 4. 계약자, 피보험자, 예금주의 전자서명완료 확인
    this.contrElstYn = this.commData.contrElstYn || 'N' // TSSPS210M 에서 넘어왔다면 Y/N값이 존재함
    this.insrdElstYn = this.commData.insrdElstYn || 'N' // TSSPS210M 에서 넘어왔다면 Y/N값이 존재함
    this.dpstrElstYn = this.commData.dpstrElstYn || 'N' // TSSPS210M 에서 넘어왔다면 Y/N값이 존재함 : 예금주전자서명완료

    // 5. 본인인증 완료한 고객 인증완료 처리
    // 5.1 초기 설정 : 계약자, 피보험자, 예금주
    this.cardOne.state = '2' // 인증하기
    this.card1Certify = false // 인증완료
    this.isOpenContractor1 = false // 본인인증Tab닫기

    this.cardTwo.state = '2' // 나중에하기
    this.card2Certify = false // 인증완료
    this.isOpenContractor2 = false // 본인인증Tab 열기

    this.cardThree.state = '2' // 인증하기
    this.card3Certify = false // 인증완료
    this.isOpenContractor3 = false // 본인인증Tab 닫기

    let elstCnt = 0 // 전자서명대상 Cnt

    // 5.1 피보험자
    if (this.insuredPerson) {
      if (this.insrdElstYn === 'Y') {
        this.cardTwo.state = '3'
        this.card2Certify = true
        this.isOpenContractor2 = false
        if (this.contrElstYn === 'N') { //계피상이 계약자 전자서명완료 미완료
          elstCnt++
          this.cardOne.state = '1' // 나중에하기
          this.card1Certify = false // 인증완료
          this.isOpenContractor1 = true // 본인인증Tab열기
        }
      } else {
        if (this.contrElstYn === 'Y') { // 계약자 전자서명완료
          this.cardTwo.state = '1' // 나중에하기
          this.isOpenContractor2 = true // 본인인증Tab열기
        }
        else if (this.contrElstYn === 'N') { //계피상이 계/피 전자서명완료 미완료
          elstCnt++
          this.cardTwo.state = '1' // 나중에하기
          this.card2Certify = false // 인증완료
          this.isOpenContractor2 = true // 본인인증Tab열기
        }
      }
    }

    // 5.2 계약자
    if (this.contrElstYn === 'Y') {
      this.cardOne.state = '3'
      this.card1Certify = true
      this.isOpenContractor1 = false
    } else { elstCnt++
      if (!this.insuredPerson) {  // 계/피 동일 계약자 전자서명완료 미완료
        this.cardOne.state = '1' // 나중에하기
        this.card1Certify = false // 인증완료
        this.isOpenContractor1 = true // 본인인증Tab열기
      }
    }

    // 5.3 예금주
    if (this.dpstrPerson) {
      if (this.dpstrElstYn === 'Y') {
        this.cardThree.state = '3'
        this.card3Certify = true
        this.isOpenContractor3 = false
      } else {
        elstCnt++
        if ((this.contrElstYn === 'Y' && !this.insuredPerson) || (this.contrElstYn === 'Y' && this.insrdElstYn === 'Y')) { // 계약자, 피보험자 전자서명완료
          // 계약자서명O, 예금주X  || 계약자서명O, 피보험자서명O
          this.cardThree.state = '1' // 나중에하기
          this.isOpenContractor3 = true // 본인인증Tab열기
        }
      }
    }

    // 5.4 버튼 숨기기
    if ( elstCnt > 1 ) {
      this.btnShowFlag = true
    } else {
      this.btnShowFlag = false
    }

    // 6. FC외에 모든 관계자 서명확인
    let fcOnlyElst = false
    if (this.contrElstYn === 'Y' && !this.insuredPerson && !this.dpstrPerson) {
      // 계약자 전자서명O, 피보험자X, 예금주X
      fcOnlyElst = true
    } else if (this.contrElstYn === 'Y' && this.insuredPerson && this.insrdElstYn === 'Y' && !this.dpstrPerson) {
      // 계약자 전자서명O, 피보험자O, 피보험 전자서명O, 예금주X
      fcOnlyElst = true
    } else if (this.contrElstYn === 'Y' && !this.insuredPerson && this.dpstrPerson && this.dpstrElstYn === 'Y') {
      // 계약자 전자서명O, 피보험자X, 예금주O, 예금주 전자서명O
      fcOnlyElst = true
    } else if (this.contrElstYn === 'Y' && this.insuredPerson && this.insrdElstYn === 'Y' && this.dpstrPerson && this.dpstrElstYn === 'Y') {
      // 계약자 전자서명O, 피보험자O, 피보험 전자서명O, 예금주O, 예금주 전자서명O
      fcOnlyElst = true
    }

    if (fcOnlyElst) {
      // FC서명만 남은 경우 계약자 인증을 활성화 함
      this.cardOne.state = '1' // 나중에하기
      this.card1Certify = false // 인증완료
      this.isOpenContractor1 = true // 본인인증Tab열기
    }

    let lv_Vm = this
    this.$commonUtil.selListEACommCd('ZA_CMUCO_SC_CD').then(function () {  
      lv_Vm.zaCmucoScCd = lv_Vm.getStore('code').getters.getState.get('ZA_CMUCO_SC_CD')

      lv_Vm.userData1.zaCmucoScCd = _.cloneDeep(lv_Vm.zaCmucoScCd)  //통신사 구분코드 (ZA_CMUCO_SC_CD)
      lv_Vm.userData2.zaCmucoScCd = _.cloneDeep(lv_Vm.zaCmucoScCd)
      lv_Vm.userData6.zaCmucoScCd = _.cloneDeep(lv_Vm.zaCmucoScCd)
    })
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    
    //this.loadComplete = true
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    isOpenContractor1 (newValue) {
      if (this.$refs.mspExpCard1 != undefined) {
        this.$refs.mspExpCard1.isExpand = newValue
        if (this.cardOne.state !== '3') {
          this.$emit('agree', this.cardOne.isAllAgree)
        }
        if (newValue) {
          PSCommUtil.fn_ScrollTopForScrollId(this.scrollId)
        }
      }
    },
    isOpenContractor2 (newValue) {
      if (this.$refs.mspExpCard2 != undefined) {
        this.$refs.mspExpCard2.isExpand = newValue
        if (this.cardTwo.state !== '3') {
          this.$emit('agree', this.cardTwo.isAllAgree)
        }
      }
    },
    isOpenContractor3 (newValue) {
      if (this.$refs.mspExpCard3 != undefined) {
        this.$refs.mspExpCard3.isExpand = newValue
        if (this.cardThree.state !== '3') {
          this.$emit('agree', this.cardThree.isAllAgree)
        }
      }
    },
    'cardOne.isAllAgree' (newValue) {
      if (newValue) {
        for ( let i = 1; i <= 5; i++ ) {
          this.$refs["mspExpCardOne"+i].isExpand = !newValue
        }
      }
      this.fn_TermsAllCheckControl(1)
    },
    'cardTwo.isAllAgree' (newValue) {
      if (newValue) {
        for ( let i = 1; i <= 5; i++ ) {
          this.$refs["mspExpCardTwo"+i].isExpand = !newValue
        }
      }
      this.fn_TermsAllCheckControl(2)
    },
    'cardThree.isAllAgree' (newValue) {
      if (newValue) {
        for ( let i = 1; i <= 5; i++ ) {
          this.$refs["mspExpCardThree"+i].isExpand = !newValue
        }
      }
      this.fn_TermsAllCheckControl(3)
    },
    // isOpenContractor3 (newValue) {
    //   this.$refs.mspExpCard3.isExpand = newValue

    //   this.$emit('agree', this.cardThree.isAllAgree)
    // },
    'cardOne.agreeRadioList' (newValue) {
      this.fn_TermsAllCheckControl(1)
    },
    'cardTwo.agreeRadioList' (newValue) {
      this.fn_TermsAllCheckControl(2)
    },
    'cardThree.agreeRadioList' (newValue) {
      this.fn_TermsAllCheckControl(3)
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_DoLater
    * 설명       : 인증 나중에하기 선택 시 컨트롤 함수
    ******************************************************************************/
    fn_DoLater (cardNumber) {
      // 1. 나중에 하기 조건 검증
      if ((cardNumber === 1 && !this.insuredPerson) || (cardNumber === 1 && this.card2Later) || (this.card1Later && cardNumber === 2)) {
        // 1.1 계약자, 피보험자 모두 나중에 하기는 불가
        if (this.dpstrPerson) {
          // 1.1.2 예금주가 있는 경우
          this.getStore('confirm').dispatch('ADD', '예금주 외 계약관계자 중 한명이상의<br />동의 및 인증이 선행되어야 합니다.')
          return false
        } else {
          // 1.1.3 예금주가 없는 경우
          this.getStore('confirm').dispatch('ADD', '계약자, 피보험자 중 1명의 동의 및 인증이 필수입니다.')
          return false
        }
      }

      // 2. 계약자 나중에 하기
      if (cardNumber === 1) {
        // 2.1 계약자 설정
        this.cardOne.state = '2'
        this.isOpenContractor1 = false
        this.card1Later = true
        this.fn_ContrAthntSave('init') // 계약자 인증정보 공백으로 업데이트

        if (this.insuredPerson && !this.card2Certify && !this.card2Later) {
          // 2.2 피보험자O, 피보험자_인증X, 피보험자_나중에하기X
          this.cardTwo.state = '1'
          this.isOpenContractor2 = true
        } else if ( this.dpstrPerson && !this.card3Certify && !this.card3Later) {
          // 2.3 예금주O, 예금주_인증X, 예금주_나중에하기X
          this.cardThree.state = '1'
          this.isOpenContractor3 = true
        } else {
          this.fn_MovePage('next')
        }
      }

      // 3. 피보험자 나중에 하기
      if (cardNumber === 2) {
        // 3.1 피보험자 설정
        this.cardTwo.state = '2'
        this.isOpenContractor2 = false
        this.card2Later = true
        this.fn_InsrdAthntSave('init') // 피보험자 인증정보 공백으로 업데이트

        if (this.card1Certify === false && this.card1Later === false) {
          // 3.2 계약자_인증X, 계약자_나중에하기X
          this.cardOne.state = '1' // 나중에하기
          this.isOpenContractor1 = true
        } else if ( this.dpstrPerson && this.card3Certify === false && this.card3Later === false) {
          // 3.1 예금주O, 예금주_인증X, 예금주_나중에하기X
          this.cardThree.state = '1'
          this.isOpenContractor3 = true
        } else {
          this.fn_MovePage('next')
        }
      }

      // 4.예금주
      if (cardNumber === 3) {
        // 4.1 예금주 설정
        this.cardThree.state = '2' // 인증하기
        this.isOpenContractor3 = false
        this.card3Later = true
        this.fn_DpstrAthntSave('init') // 예금주 인증수단 저장 : 공백

        if (this.card1Certify === false && this.card1Later === false) {
          // 4.1 계약자_인증X, 계약자_나중에하기X
          this.cardOne.state = '1' // 나중에하기
          this.isOpenContractor1 = true
        } else if (this.insuredPerson && this.card2Certify === false && this.card2Later === false && (this.card1Certify || this.card1Later)) {
          // 4.2 피보험자O, 피보험자_인증X, 피보험자_나중에하기X, (계약자_인증O or 계약자_나중에하기O)
          this.cardTwo.state = '1' // 나중에하기
          this.isOpenContractor2 = true
        } else {
          // 4.3 다음페이지 이동
          this.fn_MovePage('next')
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_DoCertify
    * 설명       : 인증하기 선택 시 컨트롤 함수
    ******************************************************************************/
    fn_DoCertify (cardNumber) {
      // 1. 계약자 인증하기
      if (cardNumber === 1) {
        // 1.1 계약자 인증
        this.cardOne.state = '1' // 나중에 하기
        this.isOpenContractor1 = true
        this.card1Later = false

        if (this.insuredPerson && this.card2Certify === false) {
          // 1.2.1 피보험자O, 피보험자_인증X
          this.cardTwo.state = '2'
          this.isOpenContractor2 = false
        }
        if (this.dpstrPerson && this.card3Certify === false) {
          // 1.2.2 예금주O, 예금주_인증X
          this.cardThree.state = '2'
          this.isOpenContractor3 = false
        }
      } else if (cardNumber === 2) {
        // 2. 피보험자 인증하기
        // 2.1 피보험자 인증
        this.cardTwo.state = '1' // 나중에하기
        this.isOpenContractor2 = true
        this.card2Later = false

        if (this.card1Certify === false) {
          // 2.2 계약자_인증X
          this.cardOne.state = '2' // 인증하기
          this.isOpenContractor1 = false
        }
        if (this.dpstrPerson && this.card3Certify === false) {
          // 2.3 예금주O, 예금주_인증X
          this.cardThree.state = '2' // 나중에하기
          this.isOpenContractor3 = false
        }
      } else if (cardNumber === 3) {
        // 3. 예금주 인증하기
        // 3.1 예금주 인증 가능여부 검증
        if ( this.insuredPerson ) {
          // 3.1.1 피보험자O
          if ( this.card1Certify === false && this.card2Certify === false) { // 계약자, 피보험자 모두 인증되지 않은 경우
            this.getStore('confirm').dispatch('ADD', '예금주 외 계약관계자 중 한명이상의<br />동의 및 인증이 선행되어야 합니다.')
            return false
          }
        } else {
          // 3.1.2 피보험자X
          if (this.card1Certify === false) { // 계약자가 인증되지 않은 경우
            this.getStore('confirm').dispatch('ADD', '예금주 외 계약관계자 중 한명이상의<br />동의 및 인증이 선행되어야 합니다.')
            return false
          }
        }

        // 3.2 예금주 인증
        this.cardThree.state = '1' // 나중에하기
        this.isOpenContractor3 = true
        this.card3Later = false

        // 3.3 계약자, 피보험자
        if (this.card1Certify === false) {
          // 3.3.1 계약자_인증 X
          this.cardOne.state = '2' // 인증하기
          this.isOpenContractor1 = false
        }
        if (this.dpstrPerson && this.card2Certify === false) {
          // 3.3.2 피보험자O, 피보험자_인증X
          this.cardTwo.state = '2' // 인증하기
          this.isOpenContractor2 = false
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_OnSelectAgreeAll
    * 설명       : 약관 전체동의 선택 시 컨트롤 함수
    ******************************************************************************/
    fn_OnSelectAgreeAll (cardNumber) {
      if (cardNumber === 1) {
        this.fn_OnSelectAgreeAll_Radio(cardNumber, this.cardOne.isAllAgree)
      //   this.cardOne.agreeCheckboxList = this.cardOne.isAllAgree ? this.cardOne.allItems : []
      //   this.cardOneDisabledFlag = !this.cardOne.isAllAgree
      } else if (cardNumber === 2) {
        this.fn_OnSelectAgreeAll_Radio(cardNumber, this.cardTwo.isAllAgree)
      //   this.cardTwo.agreeCheckboxList = this.cardTwo.isAllAgree ? this.cardTwo.allItems : []
      } else if (cardNumber === 3) {
        this.fn_OnSelectAgreeAll_Radio(cardNumber, this.cardThree.isAllAgree)
      //   this.cardThree.agreeCheckboxList = this.cardThree.isAllAgree ? this.cardThree.allItems : []
      }
    },
    /******************************************************************************
    * Function명 : fn_OnSelectAgreeAll_Radio
    * 설명       : 약관 전체동의 선택 시 컨트롤 함수
    ******************************************************************************/
    fn_OnSelectAgreeAll_Radio (cardNumber, chk) {
      let targetObj
      if (cardNumber === 1) {
        targetObj = this.cardOne
      }
      if (cardNumber === 2) {
        targetObj = this.cardTwo
      }
      if (cardNumber === 3) {
        targetObj = this.cardThree
      }

      let cnt = targetObj.allItemsRadio.length
      
      for ( let i = 0; i < cnt; i++ ) {
        if ( chk ) { //  동의함
          targetObj.agreeRadioList[i] = '1' // 동의
        } else {
          targetObj.agreeRadioList[i] = '0' // 동의안함
        }
      }
      
      if ( chk ) {
        targetObj.isAllAgree = true
        this.cardOneDisabledFlag = false
      } else {
        targetObj.isAllAgree = false
        this.cardOneDisabledFlag = true
      }
      
      this.$emit('agree', targetObj.isAllAgree)
    },
    /******************************************************************************
    * Function명 : fn_Terms4CheckControl
    * 설명       : 민감정보 및 고유식별정보 처리 약관 체크박스 컨트롤 함수
    ******************************************************************************/
    // fn_Terms4CheckControl (cardNumber, value) {
    //   let targetObj
    //   let tmpCnt1 = -1
    //   let tmpCnt2 = -1

    //   if (cardNumber === 1) {
    //     targetObj = this.cardOne.agreeCheckboxList
    //   } else {
    //     targetObj = this.cardTwo.agreeCheckboxList
    //   }

    //   if (value === 4 && (targetObj.indexOf('4') > -1)) {
    //     // 4번 약관 Checked
    //     if (targetObj.indexOf('7') === -1) {
    //       targetObj.push('7')
    //     }
    //     if (targetObj.indexOf('8') === -1) {
    //       targetObj.push('8')
    //     }
    //   } else if (value === 4 && (targetObj.indexOf('4') === -1)) {
    //     // 4번 약관 Unchecked
    //     tmpCnt1 = targetObj.indexOf('7')
    //     if (tmpCnt1 > -1) {
    //       targetObj.splice(tmpCnt1, 1)
    //     }
    //     tmpCnt2 = targetObj.indexOf('8')
    //     if (tmpCnt2 > -1) {
    //       targetObj.splice(tmpCnt2, 1)
    //     }
    //   }
    // },
    /******************************************************************************
    * Function명 : fn_TermsAllCheckControl
    * 설명       : 전체동의 약관 체크박스 컨트롤 함수 // 2021.05.31_pjo
    ******************************************************************************/
    fn_TermsAllCheckControl (cardNumber) {
      let lv_Vm = this
      let targetObj
      console.log('fn_TermsAllCheckControl.cardNumber:' + cardNumber)
      if (cardNumber === 1) {
        targetObj = this.cardOne
        // 2021.05.31_pjo
        let cnt = this.cardOne.allItemsRadio.length
        let flag = true
        let chk14 = this.cardOne.agreeRadioList[14] // 14번.전자적서류 교부방법

        /* 중간선택 추가 */
        for ( let i = 0; i < targetObj.subItemsRadio.length; i++ ) {
          flag = true
          for ( let j = 0; j < targetObj.subItemsRadio[i].length; j++ ) {
            if ( targetObj.agreeRadioList[i*3+j] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
              flag = false
              break
            }
          }
          let agreeCheckboxPos = (i + 1).toString()
          if ( flag ) {
            if (targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos) < 0) {
              targetObj.agreeCheckboxList.push(agreeCheckboxPos)
            }
          }
          else {
            if (targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos.toString()) > -1 ) {
              targetObj.agreeCheckboxList.splice(targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos), 1)
            }
          }
        }
        /* 중간선택 추가 END */

        for ( let i = 0; i < cnt - 1; i++ ) { // 14별도체크
          if ( this.cardOne.agreeRadioList[i] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
            flag = false
            break
          }
        }

        if (chk14 === '1' && flag ) { // 모두 동의
          targetObj.isAllAgree = true
          this.cardOneDisabledFlag = false
        } else if (chk14 !== '1' && flag ) { // 14 미동의, 그외 모두 동의
          targetObj.isAllAgree = true
          this.cardOneDisabledFlag = false
        } else {
          targetObj.isAllAgree = false
          this.cardOneDisabledFlag = true
        }
        // ASR200700501 전자적서류 교부방법 체크로직(2020.08.04 PJO)
        this.fn_chk()
      } else if (cardNumber === 2) {
        targetObj = this.cardTwo
        let cnt = this.cardTwo.allItemsRadio.length
        let flag = true

        /* 중간선택 추가 */
        for ( let i = 0; i < targetObj.subItemsRadio.length; i++ ) {
          flag = true
          for ( let j = 0; j < targetObj.subItemsRadio[i].length; j++ ) {
            if ( targetObj.agreeRadioList[i*3+j] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
              flag = false
              break
            }
          }
          let agreeCheckboxPos = (i + 1).toString()
          if ( flag ) {
            if (targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos) < 0) {
              targetObj.agreeCheckboxList.push(agreeCheckboxPos)
            }
          }
          else {
            if (targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos.toString()) > -1 ) {
              targetObj.agreeCheckboxList.splice(targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos), 1)
            }
          }
        }
        /* 중간선택 추가 END */
        
        for ( let i = 0; i < cnt; i++ ) {
          if ( this.cardTwo.agreeRadioList[i] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
            flag = false
            break
          }
        }
        if ( flag ) {
          targetObj.isAllAgree = true
          this.cardOneDisabledFlag = false
        } else {
          targetObj.isAllAgree = false
          this.cardOneDisabledFlag = true
        }
      } else if (cardNumber === 3) {
        targetObj = this.cardThree
        let cnt = this.cardThree.allItemsRadio.length
        let flag = true

        /* 중간선택 추가 */
        for ( let i = 0; i < targetObj.subItemsRadio.length; i++ ) {
          flag = true
          for ( let j = 0; j < targetObj.subItemsRadio[i].length; j++ ) {
            if ( targetObj.agreeRadioList[i*3+j] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
              flag = false
              break
            }
          }
          let agreeCheckboxPos = (i + 1).toString()
          if ( flag ) {
            if (targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos) < 0) {
              targetObj.agreeCheckboxList.push(agreeCheckboxPos)
            }
          }
          else {
            if (targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos.toString()) > -1 ) {
              targetObj.agreeCheckboxList.splice(targetObj.agreeCheckboxList.indexOf(agreeCheckboxPos), 1)
            }
          }
        }
        /* 중간선택 추가 END */

        for ( let i = 0; i < cnt; i++ ) {
          if ( this.cardThree.agreeRadioList[i] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
            flag = false
            break
          }
        }
        if ( flag ) {
          targetObj.isAllAgree = true
          this.cardOneDisabledFlag = false
        } else {
          targetObj.isAllAgree = false
          this.cardOneDisabledFlag = true
        }
      }
      this.$emit('agree', targetObj.isAllAgree)
    },
    /******************************************************************************
    * Function명 : fn_AuthFinish
    * 설명       : 인증완료 컨트롤 함수
    ******************************************************************************/
    fn_AuthFinish (personType) {
      // personType 이 11,12,13 이라면 인증패스로 들어온 것으로 판단
      if (personType < 10) { // 정상인증
        this.fn_InsMoblAthnt(personType) // 인증기록 저장
      } else if (personType > 10) { // 인증패스
        personType = (personType - 10)
      }
      if (personType === 1) {
        // ASR200700501 전자적서류 교부방법 체크로직(2020.08.04 PJO)
        if ( !this.fn_chk() ) return
        console.log('----------------------------fn_AuthFinish 진행여부 체크 -----------------------------------------------')
        // 1. 계약자 인증완료
        this.card1Certify = true // 인증여부
        this.cardOne.state = '3' // 동의완료
        this.isOpenContractor1 = false
        this.fn_ContrAthntSave() // 계약자 인증 정보 저장 함수
        this.fn_AuthResultSave() // 청약접수안내 LMS대상여부 DB 수정
        if (this.dpstrPerson && !this.card3Certify && !this.card3Later) {
          // 1.2 예금주O, 예금주_인증X, 예금주_나중에하기X
          this.fn_DoCertify(3)
        } else if (this.insuredPerson && !this.card2Certify && !this.card2Later) {
          // 1.1 피보험자O,피보험자_인증X, 피보험자_나중에하기X
          this.fn_DoCertify(2)
        } else {
          this.fn_MovePage('next')
        }
      } else if (personType === 2) {
        // 2. 피보험자 인증완료
        this.card2Certify = true // 인증여부
        this.cardTwo.state = '3' // 동의완료
        this.isOpenContractor2 = false
        this.fn_InsrdAthntSave() // 피보험자 인증 정보 저장 함수

        if (!this.card1Certify && !this.card1Later) {
          // 2.2 계약자_인증X, 계약자_나중에하기X
          this.fn_DoCertify(1)
        } else if (this.dpstrPerson && !this.card3Certify && !this.card3Later) {
          // 2.1 예금주O, 예금주_인증X, 예금주_나중에하기X
          this.fn_DoCertify(3)
        } else {
          this.fn_MovePage('next')
        }
      } else if (personType === 6) {
        // 3. 예금주 인증완료
        this.card3Certify = true // 인증여부
        this.cardThree.state = '3' // 동의완료
        this.isOpenContractor3 = false
        this.fn_DpstrAthntSave() // 예금주 인증 정보 저장 함수

        if (this.insuredPerson && !this.card2Certify && !this.card2Later) {
          // 3.2 피보험자O,피보험자_인증X, 피보험자_나중에하기X
          this.fn_DoCertify(2)
        } else if (!this.card1Certify && !this.card1Later) {
          // 3.1 계약자_인증X, 계약자_나중에하기X
          this.fn_DoCertify(1)
        } else {
          this.fn_MovePage('next')
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_InsMoblAthnt
    * 설명       : 인증 기록 저장 함수
    ******************************************************************************/
    fn_InsMoblAthnt (personType) {
      let pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11I1'))
      pParams.data = {}
      pParams.srnId = this.$options.screenId // 대표 화면 명
      pParams.data.moblBusnScCd = this.commData.inputData.moDCDocuMngVO.moblBusnScCd // 모바일업무구분코드(SE:청약)
      pParams.data.eltrnDoctScCd = '01' // 전자문서구분코드 01:청약서 고정
      pParams.data.eltrnDoctId = this.commData.inputData.moDCDocuMngVO.eltrnDoctId // 전자문서ID
      pParams.data.athntYmd = PSDateUtil.fn_CurrentDate() // 인증일자(오늘) - 서버에서 받아와야함
      pParams.data.athntUsScCd = !this.insuredPerson ? '01' : '03' // 인증용도구분코드  01:전자서명동의/02:정보활용동의/03:계피상의동의/04:수익자상의동의
      pParams.data.rqstrEno = PSServiceManager.getUserInfo('userId') || ' ' // 로그인 사번
      pParams.data.predMoblBusnScCd = this.commData.inputData.moDCDocuMngVO.moblBusnScCd // 모바일업무구분코드(SE:청약)
      pParams.data.predEltrnDoctScCd = '01' // 전자문서구분코드 01:청약서 고정
      pParams.data.predEltrnDoctId = this.commData.inputData.moDCDocuMngVO.eltrnDoctId // 전자문서ID
      pParams.data.predAthntYmd = PSDateUtil.fn_CurrentDate() // 인증일자(오늘) - 서버에서 받아와야함

      if (personType === 1) { // 계약자 인증
        // pParams.data.athntMthScCd = this.authType1[0].key === '1' ? '01' : '02' // 인증방법구분코드 01:휴대폰/02:신용카드
        // 190930 : 인증방법추가 : 신분증
        pParams.data.athntMthScCd = this.authType1[0].key === '1' ? '01' : this.authType1[0].key === '2' ? '02' : '03' // 인증방법구분코드 01:휴대폰/02:신용카드/03:신분증
        if (this.insuredPerson) { // 계피상이
          pParams.data.athntSignRltnrScCd = '02' // 인증서명관계자구분코드, 01:계피동일계약자동의/02:계피상이계약자동의/03:계피상이피보험자동의/04:수익자상이계약자동의/05:연금수익자동의/06:연금후순위수익자동의/07:만기수익자동의/08:상해수익자동의/09:사망수익자동의
        } else { // 계피동일
          pParams.data.athntSignRltnrScCd = '01' // 인증서명관계자구분코드, 01:계피동일계약자동의
        }
      } else if (personType === 2) { // 피보험자 인증
        // pParams.data.athntMthScCd = this.authType2[0].key === '1' ? '01' : '02' // 인증방법구분코드 01:휴대폰/02:신용카드
        // 190930 : 인증방법추가 : 신분증
        pParams.data.athntMthScCd = this.authType2[0].key === '1' ? '01' : this.authType2[0].key === '2' ? '02' : '03' // 인증방법구분코드 01:휴대폰/02:신용카드/03:신분증
        if (this.insuredPerson) { // 계피상이
          pParams.data.athntSignRltnrScCd = '03' // 03:계피상이피보험자동의
        }
      } else if (personType === 6) { // 예금주 인증
        pParams.data.athntMthScCd = this.authType3[0].key === '1' ? '01' : this.authType3[0].key === '2' ? '02' : '03' // 인증방법구분코드 01:휴대폰/02:신용카드/03:신분증
        pParams.data.athntSignRltnrScCd = '10' // 인증서명관계자구분코드, 10:예금주동의
      }
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, '', this.fn_ServiceFaultHandler)
    },

    /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler (event) {
      if (event.data.wkRslt && event.data.wkRslt === 1) {
        // 성공
      } else {
        // 실패
        //alert("인증기록이 실패하였습니다. 로그오프되었습니다.")
      }
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler (event) {
      // this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },
    /******************************************************************************
    * Function명 : fn_AuthResultSave
    * 설명       : 청약접수안내 LMS대상여부 수정 함수
    ******************************************************************************/
    fn_AuthResultSave () {
      let pParams = {}
      pParams.aenam = PSServiceManager.getUserInfo('userId') || ' ' // 변경자id
      pParams.vuchId = this.commData.inputData.moDCDocuMngVO.eltrnDoctId
      if (this.cardOne.agreeRadioList[14] === '1') { // 전자적 방법에 의한 보험계약서류 수령동의 약관에 체크되어있을 경우
        pParams.ofrAcpnGudnLmsObjYn = 'Y' // 청약접수안내LMS대상여부
        let e = this.commData.inputData.moDCDocuMngVO.emailAddr || ''
        let c = this.commData.inputData.moDCDocuMngVO.celno || ''
        // 로직보완_ASR200700501 전자적서류 교부방법 체크로직(2020.08.04 PJO)
        // (휴대폰 없는경우, 6.전자적방법 동의 할수없다. 즉,이 로직을 탈 수 없지만 기존로직 잘못된 부분 수정)
        if (c.trim().length === 0 && e.trim().length === 0) {
          pParams.isuMthScCd = '02' // 교부방법구분코드 - 02 서면 // 전화번호, 이메일 모두 없을때
        } else if (c.trim().length > 0 && e.trim().length > 0) {
          pParams.isuMthScCd = '03' // 교부방법구분코드 - 03 모바일(e) / 전화번호, 이메일 모두 있을때
        } else if (c.trim().length === 0 && e.trim().length > 0) {
          pParams.isuMthScCd = '01' // 교부방법구분코드 - 01 email / 전화번호없고 이메일만 있을때
        } else if (c.trim().length > 0 && e.trim().length === 0) {
          pParams.isuMthScCd = '04' // 교부방법구분코드 - 04 모바일 / 전화번호있고 이메일 없을때
        }
      } else {
        pParams.ofrAcpnGudnLmsObjYn = 'N' // 청약접수안내LMS대상여부
        pParams.isuMthScCd = '02' // 교부방법구분코드 - 서면
      }
      console.log('---------------------121D-----------------------------3.> ofrAcpnGudnLmsObjYn:[' + this.ofrAcpnGudnLmsObjYn + ']')
      let trnstId = 'txTSSPS99U1' // TTSD00002.ISU_MTH_SC_CD, OFR_ACPN_GUDN_LMS_OBJ_YN 업데이트
      this.post(this, pParams, trnstId, 'U')
        .then(function (response) {
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_ContrAthntSave
    * 설명       : 계약자 인증 정보 저장 함수 'C':휴대폰 , 'M':신용카드
    *            : 190930 : 인증방법 추가 : 'I':신분증
    ******************************************************************************/
    fn_ContrAthntSave (v) {
      let pParams = {
        aenam: PSServiceManager.getUserInfo('userId') || ' ', // 변경자id
        // contrAthntMethCd: (this.authType1[0].key === '1') ? 'C' : 'M',
        // 190930 : 인증방법 추가 : 'I':신분증
        contrAthntMethCd: (this.authType1[0].key === '1') ? 'C' : (this.authType1[0].key === '2') ? 'M' : 'I',
        vuchId: this.commData.inputData.moDCDocuMngVO.eltrnDoctId
      }
      let trnstId = 'txTSSPS98U9'
      if (v === 'init') { // 210M에서 넘어왔고 나중에하기 선택 시 공백으로 업데이트
        pParams.contrAthntMethCd = ' '
      }
      this.post(this, pParams, trnstId, 'U')
        .then(function (response) {
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_InsrdAthntSave
    * 설명       : TTSD00001.insrdAthntMethCd / 피보험자 인증 정보 저장 함수 'C':휴대폰 , 'M':신용카드
    *            : 190930 : 인증방법 추가 : 'I':신분증
    ******************************************************************************/
    fn_InsrdAthntSave (v) {
      let pParams = {
        aenam: PSServiceManager.getUserInfo('userId') || ' ', // 변경자id
        // insrdAthntMethCd: (this.authType2[0].key === '1') ? 'C' : 'M',
        // 190930 : 인증방법 추가 : 'I':신분증
        insrdAthntMethCd: (this.authType2[0].key === '1') ? 'C' : (this.authType2[0].key === '2') ? 'M' : 'I',
        vuchId: this.commData.inputData.moDCDocuMngVO.eltrnDoctId
      }
      let trnstId = 'txTSSPS98U10'
      if (v === 'init') { // 210M에서 넘어왔고 나중에하기 선택 시 공백으로 업데이트
        pParams.insrdAthntMethCd = ' '
      }
      this.post(this, pParams, trnstId, 'U')
        .then(function (response) {
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_DpstrAthntSave
    * 설명       : 예금주 인증 정보 저장 함수 'C':휴대폰 , 'M':신용카드, 'I':신분증
    ******************************************************************************/
    fn_DpstrAthntSave (v) {
      let pParams = {
        aenam: PSServiceManager.getUserInfo('userId') || ' ', // 변경자id
        dpstrAthntMethCd: (this.authType3[0].key === '1') ? 'C' : (this.authType3[0].key === '2') ? 'M' : 'I',
        vuchId: this.commData.inputData.moDCDocuMngVO.eltrnDoctId
      }
      let trnstId = 'txTSSPS98UB'
      if (v === 'init') { // 210M에서 넘어왔고 나중에하기 선택 시 공백으로 업데이트
        pParams.dpstrAthntMethCd = ' '
      }
      this.post(this, pParams, trnstId, 'U')
        .then(function (response) {
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_MovePage
    * 설명       : 페이지 이동 함수
    ******************************************************************************/
    fn_MovePage (key) {
      if ( this.commData.dpstrInfo.contrDpstrSameYn === 'Y') {
        // 계약자 = 예금주
        this.card3Certify = this.card1Certify
        this.authType3[0].label = this.authType1[0].label
      } else if ( this.commData.dpstrInfo.insrdDpstrSameYn === 'Y') {
        // 피보험자 = 예금주
        this.card3Certify = this.card2Certify
        this.authType3[0].label = this.authType2[0].label
      }

      this.$emit('move', key, {
        'contractorCertify': this.card1Certify, // 계약자 인증
        'insuredCertify': this.card2Certify, // 피보험자 인증
        'dpstrCertify': this.card3Certify, // 예금주 인증
        'contractorAuthType': this.authType1[0].label,
        'insuredAuthType': this.authType2[0].label,
        'dpstrAuthType': this.authType3[0].label
      })
    },
    /******************************************************************************
    * Function명 : fn_chk
    * 설명       : 6번 전자적 수령 동의 Y 체크한경우  휴대폰정보 없으면 에러팝업
    * ASR200700501 전자적서류 교부방법 체크로직(2020.08.04 PJO)
    ******************************************************************************/
    fn_chk () {
      // let c = this.commData.inputData.moDCDocuMngVO.celno || ''
      // this.commData.inputData.moDCDocuMngVO.celno = '' // test
      let c = ''
      if ( this.commData.inputData.moDCDocuMngVO.celno != null ) {
        c = this.commData.inputData.moDCDocuMngVO.celno.trim()
      }
      if (this.cardOne.agreeRadioList[14] === '1' && c.trim().length === 0 ) {
        this.alertMessage = '휴대폰 번호 등록되지 않은 고객은<br />"전자적 방법에 의한 보험계약서류 수령동의"<br />선택이 불가합니다'
        this.getStore('confirm').dispatch('ADD', this.alertMessage)
        return false
      }
      return true
    },
    /******************************************************************************
    * Function명 : fn_ClickedCheck
    * 설명       : 중분류 제목별 동의함, 동의안함 선택 시
    ******************************************************************************/
    fn_ClickedCheck (cardNumber, v) {
      let targetObj
      
      if (cardNumber === 1) {
        targetObj = this.cardOne
      }
      if (cardNumber === 2) {
        targetObj = this.cardTwo
      }
      if (cardNumber === 3) {
        targetObj = this.cardThree
      }
      if (targetObj.agreeCheckboxList.includes((v + 1).toString())) {
        if (v === 0) {
          targetObj.agreeRadioList[0] = '1' // 동의
          targetObj.agreeRadioList[1] = '1' // 동의
          targetObj.agreeRadioList[2] = '1' // 동의
        } else if (v === 1) {
          targetObj.agreeRadioList[3] = '1' // 동의
          targetObj.agreeRadioList[4] = '1' // 동의
          targetObj.agreeRadioList[5] = '1' // 동의
        } else if (v === 2) {
          targetObj.agreeRadioList[6] = '1' // 동의
          targetObj.agreeRadioList[7] = '1' // 동의
          targetObj.agreeRadioList[8] = '1' // 동의
        } else if (v === 3) {
          targetObj.agreeRadioList[9] = '1' // 동의
          targetObj.agreeRadioList[10] = '1' // 동의
          targetObj.agreeRadioList[11] = '1' // 동의
        } else if (v === 4) {
          targetObj.agreeRadioList[12] = '1' // 동의
          targetObj.agreeRadioList[13] = '1' // 동의
          if (cardNumber === 1) {
            targetObj.agreeRadioList[14] = '1' // 동의
          }
        }
      } else {
        if (v === 0) {
          targetObj.agreeRadioList[0] = '0' // 동의안함
          targetObj.agreeRadioList[1] = '0' // 동의안함
          targetObj.agreeRadioList[2] = '0' // 동의안함
        } else if (v === 1) {
          targetObj.agreeRadioList[3] = '0' // 동의안함
          targetObj.agreeRadioList[4] = '0' // 동의안함
          targetObj.agreeRadioList[5] = '0' // 동의안함
        } else if (v === 2) {
          targetObj.agreeRadioList[6] = '0' // 동의안함
          targetObj.agreeRadioList[7] = '0' // 동의안함
          targetObj.agreeRadioList[8] = '0' // 동의안함
        } else if (v === 3) {
          targetObj.agreeRadioList[9] = '0' // 동의안함
          targetObj.agreeRadioList[10] = '0' // 동의안함
          targetObj.agreeRadioList[11] = '0' // 동의안함
        } else if (v === 4) {
          targetObj.agreeRadioList[12] = '0' // 동의안함
          targetObj.agreeRadioList[13] = '0' // 동의안함
          if (cardNumber === 1) {
            targetObj.agreeRadioList[14] = '0' // 동의안함
          }
        }
      }

      this.fn_TermsAllCheckControl(cardNumber)
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>