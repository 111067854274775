/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS423D
 * 화면 설명: 전자서명 - 계좌선택
 */
<template> 
  <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="pb50">

    <!-- 보험료납입계좌 -->
    <msp-expand ref="mspExp1" expanded expandOnlyBtn btn-area-first class="mo-list-expand agree-expand" btn-area-class="firstdiv-full pt5 pb5"  > 
      <template #btn>
        <div class="fexTy3">
          <span class="fs19rem flex-1 fwb">보험료납입계좌</span>
          <span class="fs16rem ml20 crTy-bk7" v-if="currentAccountIdx !== undefined || currentCardIdx !== undefined"><mo-icon icon="msp-is-checked" class="fs28" />확인완료</span> <!-- 퍼블 추가  -->
          <mo-button class="link-arrow down"></mo-button>
        </div>
      </template>
      <template #content>
        <div class="pal3024 bd-b-Ty1">
          <!-- 계좌 --> 
          <div v-for="(accountItem, index) in accountList" :key="accountItem.accnId" class="text-gray-box text-gray-box__add__radio txt-center" :class="index === currentAccountIdx ? 'bgcolor-6' : 'bgcolor-1'">
            <div class="ns-radio-list flex">
              <mo-radio class="bgwhite" v-model="accountCheck" :value="String(index)" @input="fn_AccountClick(index)">
              <div>
                <span class="dsD fs16rem ml0 pb5">{{accountItem.bnkCdNm}}</span>
                <span class="dsD fs16rem ml0 crTy-blue4">{{accountItem.accnNo}}<b class="fwn ml10">{{accountItem.dpstrNm}}</b></span>
              </div>
              </mo-radio>
            </div>
          </div>
          <!-- 신용카드 (ASR240400333_24.05 장한나) -->
          <div v-for="(cardItem, index) in cardList" :key="cardItem.cardId" class="text-gray-box text-gray-box__add__radio txt-center" :class="index === currentCardIdx ? 'bgcolor-6' : 'bgcolor-1'">
            <div class="ns-radio-list flex">
              <mo-radio class="bgwhite" v-model="cardCheck" :value="String(index)" @input="fn_CardClick(index)">
              <div>
                <span class="dsD fs16rem ml0 pb5">{{cardItem.cardTypeNm}}</span>
                <span class="dsD fs16rem ml0 crTy-blue4">{{cardItem.cardNum}}<b class="fwn ml10">{{cardItem.stampNm}}</b></span>
              </div>
              </mo-radio>
            </div>
          </div>
          <mo-button class="ns-btn-round h40 rounded mt20 white w100 fs16rem" @click.stop="fn_OpenNewAccountReq" v-show="addAccnInfo">새로운 계좌 추가</mo-button>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0" v-show="addAccnInfo===false">  <!-- 신규계좌 입력불가시간 안내메시지 추가 (ASR240600514 / 240709) -->
            <div class="ns-certify-sed error">
              <div class="ns-certify-sed-txt">
                <div class="txt-error"><br>※ 신규계좌등록은 07:30 이후부터 가능합니다. (현장접수는 08:30이후 가능)</div>
              </div>
            </div>
          </ur-box-container>
          <mo-button class="ns-btn-round h40 rounded mt20 white w100 fs16rem" @click.stop="fn_OpenNewCardReq" v-show="CARD_YN_CHK==='Y'">새로운 카드 추가</mo-button>
          <ul class="terms-list-area crTy-bk7 fs14rem mt30">
            <li>실시간출금이체가능 금융기관 : 경남ᆞ광주 ᆞ국민 ᆞ기업 ᆞ농협 ᆞ아이엠뱅크 ᆞ부산 ᆞ신한 ᆞ외환 ᆞ우리 ᆞ우체국 ᆞ전북 ᆞSC ᆞ제주 ᆞ하나 ᆞ씨티 ᆞ삼성증권 ᆞ유안타증권 ᆞ새마을금고 ᆞ신협 ᆞ수협 ᆞ카카오뱅크</li>
            <li>실시간출금이체불가능 금융기관 :  산업 ᆞ축협 ᆞ상호저축 ᆞHSBC ᆞ도이치 ᆞ평화 ᆞ전 증권사(삼성증권, 유안타증권은 가능)</li>
          </ul>
        </div>
      </template>
    </msp-expand>
    <!-- 보험료납입계좌 end -->

    
    <!-- 자동이체 및 실시간 출금이체 신청 -->
    <msp-expand ref="mspExp2" expandOnlyBtn btn-area-first class="mo-list-expand agree-expand" btn-area-class="firstdiv-full pt5 pb5"  > 
      <template #btn>
        <div class="fexTy3">
          <span class="fs19rem fwb">자동이체 및 실시간 출금이체 신청</span>
          <mo-button class="link-arrow down"></mo-button>
        </div>
      </template>
      <template #content>
        <div class="pl24 pt10 pb10 bgcolor-1 fs14rem">
          <span class="fwb">제1회(부활)</span>
          <span class="crTy-bk7 ml6">보험료 실시간 출금이체</span>
        </div>
        <!--div class="pal2024">
          <span class="dsD fs18rem fwm must">신청여부</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
            <mo-segment-wrapper class="chip-type-wrap chip-ty1 one-ea" solid primary v-model="returnData1">
              <mo-segment-button v-for="(item, idx) in segmentData1" :value="item.key" :key="idx" :disabled="item.key === 'F' && !isKakaopay">{{item.label}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </div -->
        <div class="pal2024"> <!-- 신카납방 START (ASR240400333_24.05 장한나)-->
          <span class="dsD fs18rem fwm must">신청여부</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
            <mo-segment-wrapper solid primary v-model="returnData1" class="chip-type-wrap chip-w50"><!-- // class변경: chip-ty1 one-ea -> chip-w50 2024.04.15 -->
              <mo-segment-button v-for="(item, idx) in segmentData1" :value="item.key" :key="idx" :disabled="(item.key === 'F' && !isKakaopay)">{{item.label}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <!-- 주의안내 문구 추가 2024.04.15 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0">
            <div class="ns-certify-sed error"><!-- // error class 추가로 상태변환 -->
              <div class="ns-certify-sed-txt">
                <div class="txt-error">신청여부항목은 필수 정보입니다.</div>
              </div>
            </div>
          </ur-box-container>
        </div> <!-- 신카납방 END -->
        <div class="pl24 pt10 pb10 bgcolor-1 fs14rem">
          <span class="fwb">제2회 이후</span>
          <span class="crTy-bk7 ml6">보험료 납입 방법 선택</span>
        </div>
        <div class="pal2024">
          <div class="fexTy3">
            <div class="text-gray-box mr5 bgcolor-3">
              <div class="w100 fs16rem fwm txt-center">납입방법</div>
              <div class="w100 fs18rem fwb txt-center mt5">{{paymentTxt}}</div>
              <ur-box-container v-show="false" alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper class="chip-type-wrap chip-ty1" solid primary v-model="returnData2">
                  <mo-segment-button v-for="(item, idx) in segmentData2" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
            </div>
            <div class="text-gray-box ml5 bgcolor-3">
              <div class="w100 fs16rem fwm txt-center">이체대상</div>
              <div class="w100 fs18rem fwb txt-center mt5">보험료</div>
              <ur-box-container v-show="false" alignV="start" componentid="" direction="column" class="ns-segment mt10">
                <mo-segment-wrapper class="chip-type-wrap chip-ty1" solid primary v-model="returnData3">
                  <mo-segment-button v-for="(item, idx) in segmentData3" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                </mo-segment-wrapper>
              </ur-box-container>
            </div>
          </div>
          <span class="dsD mt20 fs18rem fwm must">이체희망일</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
            <mo-segment-wrapper class="chip-type-wrap chip-ty1" solid primary v-model="returnData4" :key="idx1">
              <mo-segment-button v-for="(item, idx) in segmentData4" :key="idx" :value="item.key" :disabled="item.key !== '20' && returnData1 === '9'">{{item.label}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <ul class="terms-list-area crTy-bk7 fs14rem mt10">
            <li>실시간 출금이체란 예금주가 전화, 방문 등의 방법으로 보험료(이자) 이체를 신청하는 경우 실시간으로 출금이체하는 것을 말합니다. 다만 2회 이후 보험료는 계약자와 예금주가 같은 경우에만 가능합니다.</li>
            <li>보험계약이 가입거절될 경우 선택하신 계약자의 계좌로 보험료가 반환됩니다.</li>
            <li>청약 유형에 따라 보험료 납입 가능 방법이 제한될 수 있습니다.</li>
            <li v-show="CARD_YN_CHK==='Y'">카드이체의 경우 승인일은 매월 20일(영업일 기준)이며, 카드승인일이 토요일 및 공휴일인 경우 익영업일에 승인됩니다.</li>
          </ul>
          <template v-if="viewFlag">
            <span class="dsD fs18rem fwm must mt50 ls--20">분할ᆞ배당ᆞ연금/휴면 보험금 자동 송금 신청 </span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
              <mo-segment-wrapper class="chip-type-wrap chip-ty1" solid primary v-model="returnData5">
                <mo-segment-button v-for="(item, idx) in segmentData5" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
            <ul class="terms-list-area crTy-bk7 fs14rem mt10">
              <li>계약자에게 지급된(분할ᆞ배당ᆞ연금/휴면보험금)금액 발생시 별도 통보 없이 신청하신 자동이체 계좌로 입금됩니다. 다만, 계약자와 예금주가 같은 경우에만 가능합니다.</li>
              <li>단, 보험계약 약관 등에 따라 별도의 신청(연금지급개시신청 등)이나 청구가 필요한 경우에는 자동송금이 불가합니다.</li>
            </ul>
          </template>
        </div>   
      </template>
    </msp-expand>
    <!-- 자동이체 및 실시간 출금이체 신청 end -->
    <!-- Content 영역 end -->

    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_BottomConfirm('이전 화면으로 이동하면 입력한 정보가 초기화 됩니다.<br />이동하시겠습니까?')">이전</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click.stop="nextLevel" :disabled="nextBtnFlag">다음</mo-button>
        </div>
      </ur-box-container>
    </template>

  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import moment from 'moment'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import MSPPS424P from '@/ui/ps/MSPPS424P' // 새로운계좌추가
import MSPPS426P from '@/ui/ps/MSPPS426P' // 새로운카드추가

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS423D', 
  screenId: 'MSPPS423D',

  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    commData: {type: Object} // 계약자정보
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return { 
      planInfo: {},
      userData1: {},
      inputData: {},
      returnData1ValidateCmpltFlag: true,
      returnData2ValidateCmpltFlag: true,
      returnData3ValidateCmpltFlag: false,
      returnData4ValidateCmpltFlag: false,
      returnData5ValidateCmpltFlag: false,
      nextBtnFlag: true,
      addAccnInfo: false, // 신규계좌추가 버튼 Enable(초기값변경 true->false , 20200617 PJO)
      viewFlag: true, // 자동송금신청 화면(즉시연금상품일시 true) Flag - 상품데이터 연동후 적용
      atRmtnAplYn: false, // 자동송금여부 가능여부 (RT :가능 /가상계좌:불가  , 2021.08.17 PJO)
      //newAccountChkFlag: false,
      bankList: [],
      unuseBankList: [],
      accountChkData: {}, // 계좌 검증데이터
      txTSSPS13S4Data: {},
      accountList: [], // 가상계좌 제거 계좌 리스트
      psBefrInptInfoSVO: {}, // 계약자 정보 신태블릿(자체) 테이블 업데이트
      alertMessage: '',
      currentAccountIdx: undefined,
      tempCurrentAccountIdx: undefined,
      segmentData1: [],
      // segmentData1Mock1: [{
      //   'key': '1',
      //   'label': '실시간 출금이체'
      // },
      // {
      //   'key': '4',
      //   'label': '가상계좌 이체'
      // }],
      segmentData1Mock3: [{
        'key': '1',
        'label': '실시간 출금이체'
      }],
      segmentData1Mock4: [{
        'key': '1',
        'label': '실시간 출금이체'
      },
      {
        'key': '4',
        'label': '가상계좌 이체'
      },
      {
        'key': 'F',
        'label': '카카오 페이'
      }
      ],
      isKakaopay: false,
      returnData1: null,
      segmentData2: [{ // {'key': 'N','label': '급여이체'} 4/9 박성배선임 요청 삭제
        'key': 'Y',
        'label': '자동이체'
      }],
      returnData2: null, //신청
      // ASR200900314 사랑온 자동이체 보험료 납입방법 변경 제2회 이후 보험료 납입방법 선택 중 이체 대상 항목  변경 (현행)  보험료 + 이자   (변경) 보험료
      segmentData3: [{ // { 'key': '02', 'label': '보험료+이자' }
        'key': '01',
        'label': '보험료'
      }],
      returnData3: null, //보험료
      returnData4: null,
      segmentData4: [{
        'key': '05',
        'label': '5일'
      },
      {
        'key': '10',
        'label': '10일'
      },
      {
        'key': '15',
        'label': '15일'
      },
      {
        'key': '20',
        'label': '20일'
      },
      {
        'key': '25',
        'label': '25일'
      }],
      segmentData5: [
        {'key': 'N', 'label': '아니요'},
        {'key': 'Y', 'label': '예'}
      ],
      returnData5: null,
      bankSelect: {},
      accontNo: '',
      accountOwner: '',
      userBirthDay: '',
      //newAccountData: {},
      //pSNewAccnRegSVO: {},
      rtpBank: ['004', '003', '011', '012', '088', '071', '023', '081', '027', '020', '039', '034', '031', '032', '037', '035', '045', '209', '240',
        '021', '026', '028', '006', '029', '010', '016', '013', '014', '015', '017', '024', '036', '053', '046', '048', '072', '073', '074', '075', '005', '033', '082',
        '007', '090'
      ], // 실시간출금이체가능 은행코드 (합병은행 추가 3/6 김태형)
      contrInsrdSameYn: '',
      ptclrPrdTId: '', // 특이상품유형코드RD
      pdinsRCCId: '', // 상품보험관계구성코드
      holyDayChkDateArry: [], // 191111, 현재날짜 +10일까지 날짜정보 조회 : 실시간이체일경우 +1영업일 조회용
      // 전자서명 시간 확대에 따른 , 전자서명검증예외시간 체크 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
      elstAccnVrfExcpTm: false, // 전자서명검증예외시간
      vSkipStrTm: '', // 전자서명 계좌 검증 예외_시작 시간
      vSkipEndTm: '', // 전자서명 계좌 검증 예외_종료 시간
      currTm: '', // 현재시간
      test_srcdt: '', // 영업일테스트용(개발,검증용)(20211221_PJO)
      currDt: '', // 현재일자변수화(20211221_PJO)
      rlpadPrm: 0, // 실납입보험료 (23.02.21_PJO)
      accountCheck: '',
      // 초회보험료 신용카드 (ASR240400333_24.05 장한나)
      CARD_YN_CHK : 'N', // 업무컨피그(초회보험료 신용카드)=Y &&  보험료납입유형값RD=C 인경우 가능 && 실납보험료 < 200 만원 인경우 = Y
      paymentTxt : '자동이체', // 자동이체 , 카드이체      
      CARD_PAYMENT_LIST: [], // 카드사 리스트(업모컨피그)
      cardList: [], // 등록카드조회 (F1MDI0438 조회값) 
      currentCardIdx: undefined,
      tempCurrentCardIdx: undefined,
      idx1:0,
      regCardId : '', // 등록한 카드의 카드Id ( MSPPS426P 등록결과  custId 넘겨줌 )
      cardCheck: ''
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {

    //강제로 watch를 일으키기 위해 data에서 null로 초기화후 create에서 다시 초기화
    this.returnData1 = ''
    this.returnData2 = 'Y', //신청
    this.returnData3 = '01', //보험료
    this.returnData4 = ''
    this.returnData5 = 'Y'

    this.planInfo = this.commData.planInfo[0]
    this.inputData = this.commData.inputData
    // 191217 : 예금주 정보 설정
    // this.userData1 = this.commData.userData1
    this.userData1 = this.commData.dpstrInfo.detail
    this.userData1.custRrnId = this.commData.dpstrInfo.detail.knb
    this.userData1.detail = {}
    this.userData1.detail.custNm = this.commData.dpstrInfo.detail.custNm
    this.userData1.detail.cnsltNo = this.commData.dpstrInfo.detail.cnsltNo
    this.userData1.partnerId = this.commData.dpstrInfo.detail.partnerId
    // 1912127 : 예금주 정보 설정 종료

    this.userBirthDay = PSDateUtil.getFullBirthDate(this.userData1.custRrnId, 'yyyy.MM.dd')
    this.txTSSPS13S4Data.custNm = this.accountOwner = this.userData1.detail.custNm
    this.txTSSPS13S4Data.rrn = this.userData1.custRrnId
    this.txTSSPS13S4Data.custAccnRltnCd = '01'
    this.fn_AtRmtnAplYn() // 자동 송금 신청 항목 활성화 분리  (2021.08.17 PJO)
    // 주석처리_start
    // this.viewFlag = false
    // if ( this.planInfo.insrNm.trim().indexOf('즉시연금') !== -1 ) {
    //   this.viewFlag = true
    // }
    // // ASR190700850 올바른성장[태아형] 사랑On가입설계 프로그램 개발 20190806
    // // 태아보험(특이보험유형코드RD=29) & 상품보험관계구성코드RD=60(태아_적립형주보험)有인 경우, 분할보험금 자동송금을 위한 사전정보 입력시 블로킹
    // // 분할·배당·연금/휴면 보험금 자동 송금 신청 항목 활성화
    // this.ptclrPrdTId = this.commData.ptclrPrdtTypCd.trim() // 특이상품유형코드RD
    // this.pdinsRCCId = this.commData.pdinsRCCId.trim() // 상품보험관계구성코드
    // if (this.ptclrPrdTId === '29' && this.pdinsRCCId === '60') {
    //   this.viewFlag = true
    // }
    // 주석처리_end
    this.contrInsrdSameYn = this.commData.contrInsrdSameYn.trim() // 계피상이여부
    this.currDt = PSDateUtil.fn_CurrentDate() // 현재날짜 (20211221_PJO)
    this.fn_Init()
    // this.fn_ServiceCall('txTSSPS13S3', 'di') // 고객계좌목록조회
    // 191111, 영업일 조회
    this.fn_ServiceCall('txTSSPS13S9', 'di') // 영업일 조회 -> 전자서명검증 예외시간 확인 ->  신규계좌등록버튼 보일지 여부 판단
    // 미사용 은행 코드 목록 조회
    // this.fn_ServiceCall('txTSSAD02S5', 'ind')
    this.fn_ServiceCall3()
    // 개발.검증일 경우 업무config의 영업일로 세팅  후  +10일 영업일리스트  재조회 (20211221_PJO)
    // if ( process.env.NODE_ENV !== 'production' ) {
    //   this.fn_ServiceCallTest()
    // }
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    // 실시간 출금이체 선택에 따른 계좌선택 활성,비활성 기능
    returnData1 () {
      if (this.returnData1.length > 0) {
        // 카드리스트선택 후 신용카드실시간납입 아닌 값 선택시 신용카드 실시간 납입 선택 해제 (ASR240400333_24.05 장한나)
        if ( this.currentCardIdx !== undefined  && this.returnData1 !== '9') { 
            this.alertMessage = '보험료납입계좌 은행계좌로 다시 선택하세요.(신용카드 선택 초기화)'
            this.fn_BottomAlert(this.alertMessage)
            this.currentCardIdx = undefined
            this.cardCheck = '' // radio uncheck
            this.returnData1ValidateCmpltFlag = false
            this.nextLevelBtnEnable()
            return
        } 
        // 계좌리스트 선택 후 신용카드실시간납입 선택시 
        else if ( this.currentAccountIdx !== undefined  && this.returnData1  === '9') { 
          this.alertMessage = '보험료납입계좌 카드로 다시 선택하세요.(계좌 선택 초기화)'
          this.fn_BottomAlert(this.alertMessage)
          this.currentAccountIdx = undefined
          this.accountCheck = '' // radio uncheck
          this.returnData1ValidateCmpltFlag = false
          this.nextLevelBtnEnable()
          return
        }

        // ASR190700850 올바른성장[태아형] 사랑On가입설계 프로그램 개발 20190806
        // 태아보험(특이보험유형코드RD=29) & 상품보험관계구성코드RD=60(태아_적립형주보험)有인 경우, 분할보험금 자동송금을 위한 사전정보 입력시 블로킹
        // 제1회(부활) 보험료 실시간 출금이체 항목에서 신청여부 "실시간 출금이체" 外 다른 항목 선택 시 → 입력화면 블로킹
        if (this.ptclrPrdTId === '29' && this.pdinsRCCId === '60') {
          if (this.returnData1 !== '1') { // 실시간이체
            this.alertMessage = '주보험[적립] 선택시,<br />실시간 출금이체만 가능합니다.'
            this.fn_BottomAlert(this.alertMessage)
            this.returnData1 = ''
            this.returnData1ValidateCmpltFlag = false
            this.nextLevelBtnEnable()
            return
          }
        }
        // ASR210800036_즉시연금 자동송금 세팅 기준 변경 (김선모/2021.08.17 PJO)
        // 자동송금여부 true일 경우 & 납입방법 선택이 되었다면
        // RT 인 경우만 자동송금 Y 선택가능
        if (this.atRmtnAplYn) {
          if (this.returnData1.length > 0) {
            if (this.returnData1 === '1') { // RT
              this.viewFlag = true
            } else { // 가상계좌
              this.viewFlag = false
            }
          }
        }

        this.paymentTxt = '자동이체'
        let flag = false
        // 191213 : 최주연프로
        // 가상계좌 선택시에 영업일 안내문구 표시
        if (this.returnData1 === '1') { // 실시간이체
          if ( this.currentAccountIdx !== undefined) {
            for (let i = 0; i <= this.rtpBank.length; i++) {
              if (this.rtpBank[i] === this.accountList[this.currentAccountIdx].bnkCd) {
                flag = true
                break
              }
            }

            if ( flag ) {
              this.returnData1ValidateCmpltFlag = true
              this.nextLevelBtnEnable()
            } else {
              this.alertMessage = '선택하신 은행은<br />가상계좌 이체만 가능합니다.'
              this.fn_BottomAlert4(this.alertMessage)
              this.returnData1ValidateCmpltFlag = false
              this.nextLevelBtnEnable()
            }
          } // 191213 : 최주연프로, 가상계좌 선택시에 영업일 안내문구 표시 종료
        } else {
          if (this.returnData1 === '4') { // 가상계좌
            this.alertMessage = '제1회 보험료 납입방법을 [가상계좌]로 선택시,<br /><br />반드시 당일에 완료할 필요 없이<br />청약서 발행일 + 5영업일 내<br />전자서명 진행이 가능합니다.<br /><br />ᆞ 설계청약현황 內 중단건 조회에서 추후 진행 가능'
          } else if (this.returnData1 === 'F') { // 카카오 페이
            this.alertMessage = '제1회 보험료 납입방법을 [간편납입]으로 선택시,<br /><br />반드시 당일에 완료할 필요 없이<br />청약서 발행일 + 5영업일 내<br />전자서명 진행이 가능합니다.<br /><br />신청 가능 시간 : 23:00<br />입금 가능 시간 : 23:10<br /><br />ᆞ 설계청약현황 內 중단건 조회에서 추후 진행 가능' // \nᆞ 실납입보험료 200만원에 한하여 진행 가능합니다.' // 시간표기변경(ASR230901005_가상,카페20->23)(23.12.05_PJO)
          } else if (this.returnData1 === '9') { // 신용카드
            this.paymentTxt = '카드이체'
            this.alertMessage = '제1회 보험료 납입방법을 [신용카드 실시간 납입]으로 선택시,<br/>' +
                                '제2회 납입방법은 [카드이체] 선택만 가능합니다.<br/><br/>' +
                                '카드이체 신청 이후 자동이체 납입방법 변경을 원하시는 경우<br/>' +
                                '콜센터(1588-3114)를 통하여 진행해 주시기 바랍니다.<br/><br/>'
            this.idx1 += 1 // ★
          }
          this.fn_BottomAlert(this.alertMessage)
          this.returnData1ValidateCmpltFlag = true
          this.nextLevelBtnEnable()
        }
      } else {
        this.returnData1ValidateCmpltFlag = false
        this.nextLevelBtnEnable()
      }
    },
    returnData2 () {
      if (this.returnData2.length > 0) {
        if (this.returnData2 === 'N') {
          this.alertMessage = '제 2회 보험료 자동이체를 신청하지 않는 경우, 전자서명 진행이 불가능합니다.'
          this.fn_BottomAlert(this.alertMessage)
          this.returnData2ValidateCmpltFlag = false
          this.nextBtnFlag = true
        } else {
          this.returnData2ValidateCmpltFlag = true
          this.nextLevelBtnEnable()
        }
      } else {
        this.returnData2ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    returnData3 () {
      if (this.returnData3.length > 0) {
        this.returnData3ValidateCmpltFlag = true
        this.nextLevelBtnEnable()
      } else {
        this.returnData3ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    returnData4 () {
      if (this.returnData4.length > 0) {
        this.returnData4ValidateCmpltFlag = true
        this.nextLevelBtnEnable()
      } else {
        this.returnData4ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    },
    returnData5 () {
      if (this.returnData5.length > 0) {
        if (this.returnData5 !== 'Y') {
          this.alertMessage = '자동 송금 신청 필수입니다.'
          this.fn_BottomAlert(this.alertMessage)
          this.returnData5ValidateCmpltFlag = false
          this.nextBtnFlag = true
        }
        else {
          this.returnData5ValidateCmpltFlag = true
          this.nextLevelBtnEnable()
        }
      } else {
        this.returnData5ValidateCmpltFlag = false
        this.nextBtnFlag = true
      }
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_AtRmtnAplYn
    * 설명       : 자동송금신청 가능여부체크
    * ASR210800036_즉시연금 자동송금 세팅 기준 변경(김선모 / 2021.08.16_PJO)
    * 즉시연금 자동송금 선택 로직을 아래와 같이 일부 수정
    * - 즉시연금 & 계약자=예금주=연금수익자 & 초회보험료 납방 RT 인 경우 ---> 자동송금 Y
    * - 즉시연금 & 그외의 경우 => n
    * 그외 자동송금 Y/N요건은 현재와 동일
    ******************************************************************************/
    fn_AtRmtnAplYn: function () {
      // 자동송금신청여부 : atRmtnAplYn (TTSD00002.AT_RMTN_APL_YN)
      this.viewFlag = false
      this.atRmtnAplYn = false
      let _BeneficiaryInfo = {} // 수익자
      // let _BeneficiaryInfo33_custNm = '' // 연금수익자_명
      let _BeneficiaryInfo33_custRrnId = '' // 연금수익자_주민번호
      if (this.commData.hasOwnProperty('userData3')) {
        _BeneficiaryInfo = this.commData.userData3
        if (_BeneficiaryInfo !== null && _BeneficiaryInfo.length > 0) {
          for (let i = 0; i < _BeneficiaryInfo.length; i++ ) {
            switch (_BeneficiaryInfo[i].bnfrRolCd) {
              case '33': // 연금수익자(기본값:계약자)
                // _BeneficiaryInfo33_custNm = _BeneficiaryInfo[i].custNm
                _BeneficiaryInfo33_custRrnId = _BeneficiaryInfo[i].custRrnId
                break
            }
          }
        }
      }
      if (this.planInfo.insrNm.trim().indexOf('즉시연금') !== -1 && this.commData.dpstrInfo.contrDpstrSameYn === 'Y') {
        if (_BeneficiaryInfo33_custRrnId !== '') {
          // 계.예.수 동일체크
          if (this.commData.userData1.custRrnId === this.commData.dpstrInfo.detail.custRrnId && this.commData.userData1.custRrnId === _BeneficiaryInfo33_custRrnId) {
            this.viewFlag = true
          } else {
            this.viewFlag = false
          }
        }
      }
      // ASR190700850 올바른성장[태아형] 사랑On가입설계 프로그램 개발 20190806
      // 태아보험(특이보험유형코드RD=29) & 상품보험관계구성코드RD=60(태아_적립형주보험)有인 경우, 분할보험금 자동송금을 위한 사전정보 입력시 블로킹
      // 분할·배당·연금/휴면 보험금 자동 송금 신청 항목 활성화
      this.ptclrPrdTId = this.commData.ptclrPrdtTypCd.trim() // 특이상품유형코드RD
      // this.commData.pdinsRCCId = '60' //  삭제할것 임시세팅
      this.pdinsRCCId = this.commData.pdinsRCCId.trim() // 상품보험관계구성코드
      if (this.ptclrPrdTId === '29' && this.pdinsRCCId === '60') {
        this.viewFlag = true
      }
      this.atRmtnAplYn = this.viewFlag // 최초는 viewFlag 기준으로 세팅
    },
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 초기화 - 이체 방법 버튼 표시 제한 처리
    ******************************************************************************/
    fn_Init: function () {
      this.fn_TrsfMthBtn()
    },
    /******************************************************************************
    * Function명  : fn_trsfMthBtn
    * 설명        : 이체 방법 버튼 표시 제한 처리
    ******************************************************************************/
    fn_TrsfMthBtn: function () {
      // 191112.구자학프로
      // 피보험자 서명, 계약자 나중에하기 이후 계약자 서명시 가상계좌만 선택가능
      // -> 실시간 출금이체, 가상계좌 모두 선택가능하게 변경

      // 계약자 = 예금주인 경우만 [가상계좌 이체] 가능함
      // 200128 : 이은영프로

      // (ASR230300107 전자서명 계좌선택화면 개선_20230306)
      // 심사결과조회 ( txTSSPS13S2 /  F1PQL0308)  PSGAFUtil.fn_PIPlanInfo 에서 세팅된다.
      // (ASR230300528_230404.PJO)
      // 1.실납보험료 (polprAbdaprem.polprAldaprem.zzrlpadPrmAm)
      // 2.초회실납입보험료 = 합계보험료 + 선납보험료 - 선납할인P ( abdapolpr.aldapolpr.zzfrtRpdPrmAm)
      // 3.실납보험료 -> 초회실납입보험료 있으면 이 값으로 셋팅
      this.rlpadPrm = this.planInfo.smtotPrm
      if ( this.planInfo.zzrlpadPrmAm !== null && this.planInfo.zzrlpadPrmAm !== undefined ) {
        this.rlpadPrm = this.planInfo.zzrlpadPrmAm
      }
      // console.log('### 합계보험료:' + this.planInfo.smtotPrm )
      // console.log('### 초회실납입보험료:' + this.rlpadPrm )

      // ASR221100288_간편납입(카카오PAY) (장한나/230221 PJO)
      // 계약자 = 예금주
      // 실납입P가 200만원 초과: 실시간이체, 가상계좌
      // 실납입P가 200만원 이하: 실시간이체, 가상계좌, 카카오페이
      if (this.commData.dpstrInfo.contrDpstrSameYn === 'Y') { // 계예동일
        this.segmentData1 = this.segmentData1Mock4
        this.isKakaopay = this.rlpadPrm <= 2000000
      } else {
        // 계약자 != 예금주 : 실시간 출금이체
        this.segmentData1 = this.segmentData1Mock3
      }
    },
    /******************************************************************************
    * Function명  : fn_AccountClick
    * 설명        : 현재 선택된 계좌 index 변경
    ******************************************************************************/
    fn_AccountClick (idx) {
      this.currentAccountIdx = undefined
      this.currentAccountIdx = this.tempCurrentAccountIdx = idx
      this.currentCardIdx = undefined // 신용카드선택 초기화 (ASR240400333_24.05 장한나)
      this.cardCheck = ''
      this.paymentTxt = '자동이체'
      if ( this.returnData1 === '9') {
        this.returnData1 = ''
      }
      // this.addAccnInfo = true // 주석처리 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)

      // ASR190700850 올바른성장[태아형] 사랑On가입설계 프로그램 개발 20190806
      // 태아보험(특이보험유형코드RD=29) & 상품보험관계구성코드RD=60(태아_적립형주보험)有인 경우, 분할보험금 자동송금을 위한 사전정보 입력시 블로킹
      // 예금주≠계약자인 계좌 선택 시 → 입력화면 블로킹
      if (this.ptclrPrdTId === '29' && this.pdinsRCCId === '60') {
        // 20.07 min.ko 계약자명-고객명 동일 여부 체크 로직 제거 ※ this.accountList[this.currentAccountIdx].dpstrNm !== this.commData.inputData.moDCDocuMngVO.custNm
        if (this.accountList[this.currentAccountIdx].custId !== this.userData1.partnerId) {
          this.alertMessage = '주보험[적립] 선택시,<br />예금주와 계약자는 동일해야합니다.'
          this.fn_BottomAlert(this.alertMessage)
          this.currentAccountIdx = this.tempCurrentAccountIdx = undefined
          this.nextLevelBtnEnable()
          return
        }
      }
      if (this.returnData1.length > 0 && this.currentAccountIdx !== undefined ) {
        let flag = false
        for (let i = 0; i <= this.rtpBank.length; i++) {
          if (this.rtpBank[i] === this.accountList[this.currentAccountIdx].bnkCd) {
            flag = true
            break
          }
        }
        if (this.returnData1 === '1') {
          if ( flag ) {
            this.returnData1ValidateCmpltFlag = true
          } else {
            this.alertMessage = '선택하신 은행은<br />가상계좌 이체만 가능합니다.'
            this.fn_BottomAlert4(this.alertMessage)
            this.returnData1ValidateCmpltFlag = false
          }
        }
      }
      // ASR200700610 [전자서명] 현장 개선 요청 사항 반영
      // 20.07 min.ko 기업은행 핸드폰계좌 선택 시 alert 발생
      if (this.accountList[this.currentAccountIdx].bnkCd === '003' && this.accountList[this.currentAccountIdx].accnNo.length >= 10 && this.accountList[this.currentAccountIdx].accnNo.length <= 11) {
        this.alertMessage = '선택하신 은행의 계좌가<br />핸드폰번호 체계의 계좌(평생계좌)인 경우<br />처리 불가하오니, 동일 통장의 본계좌를<br />확인하시고 등록 후 처리 바랍니다.'
        this.fn_BottomAlert(this.alertMessage)
        // this.currentAccountIdx = this.tempCurrentAccountIdx = undefined
        // this.nextLevelBtnEnable()
        // return
      }
      this.$refs.mspExp1.isExpand = false
      this.$refs.mspExp2.isExpand = true
      this.nextLevelBtnEnable()
    },
    /******************************************************************************
    * Function명  : fn_OpenNewAccountReq
    * 설명        : 새로운 계좌 추가 버튼 클릭 시 수행
    ******************************************************************************/
    fn_OpenNewAccountReq () {
      //this.fn_ServiceCall('txTSSAD02S3', 'ind')
      const popMSPPS424P = this.$bottomModal.open(MSPPS424P, {
        properties: {
          userData1: this.userData1, // 예금주
          inputData: this.inputData  //
        },
        
        listeners: {
          // 대상팝업 confirmPopup $emit 이벤트명 
          confirmPopup: (rtnData) => {
            this.accountList.push(rtnData)
            this.addAccnInfo = true
            this.fn_AccountClick(this.accountList.length - 1)
            this.accountCheck = String(this.accountList.length - 1) // radio 체크
            this.$bottomModal.close(popMSPPS424P) // 모달 닫기                        
          },
          // 대상팝업 confirmPopup $emit 이벤트명 
          cancelPopup: () => {
            this.addAccnInfo = !this.elstAccnVrfExcpTm // 전자서명 계좌 검증 예외시간이 아닌 경우만 계좌등록가능  (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
            this.accontNo = ''
            this.bankSelect = {key: '', label: ''}
            this.currentAccountIdx = this.tempCurrentAccountIdx

            this.$bottomModal.close(popMSPPS424P) // 모달 닫기                        
          }
        }
      })
    },
    /******************************************************************************
    * Function명  : fn_OpenNewCardReq
    * 설명        : 새로운 카드 추가 버튼 클릭 시 수행 (ASR240400333_24.05 장한나)
    ******************************************************************************/
    fn_OpenNewCardReq () {
      const popMSPPS426P = this.$bottomModal.open(MSPPS426P, {
        properties: {
          userData1: this.userData1, // 예금주
          CARD_PAYMENT_LIST: this.CARD_PAYMENT_LIST  // 등록가능한 카드사 리스트
        },
        
        listeners: {
          // 대상팝업 confirmPopup $emit 이벤트명 
          confirmPopupCard: (rtnData) => {
            this.cardCheck = ''
            this.regCardId = rtnData.cardId // 등록한 카드의 카드id
            this.fn_ServiceCall ('txTSSPS15S1', 'di') // 카드 정보 재조회
            
            this.$bottomModal.close(popMSPPS426P) // 모달 닫기                        
          }
          // // 대상팝업 confirmPopup $emit 이벤트명 
          // cancelPopupCard: () => {
          //   this.addAccnInfo = !this.elstAccnVrfExcpTm // 전자서명 계좌 검증 예외시간이 아닌 경우만 계좌등록가능  (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
          //   this.currentAccountIdx = this.tempCurrentAccountIdx

          //   this.$bottomModal.close(popMSPPS426P) // 모달 닫기                        
          // }
        }
      })
    },
    /******************************************************************************
    * Function명  : fn_AccChkParam
    * 설명        : 기존계좌 검증 파라미터 생성
    ******************************************************************************/
    fn_AccChkParam () { // fdp-validator(fdp-form-wrapper)에서 result 넘어옴(PASS: true, FAIL: false)
      // 기존계좌 검증
      this.accountChkData = {
        BUKRS: 'I100',
        ZA_TLGM_DTL_DMN_BNK_CD: this.accountList[this.currentAccountIdx].bnkCd,
        ZA_TLGM_RECI_ACCN_NO: this.accountList[this.currentAccountIdx].accnNo,
        ZA_DPST_PESON_NM: this.accountList[this.currentAccountIdx].dpstrNm,
        ZA_DPST_PESON_NO: this.userBirthDay.replace(/\./g, '').substring(2, 8)
      }
      // this.fn_ServiceCall('txTSSPS13K1', 'di') // 위치이동 전자서명 완료시간 확대_사랑온_20200623_PJO)
      //this.newAccountChkFlag = false
      // 현재시간체크 후 진행 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
      let t_This = this
      this.$commonUtil.getServerTime().then(function (response) {
        t_This.currTm = moment(response).format('HHmmss') // 서버시간( return 값. 170838 )
        t_This.fn_ChkSkipTime('accChk', t_This.currTm)
      })
    },
    /******************************************************************************
    * Function명  : fn_UpdPaymtInfo
    * 설명        : 보험료납입정보수정
    ******************************************************************************/
    fn_UpdPaymtInfo () {
      if (!this.alert) {
        this.psBefrInptInfoSVO.vuchId = this.inputData.moDCDocuMngVO.eltrnDoctId
        // this.psBefrInptInfoSVO.bnkAccnId = this.accountList[this.currentAccountIdx].accnId // 은행계좌ID
        this.psBefrInptInfoSVO.frtmPrmPadMthCd = this.returnData1 // 초회보험료납입방법코드
        this.psBefrInptInfoSVO.prmAtrYn = this.returnData2 // 보험료자동이체여부
        this.psBefrInptInfoSVO.trsfObjCd = this.returnData3 // 이체대상코드
        this.psBefrInptInfoSVO.trsfHopDdCd = this.returnData4 // 이체희망일코드
        if (this.viewFlag) {
          this.psBefrInptInfoSVO.atRmtnAplYn = this.returnData5 // 자동송금신청여부
        } else this.psBefrInptInfoSVO.atRmtnAplYn = ' '
        if (this.returnData1 === 'F') { // ASR221100288_간편납입(카카오PAY)(23.02.21_PJO)
          this.psBefrInptInfoSVO.rdamtBusnScCd = 'FKKAO' // 입출금업무구분코드: FKKAO 카카오페이
        }
        // 지급카드ID (ASR240400333_24.05 장한나)
        if ( this.returnData1 === '9') { // 1 RT, 4 가상계좌, F 간편납입, 9 신용카드
          this.psBefrInptInfoSVO.bnkAccnId = ' ' // 은행계좌ID
          this.psBefrInptInfoSVO.crmtBuagCcardOut = this.cardList[this.currentCardIdx].cardId
        } else {
          this.psBefrInptInfoSVO.bnkAccnId = this.accountList[this.currentAccountIdx].accnId // 은행계좌ID
          this.psBefrInptInfoSVO.crmtBuagCcardOut = ' ' // crmtBuagCcardOut
        }
        console.log('>>>>보험료납입정보수정:'+ JSON.stringify(this.psBefrInptInfoSVO)) 
        this.fn_ServiceCall('txTSSPS99U3', 'ind') // 보험료납입정보수정
      }
    },
    nextLevel (event) {
      // 191111 : alert6 알림팝업 삭제 : 장유휘 프로 요청
      //        : 인증 순서 및 여부와 상관업시 초회출금 실시간 자동이체 선택시에만 나타남
      //        : 초회출금 실시간 자동이체 선택 + 계좌정보 입력에서 [다음] 선택시 표시
      //        : [본 전자서명의 계약일자는 **년 **월 **일 입니다. **년 **월 **일 23:00까지만 청약 접수가 가능합니다 (확인)]
      if ( this.commData.nundwYn === 'N' && 
          ((this.currentAccountIdx !== undefined && this.returnData1 === '1')|| // 계좌선택이 되어있고, 실시간 출금이체   선심사여부(nundwYn)가 N인경우도 추가(2024.02.19-선심사PJT)
           (this.currentCardIdx !== undefined && this.returnData1 === '9')) ) {  // 카드선택 && 신용카드실시간납입 선택
        let curYmd = this.currDt // PSDateUtil.fn_CurrentDate() // 현재일자변수화(20211221_PJO)
        let nxtSalesYmd = this.currDt // PSDateUtil.fn_CurrentDate() // 현재일자변수화(20211221_PJO)

        let i = 0
        let holyDayLen = this.holyDayChkDateArry.length
        for ( i; i < holyDayLen; i++ ) {
          // +1영업일 조회
          if ( this.holyDayChkDateArry[i].holyCd === '00') {
            nxtSalesYmd = this.holyDayChkDateArry[i].slrcYmYmd
            break
          }
        }
        // 191209 : 장유휘 프로
        //        : 회계년도마감으로 청약접수일자 표시 팝업 고정
        // 계약일자가 12월, 청약접수 가능일자가 이듬해 1월일 경우, 청약접수가능일을 해당년의 마지막날(12월 31일)로 고정
        // EX ) 본 전자서명의 계약일자는 2019년 12월 31일입니다. 2019년 12월 31일 23:00까지만 청약접수가 가능합니다  // 20:00 ->23:00 ASR201200613_회계년도 마감_사랑온변경(20201222 PJO)
        //
        // ASR211200170_회계년도 마감 관련 시스템 변경사항_가입설계(도유림 / 20211221_PJO)
        // 1) +1영업일이 다음년도일 경우
        // 본 전자서명의 계약일자는 2021년 12월 31일 입니다.
        // 회계연도 마감 등 대내외 정책 변경에 따라 2021년   12월 31일 23:00까지 청약 접수/출금  가능합니다.
        // 2) 그외
        // 본 전자서명의 계약일자는 2021년 12월 30일입니다. 2021년 12월 31일 23:00까지만 청약접수가 가능합니다
        /* ASR221200180_회계년도변경시 별도 로직제거 ( 이현수 / 221220_PJO)
        if ( curYmd.substr(0, 4) !== nxtSalesYmd.substr(0, 4)) {
          nxtSalesYmd = curYmd.substr(0, 4) + '1231'
          this.alert7Message = '본 전자서명의 계약일자는 ' + curYmd.substr(0, 4) + '년 ' + curYmd.substr(4, 2) + '월 ' + curYmd.substr(6, 2) + '일 입니다.\n\n' + '회계연도 마감 등 대내외 정책 변경에 따라\n\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지 청약 접수/출금 가능합니다.'
        } else {
          this.alert7Message = '본 전자서명의 계약일자는 ' + curYmd.substr(0, 4) + '년 ' + curYmd.substr(4, 2) + '월 ' + curYmd.substr(6, 2) + '일 입니다.\n\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지만 청약접수가 가능합니다.'
        }
        */
        this.alertMessage = '본 전자서명의 계약일자는 ' + curYmd.substr(0, 4) + '년 ' + curYmd.substr(4, 2) + '월 ' + curYmd.substr(6, 2) + '일 입니다.<br /><br />' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지만 청약접수가 가능합니다.'
        this.fn_BottomAlert7(this.alertMessage)
      } else {
        this.nextLevelMove(event)
      }
    },
    nextLevelMove (event) {
      if (this.returnData1.length !== 0) {
        if (this.currentCardIdx !== undefined && this.returnData1 === '9') { // ASR240400333_카드납방추가(장한나/240524)
          this.fn_UpdPaymtInfo() // 보험료납입정보수정
        } else if (this.currentAccountIdx === undefined && this.returnData1 === '1') { // 계좌선택이 안되었고 실시간 출금이체시 알럿
          this.alertMessage = '계좌를 선택해주세요!'
          this.fn_BottomAlert(this.alertMessage)
        } else {
          this.fn_AccChkParam() // 기존계좌 검증
        }
      } else {
        this.fn_AccChkParam() // 기존계좌 검증
      }
    },
    // 다음버튼 활성화,비활성화 여부 판단
    nextLevelBtnEnable () {
      if (this.viewFlag) { // 자동송금신청 화면여부(즉시연금상품인 경우 노출되는 화면)
        if ( (this.currentAccountIdx !== undefined || this.currentCardIdx !== undefined ) && this.returnData1ValidateCmpltFlag && this.returnData2ValidateCmpltFlag && this.returnData3ValidateCmpltFlag &&
                this.returnData4ValidateCmpltFlag && this.returnData5ValidateCmpltFlag ) {
          this.nextBtnFlag = false // false 면  다음버튼활성화
        } else {
          this.nextBtnFlag = true
        }
      } else {
        if ( (this.currentAccountIdx !== undefined || this.currentCardIdx !== undefined ) && this.returnData1ValidateCmpltFlag && this.returnData2ValidateCmpltFlag && this.returnData3ValidateCmpltFlag &&
                this.returnData4ValidateCmpltFlag ) {
          this.nextBtnFlag = false
        } else {
          this.nextBtnFlag = true
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ServiceCall2
    * 설명       : 전자서명 계좌 검증 예외 시간 조회
    ******************************************************************************/
    // txTSSAD02S3 업무환경그룹 상세검색
    // (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
    fn_ServiceCall2 () {
      var t_This = this
      let pParams = {}
      t_This.vSkipStrTm = ''
      t_This.vSkipEndTm = ''
      let btnId = 'S'
      pParams = {envId: 'ELST_ACCN_VRF_EXCP_TM'} // 전자서명 계좌 검증 예외 시간
      /*
      .그룹 명/ID  : 전자서명 확대시간 / ELST_ACCN_VRF_EXCP_TM
      .설명: 상세 이름/  값
      1) 시작시간 : STR_TM / 233000
      2) 종료시간 : END_TM / 073000
      */
      this.post(t_This, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            // 전자서명 계좌 검증 예외 시간 리스트 존재, ELST_ACCN_VRF_EXCP_TM
            let vList = response.body.returnDtlList
            if ( vList.length > 0 ) {
              for (const idx in vList) {
                const item = vList[idx]
                let o = {}
                o = Object.assign({}, item)
                o.label = item.smoUnitl // ID  (STR_TM ~ END_TM)
                if (o.label === 'STR_TM' ) { // 시작시간
                  t_This.vSkipStrTm = item.envCntnt // 값 (230000 ~ 070000)
                }
                if (o.label === 'END_TM' ) { // 종료시간
                  t_This.vSkipEndTm = item.envCntnt // 값 (230000 ~ 070000)
                }
              } // for~
            } else {
              console.log('list 값 없읍!')
            }
          }
          t_This.$commonUtil.getServerTime().then(function (response) {
            t_This.currTm = moment(response).format('HHmmss') // 서버시간( return 값. 170838 )
            t_This.fn_ChkSkipTime('init', t_This.currTm)
          })
        })
        .catch(function (error) {
          console.log('■■■ serviceCall2_error.......')
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_ServiceCall3
    * 설명       : 고객계좌목록조회
    ******************************************************************************/
    // txTSSAD02S3 업무환경그룹 상세검색
    // ASR211100077_[전자서명] 미사용은행코드 목록 제외 반영_youngki78.lee
    fn_ServiceCall3 () {
      this.unuseBankList = []
      var lv_Vm = this
      let btnId = 'S'
      let pParams = {}
      pParams = {envId: 'UNUSE_BNK_CD'} // 미사용은행목록
      this.post(lv_Vm, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            lv_Vm.unuseBankList = response.body.returnDtlList
          } else {
            console.log('body 값 없음!')
          }
          lv_Vm.fn_ServiceCall('txTSSPS13S3', 'di') // 고객계좌목록조회
        })
        .catch(function (error) {
          console.log('■■■ serviceCall3_error.......')
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_ChkSkipTime
    * 설명       : 전자서명 계좌 검증 예외 시간일경우 체크
    ******************************************************************************/
    // 전자서명 계좌 검증 예외 시간일경우 체크 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
    // mode : init 화면초기진입시점 , accChk 계좌체크(예금주체크), accAdd  계좌등록
    // vCurrTm :  서버시간 , 170838 (HHmmss)
    fn_ChkSkipTime (mode, vCurrTm) {
      let t_This = this
      t_This.currTm = vCurrTm
      // 시작시간이 현재시간이후이면 true ,  종료시간이 현재시간이전이면 true
      // ex) 233000 <= 233000 || 073000 >= 233000  : true
      // ex) 233000 <= 100000 || 073000 >= 100000  : false
      // console.log('------cvCurrTm:' + vCurrTm)
      // t_This.currTm = '000100' // test
      if ( t_This.vSkipStrTm <= t_This.currTm || t_This.vSkipEndTm >= t_This.currTm ) { // 전자서명 계좌 검증 예외 시간이면 신규계좌추가 버튼 안보이기
        t_This.elstAccnVrfExcpTm = true // 전자서명 계좌 검증 예외 시간 true
        t_This.addAccnInfo = false // 신규계좌추가버튼 안보이고
      } else {
        t_This.elstAccnVrfExcpTm = false // 전자서명 계좌 검증 예외 시간 false
        t_This.addAccnInfo = true
      }

      let vLogTitle = '' // 다이나트레이스 로그 title
      let vLog = '' // 다이나트레이스 로그
      // 영수증번호
      let tmpVuchId = ''
      if (t_This.inputData.moDCDocuMngVO.eltrnDoctId !== 'undefined' && t_This.inputData.moDCDocuMngVO.eltrnDoctId !== undefined) {
        tmpVuchId = t_This.inputData.moDCDocuMngVO.eltrnDoctId
      }
      if ( mode === 'init') { // init 화면초기진입시점
        vLogTitle = '423D_초기진입시점'
      } else if ( mode === 'accChk') { // accChk 계좌체크(예금주체크)
        vLogTitle = '423D_계좌검증'
        if ( t_This.elstAccnVrfExcpTm ) { // 전자서명 계좌 검증 예외 시간 true
          vLog += ',예금주체크_SKIP후 자동이체 계좌 업데이트'
          t_This.fn_UpdPaymtInfo() // 자동이체 계좌 업데이트
        } else {
          vLog += ',예금주체크_진행'
          t_This.fn_ServiceCall('txTSSPS13K1', 'di') // 예금주체크 후 정상이면 -> 자동이체 계좌 업데이트
        }
      }
      vLog += ',현재시간:[' + t_This.currTm + ']'
      vLog += ',전자서명확대시간:[' + t_This.vSkipStrTm + ']이후~[' + t_This.vSkipEndTm + ']이전'
      vLog += ',계좌검증예외여부:[' + t_This.elstAccnVrfExcpTm + ']'
      vLog += ',신규계좌등록가능여부:[' + t_This.addAccnInfo + ']'
      // ASR220600423_사랑온 가설 Dynatracae 연계 라이브러리 수정_20220621
      // PSServiceManager.setDynaTraceLog( vLogTitle, vLog) // event명, vLog
      
      PSServiceManager.setDynaTraceLog(vLogTitle, vLog, 'PS423D', tmpVuchId)
    },
    /******************************************************************************
    * Function명 : fn_ServiceCall
    * 설명       : 서비스 호출 함수
    ******************************************************************************/
    fn_ServiceCall (trnstId, serviceDiv) {
      var lv_Vm = this
      let pParams = {}
      let btnId = 'S'
      let serviceName = ''

      switch (serviceDiv) {
        case 'di':
          switch (trnstId) {
            case 'txTSSPS13S3': // 고객계좌목록 조회
              serviceName = 'S'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.iic'
              pParams.trnstId = 'txTSSPS13S3'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0013_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0013VO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0013VO'
              pParams.data = this.txTSSPS13S4Data
              break
            case 'txTSSPS13K1': // 계좌검증(예금주 체크)
              serviceName = 'K'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.eai'
              pParams.trnstId = 'txTSSPS13K1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1CDE0001_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDE0001ReqVO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDE0001ResVO'
              pParams.data = this.accountChkData
              break
            case 'txTSSPS13S9': // 191111, 현재날짜 +10일까지 날짜정보 조회 : 실시간이체일경우 +1영업일 조회용
              pParams.appId = 'sli.ic'
              pParams.trnstId = ''
              pParams.fnctScCd = 'S'
              pParams.serviceId = ''
              pParams.trnstId = 'txTSSPS13S9'
              pParams.reqSrvcNm = 'ICCommMknoCtlImpl'
              pParams.reqMthdNm = 'selListYmdInfo'
              pParams.reqVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
              pParams.resVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              // pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), +1)}
              pParams.data = {slrcYmYmd: PSDateUtil.addDate(this.currDt, +1)} // 현재일자변수화(20211221_PJO)
              break
            case 'txTSSPS15S1': // F1MDI0438 고객카드목록 조회 (ASR240400333_24.05 장한나)
              serviceName = 'S'
              pParams.srnId = this.$options.screenId // 대표 화면 명
              pParams.appId = 'sli.iic'
              pParams.trnstId = 'txTSSPS15S1'
              pParams.fnctScCd = serviceName
              pParams.serviceId = 'C339_F1MDI0438_S'
              pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0438VO'
              pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0438VO'
              pParams.data = {custId:this.commData.dpstrInfo.detail.partnerId}
              break
            default:
              break
          }
          PSServiceManager.invoke(pParams, lv_Vm.fn_ServiceResultHandler, btnId, lv_Vm.fn_ServiceFaultHandler)
          break

        /********************************************************
        * 자바서비스 호출
        ********************************************************/
        case 'ind':
          switch (trnstId) {
            case 'txTSSPS99U3':
              pParams = this.psBefrInptInfoSVO
              break
            default:
              break
          }

          this.post(lv_Vm, pParams, trnstId, btnId)
            .then(function (response) {
              // 서버 데이터 가져오기
              switch (trnstId) {
                case 'txTSSPS99U3': // 보험료납입정보 업데이트(자동이체 정보 업데이트)
                  if (response.body !== null) {
                    // lv_Vm.$emit('move', 'app')
                    lv_Vm.$emit('move', 'next', {'frtmPrmPadMthCd': lv_Vm.returnData1}) // 초회보험료납입방법코드를 MSP420M화면에 전달함.(MSP424D화면에서 사용함.) (2024.05.28-선심사PJT)
                  }
                  break
                default:
                  break
              }
            })
            .catch(function (error) {
              lv_Vm.error(error)
            })
          break
        default:
          break
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler (lv_Vm, pParams, moreData) {
      let trnstId = lv_Vm.common.fahrTrnId

      if (lv_Vm.data !== null) {
        switch (trnstId) {
          case 'txTSSPS13S3': // 고객계좌목록조회
            if ( lv_Vm.data !== null && lv_Vm.data.cmcustAccnInfoListVO.length > 0 ) {
              for ( let i = 0; i < lv_Vm.data.cmcustAccnInfoListVO.length; i++ ) {
                if ( lv_Vm.data.cmcustAccnInfoListVO[i].accnScCd === '01' && lv_Vm.data.cmcustAccnInfoListVO[i].accnStatCd === '00' ) {
                  // ASR200700610 [전자서명] 현장 개선 요청 사항 반영
                  // 20.07 min.ko 기업은행(은행코드 003) 10-11자리 계좌는 목록에서 제외
                  if (lv_Vm.data.cmcustAccnInfoListVO[i].bnkCd === '003' && lv_Vm.data.cmcustAccnInfoListVO[i].accnNo.length >= 10 && lv_Vm.data.cmcustAccnInfoListVO[i].accnNo.length <= 11) continue
                  lv_Vm.data.cmcustAccnInfoListVO[i].custId = lv_Vm.data.custId
                  let t_unuseBankList = this.unuseBankList
                  let inUnuse = false
                  if (piCommonUtil.isObj(t_unuseBankList)) {
                    for (let j = 0; j < t_unuseBankList.length; j++) {
                      if (lv_Vm.data.cmcustAccnInfoListVO[i].bnkCd === t_unuseBankList[j].envCntnt) {
                        inUnuse = true
                        break
                      }
                    }
                  }
                  if (!inUnuse) {
                    this.accountList.push(lv_Vm.data.cmcustAccnInfoListVO[i])
                  }
                }
              }
            }
            this.accountList.sort(
              function (a, b) {
                return b.trtYmd.localeCompare(a.trtYmd)
              }
            )

            if (this.accountList.length === 0) {
              this.alertMessage = '은행 계좌 정보가 없습니다.'
              this.fn_BottomAlert(this.alertMessage)
            }

            // 카드납방을 위한 카드 정보조회 (은행계좌목록 조회 후 카드목록 조회한다.) /(ASR240400333_24.05 장한나)
            this.fn_CardInfo ()
            break
          case 'txTSSPS13K1': // 예금주체크
            if (lv_Vm.data.za_RFND_YN === 'Y') {
              // let obj = {
              //   bnkCdNm: this.bankSelect.label,
              //   accnNo: this.accountChkData.ZA_TLGM_RECI_ACCN_NO,
              //   dpstrNm: lv_Vm.data.za_DPST_PESON_NM // I/F 결과값 > 예금주명
              // }
              // this.newAccountData = obj
              this.fn_UpdPaymtInfo() // 기존계좌 검증
            } else {
              this.alertMessage = lv_Vm.data.natxt
              this.fn_BottomAlert(this.alertMessage)
            }
            break
          case 'txTSSPS13S9': // 191111, 현재날짜 +10일까지 날짜정보 조회 : 실시간이체일경우 +1영업일 조회용
            if (lv_Vm.data !== null && lv_Vm.data.icymdMngVO.length > 0) {
              this.holyDayChkDateArry = lv_Vm.data.icymdMngVO
            }
            this.fn_ServiceCall2() // 전자서명 계좌 검증 예외 시간 확인 (ASR200501007_전자서명 완료시간 확대_사랑온_20200623_PJO)
            break
          case 'txTSSPS15S1': // txTSSPS15S1 등록카드조회 F1MDI0438 // (ASR240400333_24.05 장한나)
          /*
    "custId": "0412742771",
    "listCardInfoVO": [
        {
            "cardId": "000002", // 지급카드 ID
            "cardType": "0001", // 지급카드 유형
            "cardNum": "379183689420724", // 카드 번호
            "carddefault": " ",
            "creditCardNm": " ",
            "validTo": "2024.04.25", // 지급카드: 효력 시작일 
            "validFrom": "9999.12.31", // 지급카드: 효력 종료일 
            "maskNum": " ",
            "stampNm": "이은별", // 지급카드: 카드 소유자 이름
            "identificationNum": " ",
            "issuer": " ",
            "issuingDate": "00000000",
            "cardMnChkYn": "Y",
            "bpCardStatCd": "01", // BP카드상태코드
            "cardAvlYm": "202506", // 카드유효년월
            "cardTypeNm": "삼성카드", // 카드회사명
            "bpCardStatCdNm": "정상"
        }
    ]
          */
            this.cardList = []
            let tmpIdx = -1

            // this.CARD_PAYMENT_LIST
            if ( lv_Vm.data !== undefined && lv_Vm.data.listCardInfoVO !== null && lv_Vm.data.listCardInfoVO.length > 0 && (lv_Vm.data.custId === this.commData.dpstrInfo.detail.partnerId) ) {
              for ( let i = 0; i < lv_Vm.data.listCardInfoVO.length; i++ ) {
                if ( lv_Vm.data.listCardInfoVO[i].bpCardStatCd === '01') { // BP카드상태코드 01 정상  /02 불량 /03 거래중지
                  // 유효기간 이내카드만 가능 ( - 오늘 이후만 true )
                  if ( !this.chkDate(lv_Vm.data.listCardInfoVO[i].validFrom) )  continue // 효력 종료일
                  if ( !this.chkDate(lv_Vm.data.listCardInfoVO[i].cardAvlYm + '31') ) continue // 카드유효년월+'31'

                  for (let j = 0; j < this.CARD_PAYMENT_LIST.length; j++) {
                    if( this.CARD_PAYMENT_LIST[j].name !== lv_Vm.data.listCardInfoVO[i].cardTypeNm) continue
                    // 업무컨피그.등록된 카드사명과 동일한 경우만 카드납방가능
                    this.cardList.push(lv_Vm.data.listCardInfoVO[i])
                    // 새로운카드 등록 ->카드정보재조회->등록한 카드 선택
                    if(this.regCardId !== '' && this.regCardId !== null && this.regCardId !== undefined &&  lv_Vm.data.listCardInfoVO[i].cardId === this.regCardId ) {
                      tmpIdx = i
                    }
                  }
                }
              }
            }
            if(this.regCardId !== '' && this.regCardId !== null && this.regCardId !== undefined && tmpIdx !== -1 ) {
              this.fn_CardClick( tmpIdx)
              this.regCardId = '' // ★초기화
            }
            /* 카드리스트 없어도 메세지 안보이기 (장한나 프로 요청)
            if (this.cardList.length === 0) {
              this.alertMessage += '\n카드 정보가 없습니다'  
              this.fn_BottomAlert(this.alertMessage)
            }*/
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ServiceFaultHandler
    * 설명       : 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler (event) {
      if (event.msgCd === 'EEAC928') {
        event.msgDesc = event.msgDesc.substring(event.msgDesc.indexOf('은행'), event.msgDesc.length)
        
        // 은행계좌목록 전혀 없는경우 PO 호출후 ERROR 인경우도 존재하여, 은행계좌목록 오류시 "카드납방을 위한 카드 정보조회" 호출 여기서도 필요함
        this.fn_CardInfo ()
      }
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },
    /**
    * 영업일 컨피그값으로 세팅
    * (개발,검증 테스트용)
    * (20211221_PJO)
    */
    fn_serviceCallTest () {
      var lv_Vm = this
      let btnId = 'S'
      let pParams = {}
      pParams = {envId: 'ELST_TEST_SRCDT'} // 전자서명_테스트_영업일
      this.post(lv_Vm, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            let vList = response.body.returnDtlList
            if ( vList.length > 0 ) {
              for (const idx in vList) {
                const item = vList[idx]
                let o = {}
                o = Object.assign({}, item)
                o.label = item.smoUnitl // ID
                if (o.label === 'SRCDT' ) {
                  lv_Vm.test_srcdt = item.envCntnt // 값
                  console.log('■■■ lv_Vm.test_srcdt:' + lv_Vm.test_srcdt)

                  lv_Vm.currDt = lv_Vm.test_srcdt
                  lv_Vm.fn_ServiceCall('txTSSPS13S9', 'di') // 영업일재조회
                }
              } // for~
            } else {
              console.log('list 값 없읍!')
            }
          }
        })
        .catch(function (error) {
          console.log('■■■ serviceCallTest_error.......')
          window.vue.error(error)
        })
    },
    
    /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업
    * this.fn_BottomAlert(this.alertMessage) 를 사용할 경우 <br />이 많을경우 스크롤이 생긴다.
    ******************************************************************************/
    fn_BottomAlert (pContent) {
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_AlertPop)
          },
         onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },
    /******************************************************************************
    * Function명  : fn_BottomAlert4
    * 설명        : 확인 팝업 - MSPPS423D의 fdp-confirm4 컨버전
    ******************************************************************************/
    fn_BottomAlert4 (pContent) {
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.returnData1 = '4' //가상계좌 이체
            this.$bottomModal.close(lv_AlertPop)
          },
         onPopupClose: () => {
            this.returnData1 = '4' //가상계좌 이체
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },

    /******************************************************************************
    * Function명  : fn_BottomAlert7
    * 설명        : 확인 팝업 - MSPPS423D의 fdp-confirm7 컨버전
    ******************************************************************************/
    fn_BottomAlert7 (pContent) {
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.nextLevelMove()
            this.$bottomModal.close(lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },

    /******************************************************************************
    * Function명  : fn_BottomConfirm
    * 설명        : Confirm 팝업
    ******************************************************************************/
    fn_BottomConfirm (contents) {
      const lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: "알림",
          content: contents,
          title_pos_btn: '확인',
          title_neg_btn: '취소'
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(lv_ConfirmPop)
            this.$emit('move', 'previous')
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(lv_ConfirmPop)
          }
        }
      })
    },
    /******************************************************************************
    * 초회납입방법 신용카드납 on/off 체크 (ASR240400333_24.05 장한나)
      CARD_YN_CHK : Y / N  -  업무컨피그(초회보험료 신용카드)=Y &&  보험료납입유형값RD=C 인경우 가능
    ******************************************************************************/
    fn_CARD_YN () {
      // 로컬강제테스트
      // this.lv_InputData.zzPrmPadTVId = 'C'
      let lv_Vm = this
      let btnId = 'S'
      let CARD_YN = ''
      lv_Vm.CARD_YN_CHK = 'N'
      let pParams = {}
      pParams = {envId: 'CARD_YN',sno: '1'} // 초회보험료 신용카드 가능여부
      this.post(lv_Vm, pParams, 'txTSSAD02S4', btnId)
        .then(function (response) {
        if (response.body !== null) {
           let returnVo = response.body
           if( !lv_Vm.$bizUtil.isEmpty(returnVo.envCntnt)) { // 값 ENV_CNTNT
            CARD_YN = returnVo.envCntnt
            if ( !lv_Vm.$bizUtil.isEmpty( lv_Vm.commData.prmPadTVId ) &&  (lv_Vm.commData.prmPadTVId === 'C' && CARD_YN === 'Y') ) {
              lv_Vm.CARD_YN_CHK = 'Y'
            }
          }
          console.log('업무컨피그.CARD_YN:' + CARD_YN +' /CARD_YN_CHK:' + lv_Vm.CARD_YN_CHK)
          // 고객카드목록 조회 txTSSPS15S1 /F1MDI0438 -> fn_ServiceResultHandler 
          if (lv_Vm.CARD_YN_CHK === 'Y' ) {
            lv_Vm.segmentData1.push({'key': '9','label': '신용카드 실시간납입'} )
            lv_Vm.fn_CARD_PAYMENT_LIST()   // 업무컨피그. 카드사목록 (CARD_PAYMENT_LIST)
          }  else {console.log ('업무컨피그.CARD_YN = N') }
        }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * 초회납입방법 신용카드  종류 
      업무컨피그 조회 : CARD_PAYMENT_LIST 초회보험료 카드납 관리
    ******************************************************************************/
    fn_CARD_PAYMENT_LIST () {
      let lv_Vm = this
      let btnId = 'S'
      lv_Vm.CARD_PAYMENT_LIST = []
      let pParams = {}
      pParams = {envId: 'CARD_PAYMENT',sno: '1'} // 초회보험료 카드납 관리 CARD_PAYMENT_LIST
      this.post(lv_Vm, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            let responseList = response.body.returnDtlList

            for (let i = 0; i < responseList.length; i++) {
              let list = {}
              list.name = responseList[i].smoUnitl // 예) 삼성카드
              list.code = responseList[i].envCntnt // 예) 365
              lv_Vm.CARD_PAYMENT_LIST.push(list)
            }
          } else {
            console.log('CARD_PAYMENT.list X')
          }
          // ~for
          // 고객등록 카드리스트 조회 / txTSSPS15S1 /F1MDI0438 -> fn_ServiceResultHandler (txTSSPS13S3 이후에 조회하기)
          lv_Vm.fn_ServiceCall ('txTSSPS15S1', 'di')
        }
        )
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    fn_CardInfo 카드 정보 조회 /(ASR240400333_24.05 장한나)
    (호출: txTSSPS13S3 고객은행계좌목록 이후에 조회됨)
    
    .신용카드 납입 가능 조건
    계예동일 && 보험료납입유형값RD === C (순수보장형) && 실납보험료 200 이하인경우만 && 업무컨피그.초회보험료 신용카드 가능여부=Y( CARD_YN ) && 선납횟수 (심사결과 -zzfrtmPrpaNVl ) 가 없어야됨.

    .function 순서
    1) 업무컨피그.초회보험료 신용카드 가능여부 ( CARD_YN ) this.fn_CARD_YN() / CARD_YN == Y이면
    2) 업무컨피그.초회보험료 카드납 관리 ( CARD_PAYMENT_LIST )  this.fn_CARD_PAYMENT_LIST()
    3) 고객등록 카드리스트 조회 (txTSSPS15S1 /F1MDI0438 -> fn_ServiceResultHandler )
    ******************************************************************************/
    fn_CardInfo() {
      let _zzfrtmPrpaNVl= 0
      if ( this.planInfo.zzfrtmPrpaNVl !== null && this.planInfo.zzfrtmPrpaNVl !== undefined ) _zzfrtmPrpaNVl = this.planInfo.zzfrtmPrpaNVl
      console.log('▶423D.created:계예동일여부:'+ this.commData.dpstrInfo.contrDpstrSameYn + '/보험료납입유형값RD:' + this.commData.prmPadTVId + '/ 실납보험료:'+ this.rlpadPrm + '/ 선납횟수:'+ _zzfrtmPrpaNVl)
      if (this.commData.dpstrInfo.contrDpstrSameYn !== 'Y' || this.$bizUtil.isEmpty( this.commData.prmPadTVId) || this.commData.prmPadTVId !== 'C' || this.rlpadPrm > 2000000 || _zzfrtmPrpaNVl > 0 ) {
        return false
      }
      this.fn_CARD_YN()
    },
    /******************************************************************************
     vDate 가 오늘 이후만 true ( 오늘포함)
     @vDate 비교일자
    ******************************************************************************/
    chkDate (vDate) {
      let result = false
      // vDate = '' //
      if ( this.$bizUtil.isEmpty(vDate))  return result // 빈값이면 false 리턴

      vDate = vDate.replace(/[.]/g, '')
      if ( this.currDt <= vDate  ) result = true // 오늘과 같거나 미래일자만 체크
      // console.log('vDate['+ vDate +'] result [' +result +']')

      return result
    },
    /******************************************************************************
    * Function명  : fn_CardClick
    * 설명        : 현재 선택된 카드 index 변경
    ******************************************************************************/
    fn_CardClick (idx) {
      this.currentAccountIdx = undefined // 선택된 계좌 초기화
      this.accountCheck = ''
      this.currentCardIdx = undefined
      this.currentCardIdx = this.tempCurrentCardIdx = idx
      this.cardCheck = String(idx) // radio 체크
      this.$refs.mspExp1.isExpand = false
      this.$refs.mspExp2.isExpand = true

      // 카드선택시 신용카드 선택 / 20일 선택
      this.paymentTxt = '카드이체'
      this.returnData1 = '9' // 초회납방.신용카드
      this.returnData4 = '20' // 이체희망일코드
      this.returnData1ValidateCmpltFlag = true
      this.nextLevelBtnEnable()
    },
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>